<template>
  <topo :titulo="'Ficha por Modelo'"></topo>
  <div>
    <div class="container-fluid mt-3">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <stats-card>
            <div class="row">
              <div class="col-sm-6">
                <label>Modelo</label>
                <select class="form-select">
                  <option v-for="modelo in state.modelos" :key="modelo.id">
                    {{ modelo.nome_modelo }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <label>Observação do treino</label>
                <textarea class="form-control" rows="6"></textarea>
              </div>
            </div>

            <button
              class="btn btn-success"
              @click="salvarFicha"
              :disabled="state.isLoading"
              style="margin-top: 10px"
            >
              Continuar
            </button>
            <button
              class="btn btn-danger"
              @click="cancelarFicha"
              :disabled="state.isLoading"
              style="margin-top: 10px"
            >
              Cancelar
            </button>
          </stats-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive } from "vue";
import services from "../services";
import CorpoBuscaCliente from "../components/CorpoBuscaClientes";
import { useRouter } from "vue-router";
//import Modal from "../components/EstruturaModal/EstruturaModal.vue";
import { useStorage } from "vue3-storage";
import { pt } from "date-fns/locale";
import Datepicker from "vue3-datepicker";
import topo from "../components/topo";
export default {
  //components: { Modal },
  setup(_, { emit }) {
    // var isOpen = ref(false);
    const storage = useStorage();
    var data_atual = new Date();
    var dd = String(data_atual.getDate()).padStart(2, "0");
    var mm = String(data_atual.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = data_atual.getFullYear();

    data_atual = dd + "/" + mm + "/" + yyyy;
    /*
    var nome_usuario = storage.getStorageSync("nome_pessoa");
    var id_func_usuario = storage.getStorageSync("colaborador_id");
    
    
*/
    var empresa_id = storage.getStorageSync("empresa_id");
    var token = storage.getStorageSync("token");

    const router = useRouter();

    function cancel() {
      router.push({ name: "fichasTreino" });
    }

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      ficha_id: "",
      isOpenModalMusculo: false,
      data_inicial: new Date(),
      modelos: [],
    });

    onMounted(() => {
      fetchfichas();
      /* state.ficha_id = router.currentRoute._value.params.id;
      state.alterar = router.currentRoute._value.params.alterar;
      fetchCategoria();
      fetchMusculos();

      if (state.alterar != null) {
        carregarFicha(state.ficha_id);
      }*/
    });

    function cancelarFicha() {
      console.log("aqui");
    }

    async function salvarFicha() {
      state.isLoading = false;
      try {
        const { errors } = await services.ficha.saveTreinosFicha({
          empresa_id: empresa_id,
          token: token,
          treinos: state.treinos,
          ficha_id: state.ficha_id,
        });

        if (!errors) {
          state.isLoading = false;
          router.push({ name: "fichasTreino" });
          return;
        }
        //   router.push({ name: "fichasTreino" });
        state.isLoading = false;
      } catch {
        state.isLoading = false;
      }
    }

    async function fetchClientes() {
      try {
        state.isLoading = true;
        const { data } = await services.clientes.clientesPorNome({
          empresa_id: empresa_id,
          token: token,
          nome: state.nome,
        });
        console.log(data);
        //  console.log(data)
        state.clientes = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function fetchfichas() {
      try {
        state.isLoading = true;
        const { data } = await services.ficha.getModeloAll({
          token: token,
          empresa_id: empresa_id,
        });
        state.modelos = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function fetchMusculos() {
      try {
        state.isLoading = true;
        const { data } = await services.ficha.getGruposMuscular({
          empresa_id: empresa_id,
          token: token,
        });
        console.log(data);
        //  console.log(data)
        state.musculos = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }

    async function handleSubmit() {}
    return {
      topo,
      state,
      salvarFicha,
      handleSubmit,
      fetchClientes,
      CorpoBuscaCliente,
      emit,
      cancel,
      Datepicker,
      pt,
      fetchMusculos,
      cancelarFicha,
    };
  },
};
</script>

<style scoped>
textarea {
  resize: none;
}
input {
  border-color: #b8b8b8;
  border-width: 1px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
}
input:focus {
  border-color: #252525;
}
select {
  border-color: #b8b8b8;
  border-width: 1px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
  margin-bottom: 10px;
}
select:focus {
  border-color: #252525;
}
.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}
.btn_salvar:hover {
  transform: none;
  color: #fff;
}
.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}
.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}
.div_s {
  height: 350px;
  overflow-y: scroll;
}
.btn_fechar {
  color: #fff;
  background-color: #788596;
}
.btn_fechar:hover {
  color: #fff;
  background-color: #788596;
  transform: none;
}
.btn_lupa {
  background-color: #aede00;
  padding: 10px;
  border-color: #aede00 solid 1px !important;
  color: rgb(0, 0, 0) !important;
  border-radius: 10px !important;
}
.btn_lupa:hover {
  transform: none;
}
.btn_transparente {
  border: none;
  background: none;
  color: #fff;
  margin-top: -5px;
  margin-left: 12pt;
}
.icone_azul {
  color: #034aa6;
  font-size: 12pt;
}

.btn_novo_musc {
  width: 40px;
  height: 40px;
  padding: 0px !important;
  border-radius: 50%;
  color: #fff;
}
.btn_novo_musc {
  font-size: 14pt;
}

.btn_novo {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}
.btn_add_grupo {
  color: #ffff;
  background: #034aa6 !important;
  border: #034aa6 solid 1px !important;
}
.btn_add_grupo:hover {
  color: #fff;
}

.btn_trash {
  background: #d93a3a;
  color: #fff;
  padding: 5px 7px;
}
.btn_trash:hover {
  color: #fff;
}

.btn_alterar {
  background: #034aa6;
  color: #fff;
  padding: 5px 7px;
}

.btn_alterar:hover {
  color: #fff;
}
i {
  font-size: 12pt;
}
</style>