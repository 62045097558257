export default httpClient => ({
  getAll: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/despesas-fixas/' + empresa_id, { headers })
    return { data: response.data }
  },
  getById: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/seleciona/despesa-fixa/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },


  insertViaContaPagar: async ({ token, empresa_id, recorrencia, nome, valor }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/cria/despesa-fixa/' + empresa_id, {
      token,
      recorrencia,
      nome,
      valor,
      status: "ativo",
    }, { headers })
    return { data: response.data }
  },

  insert: async ({ token, empresa_id, recorrencia, nome, valor, status }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/cria/despesa-fixa/' + empresa_id, {
      token,
      recorrencia,
      nome,
      valor,
      status,
    }, { headers })
    return { data: response.data }
  },
    update: async ({ empresa_id, token, id, recorrencia, nome, valor, status }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/edita/despesa-fixa/' + empresa_id, {
      token,
      id,
      recorrencia,
      nome,
      valor,
      status
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
      deletar: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/deleta/despesa-fixa/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },
})