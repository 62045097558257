const defaultPagination = {
  limit: 5,
  offset: 0
}
export default httpClient => ({
  listar: async ({ token, empresa_id } = defaultPagination) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/grupo-muscular/' + empresa_id, {} ,{ headers });
    return { data: response.data }
  },

  obterPorId: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/seleciona/grupo-muscular/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },


  qtdTotal: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/qtd-avaliacoes/' + empresa_id, {
      token: token
    }, { headers })
    return { data: response.data }
  },

  save: async ({ empresa_id, token, formGrupo }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/web/cria/grupo-muscular/' + empresa_id, formGrupo, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

    update: async ({ empresa_id, token, formGrupo }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/web/edita/grupo-muscular/' + empresa_id, formGrupo, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  deletar: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/deleta/grupo-muscular/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },

})