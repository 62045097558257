<template>
  <barra-lateral />
  <div style="background-color: #f8f9fe; min-height: 100vh">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <div>
            <topo :titulo="'Caixas'" @openOffcanvas="openOffcanvas"></topo>
            <base-header
              type="gradient-success"
              class="pb-md-6 pb-md-8 pt-md-3"
            >
              <div class="row">
                <div class="col-12 div_btn_caixa d-md-none">
                  <button
                    type="button"
                    class="btn_buscar"
                    @click="state.isOpen = true"
                  >
                    Abrir Caixa
                  </button>
                </div>
              </div>

              <div class="row linha2">
                <div class="col-lg-3 col-6">
                  <label>Data Inicial</label>
                  <Datepicker
                    v-model="state.data_inicial_busca"
                    :locale="pt"
                    inputFormat="dd/MM/yyyy"
                    style="
              display: block;
              width: 100%;
              height: calc(1.5em + 1.25rem + 2px);
              padding: 0.625rem 0.75rem;
              font-size: 0.875rem;
              font-weight: 400;
              line-height: 1.5;
              color: #8898aa;
              background-color: #fff;
              background-clip: padding-box;
              border: 1px solid #cad1d7;
              border-radius: 0.375rem;
              box-shadow: none;
              transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
              }
            "
                  />
                </div>
                <div class="col-lg-3 col-6">
                  <label>Data Final</label>
                  <Datepicker
                    v-model="state.data_final_busca"
                    :locale="pt"
                    inputFormat="dd/MM/yyyy"
                    style="
              display: block;
              width: 100%;
              height: calc(1.5em + 1.25rem + 2px);
              padding: 0.625rem 0.75rem;
              font-size: 0.875rem;
              font-weight: 400;
              line-height: 1.5;
              color: #8898aa;
              background-color: #fff;
              background-clip: padding-box;
              border: 1px solid #cad1d7;
              border-radius: 0.375rem;
              box-shadow: none;
              transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
              }
            "
                  />
                </div>
                <div class="col-lg-3 col-12">
                  <label>Status</label>
                  <select
                    type="text"
                    class="form-select"
                    placeholder="Status"
                    v-model="state.status_busca"
                  >
                    <option value="">Todos</option>
                    <option value="aberto">Aberto</option>
                    <option value="fechado">Fechado</option>
                  </select>
                </div>
                <div class="col-lg-3 col-12">
                  <button
                    type="primary"
                    class="btn_buscar"
                    @click="buscaDataCaixa()"
                  >
                    Buscar
                  </button>
                </div>
                <div class="col-lg-3 col-6 div_btn_caixa d-none d-md-block">
                  <button
                    style="margin-top: 23px"
                    type="button"
                    class="btn_buscar"
                    @click="state.isOpen = true"
                  >
                    Abrir Caixa
                  </button>
                </div>
              </div>

              <!-- Card stats -->
              <div class="row">
                <div class="col-xl-3 col-lg-6" v-if="caixoAberto">
                  <card class="card-stats">
                    <div class="quad">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-safe"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M1 1.5A1.5 1.5 0 0 1 2.5 0h12A1.5 1.5 0 0 1 16 1.5v13a1.5 1.5 0 0 1-1.5 1.5h-12A1.5 1.5 0 0 1 1 14.5V13H.5a.5.5 0 0 1 0-1H1V8.5H.5a.5.5 0 0 1 0-1H1V4H.5a.5.5 0 0 1 0-1H1V1.5zM2.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5h12a.5.5 0 0 0 .5-.5v-13a.5.5 0 0 0-.5-.5h-12z"
                        />
                        <path
                          d="M13.5 6a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5zM4.828 4.464a.5.5 0 0 1 .708 0l1.09 1.09a3.003 3.003 0 0 1 3.476 0l1.09-1.09a.5.5 0 1 1 .707.708l-1.09 1.09c.74 1.037.74 2.44 0 3.476l1.09 1.09a.5.5 0 1 1-.707.708l-1.09-1.09a3.002 3.002 0 0 1-3.476 0l-1.09 1.09a.5.5 0 1 1-.708-.708l1.09-1.09a3.003 3.003 0 0 1 0-3.476l-1.09-1.09a.5.5 0 0 1 0-.708zM6.95 6.586a2 2 0 1 0 2.828 2.828A2 2 0 0 0 6.95 6.586z"
                        />
                      </svg>
                    </div>
                    <p class="title2">Caixas Abertos</p>
                    <div class="num">{{ state.caixas_abertos }}</div>
                  </card>
                </div>
                <div class="col-xl-3 col-lg-6" v-if="caixaLocal">
                  <card class="card-stats">
                    <div class="quad">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-bank"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.501.501 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89L8 0ZM3.777 3h8.447L8 1 3.777 3ZM2 6v7h1V6H2Zm2 0v7h2.5V6H4Zm3.5 0v7h1V6h-1Zm2 0v7H12V6H9.5ZM13 6v7h1V6h-1Zm2-1V4H1v1h14Zm-.39 9H1.39l-.25 1h13.72l-.25-1Z"
                        />
                      </svg>
                    </div>
                    <p class="title2">Locais</p>
                    <div class="num">{{ state.locais_caixa }}</div>
                  </card>
                </div>
              </div>
            </base-header>
            <div>
              <div
                class="card shadow p-3"
                :class="type === 'dark' ? 'bg-default' : ''"
                s
              >
                <div class="row">
                  <div class="col">
                    <div class="table-responsive">
                      <caixa-loading v-if="state.isLoading" />
                      <table
                        v-else
                        class="table tablesorter table align-items-center table-flush"
                      >
                        <thead class="thead-light">
                          <th>Código</th>
                          <th>Data Inicial</th>
                          <th>Data Final</th>
                          <th>Status</th>
                          <th></th>
                        </thead>
                        <tbody class="list">
                          <corpo-caixa
                            v-for="caixa in state.caixas"
                            :key="caixa.id"
                            :caixa="caixa"
                            :width="'800px'"
                            @modalVisualizarCaixa="modalVisualizarCaixa"
                          />
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    :open="state.modal_escolher_cartao"
    @close="state.modal_escolher_cartao = !state.modal_escolher_cartao"
    :width="'400px'"
  >
    <div style="padding: 20px">
      <h4>Escolher Bandeiras</h4>
      <div class="row">
        <div class="col-sm-12">
          <label>Bandeira</label>
          <select class="form-select" v-model="state.bandeira" id="bandeira">
            <select-bandeiras
              v-for="bandeira in state.bandeiras"
              :key="bandeira.id"
              :bandeira="bandeira"
            />
          </select>
        </div>
      </div>

      <div class="row botoes_modal">
        <div class="col-sm-6">
          <button
            type="button"
            class="btn btn-danger largura_total"
            @click="fecharModal('cartao')"
          >
            Fechar
          </button>
        </div>
        <div class="col-sm-6">
          <button
            type="button"
            class="btn btn-primary largura_total"
            @click="escolherCartao()"
          >
            Escolher
          </button>
        </div>
      </div>
    </div>
  </Modal>
  <Modal
    :open="state.isOpen"
    :width="'800px'"
    @close="state.isOpen = !state.isOpen"
  >
    <div class="modal-caixa">
      <h1>Abrir Caixa</h1>
      <div class="row">
        <div class="col-sm-3">
          <label>Data</label>
          <input
            type="text"
            class="form-control"
            v-model="state.data_cadastro"
          />
        </div>
        <div class="col-sm-9">
          <label>Funcionario cadastrante</label>
          <div class="row">
            <div class="col-4 col-md-3">
              <div class="form-group">
                <input
                  readonly
                  type="text"
                  class="form-control"
                  v-model="state.id_cadastrante"
                />
              </div>
            </div>
            <div class="col-8 col-md-9">
              <div class="form-group">
                <input
                  readonly
                  type="text"
                  class="form-control"
                  v-model="state.nome_cadastrante"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <label>Local de Contas</label>
          <select type="text" class="form-select" v-model="state.local_contas">
            <option
              v-for="local_conta in state.locais_conta"
              :key="local_conta.id"
              :value="local_conta.id"
            >
              {{ local_conta.nome }}
            </option>
          </select>
          <button class="btn btn_salvar" type="button" @click="handleSubmit">
            Salvar
          </button>
          <button
            class="btn btn_cancelar my-4"
            type="button"
            @click="fecharModal"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </Modal>
  <Modal
    :open="state.isOpenVisualizar"
    @close="state.isOpenVisualizar = !state.isOpenVisualizar"
    :width="'800px'"
  >
    <h1>Fechamento de Caixa</h1>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label>Usuário</label>
          <input
            type="text"
            class="form-control input"
            readonly
            v-model="state.id_cadastrante"
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>Data Inicial</label>
          <input
            type="text"
            class="form-control input"
            readonly
            v-model="state.data_inicial"
          />
        </div>
      </div>
      <div class="col-md-3">
        <label>Data Final</label>
        <datepicker
          style="
            border-color: #b8b8b8;
            color: #505050;
            border-radius: 15px;
            border: #b8b8b8 solid 2px;
            transition: 0.5s;
            width: 100%;
            padding: 7px;
          "
          :model-value="state.data_final"
          :locale="pt"
          v-model="state.data_final"
          input-format="dd/MM/yyyy"
        />
      </div>
      <div class="col-md-3">
        <label>Local de Contas</label>
        <select type="text" class="form-select" v-model="state.local_conta_id">
          <option
            v-for="local_conta in state.locais_conta"
            :key="local_conta.id"
            :value="local_conta.id"
          >
            {{ local_conta.nome }}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 div_btn_contas" v-if="state.busca_ativo == false">
        <button
          class="btn btn_buscar_contas"
          type="button"
          @click="buscarContas"
        >
          Buscar Contas
        </button>
      </div>
      <div class="col-md-12 div_btn_entradas" v-if="state.busca_ativo == true">
        <button class="btn btn_ver_entradas" type="button">Ver Entradas</button>
        <button class="btn btn_ver_saidas" type="button">Ver Saídas</button>
      </div>
    </div>
    <div class="row" v-if="state.busca_ativo == true">
      <div class="col-md-6">
        <p class="title_modal_visu">Total de Entrada</p>

        <div
          class="row"
          v-for="tipo_pagamento in state.array_tipo_pagamento"
          :key="tipo_pagamento"
        >
          <div class="col-md-6">
            <p>{{ tipo_pagamento.nome }}</p>
          </div>
          <div class="col-md-5 valores">
            <p>{{ tipo_pagamento.valor }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <p>Valor Suprímentos</p>
          </div>
          <div class="col-md-5 valores">
            <p>R$ 0,00</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <p>Valor Total</p>
          </div>
          <div class="col-md-5 valores">
            <p>{{ state.valor_total_soma_entradas_mostrar }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <p class="title_modal_visu">Total de Saída</p>
        <div class="row">
          <div class="col-md-6">
            <p>Fornecedores</p>
          </div>
          <div class="col-md-5 valores">
            <p>{{ state.valor_soma_fornecedores_mostrar }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <p>Salários/Férias/Recisão</p>
          </div>
          <div class="col-md-5 valores">
            <p>{{ state.valor_soma_colaboradores }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <p>Outras Despesas</p>
          </div>
          <div class="col-md-5 valores">
            <p>R$ 0,00</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <p>Total Saídas</p>
          </div>
          <div class="col-md-5 valores">
            <p>{{ state.valor_total_soma_saidas_mostrar }}</p>
          </div>
        </div>

        <p class="title_modal_visu">Total Caixa</p>
        <div class="row">
          <div class="col-md-6">
            <p>Saldo Operacional</p>
          </div>
          <div class="col-md-5 valores">
            <p>{{ state.valor_operacional_mostrar }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <p>Saldo Inicial</p>
          </div>
          <div class="col-md-5 valores">
            <p>{{ state.saldo_inicial_mostrar }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <p>Saldo Final</p>
          </div>
          <div class="col-md-5 valores">
            <p>{{ state.saldo_final_mostrar }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="state.selecionado_status == 'aberto'">
      <div class="col-md-6">
        <button class="btn btn_salvar my-4" type="button" @click="salvarCaixa">
          Salvar
        </button>
        <button
          class="btn btn_cancelar my-4"
          type="button"
          @click="fecharModalCaixa"
        >
          Cancelar
        </button>
      </div>
      <div
        class="col-md-6 div_cancelar_buscar"
        v-if="state.busca_ativo == true"
        @click="desativarBtnBuscaContas"
      >
        <button class="btn btn_cancelar_busca">Cancelar Buscar</button>
      </div>
    </div>
    <button
      class="btn btn_cancelar my-4"
      type="button"
      @click="fecharModalCaixa"
      v-if="state.selecionado_status == 'fechado'"
    >
      Fechar
    </button>
  </Modal>
  <OffcanvasComponent ref="offcanvasRef" />
</template>
<script>
import Modal from "../components/EstruturaModal/EstruturaModal.vue";
import { pt } from "date-fns/locale";
import Datepicker from "vue3-datepicker";
import { onMounted, reactive, ref } from "vue";
import CorpoCaixa from "../components/CorpoTabelaCaixa";
import CaixaLoading from "../components/CorpoTabelaCaixa/Loading";
import services from "../services";
import BarraLateral from "../layout/Sistema/barraLateral";
import OffcanvasComponent from "../layout/Sistema/barraLateralMobile";
import { useStorage } from "vue3-storage";

import topo from "../components/topo";
export default {
  name: "tables",
  components: {
    CaixaLoading,
    topo,
    Modal,
    BarraLateral,
    OffcanvasComponent,
    Datepicker,
    CorpoCaixa,
  },

  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");
    var nome_usuario = storage.getStorageSync("nome_pessoa");
    var id_func_usuario = storage.getStorageSync("colaborador_id");

    var data_atual = new Date();
    var dd = String(data_atual.getDate()).padStart(2, "0");
    var mm = String(data_atual.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = data_atual.getFullYear();

    data_atual = dd + "/" + mm + "/" + yyyy;

    var today = new Date();
    //  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    //  var yyyy = today.getFullYear();

    ///  var primeiro_dia_mes_atual = "01/" + mm + "/" + yyyy;

    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const state = reactive({
      caixoAberto: false,
      caixaLocal: false,
      id_cadastrante: id_func_usuario,
      nome_cadastrante: nome_usuario,
      data_cadastro: data_atual,
      data_inicial_busca: firstDayOfMonth,
      data_final_busca: lastDayOfMonth,
      status_busca: "",
      isLoading: false,
      caixas: [],
      currentClientType: "",
      hasError: false,
      isOpenVisualizar: false,
      busca_ativo: false,
      array_tipo_pagamento: [],
      valor_total_soma_entradas: 0,
      valor_total_soma_saidas: 0,
    });
    //    onErrorCaptured(handleErrors);
    // Ref para acessar o método do componente filho (OffcanvasComponent)
    const offcanvasRef = ref(null);
    const isVisible = ref(false);

    // Variável para monitorar o estado do isVisible
    const offcanvasVisible = ref(false);
    onMounted(() => {
      telaCaixaWeb();
      fetchCaixas();
      fetchLocalContas();
      // Monitorando o estado do isVisible no componente filho
      /*   if (offcanvasRef.value) {
        offcanvasRef.value.isVisible &&
          (offcanvasVisible.value = offcanvasRef.value.isVisible);
      }*/
    });

    // Função para abrir o offcanvas chamando o método do filho
    const openOffcanvas = () => {
      // Estado para controlar a visibilidade do offcanvas
      console.log(isVisible.value);
      //offcanvasRef.value.showOffcanvas();
      if (isVisible.value) {
        offcanvasRef.value.hideOffcanvas();
      } else {
        offcanvasRef.value.showOffcanvas();
      }
    };

    async function fetchCaixas() {
      try {
        state.isLoading = true;
        const { data } = await services.caixa.listar({
          token: token,
          empresa_id: empresa_id,
        });

        for (let i = 0; i < data.length; i++) {
          //   const element = array[i];
          data[i].data_inicial = dataAtualFormatada(data[i].data_inicial);
          if (data[i].data_final) {
            data[i].data_final = dataAtualFormatada(data[i].data_final);
          }
        }
        state.caixas = data;
        state.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    }

    async function modalVisualizarCaixa(id) {
      try {
        const { data } = await services.caixa.obterPorId({
          token: token,
          empresa_id: empresa_id,
          id: id,
        });

        //  console.log();
        if (data.data_final != null) {
          var dt_final = new Date(data.data_final.slice(0, -9));
          state.data_final = dt_final;
        } else {
          state.data_final = null;
        }

        //   console.log(data.data_final.slice(0, -9));
        //    console.log(mydate);
        //  console.log(mydate);

        //    var mydate = dataAtualFormatada(data.data_final);

        // //   state.data_final = mydate;
        //    console.log(data.data_final);
        //    console.log(mydate);

        state.caixa_selecionado_id = data.id;
        state.data_inicial = data.data_inicial;
        state.local_conta_id = data.local_conta_id;
        state.id_cadastrante = data.funcionario_cadastrante_id;
        state.selecionado_status = data.status;
        state.data_final = new Date();
        state.isOpenVisualizar = true;
        if (data.status == "aberto") {
          state.busca_ativo = false;
        } else {
          state.busca_ativo = true;
        }
      } catch (error) {
        console.log(error);
      }
    }

    function fecharModalCaixa() {
      state.isOpenVisualizar = false;
      state.busca_ativo = false;
    }

    async function salvarCaixa() {
      //Data Final
      var dia_data_final = state.data_final.getDate();
      if (dia_data_final < 10) {
        dia_data_final = "0" + dia_data_final;
      }

      var mes_data_final = state.data_final.getMonth();
      mes_data_final = mes_data_final + 1;
      if (mes_data_final < 10) {
        mes_data_final = "0" + mes_data_final;
      }
      var data_final =
        dia_data_final.toString() +
        "/" +
        mes_data_final.toString() +
        "/" +
        state.data_final.getFullYear().toString();

      try {
        var result = await services.caixa.fecharCaixa({
          token,
          id: state.caixa_selecionado_id,
          funcionario_fechador_id: id_func_usuario,
          valor_entradas_debito: state.valor_soma_entradas_debito,
          valor_entradas_credito: state.valor_soma_entradas_credito,
          valor_colaboradores: state.valor_soma_colaboradores,
          valor_fornecedores: state.valor_soma_fornecedores,
          valor_total_entradas: state.valor_total_soma_entradas,
          valor_total_saidas: state.valor_total_soma_saidas,
          valor_sangria: 0,
          valor_suprimento: 0,
          saldo_operacional: state.valor_operacional,
          saldo_inicial: state.saldo_inicial,
          saldo_final: state.saldo_final,
          data_final: data_final,
          empresa_id,
        });
        if (result.data.verifica_erro == false) {
          state.isOpenVisualizar = false;
          fetchCaixas();
        } else {
          alert("Erro ao Fechar caixa");
        }
      } catch (error) {
        console.log(error);
      }
    }

    function desativarBtnBuscaContas() {
      state.busca_ativo = false;
    }

    async function somaContaReceberTipoPagamentoLocalPagamentoCaixa(
      tipo_pagamento
    ) {
      var soma = 0.0;
      try {
        var contas =
          await services.contaReceber.buscaContaReceberPorStatusTipoPagLocalPagCaixaId(
            {
              local_conta_id: state.local_conta_id,
              caixa_id: state.caixa_selecionado_id,
              tipo_pagamento_id: tipo_pagamento.id,
              status: "recebido",
              token: token,
              empresa_id: empresa_id,
            }
          );

        contas.data.forEach((conta) => {
          soma = soma + parseFloat(conta.valor_recebido_liquido);
        });

        console.log(soma);
        return soma;
      } catch (error) {
        console.log(error);
      }
    }

    async function buscarContas() {
      //ENTRADAS
      //Selecionar tipos de pagamento
      try {
        var tipos_pagamento = await services.tipoPagamento.listar({
          token: token,
          empresa_id: empresa_id,
        });
      } catch (error) {
        console.log(error);
      }

      var valor_total_soma_entradas = 0;
      //    var valor_soma_suprimentos = 0;
      var valor_soma_entradas_debito = 0;
      var valor_soma_entradas_credito = 0;
      var obj_tipo_pagamento;
      var valor_soma_tipo_pagamento = 0;
      state.array_tipo_pagamento = [];
      for (let i = 0; i < tipos_pagamento.data.length; i++) {
        obj_tipo_pagamento = {};
        valor_soma_tipo_pagamento =
          await somaContaReceberTipoPagamentoLocalPagamentoCaixa(
            tipos_pagamento.data[i]
          );
        obj_tipo_pagamento.nome = tipos_pagamento.data[i].nome;
        obj_tipo_pagamento.valor = valor_soma_tipo_pagamento.toLocaleString(
          "pt-br",
          { style: "currency", currency: "BRL" }
        );
        state.array_tipo_pagamento.push(obj_tipo_pagamento);
        valor_total_soma_entradas =
          valor_total_soma_entradas + valor_soma_tipo_pagamento;

        if (tipos_pagamento.data[i].tipo == "debito") {
          valor_soma_entradas_debito =
            valor_soma_entradas_debito + valor_soma_tipo_pagamento;
        } else {
          valor_soma_entradas_credito =
            valor_soma_entradas_credito + valor_soma_tipo_pagamento;
        }

        state.valor_soma_entradas_debito = valor_soma_entradas_debito;
        state.valor_soma_entradas_credito = valor_soma_entradas_credito;
      }

      state.valor_total_soma_entradas = valor_total_soma_entradas;
      state.valor_total_soma_entradas_mostrar =
        valor_total_soma_entradas.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      //SAIDAS

      //Fornecedores
      try {
        var contas_pagar_fornecedor =
          await services.contaPagar.contasFornecedorLocalCaixa({
            token: token,
            empresa_id,
            status: "pago",
            local_conta_id: state.local_conta_id,
            caixa_id: state.caixa_selecionado_id,
          });
      } catch (error) {
        console.log(error);
      }

      var valor_soma_fornecedores = 0.0;

      for (let i = 0; i < contas_pagar_fornecedor.data.length; i++) {
        valor_soma_fornecedores =
          valor_soma_fornecedores + contas_pagar_fornecedor.data[i].valor_pago;
      }

      //  valor_soma_fornecedores
      //Colaboradores
      try {
        var contas_pagar_colaborador =
          await services.contaPagar.contasPagarColaboradorLocalCaixa({
            token: token,
            empresa_id,
            status: "pago",
            local_conta_id: state.local_conta_id,
            caixa_id: state.caixa_selecionado_id,
          });
      } catch (error) {
        console.log(error);
      }

      var valor_soma_colaborador = 0.0;

      for (let i = 0; i < contas_pagar_colaborador.data.length; i++) {
        valor_soma_colaborador =
          valor_soma_colaborador + contas_pagar_colaborador.data[i].valor_pago;
      }

      //valor_soma_fornecedores
      var valor_total_soma_saidas =
        valor_soma_fornecedores + valor_soma_colaborador;
      var valor_total_caixa =
        valor_total_soma_entradas - valor_total_soma_saidas;

      state.valor_soma_fornecedores = valor_soma_fornecedores;
      state.valor_soma_fornecedores_mostrar =
        valor_soma_fornecedores.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });

      state.valor_soma_colaboradores = valor_soma_colaborador;
      state.valor_soma_colaboradores_mostrar =
        valor_soma_colaborador.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });

      state.valor_total_soma_saidas = valor_total_soma_saidas;
      state.valor_total_soma_saidas_mostrar =
        valor_total_soma_saidas.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });

      state.valor_operacional = valor_total_caixa;
      state.valor_operacional_mostrar = valor_total_caixa.toLocaleString(
        "pt-br",
        { style: "currency", currency: "BRL" }
      );

      try {
        var local_conta = await services.localContas.getByID({
          token: token,
          empresa_id,
          local_conta_id: state.local_conta_id,
        });
      } catch (error) {
        console.log(error);
      }
      state.saldo_inicial_mostrar =
        "R$ " +
        local_conta.data.saldo_atual.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });

      var saldo_inicial = local_conta.data.saldo_atual
        .replace(".", "")
        .replace(",", ".");
      state.saldo_inicial = saldo_inicial;

      var saldo_final = parseFloat(saldo_inicial) + valor_total_caixa;
      //console.log(parseFloat(saldo_inicial) +10 );

      state.saldo_final = saldo_final;
      state.saldo_final_mostrar = saldo_final.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });

      console.log(local_conta.data.saldo_atual);
      state.busca_ativo = true;
    }
    async function fetchLocalContas() {
      try {
        state.isLoading = true;
        const { data } = await services.localContas.getAll({
          token: token,
          empresa_id: empresa_id,
        });
        //  console.log(data)
        state.locais_conta = data;
        state.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    }
    function fecharModal() {
      state.isOpen = false;
      state.data = "";
      state.usuario = "";
    }
    async function handleSubmit() {
      const { errors } = await services.caixa.save({
        token,
        empresa_id,
        funcionario_cadastrante_id: state.id_cadastrante,
        data_cadastro: state.data_cadastro,
        local_contas: state.local_contas,
      });
      console.log(errors);
      state.isOpen = false;
      fetchCaixas();
    }

    function dataAtualFormatada(data_americana) {
      if (data_americana != null) {
        var data = new Date(data_americana);
        var dataFormatada = data.toLocaleDateString("pt-BR", {
          timeZone: "UTC",
        });
        return dataFormatada;
      }
    }

    async function telaCaixaWeb() {
      try {
        const telaCaixaWeb = await services.caixa.telaCaixaWeb({
          token: token,
          empresa_id: empresa_id,
        });
        if (telaCaixaWeb.data.verifica_erro == false) {
          state.caixas_abertos = telaCaixaWeb.data.qtd_caixas_abertos;
          state.locais_caixa = telaCaixaWeb.data.qtd_locais_caixa;
        }

        //    state.contaAtrasada = contaAtrasada.data;
      } catch (error) {
        console.log(error);
      }
    }
    async function buscaDataCaixa() {
      //Data Inicial
      var dia_data_inicial = state.data_inicial_busca.getDate();
      if (dia_data_inicial < 10) {
        dia_data_inicial = "0" + dia_data_inicial;
      }

      var mes_data_inicial = state.data_inicial_busca.getMonth();
      mes_data_inicial = mes_data_inicial + 1;
      if (mes_data_inicial < 10) {
        mes_data_inicial = "0" + mes_data_inicial;
      }
      var data_inicial =
        dia_data_inicial.toString() +
        "/" +
        mes_data_inicial.toString() +
        "/" +
        state.data_inicial_busca.getFullYear().toString();

      //Data Final
      var dia_data_final = state.data_final_busca.getDate();
      if (dia_data_final < 10) {
        dia_data_final = "0" + dia_data_final;
      }

      var mes_data_final = state.data_final_busca.getMonth();
      mes_data_final = mes_data_final + 1;
      if (mes_data_final < 10) {
        mes_data_final = "0" + mes_data_final;
      }
      var data_final =
        dia_data_final.toString() +
        "/" +
        mes_data_final.toString() +
        "/" +
        state.data_final_busca.getFullYear().toString();

      try {
        state.isLoading = true;
        const { data } = await services.caixa.buscaDataCaixa({
          token: token,
          empresa_id: empresa_id,
          data_inicial: data_inicial,
          data_final: data_final,
          status: state.status_busca,
        });

        for (let i = 0; i < data.length; i++) {
          //   const element = array[i];
          data[i].data_inicial = dataAtualFormatada(data[i].data_inicial);
          if (data[i].data_final) {
            data[i].data_final = dataAtualFormatada(data[i].data_final);
          }
        }

        state.caixas = data;
        //  console.log(data)
        state.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    }
    return {
      state,
      fecharModal,
      modalVisualizarCaixa,
      fecharModalCaixa,
      buscarContas,
      handleSubmit,
      salvarCaixa,
      pt,
      desativarBtnBuscaContas,
      buscaDataCaixa,
      offcanvasRef,
      openOffcanvas,
    };
  },
};
</script>
<style scoped>
@media (min-width: 1000px) and (max-width: 1400px) {
  .espaco_md {
    padding-left: 8rem;
  }
}

@media (min-width: 768px) and (max-width: 999px) {
  .espaco_md {
    padding-left: 8rem;
  }
}

.bv {
  font-weight: bold;
  font-size: 18pt;
  color: #162b48;
  margin-bottom: 0;
}

svg {
  color: #aede00;
  width: 24px;
  height: 24px;
}

.valores {
  text-align: right;
}

.quad {
  padding: 13px;
  gap: 10px;
  width: 50px;
  height: 50px;
  background: #010d1f;
  border-radius: 12px;
}

.title2 {
  color: #000;
  margin-top: 10px;
}

.num {
  margin-top: -15px;
}

.btn_abrir_caixa {
  transform: none;
}

.btn_abrir_caixa:hover {
  transform: none;
}

.div_btn_caixa {
  margin-bottom: 15px;
}

.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}

.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}

.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}

.btn_salvar:hover {
  transform: none;
  color: #fff;
}

.modal-caixa {
  padding: 2rem;
}

.div_usuario_modal {
  border-color: #b8b8b8;
  border-width: 2px;
  color: #505050;
  border-radius: 15px;
  transition: 0.5s;
}

.btn_buscar_contas {
  color: #000;
  background: #aede00;
  border: #aede00 solid 2px;
}

.btn_buscar_contas:hover {
  transform: none;
  color: #000;
}

.div_btn_contas {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.div_btn_entradas {
  text-align: right;
  margin-top: 20px;
  margin-bottom: 20px;
}

.title_modal_visu {
  color: #000;
  font-weight: bold;
}

.btn_ver_entradas {
  color: #000;
  background: #aede00;
  border: #aede00 solid 2px;
  padding: 5px;
  width: 25%;
}

.btn_ver_entradas:hover {
  transform: none;
  color: #000;
}

.btn_ver_saidas {
  color: #fff;
  background: #788596;
  border: #788596 solid 2px;
  padding: 5px;
  width: 20%;
}

.btn_ver_saidas:hover {
  transform: none;
  color: #fff;
}

.btn_cancelar_busca {
  color: #fff;
  background: #788596;
  border: #788596 solid 2px;
  padding: 5px;
  width: 40%;
}

.btn_cancelar_busca:hover {
  transform: none;
  color: #fff;
}

.div_cancelar_buscar {
  text-align: right;
  padding-top: 30px;
}

.btn_buscar {
  font-size: 10pt;
  border-radius: 5px;
  background-color: rgb(3, 74, 166);
  border: none;
  color: #fff;
  font-weight: 700;
  padding: 10px;
  margin-top: 25px;
  width: 100%;
}

.btn_busca:hover {
  transform: none;
}

label {
  margin-bottom: 0;
}

@media (min-width: 992px) and (max-width: 1199px) {
}
</style>
