<template>
  <div class="topo">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-9">
          <h1>{{ titulo }}</h1>
          <slot></slot>
        </div>
        <div class="col-3 d-block d-md-none text-right">
          <button @click="$emit('openOffcanvas')" class="btn_transparent">
            <i class="bi bi-list"></i>
          </button>
        </div>
        <div
          class="col-lg-3 d-none d-md-flex justify-content-end align-items-center"
        >
          <div class="user-info">
            <div class="dropdown">
              <a
                class="user-dropdown"
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span class="user-name">{{ nome_pessoa }}</span>
                <img :src="foto || '/img/user_sifit.png'" class="user-image" />
              </a>
              <ul
                class="dropdown-menu dropdown-menu-end"
                aria-labelledby="dropdownMenuLink"
              >
                <li>
                  <button @click="deslogar" class="dropdown-item">Sair</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { reactive } from "vue";
import { useStorage } from "vue3-storage";
export default {
  props: {
    titulo: { type: String, required: true },
  },
  setup() {
    const storage = useStorage();
    const string = storage.getStorageSync("nome_pessoa");
    const nome_pessoa = string.split(" ")[0];

    const foto = storage.getStorageSync("foto");

    const state = reactive({
      idioma: "PT",
    });
    function deslogar() {
      storage.removeStorageSync("token");
      router.push("/login");
    }
    return {
      state,
      nome_pessoa,
      foto,
      deslogar,
      router,
    };
  },
};
</script>

<style scoped>
.topo {
  background-color: #fff;
  padding: 15px 0;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

h1 {
  font-weight: 600;
  color: #495057;
  font-size: 22px;
  margin: 0;
}

.btn_transparent {
  border: none;
  background-color: transparent;
}

.btn_transparent i {
  font-size: 24px;
  color: #6c757d;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-dropdown {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #343a40;
}

.user-image {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.user-name {
  margin-right: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #495057;
}

.dropdown-menu {
  min-width: 150px;
}

.dropdown-item {
  font-size: 14px;
  padding: 8px 16px;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
}
</style>
