export default httpClient => ({
  buscaMarca: async ({ token, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/marca-aparelho-biometria', {
      token: token,
      id: id,
    }, { headers })
    return { data: response.data }
  },
  modeloAparelho: async ({ token, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/aparelho-biometria', {
      token: token,
      id: id,
    }, { headers })
    return { data: response.data }
  },
})