export default httpClient => ({
  getAll: async ({ token, empresa_id }) => {

    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/produtos/' + empresa_id, { headers });
    return { data: response.data }
  },
  getByTipo: async ({ token, empresa_id, tipo }) => {
    console.log("aquii");
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/produtos-por-tipo/' + empresa_id, {
      token,
      tipo
    }, { headers })
    return { data: response.data }
  },
  obterPorId: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/produto/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },
  save: async ({ token, empresa_id, nome, descricao, valor, pontos, status, tipo }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/criar-produto/' + empresa_id, {
      token,
      nome,
      descricao,
      valor,
      pontos,
      status,
      tipo
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
    update: async ({ token, id, empresa_id, nome, descricao, valor, pontos,status, tipo }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/alterar-produto/' + empresa_id, {
      id,
      nome,
      descricao,
      valor,
      pontos,
      status,
      tipo
    }, { headers })
    return {
      data: response.data
    }
  },
  saveImagem: async (formImagem, empresa_id,token) => {
    const headers = {
            "Content-Type": "multipart/form-data",
      "Authorization": token
    }
    const response = await httpClient.post('/criar-imagens-produto/'+empresa_id, formImagem,{ headers })
    return {
      data: response.data,
    }
  },
});