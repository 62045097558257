<template>
  <barra-lateral />
  <div style="background-color: #f8f9fe; min-height: 100vh">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <topo :titulo="'Modelos Contrato'"></topo>
          <div>
            <div
              class="card shadow p-3 mt-3"
              :class="type === 'dark' ? 'bg-default' : ''"
              s
            >
              <div class="row align-items-center">
                <div class="col text-end mb-2">
                  <a href="/adicionar-modelo-contrato">
                    <button
                      type="primary"
                      class="btn btn-primary"
                      id="btn_new_product"
                    >
                      Adicionar Modelo Contrato
                    </button>
                  </a>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="table-responsive">
                    <table
                      class="table tablesorter table align-items-center table-flush"
                    >
                      <thead class="thead-light">
                        <th>Código</th>
                        <th>Nome</th>
                        <th>Status</th>
                        <th></th>
                      </thead>
                      <tbody class="list">
                        <corpo-modelo
                          v-for="modelo in state.modelos"
                          :key="modelo.id"
                          :modelo="modelo"
                          @deletar="deletar"
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, onErrorCaptured } from "vue";
import CorpoModelo from "../components/CorpoTabelaModeloContrato";
import services from "../services";
import topo from "../components/topo";
import { useStorage } from "vue3-storage";
import BarraLateral from "../layout/Sistema/barraLateral";
export default {
  name: "tables",
  components: { topo, BarraLateral },
  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");
    const state = reactive({
      isLoading: false,
      modelos: [],
      currentClientType: "",
      hasError: false,
      id: "",
      nome: "",
      mostrar_home: "",
      tipo_caixa: "interno",
      descricao: "",
    });
    onErrorCaptured(handleErrors);

    onMounted(() => {
      fetchModelosContrato();
    });
    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }

    async function deletar(id) {
      if (confirm("Deseja deletar o Modelo?")) {
        await services.modelos
          .deletar({
            token,
            empresa_id,
            id,
          })
          .then((data) => {
            if (data.data.verifica_erro) {
              alert("Erro ao deletar Modelo");
            } else {
              fetchModelosContrato();
            }
          })
          .catch(() => {
            alert("Erro ao deletar Modelo");
          });
      }
    }

    async function salvarModelo() {
      if (state.id != null && state.id != undefined && state.id != "") {
        let retorno = await services.modelos.update({
          empresa_id,
          token,
          id: state.id,
          nome: state.nome,
          mostrar_home: state.mostrar_home,
          caixa: state.caixa,
          descricao: state.descricao,
        });

        if (retorno.data.verifica_erro == false) {
          fetchModelosContrato();
          state.status_modal = false;
        } else {
          alert("Erro ao salvar plano");
        }
      } else {
        let retorno = await services.modelos.save({
          empresa_id,
          token,
          nome: state.nome,
          mostrar_home: state.mostrar_home,
          caixa: state.caixa,
          descricao: state.descricao,
        });

        if (retorno.data.verifica_erro == false) {
          fetchModelosContrato();
          state.status_modal = false;
        } else {
          alert("Erro ao salvar plano");
        }
      }
    }

    async function fetchModelosContrato() {
      try {
        state.isLoading = true;
        const { data } = await services.modeloContrato.listar({
          token: token,
          empresa_id: empresa_id,
        });
        //  console.log(data)
        state.modelos = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    return {
      state,
      CorpoModelo,
      salvarModelo,
      deletar,
    };
  },
};
</script>

<style scoped>
.btn-primary {
  background-color: rgb(3, 74, 166);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(3, 74, 166);
  border-radius: 10px;
  font-size: 10pt;
  padding: 12px;
  font-weight: 700;
  margin-bottom: 15px;
}
.modelo {
  width: 100%;
  height: 100px;
  border: #034aa6 solid 1px;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px;
}

.nome_modelo {
  font-weight: bold;
}

.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}

.btn_salvar:hover {
  transform: none;
  color: #fff;
}

.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}

.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}

textarea {
  resize: none;
}
@media (max-width: 767px) {
  .btn-primary {
    width: 100%;
    margin-top: 10px;
    text-align: center;
  }
}
</style>