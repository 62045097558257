<template>
  <barra-lateral />
  <div style="background-color: #f8f9fe">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <topo :titulo="'Cadastrar Fornecedor'"></topo>
          <div class="row">
            <div class="col-xl-12 col-lg-12">
              <stats-card>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/">Home</router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link to="/fornecedores" class="primary"
                        >Fornecedores
                      </router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Adicionar
                    </li>
                  </ol>
                </nav>
                <form @submit.prevent="handleSubmit">
                  <div class="row">
                    <div class="col-md-6 col-lg-4">
                      <div class="checks">
                        <div class="title_check">
                          <spam class="span_login">Pessoa</spam>
                        </div>
                        <div style="clear: both"></div>
                        <div>
                          <input
                            class="chekcbox"
                            type="radio"
                            value="fisica"
                            v-model="state.tipo_empresa"
                            id="radio_fisica"
                          />
                          <label for="radio_fisica" class="lbl_check_pessoa">
                            Física</label
                          >
                          <input
                            class="chekcbox"
                            type="radio"
                            value="juridica"
                            v-model="state.tipo_empresa"
                            id="radio_juridica"
                          />
                          <label for="radio_juridica" class="lbl_check_pessoa">
                            Jurídica</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <h2>Dados Principais</h2>
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Código</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="state.id.value"
                        />
                      </div>
                    </div>
                    <div class="col-md-9 col-lg-6">
                      <div class="form-group">
                        <label>Nome</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="state.nome.value"
                        />
                        <span
                          v-if="!!state.nome.errorMessage"
                          class="block font-medium text-brand-danger"
                        >
                          {{ state.nome.errorMessage }}
                        </span>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="form-group">
                        <label>Status</label>
                        <select
                          class="form-select"
                          v-model="state.status.value"
                        >
                          <option value="ativo">Ativo</option>
                          <option value="inativo">Inativo</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-4" v-if="state.tipo_empresa == 'fisica'">
                      <div class="form-group">
                        <label>Data de Nascimento</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="state.data_nascimento.value"
                          v-mask-date.br
                        />
                        <span
                          v-if="!!state.data_nascimento.errorMessage"
                          class="block font-medium text-brand-danger"
                        >
                          {{ state.data_nascimento.errorMessage }}
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-4" v-if="state.tipo_empresa == 'fisica'">
                      <div class="form-group">
                        <label>Sexo</label>
                        <select
                          name=""
                          id=""
                          class="form-select"
                          v-model="state.sexo.value"
                        >
                          <option value="masculino">Masculino</option>
                          <option value="feminino">Feminino</option>
                        </select>
                        <span
                          v-if="!!state.sexo.errorMessage"
                          class="block font-medium text-brand-danger"
                        >
                          {{ state.sexo.errorMessage }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <h2>Documentos</h2>
                  <div class="row">
                    <div
                      class="col-md-4"
                      v-if="state.tipo_empresa == 'juridica'"
                    >
                      <div class="form-group">
                        <label>CNPJ</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="state.cnpj.value"
                          v-mask-cnpj
                        />
                      </div>
                    </div>
                    <div class="col-md-4" v-if="state.tipo_empresa == 'fisica'">
                      <div class="form-group">
                        <label>RG</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="state.rg.value"
                        />
                      </div>
                    </div>
                    <div class="col-md-4" v-if="state.tipo_empresa == 'fisica'">
                      <div class="form-group">
                        <label>CPF</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="state.cpf.value"
                          v-mask-cpf
                        />
                      </div>
                    </div>
                  </div>
                  <h2>Dados para Contato</h2>
                  <div class="row">
                    <div class="col-md-4 col-lg-3">
                      <div class="form-group">
                        <label>Telefone Principal</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="state.telefone.value"
                          v-mask-phone.br
                        />
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3 d-lg-none d-xl-block">
                      <div class="form-group">
                        <label>Telefone Alternativo</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="state.tel_emergencia.value"
                          v-mask-phone.br
                        />
                      </div>
                    </div>
                    <div class="col-md-4 d-none d-lg-block d-xl-none">
                      <div class="form-group">
                        <label>Telefone Alternativo</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="state.tel_emergencia.value"
                          v-mask-phone.br
                        />
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3">
                      <div class="form-group">
                        <label>Celular/WhatsApp</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="state.celular.value"
                          v-mask-phone.br
                        />
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="form-group">
                        <label>E-mail</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="state.email.value"
                        />
                        <span
                          v-if="!!state.email.errorMessage"
                          class="block font-medium text-brand-danger"
                        >
                          {{ state.email.errorMessage }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <h2>Endereço</h2>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>País</label>
                        <select class="form-select" v-model="state.pais.value">
                          <select-paises
                            v-for="pais in state.paises"
                            :key="pais.id"
                            :pais="pais"
                          />
                        </select>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Estado</label>
                        <select
                          class="form-select"
                          v-model="state.estado.value"
                          @change="fetchCidades($event.target.value)"
                        >
                          <select-estados
                            v-for="estado in state.estados"
                            :key="estado.id"
                            :estado="estado"
                          />
                        </select>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Cidade</label>
                        <select class="form-select" v-model="state.cidade">
                          <option
                            v-for="cidade in state.cidades"
                            :key="cidade.id"
                            :value="cidade.id"
                          >
                            {{ cidade.nome }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-8 col-lg-3">
                      <div class="form-group">
                        <label>Endereço</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="state.endereco.value"
                        />
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-2">
                      <div class="form-group">
                        <label>Número</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="state.numero.value"
                        />
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-2">
                      <div class="form-group">
                        <label>Bairro</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="state.bairro.value"
                        />
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-2">
                      <div class="form-group">
                        <label>Complemento</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="state.complemento.value"
                        />
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3">
                      <div class="form-group">
                        <label>CEP</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="state.cep.value"
                          v-mask-cep
                        />
                      </div>
                    </div>
                  </div>
                  <h2>Observação</h2>
                  <div class="row">
                    <div class="col-md-12">
                      <div>
                        <textarea class="area_obs"></textarea>
                      </div>
                    </div>
                  </div>
                  <button
                    class="btn btn_salvar"
                    id="submit-button"
                    :disabled="state.isLoading"
                    type="submit"
                  >
                    Salvar
                  </button>
                  <button
                    class="btn btn_cancelar"
                    id="submit-button"
                    type="button"
                    @click="cancel()"
                  >
                    Cancelar
                  </button>
                </form>
              </stats-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import services from "../services";
import { useField } from "vee-validate";
import { validateEmptyAndLength3, validateEmpty } from "../utils/validators";
import SelectPaises from "../components/PaisesSelect.vue";
import SelectEstados from "../components/EstadosSelect.vue";
import topo from "../components/topo";
import { useRouter } from "vue-router";
import { useStorage } from "vue3-storage";
import BarraLateral from "../layout/Sistema/barraLateral";
export default {
  components: { SelectPaises, SelectEstados, topo, BarraLateral },
  setup(_, { emit }) {
    const router = useRouter();
    const storage = useStorage();

    var empresa_id = storage.getStorageSync("empresa_id");
    var token = storage.getStorageSync("token");

    console.log(token);
    console.log(empresa_id);

    function cancel() {
      router.push({ name: "fornecedores" });
    }

    const { value: idValue, errorMessage: idErrorMessage } = useField(
      "id",
      validateEmptyAndLength3
    );

    const { value: statusValue, errorMessage: statusErrorMessage } = useField(
      "status",
      validateEmptyAndLength3
    );

    const {
      value: sempreLiberadoValue,
      errorMessage: sempreLiberadoErrorMessage,
    } = useField("sempre_liberado", validateEmptyAndLength3);

    const { value: nomeValue, errorMessage: nomeErrorMessage } = useField(
      "nome",
      validateEmptyAndLength3
    );

    const {
      value: dataNascimentoValue,
      errorMessage: dataNascimentoErrorMessage,
    } = useField("data_nascimento", validateEmpty);

    const { value: sexoValue, errorMessage: sexoErrorMessage } = useField(
      "sexo",
      validateEmpty
    );

    const { value: tipoSangueValue, errorMessage: tipoSangueErrorMessage } =
      useField("tipo_sangue", validateEmpty);

    const { value: telefoneValue, errorMessage: telefoneErrorMessage } =
      useField("telefone", validateEmptyAndLength3);

    const { value: celularValue, errorMessage: celularErrorMessage } = useField(
      "celular",
      validateEmpty
    );

    const {
      value: telEmergenciaValue,
      errorMessage: telEmergenciaErrorMessage,
    } = useField("tel_emergencia", validateEmpty);

    const { value: emailValue, errorMessage: emailErrorMessage } = useField(
      "email",
      validateEmpty
    );

    const { value: cpfValue, errorMessage: cpfErrorMessage } = useField(
      "cpf",
      validateEmptyAndLength3
    );
    const { value: cnpjValue, errorMessage: cnpjErrorMessage } = useField(
      "cnpj",
      validateEmptyAndLength3
    );

    const { value: rgValue, errorMessage: rgErrorMessage } = useField(
      "rg",
      validateEmptyAndLength3
    );

    const { value: paisValue, errorMessage: paisErrorMessage } = useField(
      "pais",
      validateEmptyAndLength3
    );

    const { value: estadoValue, errorMessage: estadoErrorMessage } = useField(
      "estado",
      validateEmptyAndLength3
    );

    const { value: cidadeValue, errorMessage: cidadeErrorMessage } = useField(
      "cidade",
      validateEmptyAndLength3
    );

    const { value: cepValue, errorMessage: cepErrorMessage } = useField(
      "cep",
      validateEmptyAndLength3
    );

    const { value: enderecoValue, errorMessage: enderecoErrorMessage } =
      useField("endereco", validateEmptyAndLength3);

    const { value: numeroValue, errorMessage: numeroErrorMessage } = useField(
      "numero",
      validateEmptyAndLength3
    );

    const { value: bairroValue, errorMessage: bairroErrorMessage } = useField(
      "bairro",
      validateEmptyAndLength3
    );

    const { value: complementoValue, errorMessage: complementoErrorMessage } =
      useField("complemento", validateEmptyAndLength3);

    const { value: observacaoValue, errorMessage: observacaoErrorMessage } =
      useField("observacao", validateEmptyAndLength3);

    const state = reactive({
      paises: [],

      hasErrors: false,
      isLoading: false,
      tipo_empresa: "juridica",

      id: {
        value: idValue,
        errorMessage: idErrorMessage,
      },
      status: {
        value: statusValue,
        errorMessage: statusErrorMessage,
      },
      sempre_liberado: {
        value: sempreLiberadoValue,
        errorMessage: sempreLiberadoErrorMessage,
      },
      nome: {
        value: nomeValue,
        errorMessage: nomeErrorMessage,
      },
      data_nascimento: {
        value: dataNascimentoValue,
        errorMessage: dataNascimentoErrorMessage,
      },
      sexo: {
        value: sexoValue,
        errorMessage: sexoErrorMessage,
      },
      tipo_sangue: {
        value: tipoSangueValue,
        errorMessage: tipoSangueErrorMessage,
      },
      telefone: {
        value: telefoneValue,
        errorMessage: telefoneErrorMessage,
      },
      celular: {
        value: celularValue,
        errorMessage: celularErrorMessage,
      },
      tel_emergencia: {
        value: telEmergenciaValue,
        errorMessage: telEmergenciaErrorMessage,
      },
      email: {
        value: emailValue,
        errorMessage: emailErrorMessage,
      },
      cpf: {
        value: cpfValue,
        errorMessage: cpfErrorMessage,
      },
      cnpj: {
        value: cnpjValue,
        errorMessage: cnpjErrorMessage,
      },
      rg: {
        value: rgValue,
        errorMessage: rgErrorMessage,
      },
      pais: {
        value: paisValue,
        errorMessage: paisErrorMessage,
      },
      estado: {
        value: estadoValue,
        errorMessage: estadoErrorMessage,
      },
      cidade: {
        value: cidadeValue,
        errorMessage: cidadeErrorMessage,
      },
      cep: {
        value: cepValue,
        errorMessage: cepErrorMessage,
      },
      endereco: {
        value: enderecoValue,
        errorMessage: enderecoErrorMessage,
      },
      numero: {
        value: numeroValue,
        errorMessage: numeroErrorMessage,
      },
      bairro: {
        value: bairroValue,
        errorMessage: bairroErrorMessage,
      },
      complemento: {
        value: complementoValue,
        errorMessage: complementoErrorMessage,
      },
      observacao: {
        value: observacaoValue,
        errorMessage: observacaoErrorMessage,
      },
    });
    async function fetchForncedor() {
      try {
        state.isLoading = true;
        const { data } = await services.fornecedores.obterPorId({
          type: state.currentClientType,
          token: token,
          empresa_id: empresa_id,
          id: router.currentRoute._value.params.id,
        });
        state.rg.value = data.rg;
        state.cpf.value = data.cpf;
        state.cidade = data.cidade_id;
        state.nome.value = data.nome;
        state.telefone.value = data.telefone;
        state.celular.value = data.celular;
        state.tel_emergencia.value = data.tel_emergencia;
        state.data_nascimento.value = data.data_nascimento;
        state.status.value = data.status;
        state.email.value = data.email;
        state.estado.value = data.estado_id;
        state.endereco.value = data.endereco;
        state.numero.value = data.numero;
        state.bairro.value = data.bairro;
        state.observacao.value = data.observacao;
        state.complemento.value = data.complemento;
        state.cep.value = data.cep;
        state.pais.value = data.pais;
        state.estado.value = data.estado;
        state.sexo.value = data.sexo;
        state.tipo_sangue.value = data.tipo_sangue;
        state.tipo_empresa = data.tipo_empresa;
        state.cnpj = data.cnpj;

        return;
        /*
         */
        //  console.log(data)
        //   state.pagination = data.pagination
      } catch (error) {
        handleErrors(error);
      }
    }

    onMounted(() => {
      if (router.currentRoute._value.params.id != undefined) {
        fetchForncedor();
      }
      fetchPaises(), fetchEstados();
    });

    async function fetchPaises() {
      try {
        state.isLoading = true;
        const { data } = await services.paises.getAll({
          type: state.currentFeedbackType,
        });
        console.log(data);
        //  console.log(data)
        state.paises = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function fetchEstados() {
      try {
        state.isLoading = true;
        const { data } = await services.estados.getAll({
          type: state.currentFeedbackType,
        });
        console.log(data);
        //  console.log(data)
        state.estados = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function fetchCidades(estado_id) {
      try {
        state.isLoading = true;
        const { data } = await services.cidades.getAll(estado_id);
        console.log(data);
        state.cidades = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }

    async function handleSubmit() {
      //  try {
      state.isLoading = true;

      if (!state.id.value) {
        const { errors } = await services.fornecedores.save({
          token: token,
          status: state.status.value,
          empresa_id: empresa_id,
          nome: state.nome.value,
          data_nascimento: state.data_nascimento.value,
          sexo: state.sexo.value,
          tipo_sangue: state.tipo_sangue.value,
          telefone: state.telefone.value,
          celular: state.celular.value,
          tel_emergencia: state.tel_emergencia.value,
          email: state.email.value,
          cpf: state.cpf.value,
          rg: state.rg.value,
          pais: state.pais.value,
          estado: state.estado.value,
          cidade_id: state.cidade,
          cep: state.cep.value,
          endereco: state.endereco.value,
          numero: state.numero.value,
          bairro: state.bairro.value,
          complemento: state.complemento.value,
          observacao: state.observacao.value,
          cnpj: state.cnpj.value,
          tipo_pessoa: state.tipo_empresa,
        });

        if (!errors) {
          //       window.localStorage.setItem('token', data.token)
          router.push({ name: "fornecedores" });
          state.isLoading = false;
          return;
        }

        if (errors.status === 400) {
          //  toast.error('Ocorreu um erro ao criar a conta')
        }
      } else {
        const { errors } = await services.fornecedores.update({
          id: state.id.value,
          status: state.status.value,
          empresa_id: empresa_id,
          nome: state.nome.value,
          data_nascimento: state.data_nascimento.value,
          sexo: state.sexo.value,
          tipo_sangue: state.tipo_sangue.value,
          telefone: state.telefone.value,
          celular: state.celular.value,
          tel_emergencia: state.tel_emergencia.value,
          email: state.email.value,
          cpf: state.cpf.value,
          rg: state.rg.value,
          pais: state.pais.value,
          estado: state.estado.value,
          cidade_id: state.cidade,
          cep: state.cep.value,
          endereco: state.endereco.value,
          numero: state.numero.value,
          bairro: state.bairro.value,
          complemento: state.complemento.value,
          observacao: state.observacao.value,
        });
        console.log(errors);
        if (!errors) {
          //       window.localStorage.setItem('token', data.token)
          router.push({ name: "fornecedores" });
          state.isLoading = false;
          return;
        }

        if (errors.status === 400) {
          //  toast.error('Ocorreu um erro ao criar a conta')
        }
      }

      state.isLoading = false;
      /*   } catch (error) {
        console.log(error);
        state.isLoading = false;
        state.hasErrors = !!error;
        //  toast.error('Ocorreu um erro ao criar a conta')
      }*/
    }
    return {
      state,
      // handleCategory,
      handleSubmit,
      fetchCidades,
      emit,
      cancel,
    };
  },
};
</script>
<style scoped>
.form-group {
  margin-bottom: 0.5rem;
}

h2 {
  color: #252525;
  font-weight: 600;
}

textarea {
  resize: none;
}

.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}

.btn_salvar:hover {
  transform: none;
  color: #fff;
}

.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}

.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}

input {
  border-color: #b8b8b8;
  border-width: 1px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
}

input:focus {
  border-color: #252525;
}

select {
  border-color: #b8b8b8;
  border-width: 1px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
}

select:focus {
  border-color: #252525;
}

textarea {
  resize: none;
}

h2 {
  margin-top: 10px;
}

.area_obs {
  width: 100%;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
  outline: none;
  height: 100px;
  border: 1px solid #b8b8b8;
  resize: none;
}

.area_obs:focus {
  border-color: #252525;
  outline: none;
}

.checks {
  color: #000;
  border-radius: 10px;
  outline: none;
  border: 1px solid #b8b8b8;
  resize: none;
  text-align: center;
  padding-bottom: 5px;
  display: inline-block;
  padding: padding-righ;
  padding-right: 10px;
}

.chekcbox {
  margin-left: 10px;
}

.lbl_check_pessoa {
  margin-left: 3px;
}

.title_check {
  margin-top: -14px;
  float: left;
  margin-left: 15px;
}

.span_login {
  background-color: #fff;
}
</style>