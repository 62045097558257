<template>
  <div style="background: #000d1f">
    <main>
      <topo
        @abrirModal="abrirModal"
        @alterarIdioma="alterarIdioma"
        :idioma="state.idioma"
      />
      <section id="banner_1">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-7 col-lg-4 offset-lg-1">
              <p class="sw">SISTEMA WEB</p>

              <h1 class="ge" v-if="state.idioma == 'PT'">GESTÃO EFICIENTE</h1>
              <h1 class="ge" v-else>GESTIÓN EFICIENTE</h1>

              <h1 class="dsa" v-if="state.idioma == 'PT'">DA SUA ACADEMIA</h1>
              <h1 class="dsa" v-else>DE TU GIMNASIO</h1>

              <p class="txt1" v-if="state.idioma == 'PT'">
                Conte com diversas funcionalidades que facilitarão a sua vida e
                melhorarão a experiência do seu aluno.
              </p>
              <p class="txt1" v-else>
                Cuente con varias funciones que le harán la vida más fácil y
                mejorarán la experiencia de sus estudiantes.
              </p>

              <div class="div_whats">
                <a
                  href="https://api.whatsapp.com/send?phone=44984387269&text=Ol%C3%A1,%20gostaria%20de%20conhecer%20o%20sistema!"
                  target="blank"
                >
                  <img class="img_whats" src="../../assets/img_new/zap.png" />
                </a>
              </div>
              <div class="btn_linha">
                <button class="btn" type="button" @click="abrirModal()">
                  <span v-if="state.idioma == 'PT'"
                    >Solicitar Demonstração</span
                  >
                  <span v-else>Solicitar demostración</span>
                </button>
              </div>
            </div>
            <div class="col-sm-5 col-lg-6 offset-0 offset-xl-1 banner1_r">
              <img src="../../assets/img_new/banner_1.png" />
            </div>
          </div>
        </div>
      </section>
      <section id="banner_2">
        <div class="conteudo">
          <div class="container">
            <div class="row">
              <div class="col-lg-5 offset-lg-7">
                <div class="contents">
                  <div class="space"></div>
                  <h1 class="ap" v-if="state.idioma == 'PT'">
                    A SOLUÇÃO IDEAL PARA
                  </h1>
                  <h1 class="ap" v-else>LA SOLUCIÓN IDEAL PARA</h1>

                  <h1 class="on" v-if="state.idioma == 'PT'">O SEU NEGÓCIO</h1>
                  <h1 class="on" v-else>TU NEGOCIO</h1>

                  <p class="txt" v-if="state.idioma == 'PT'">
                    No Sifit você encontra diversas funcionalidades focadas em
                    tornar o seu dia-a-dia mais prático
                  </p>
                  <p class="txt" v-else>
                    En Sifit encontrarás varias funcionalidades enfocadas a
                    hacer más práctico tu día a día.
                  </p>
                </div>
                <div class="ret" @click="controleFinanceiro">
                  <h1>
                    <img
                      class="icon"
                      src="../../assets/img_new/icon1.png"
                      alt="Finanças"
                    />
                    <span v-if="state.idioma == 'PT'" class="titulo_ret"
                      >Controle Financeiro</span
                    >
                    <span v-else>Control Financero</span>

                    <svg
                      ref="ico_controle_financeiro"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-caret-down-fill triangle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                      />
                    </svg>
                  </h1>
                  <Collapse :when="state.visualizar_controle_fin" class="text2">
                    <p v-if="state.idioma == 'PT'">
                      Economize tempo e ganhe agilidade na parte financeira de
                      sua academia.
                    </p>
                    <p v-else>
                      Ahorra tiempo y gana agilidad en la parte financiera de tu
                      academia.
                    </p>
                  </Collapse>
                </div>
                <div class="ret" @click="avaliacaoFisica">
                  <h1>
                    <img
                      class="icon"
                      src="../../assets/img_new/icon2.png"
                      alt="Finanças"
                    />
                    <span v-if="state.idioma == 'PT'" class="titulo_ret"
                      >Avaliação Física</span
                    >
                    <span v-else>Evaluación física</span>

                    <svg
                      ref="ico_avaliacao_fisica"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-caret-down-fill triangle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                      />
                    </svg>
                  </h1>
                  <Collapse
                    :when="state.visualizar_avaliacao_fis"
                    class="text2"
                  >
                    <p v-if="state.idioma == 'PT'">
                      Faça a avaliação física de seus alunos, compare os
                      resultados e mostre sua evolução.
                    </p>
                    <p v-else>
                      Realiza una evaluación física de tus alumnos, compara los
                      resultados y muestra su progreso.
                    </p>
                  </Collapse>
                </div>
                <div class="ret" @click="controleAcesso">
                  <h1>
                    <img
                      class="icon"
                      src="../../assets/img_new/icon3.png"
                      alt="Finanças"
                    />
                    <span v-if="state.idioma == 'PT'" class="titulo_ret"
                      >Controle de Acesso</span
                    >
                    <span v-else>Controle de acceso</span>

                    <svg
                      ref="ico_controle_acesso"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-caret-down-fill triangle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                      />
                    </svg>
                  </h1>
                  <Collapse :when="state.visualizar_controle_ace" class="text2">
                    <p v-if="state.idioma == 'PT'">
                      Monitore e controle o acesso de seus clientes a sua
                      academia ou estúdio.
                    </p>
                    <p v-else>
                      Monitoriza y controla el acceso de tus clientes a tu
                      gimnasio o estudio.
                    </p>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="banner_3">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="tm" v-if="state.idioma == 'PT'">
                <h1 class="ge">DIGITALIZE SUA</h1>
                <h1 class="dsa">ACADEMIA</h1>
              </div>
              <div class="tm" v-else>
                <h1 class="ge">DIGITALIZA TU</h1>
                <h1 class="dsa">GIMNASIO</h1>
              </div>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-check-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                  />
                </svg>
                <span v-if="state.idioma == 'PT'">
                  Faça avaliação física pelo seu smartphone e envie o resultado
                  e as comparações para seus alunos via whatsapp</span
                >
                <span v-else>
                  Realiza pruebas físicas con tu smartphone y envía los
                  resultados y comparativas a tus alumnos vía WhatsApp</span
                >
              </p>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-check-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                  />
                </svg>
                <span v-if="state.idioma == 'PT'">
                  Controle a quantidade de alunos de cada personal</span
                >
                <span v-else>
                  Controlar el número de alumnos en cada personal</span
                >
              </p>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-check-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                  />
                </svg>
                <span v-if="state.idioma == 'PT'">
                  Prescreva e gerencie treinos</span
                >
                <span v-else> Prescribir y gestionar entrenamientos</span>
              </p>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-check-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                  />
                </svg>
                <span v-if="state.idioma == 'PT'">
                  Crie planos personalizados para os seus clientes, gere
                  contratos, envie avisos sobre o vencimento da matrícula</span
                >
                <span v-else>
                  Crea planes personalizados para tus clientes, genera
                  contratos, envía avisos sobre el vencimiento de
                  registros</span
                >
              </p>
              <button class="btss" type="button" @click="abrirModal()">
                <span v-if="state.idioma == 'PT'">Solicitar Demonstração</span>
                <span v-else>Solicitar Demostración</span>
              </button>
            </div>
            <div class="col-md-6">
              <img
                class="sifit_fone"
                src="../../assets/img_new/sifit_fone.png"
                alt="Sift_Phone"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="banner_4">
        <div class="container">
          <div class="title" v-if="state.idioma == 'PT'">
            <h1>NOSSOS PLANOS</h1>
            <p>Escolha a opção que mais se encaixa no seu negócio.</p>
          </div>

          <div class="title" v-else>
            <h1>NUESTROS PLANES</h1>
            <p>Elige la opción que mejor se adapta a tu negocio..</p>
          </div>

          <div class="row">
            <div
              class="col-md-6 col-lg-3"
              v-for="(plano, index) in state.planos"
              :key="index"
            >
              <div class="btn_popular" v-if="index == 2">MAIS POPULAR</div>

              <div :class="index == 2 ? 'coluna2' : 'coluna'">
                <p class="plano" :class="index == 2 ? 'plano2' : 'plano'">
                  {{ plano.nome }}
                </p>
                <h1 :class="index == 2 ? 'RS1' : 'RS'">
                  {{ plano.valor
                  }}<span
                    :class="index == 2 ? 'spn1' : 'spn'"
                    v-if="state.idioma == 'PT'"
                    >por mês</span
                  >
                  <span :class="index == 2 ? 'spn1' : 'spn'" v-else
                    >por mês</span
                  >
                </h1>
                <hr />
                <div v-for="(vantagem, i) in plano.vantagens" :key="i">
                  <div class="paragrafo">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-check-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                      />
                    </svg>
                    <div class="cont_paragrafo">{{ vantagem.nome }}</div>
                  </div>
                  <div style="clear: both"></div>
                </div>
                <div class="btns">
                  <button
                    class="btn1"
                    type="button"
                    @click="abrirModal(plano.id)"
                  >
                    <span v-if="state.idioma == 'PT'">Escolher plano</span>
                    <span v-else>Elige Plan</span>
                  </button>
                  <a href="/contato">
                    <button class="btn2" type="button">
                      <span v-if="state.idioma == 'PT'"
                        >Fale com nossa equipe</span
                      >
                      <span v-else>Habla con nuestro equipo</span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="banner_5" v-if="state.idioma == 'PT'">
        <div class="container">
          <div class="row">
            <div class="col-lg-5 d-lg-none d-xl-none">
              <div class="contents">
                <div class="tp">
                  <h1 class="ge">QUEM USA SIFIT</h1>
                  <h1 class="dsa">RECOMENDA</h1>
                  <p class="txt">
                    Venha fazer parte da comunidade de gestores de academia que
                    facilitaram sua vida e digitalizaram seu negócio com o
                    SIFIT. Não tem certeza se irá adaptar-se ao sistema?
                    <strong @click="abrirModal()" class="testeGratis"
                      >Teste grátis!</strong
                    >
                  </p>
                </div>
                <button class="btt" type="button" @click="abrirModal()">
                  <span>Solicitar Demonstração</span>
                </button>
              </div>
            </div>
            <div class="col-lg-7 space-l">
              <div>
                <img
                  class="coracao"
                  src="../../assets/img_new/coracao.png"
                  alt="Coração"
                />
                <img
                  class="ft1"
                  src="../../assets/img_new/ft1.png"
                  alt="Foto 1"
                />
                <div class="quad">
                  <p class="pb">
                    O sistema Sifit é um sistema completo! Tem nos ajudado muito
                    com o controle de alunos, fluxo de entrada e saída e nas
                    avaliações.
                  </p>
                  <p class="pv">
                    Rúbia - Academia Intense Fitness Umuarama - PR
                  </p>
                </div>
              </div>
              <div style="clear: both"></div>
              <div class="div_segundo_quadro">
                <img
                  class="like"
                  src="../../assets/img_new/like.png"
                  alt="like"
                />
                <div class="quad2">
                  <p class="pp">
                    Uso o sifit e com certeza está me ajudando muito como
                    sistema para personal. Indico para todos.
                  </p>
                  <p class="pa">Wallef Rocha Branco - Personal Umuarama - PR</p>
                </div>
                <img
                  class="ft2"
                  src="../../assets/img_new/ft2.png"
                  alt="Foto 2"
                />
              </div>
            </div>
            <div class="col-md-5 d-none d-sm-none d-md-none d-lg-block">
              <div class="contents">
                <div class="tp">
                  <h1 class="ge">QUEM USA SIFIT</h1>
                  <h1 class="dsa">RECOMENDA</h1>
                  <p class="txt">
                    Venha fazer parte da comunidade de gestores de academia que
                    facilitaram sua vida e digitalizaram seu negócio com o
                    SIFIT. Não tem certeza se irá adaptar-se ao sistema?
                    <strong @click="abrirModal()" class="testeGratis"
                      >Teste grátis!</strong
                    >
                  </p>
                </div>
                <button class="btt" type="button" @click="abrirModal()">
                  <span>Solicitar Demonstração</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="banner_6">
        <div class="container">
          <div class="title2" v-if="state.idioma == 'PT'">
            <h1 class="ge">TORNE O SEU DIA-A-DIA</h1>
            <h1 class="dsa">MAIS PRÁTICO</h1>
          </div>

          <div class="title2" v-else>
            <h1 class="ge">HAZ TU DÍA A DÍA</h1>
            <h1 class="dsa">MÁS PRÁCTICO</h1>
          </div>

          <a href="/"
            ><img
              class="logo1"
              src="../../assets/img_new/logo1.png"
              alt="Sifit"
          /></a>
          <div class="social">
            <a href="https://www.facebook.com/sistemasifit" target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-facebook"
                viewBox="0 0 16 16"
              >
                <path
                  d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"
                />
              </svg>
            </a>
            <a href="https://www.instagram.com/sistema_sifit/" target="blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-instagram"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"
                />
              </svg>
            </a>
          </div>
        </div>
      </section>
      <bottom :idioma="state.idioma" />
    </main>
  </div>
  <Modal
    :open="state.modalOpen"
    @close="state.modalOpen = !state.modalOpen"
    :width="'800px'"
  >
    <div class="container" v-if="state.pedidoEnviado == false">
      <button class="btn_transparent" @click="fecharModal">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          class="svg_fechar"
          viewBox="0 0 16 16"
        >
          <path
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
      </button>
      <h1 class="modal_h1">VOCÊ ESTÁ A UM PASSO DE SIMPLIFICAR</h1>
      <h1 class="modal_h2">A GESTÃO DA SUA ACADEMIA!</h1>
      <p class="p_modal">Informação do Plano</p>
      <div class="row">
        <div class="col-sm-8">
          <label for="">Plano:</label>
          <select class="form-select" v-model="state.demonstracao.plano_id">
            <option
              v-for="(plano, index) in state.planos"
              :key="index"
              :value="plano.id"
            >
              {{ plano.nome }}
            </option>
          </select>
        </div>
        <div class="col-sm-4">
          <label for="">Data de Vencimento:</label>
          <select
            class="form-select"
            v-model="state.demonstracao.data_vencimento"
          >
            <option value="05">dia / 05</option>
            <option value="10">dia / 10</option>
            <option value="15">dia / 15</option>
            <option value="20">dia / 20</option>
          </select>
        </div>
      </div>
      <p class="p_modal">Informações de Contato</p>
      <div class="row">
        <div class="col-sm-6">
          <label for="">Empresa (Se houver):</label>
          <input
            v-model="state.demonstracao.empresa"
            type="text"
            class="form-control"
          />
        </div>

        <div class="col-sm-6">
          <label for="">Nome do Responsável:</label>
          <input
            v-model="state.demonstracao.nome_responsavel"
            type="text"
            class="form-control"
          />
        </div>
      </div>
      <div class="row">
        <!--
        <div class="col-sm-3">
          <label for="">CPF/CNPJ:</label>
          <input
            v-model="state.demonstracao.cpf_cnpj"
            type="text"
            class="form-control"
          />
        </div>
        -->
        <div class="col-sm-7">
          <label for="">Email:</label>
          <input
            v-model="state.demonstracao.email"
            type="text"
            class="form-control"
          />
        </div>
        <div class="col-sm-5">
          <label for="">Telefone:</label>
          <input
            v-model="state.demonstracao.telefone"
            type="text"
            class="form-control"
            v-mask="['(##) ####-####', '(##) #####-####']"
          />
        </div>
      </div>
      <div class="div_btn_modal">
        <button class="btn_enviar_modal" type="button" @click="handleSubmit">
          <span>Quero Melhorar Minha Gestão!</span>
        </button>
      </div>
    </div>
    <div class="container" v-else>
      <div style="padding-top: 60px; padding-bottom: 60px">
        <h1 class="modal_h2">PARABÉNS PELA ESCOLHA!</h1>
        <h1 class="modal_h1">
          EM BREVE UM CONSULTOR ENTRARÁ EM CONTATO COM VOCÊ
        </h1>
      </div>
      <div class="div_btn_modal">
        <button
          class="btn_enviar_modal"
          type="button"
          @click="fecharModalEnvidado"
        >
          <span>FECHAR</span>
        </button>
      </div>
    </div>
  </Modal>
</template>
<script>
import { onMounted, reactive, ref } from "vue";
import services from "../../services";
import Topo from "../../layout/Site/topoSite.vue";
import Bottom from "../../layout/Site/bottomSite.vue";
import Modal from "../../components/EstruturaModal/EstruturaModal.vue";
import { mask } from "vue-the-mask";
export default {
  components: { Modal, Topo, Bottom },
  directives: { mask },
  setup(_, { emit }) {
    const ico_controle_financeiro = ref(null);
    const ico_avaliacao_fisica = ref(null);
    const ico_controle_acesso = ref(null);
    const state = reactive({
      demonstracao: {
        plano_id: 0,
        data_vencimento: "",
        empresa: "",
        nome_responsavel: "",
        cpf_cnpj: "",
        email: "",
        telefone: "",
      },
      planos: [],
      modalOpen: false,
      hasError: false,
      visualizar_controle_fin: false,
      visualizar_avaliacao_fis: false,
      visualizar_controle_ace: false,
      pedidoEnviado: false,
      idioma: "PT",
    });
    onMounted(() => {
      fetchPlanos();
      // table.width='100px';
    });

    function alterarIdioma(idioma) {
      state.idioma = idioma;
    }

    function abrirModal(plano_id) {
      if (plano_id != undefined) {
        state.demonstracao.plano_id = plano_id;
      }
      state.modalOpen = true;
    }
    function fecharModal() {
      state.modalOpen = false;
    }

    function fecharModalEnvidado() {
      state.modalOpen = false;
      setTimeout(() => {
        state.pedidoEnviado = false; // altera o conteúdo após 1 segundo
      }, 1000);
    }

    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }
    async function handleSubmit() {
      //console.log('Nome: '+state.nome )
      const { errors } = await services.site.salvarPedido({
        plano_id: state.demonstracao.plano_id,
        data_vencimento: state.demonstracao.data_vencimento,
        empresa: state.demonstracao.empresa,
        nome_responsavel: state.demonstracao.nome_responsavel,
        cpf_cnpj: state.demonstracao.cpf_cnpj,
        email: state.demonstracao.email,
        telefone: state.demonstracao.telefone,
      });

      state.pedidoEnviado = true;

      console.log(errors);
    }

    function controleFinanceiro() {
      if (state.visualizar_controle_fin == true) {
        ico_controle_financeiro.value.style.transform = "rotate(0deg)";
        state.visualizar_controle_fin = false;
      } else {
        ico_controle_financeiro.value.style.transform = "rotate(180deg)";
        state.visualizar_controle_fin = true;
      }
    }
    ico_avaliacao_fisica;
    function avaliacaoFisica() {
      if (state.visualizar_avaliacao_fis == true) {
        ico_avaliacao_fisica.value.style.transform = "rotate(0deg)";
        state.visualizar_avaliacao_fis = false;
      } else {
        ico_avaliacao_fisica.value.style.transform = "rotate(180deg)";
        state.visualizar_avaliacao_fis = true;
      }
    }
    function controleAcesso() {
      if (state.visualizar_controle_ace == true) {
        ico_controle_acesso.value.style.transform = "rotate(0deg)";
        state.visualizar_controle_ace = false;
      } else {
        ico_controle_acesso.value.style.transform = "rotate(180deg)";
        state.visualizar_controle_ace = true;
      }
    }

    async function fetchPlanos() {
      try {
        state.isLoading = true;
        const { data } = await services.planosSifit.getAll({});
        //  console.log(data)
        state.planos = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    return {
      abrirModal,
      fecharModal,
      fecharModalEnvidado,
      handleSubmit,
      controleFinanceiro,
      avaliacaoFisica,
      controleAcesso,
      emit,
      state,
      ico_controle_financeiro,
      ico_avaliacao_fisica,
      ico_controle_acesso,
      alterarIdioma,
    };
  },
};
</script>
<style>
@import "../../app.scss";
</style>
<style scoped>
html {
  scroll-behavior: smooth;
}
body {
  background: #000d1f;
  /*---------<section id="banner_1">-------------*/
}
p {
  font-family: "Montserrat";
  font-weight: 500;
}
#banner_1 {
  margin-top: 80px;
}
#banner_1 h1 {
  font-family: "Exo";
  font-size: 46px;
  margin: 0 0 0 0;
  margin-bottom: 0;
}
#banner_1 p {
  margin-bottom: 0;
}
.banner1_r {
  text-align: right;
}
.sw {
  color: #034aa6;
  margin-top: 70px;
}
.ge {
  color: white;
  font-size: 48px;
  width: 100%;
}
.dsa {
  color: #aede00;
  font-size: 48px;
  line-height: 1;
}
.txt1 {
  color: white;
  text-align: justify;
  width: 100%;
  margin-top: 30px;
  line-height: 1;
}
.btn_linha {
  border: #505050 1px solid;
  border-radius: 8px;
  text-align: right;
  padding: 10px;
  margin-top: 30px;
  transform: none;
}
/*---Botão---*/
.btn {
  font-family: "Montserrat";
  font-style: normal;
  text-align: center;
  color: white;
  cursor: pointer;
  background-color: #034aa6;
}
.img_whats {
  z-index: 100;
  position: fixed;
  right: 25px;
  width: 60px;
}
.btn:hover {
  margin-top: 1px;
  border-bottom: none;
  transform: none;
}
.btn span {
  position: relative;
  transition: 0.2s;
}
.btn span:after {
  margin-top: 0px;
  content: "\2794";
  position: absolute;
  opacity: 0;
  top: 0px;
  right: -10px;
  transition: 0.2s;
  color: white;
}
.btn:hover span {
  padding-right: 10px;
  color: white;
}
.btn:hover span:after {
  opacity: 1;
  right: 1;
}
/*---------<section id="banner_2">-------------*/

#banner_2 {
  background-image: url("../../assets/img_new/woman-doing.png");
  height: 750px;
  background-position: left;
  background-repeat: no-repeat;
  margin-top: 200px;
}
#banner_2 {
  margin-top: 80px;
}
#banner_2 h1 {
  font-family: "Exo";
  margin: 0 0 0 0;
  margin-bottom: 0;
}
#banner_2 p {
  margin-bottom: 0;
}

.conteudo {
  /* background-image: radial-gradient(rgba(0,0,0,0), rgba(0,13,31,1) 70%);*/
  /*background-image: linear-gradient(to right, rgba(0,0,0,0), rgba(0,13,31,1));*/
  background: repeating-linear-gradient(
      to right,
      rgba(0, 13, 31, 1) 10%,
      rgba(0, 0, 0, 0) 60%,
      rgba(0, 13, 31, 1) 90%
    ),
    repeating-linear-gradient(
      to bottom,
      rgba(0, 13, 31, 1),
      rgba(0, 0, 0, 0),
      rgba(0, 13, 31, 1)
    );

  width: 100%;
  height: 750px;
}
.space {
  margin-top: 50px;
}
.ap {
  color: white;
  width: 100%;
  line-height: 1;
  font-size: 26px;
}
.on {
  color: #aede00;
  font-size: 26px;
  line-height: 1;
  margin-bottom: 20px;
}
.titulo_ret {
  font-size: 18pt;
}
.txt {
  width: 100%;
  line-height: 1;
  margin-top: 10px;
  padding-bottom: 15px;
}
.ret {
  width: 100%;
  padding: 30px;
  border: white solid 1px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
  margin-bottom: 10px;
  cursor: pointer;
  transition: transform 1s;
}
.ret h1,
p {
  color: white;
}
.text2 {
  color: white;
  margin-left: 64px;
  line-height: 1;
  transition: height 300ms cubic-bezier(0.3, 0, 0.6, 1);
}
.icon {
  margin-right: 10px;
  color: white;
  background: #aede00;
  border: #aede00 solid 1px;
  border-radius: 10px;
  width: 40px;
}
.triangle {
  float: right;
  font-size: 10px;
}
/*---------<section id="banner_3">-------------*/
#banner_3 {
  margin-top: 50px;
  padding-top: 110px;
  padding-bottom: 120px;
  background-image: linear-gradient(
    to bottom,
    rgba(1, 13, 31, 0.5),
    rgba(3, 74, 166, 0.47),
    rgba(1, 13, 31, 0.5)
  );
}
#banner_3 {
  padding-bottom: 30px;
}
#banner_3 h1 {
  font-family: "Exo";
  font-weight: 800;
  size: 64px;
  margin: 0 0 0 0;
  margin-bottom: 0;
}
#banner_3 p {
  margin-bottom: 0;
  font-weight: 600;
}
.tm {
  margin-top: 100px;
}
.tm h1 {
  font-size: 40px;
}
.ge h1 {
  margin-top: 50px;
}
#banner_3 p {
  color: white;
  margin-bottom: 15px;
  font-weight: 500;
}
svg {
  color: #aede00;
}
.sifit_fone {
  height: 700px;
  margin-left: 20px;
}
/*-------botao banner_3----------*/
.btss:hover span:after {
  opacity: 1;
  right: 1;
}
.btss {
  border-radius: 8px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  text-align: center;
  border: #aede00 solid 1px;
  color: #aede00;
  background-color: #000d1f;
  cursor: pointer;
  padding: 10px;
}
.btss span {
  position: relative;
  transition: 0.2s;
}
.btss:hover {
  background-color: #aede00;
  border: #aede00 solid 1px;
  color: #000d1f;
}
.btss span:after {
  margin-top: 0px;
  content: "\2794";
  position: absolute;
  opacity: 0;
  top: 0px;
  right: -10px;
  transition: 0.2s;
  margin-top: -2px;
}
.btss:hover span {
  padding-right: 15px;
  margin-right: 15px;
}
/*---------<section id="banner_4">-------------*/
#banner_4 {
  background-image: url("../../assets/img_new/peso.png");
  height: 680px;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 50px;
  background-size: cover;
}
hr {
  margin: 0 0 10px 0;
}
.title {
  color: white;
  padding-top: 50px;
}
.title h1 {
  color: white;
  font-size: 23pt;
}
.btn_popular {
  padding: 7px;
  position: absolute;
  background: #aede00;
  border: #aede00 solid 1px;
  border-radius: 13px;
  color: white;
  font-family: "Montserrat";
  font-size: 80%;
  font-weight: 900;
  text-align: center;
  margin-left: 146px;
  margin-top: -10px;
  z-index: 20;
}
.plano {
  font-size: 20px;
  font-weight: bolder;
  color: #000000;
  margin-bottom: 0%;
}
.RS {
  color: #000000;
}
.spn {
  font-size: 15px;
  color: #272626;
}
.coluna {
  position: relative;
  padding: 20px;
  height: 500px;
  border-radius: 35px 0px;
  background: linear-gradient(
    177.25deg,
    rgba(255, 255, 255, 0.63) 1.86%,
    rgba(125, 125, 125, 0.342) 111.88%
  );
  border: rgb(220, 218, 218) solid 2px;
}
.coluna:hover {
  background: linear-gradient(
    177.25deg,
    rgba(255, 255, 255, 0.693) 1.86%,
    rgba(140, 140, 140, 0.342) 111.88%
  );
  border: #aede00 solid 2px;
  transition: 0.2s;
}
.coluna .paragrafo {
  font-weight: bold;
  color: #000000;
}
.btns {
  position: absolute;
  text-align: center;
  bottom: 10px;
  left: 0;
  width: 100%;
}
/*----botao (escolher plano)------------*/
.btn1:hover span:after {
  opacity: 1;
  right: 1;
}
.btn1 {
  border-radius: 8px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  border: #aede00 solid 1px;
  color: #000d1f;
  background-color: #aede00;
  cursor: pointer;
  padding: 10px;
  width: 80%;
  margin-bottom: 5px;
}
.btn1 span {
  position: relative;
  transition: 0.2s;
}
.btn1:hover {
  background-color: #aede00;
  border: #aede00 solid 1px;
  color: #000d1f;
}
.btn1 span:after {
  margin-top: 0px;
  content: "\2794";
  position: absolute;
  opacity: 0;
  top: 0px;
  right: -10px;
  transition: 0.2s;
  margin-top: -2px;
}
.btn1:hover span {
  padding-right: 15px;
  margin-right: 15px;
}
/*----botao (Fala com nossa equipe)----*/
.btn2 {
  border-radius: 8px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  font-size: small;
  border: #aede00 solid 1px;
  color: #aede00;
  background-color: rgba(158, 158, 158, 0.342);
  cursor: pointer;
  padding: 10px;
  width: 80%;
}
.btn2 span {
  position: relative;
  transition: 0.2s;
}
.btn2:hover {
  background-color: rgba(107, 107, 107, 0.342);
  border: #aede00 solid 1px;
}
.paragrafo svg {
  display: inline-block;
  margin-top: 3px;
  float: left;
}
.cont_paragrafo {
  display: inline-block;
  width: 80%;
  margin-left: 10px;
  float: left;
}
.coluna2 {
  padding: 20px;
  height: 500px;
  border-radius: 35px 0px;
  background: #034aa6;
  position: relative;
}
.coluna2:hover {
  background: #083877;
  transition: 0.2s;
}
.coluna2 .paragrafo {
  font-weight: bold;
  color: #ffffff;
}
.plano2 {
  font-size: 20px;
  font-weight: bolder;
  color: #ffffff;
  margin-bottom: 0%;
}
.RS1 {
  color: #ffffff;
}
.spn1 {
  font-size: 15px;
  color: #c7c7c7;
}
.btn12:hover span:after {
  opacity: 1;
  right: 1;
}
.btn12 {
  border-radius: 8px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  border: #aede00 solid 1px;
  color: #000d1f;
  background-color: #aede00;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  margin-bottom: 5px;
}
.btn12 span {
  position: relative;
  transition: 0.2s;
}
.btn12:hover {
  background-color: #aede00;
  border: #aede00 solid 1px;
  color: #000d1f;
}
.btn12 span:after {
  margin-top: 0px;
  content: "\2794";
  position: absolute;
  opacity: 0;
  top: 0px;
  right: -10px;
  transition: 0.2s;
  margin-top: -2px;
}
.btn12:hover span {
  padding-right: 15px;
  margin-right: 15px;
}
.btns2 {
  margin-top: 53px;
}
.testeGratis {
  cursor: pointer;
}
/*-------- <section id="banner_5">--------------*/
#banner_5 h1 {
  font-family: "Exo";
  size: 64px;
  margin-bottom: 0;
}
#banner_5 p {
}
#banner_5 a {
  color: white;
}
/*-----botao----*/
.tp {
  margin-top: 130px;
  margin-left: 50px;
  margin-bottom: 50px;
}
.btt:hover span:after {
  opacity: 1;
  right: 1;
}
.btt {
  margin-left: 50px;
  border-radius: 8px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  text-align: center;
  border: #aede00 solid 1px;
  color: #aede00;
  background-color: #000d1f;
  cursor: pointer;
  padding: 10px;
}
.btt span {
  position: relative;
  transition: 0.2s;
}
.btt:hover {
  background-color: #aede00;
  border: #aede00 solid 1px;
  color: #000d1f;
}
.btt span:after {
  margin-top: 0px;
  content: "\2794";
  position: absolute;
  opacity: 0;
  top: 0px;
  right: -10px;
  transition: 0.2s;
  margin-top: -2px;
  color: #000d1f;
}
.btt:hover span {
  padding-right: 15px;
  margin-right: 15px;
}
.space-l {
  padding-top: 20px;
}
.ft1 {
  margin-top: 250px;
  float: left;
  bottom: 0;
}
.ft2 {
  margin-top: 15px;
  margin-left: 5px;
  position: inline-block;
}
.quad {
  width: 330px;
  height: 200px;
  left: 214.31px;
  padding: 25px;
  padding-left: 40px;
  margin-top: -50px;
  background: rgba(3, 74, 166, 1);
  border-radius: 41.0147px 41.0147px 41.0147px 0px;
  display: inline-block;
}
.quad .pb {
  color: white;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
}
.quad .pv {
  color: #aede00;
  font-size: 15px;
}
.quad2 {
  padding: 30px;
  width: 330px;
  height: 180px;
  background: rgba(255, 255, 255, 1);
  border-radius: 41.0147px 41.0147px 0px 41.0147px;
  display: inline-block;
}
.quad2 .pp {
  color: #000000;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.quad2 .pa {
  color: rgb(7, 4, 219);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.like {
  float: left;
  display: inline-block;
  margin-left: 70px;
  margin-right: 30px;
}
.coracao {
  float: right;
  display: inline-block;
  margin-right: 220px;
  margin-top: 0px;
}
.div_segundo_quadro {
  margin-top: 40px;
}
/*-------<section id="banner_6">----------*/
#banner_6 {
  background: #034aa6;
}
.title2 {
  margin-top: 100px;
  padding-top: 50px;
}
.logo1 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.social {
  float: right;
  margin-top: 45px;
  margin-bottom: 10px;
}
.social svg {
  width: 50px;
  height: 60px;
  padding: 10px;
}
/*----MODAL----*/
.svg_fechar {
  color: rgb(0, 0, 0);
  width: 100%;
  height: 50px;
}
.btn_transparent {
  background: transparent;
  float: right;
  border: none;
}
.modal_h1 {
  font-family: "Exo";
  color: #000000;
  font-size: 18pt;
  line-height: 1;
}
.modal_h2 {
  font-family: "Exo";
  color: #aede00;
  font-size: 20pt;
}
.p_modal {
  font-family: "Exo";
  color: #000;
  font-size: 14pt;
  margin-top: 0.4rem;
  margin-bottom: 0.3rem;
}
.btn_enviar_modal {
  font-family: "Montserrat";
  font-style: normal;
  text-align: center;
  color: white;
  cursor: pointer;
  border-radius: 15px;
  padding: 10px 8px;
  background-color: #034aa6;
  border: #034aa6 solid 1px;
  font-weight: 600;
}
.btn_enviar_modal:hover {
  background-color: #00367d;
  border: #00367d solid 1px;
}
.div_btn_modal {
  width: 100%;
  text-align: center;
  margin-top: 15px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  #banner_2 h1 {
    font-size: 20px;
  }
  .coluna {
    padding: 25px;
    height: 515px;
  }
  .coluna .paragrafo {
    color: #000000;
    font-size: small;
  }
  .coluna2 {
    padding: 20px;
    height: 515px;
  }
  .coluna2 .paragrafo {
    color: #ffffff;
    font-size: small;
  }
  .btn_popular {
    padding: 7px;
    position: absolute;
    background: #aede00;
    border: #aede00 solid 1px;
    border-radius: 13px;
    color: white;
    font-family: "Montserrat";
    font-size: 70%;
    font-weight: 900;
    text-align: center;
    margin-left: 146px;
    margin-top: -25px;
    z-index: 20;
  }
  .btn1 {
    border-radius: 8px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    border: #aede00 solid 1px;
    color: #000d1f;
    background-color: #aede00;
    cursor: pointer;
    padding: 7px;
    width: 80%;
    margin-bottom: 5px;
  }
  .btn2 {
    border-radius: 8px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    font-size: small;
    border: #aede00 solid 1px;
    color: #aede00;
    background-color: rgba(158, 158, 158, 0.342);
    cursor: pointer;
    padding: 10px;
    width: 80%;
    margin-bottom: 10px;
  }
  .plano {
    font-size: 15px;
    font-weight: bolder;
    color: #000000;
    margin-bottom: 0%;
  }
  .RS {
    color: #000000;
    font-size: 25px;
  }
  .spn {
    font-size: 12px;
    color: #272626;
  }

  .RS1 {
    color: #ffffff;
    font-size: 25px;
  }
  .spn1 {
    font-size: 12px;
    color: #c7c7c7;
  }
  .like {
    float: left;
    display: inline-block;
    margin-left: 20px;
    margin-right: 10px;
  }
  .coracao {
    float: right;
    display: inline-block;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: -120px;
  }
  .ge {
    color: white;
    font-size: 40px;
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sw {
    color: #034aa6;
    margin-top: 0px;
    text-align: center;
  }
  #banner_1 h1 {
    text-align: center;
  }
  #banner_1 {
    margin-top: 50px;
  }
  .banner1_r img {
    width: 100%;
  }
  .txt1 {
    color: white;
    text-align: center;
    width: 100%;
    margin-top: 20px;
    line-height: 1;
    padding: 20px;
  }

  .btn_linha {
    text-align: left;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 20px;
  }
  .space {
    margin-top: 80px;
  }
  .txt {
    text-align: center;
  }
  .ap {
    text-align: center;
  }
  .on {
    text-align: center;
  }
  .sifit_fone {
    height: 550px;
    margin-left: 10px;
    margin-top: 80px;
  }
  #banner_3 {
    padding-top: 10px;
  }
  .title {
    text-align: center;
  }
  .coluna,
  .coluna2 {
    margin-top: 40px;
  }
  #banner_4 {
    height: 1150px;
  }
  .btn_popular {
    margin-left: 210px;
    margin-top: 20px;
  }
  .ge {
    text-align: center;
    font-size: 36px;
  }
  .dsa {
    text-align: center;
    font-size: 36px;
  }
  .txt {
    text-align: center;
    padding-bottom: 0px;
  }
  .btt {
    margin-left: 265px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  #banner_1 h1 {
    text-align: center;
    font-size: 35px;
  }
  .sw {
    text-align: center;
    margin-top: 0px;
  }
  .txt1 {
    text-align: center;
  }
  .btn_linha {
    margin-left: 15px;
    margin-right: 15px;
    text-align: left;
  }
  img {
    width: 80%;
    margin-top: 25px;
  }
  .icon {
    width: 50px;
    height: 50px;
  }
  .ap {
    text-align: center;
  }
  .on {
    text-align: center;
  }
  .txt {
    text-align: center;
  }
  #banner_2 {
    margin-top: 100px;
  }
  #banner_3 {
    margin-top: 0px;
    padding-top: 0px;
  }
  .sifit_fone {
    width: 50%;
    height: 400px;
    margin-left: 150px;
  }
  #banner_4 {
    height: 2300px;
    padding: 70px;
    margin-top: 0px;
  }
  .coluna {
    margin-top: 15px;
  }
  .coluna2 {
    margin-top: 15px;
  }
  .title {
    text-align: center;
  }
  .btn_popular {
    margin-left: 310px;
    margin-top: 5px;
    padding: 5px;
  }
  .ge {
    font-size: 35px;
    text-align: center;
  }
  .dsa {
    font-size: 35px;
    text-align: center;
  }
  .tp {
    margin-left: 0px;
    margin-top: 85px;
    margin-bottom: 0px;
  }
  .btt {
    margin-left: 150px;
  }
  .ft1 {
    width: 15%;
    margin-top: 200px;
  }
  .coracao {
    display: none;
  }
  .like {
    display: none;
  }
  .ft2 {
    width: 15%;
  }
  .quad2[data-v-ddab4b06] {
    padding: 15px;
    width: 250px;
    height: 180px;
    margin-top: 25px;
  }
  .quad {
    margin-top: 70px;
  }
  .logo1 {
    width: 30%;
    padding-top: 0px;
  }
  .social svg {
    margin-top: 0px;
  }
  .ge {
    font-size: 25px;
  }
  .dsa {
    font-size: 25px;
  }
  .social {
    margin-top: 25px;
  }
}
@media (max-width: 575px) {
  .sw {
    margin-top: 0px;
    text-align: center;
  }
  #banner_1 {
    margin-top: 0px;
  }
  #banner_1 h1 {
    text-align: center;
    font-size: 30px;
  }
  .txt1 {
    text-align: center;
    padding: 5px;
  }
  .btn_linha {
    margin-left: 15px;
    margin-right: 15px;
    text-align: left;
  }
  img {
    width: 90%;
    margin-top: 30px;
  }
  #banner_2 {
    margin-top: 30px;
  }
  .ap {
    text-align: center;
    font-size: 30px;
  }
  .on {
    text-align: center;
    font-size: 30px;
  }
  .txt {
    padding: 5px;
    text-align: center;
  }
  .icon {
    width: 15%;
    margin-top: 0px;
  }
  h1 {
    font-size: 20px;
  }
  #banner_3 {
    margin-top: 0px;
    padding-top: 0px;
  }
  .tm {
    margin-top: 0px;
    text-align: center;
  }
  .ge {
    font-size: 30px;
  }
  .dsa {
    font-size: 30px;
  }
  p {
    padding: 5px;
  }
  .sifit_fone {
    display: none;
  }
  .title {
    padding-top: 0px;
  }
  #banner_4 {
    height: 2230px;
    margin-top: 30px;
    padding: 25px;
  }
  .coluna {
    margin-top: 20px;
  }
  .coluna2 {
    margin-top: 20px;
  }
  .btn_popular {
    margin-left: 231px;
    margin-top: 5px;
    padding: 4px;
  }
  .tp {
    margin-top: 50px;
    margin-left: 0px;
    margin-bottom: 0px;
    padding: 5px;
    text-align: center;
  }
  .btt {
    margin-left: 70px;
  }
  .coracao {
    display: none;
  }
  .like {
    display: none;
  }
  .ft1,
  .ft2,
  .quad,
  .quad2 {
    display: none;
  }
  .ge,
  .dsa {
    text-align: center;
  }
  .logo1 {
    width: 30%;
    padding-top: 0px;
  }
  .social {
    margin-top: 20px;
  }
}
</style>
