<template>
  <barra-lateral />
  <div style="background-color: #f8f9fe; min-height: 100vh">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <topo
            :titulo="'Contas a Receber'"
            @openOffcanvas="openOffcanvas"
          ></topo>
          <base-header type="gradient-success" class="pb-md-6 pb-md-8 pt-md-3">
            <div class="row">
              <div class="col-12 d-md-none">
                <router-link
                  to="/adicionar-conta-receber"
                  class="primary"
                  v-if="array_permissoes.includes('59')"
                >
                  <button
                    class="btn_conta"
                    style="margin-top: 24px"
                    type="buttons"
                  >
                    Adicionar Nova Conta
                  </button>
                </router-link>
                <button
                  class="btn_conta"
                  style="margin-top: 24px"
                  type="buttons"
                  v-else
                >
                  Adicionar Nova Conta
                </button>
              </div>
              <div class="col-3 col-lg-2 col-md-3">
                <label>Código</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Código"
                  v-model="state.codigo"
                />
              </div>
              <div class="col-9 col-lg-7 col-md-9">
                <label>Nome</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nome:"
                  v-model="state.nome_busca"
                />
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6 mb-2 d-none d-md-block">
                <router-link
                  to="/adicionar-conta-receber"
                  class="primary"
                  v-if="array_permissoes.includes('59')"
                >
                  <button
                    class="btn_conta"
                    style="margin-top: 24px"
                    type="buttons"
                  >
                    Adicionar Nova Conta
                  </button>
                </router-link>
                <button
                  class="btn_conta"
                  style="margin-top: 24px"
                  type="buttons"
                  v-else
                  disabled
                >
                  Adicionar Nova Conta
                </button>
              </div>
            </div>
            <div class="row linha2">
              <div class="col-sm-6 col-lg-3">
                <label>Tipo de Data</label>
                <select class="form-select" v-model="state.tipo_data">
                  <option value="Vencimento">Vencimento</option>
                  <option value="Recebimento">Recebimento</option>
                </select>
              </div>
              <div class="col-6 col-sm-6 col-lg-3 col-xl-2">
                <label>Data Inicial</label>
                <Datepicker
                  v-model="state.data_inicial_busca"
                  :locale="pt"
                  inputFormat="dd/MM/yyyy"
                  style="
                  display: block;
                  width: 100%;
                  padding:8px;
                  font-size: 0.875rem;
                  font-weight: 400;
                  line-height: 1.5;
                  color: #212529;
                  background-color: #fff;
                  background-clip: padding-box;
                  border: 1px solid #DEE2E6;
                  border-radius: 0.375rem;
                  box-shadow: none;
                  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
                }
            "
                />
              </div>
              <div class="col-6 col-sm-6 col-lg-3 col-xl-2">
                <label>Data Inicial</label>
                <Datepicker
                  v-model="state.data_inicial_busca"
                  :locale="pt"
                  inputFormat="dd/MM/yyyy"
                  style="
              display: block;
              width: 100%;
              padding: 8px;
              font-size: 0.875rem;
              font-weight: 400;
              line-height: 1.5;
              color: #212529;
              background-color: #fff;
              background-clip: padding-box;
              border: 1px solid #DEE2E6;
              border-radius: 0.375rem;
              box-shadow: none;
              transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
              }
            "
                />
              </div>
              <div class="col-sm-6 col-lg-3 col-xl-2">
                <label>Status</label>
                <select
                  class="form-select"
                  aria-placeholder="Status"
                  v-model="state.status"
                >
                  <option value="">Todos</option>
                  <option value="pendente">Pendente</option>
                  <option value="cancelado">Cancelado</option>
                  <option value="recebido">Recebido</option>
                  <option value="vencido">Vencido</option>
                </select>
              </div>
              <div class="col-lg-2">
                <button
                  type="primary"
                  class="btn_busca"
                  @click="buscarContaPorNomeCliente()"
                >
                  Buscar
                </button>
              </div>
            </div>
            <!-- Card stats -->
            <div class="row" v-if="array_permissoes.includes('103')">
              <div class="col-xl-3 mt-4 col-lg-6" v-if="state.valorPendente">
                <div class="card-stats">
                  <i class="bi bi-cash i_quad"></i>
                  <p class="title1">Valor Previsto para o mês</p>
                  <div class="num">R$ {{ state.total_mes }}</div>
                  <div class="conteudo">
                    <span class="arow">Valor para receber neste mês!</span>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 mt-4 col-lg-6" v-if="state.valorRecebido">
                <div class="card-stats">
                  <i class="bi bi-piggy-bank i_quad"></i>
                  <p class="title1">Valor Recebido</p>
                  <div class="num">R$ {{ state.total_recebido_mes }}</div>
                  <div class="conteudo">
                    <span class="arow"
                      >{{ state.percentual_recebido }} % recebido.</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-xl-3 mt-4 col-lg-6" v-if="state.qtd_pendente_mes">
                <div class="card-stats">
                  <i class="bi bi-cash-coin i_quad"></i>
                  <p class="title1">Quantidade de contas pendentes mês</p>
                  <div class="num">{{ state.qtd_pendente_mes }}</div>
                  <div class="conteudo">
                    <span class="arow">Contas ainda pendente este mês.</span>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 mt-4 col-lg-6" v-if="atrasoConta">
                <card class="card-stats">
                  <i class="bi bi-cash-coin i_quad"></i>
                  <p class="title1">Contas Atrasadas</p>
                  <div class="num">{{ state.contaAtrasada }}</div>
                  <div class="conteudo"></div>
                </card>
              </div>
            </div>
          </base-header>
          <div>
            <div
              class="card shadow p-3"
              :class="type === 'dark' ? 'bg-default' : ''"
              s
            >
              <div class="row" style="margin-top: 10px">
                <div class="col">
                  <div class="table-responsive">
                    <conta-receber-loading v-if="state.isLoading" />
                    <table
                      v-else
                      class="table tablesorter table align-items-center table-flush"
                    >
                      <thead class="thead-light">
                        <th>Código</th>
                        <th>Cliente</th>
                        <th class="th_display">Título</th>
                        <th>Valor Receber</th>
                        <th>Valor Recebido</th>
                        <th></th>
                      </thead>
                      <tbody class="list">
                        <corpo-tabela-conta-receber
                          v-for="conta in state.contas"
                          :key="conta.id"
                          :conta="conta"
                          @deletarContaReceber="deletarContaReceber"
                          @modalVisualizar="modalVisualizar"
                          :permissoes="array_permissoes"
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <OffcanvasComponent ref="offcanvasRef" />
  <Modal
    :open="state.isOpen"
    :width="'800px'"
    @close="state.isOpen = !state.isOpen"
  >
    <div class="modal_visu_contapagar">
      <h1 class="title_modal">Visualizar Conta a Receber</h1>
      <div class="row">
        <div class="col-md-3">
          <h3>Func.Cadastrante</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.nome_cadastrante }}</p>
          </div>
          <div v-if="state.modo == 'editar'">
            <input
              readonly
              type="text"
              class="form-control"
              v-model="state.nome_cadastrante"
            />
          </div>
        </div>
        <div class="col-md-3">
          <h3>Data Lançamento</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.data_cadastro }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <input
              readonly
              type="text"
              class="form-control"
              v-model="state.data_cadastro"
            />
          </div>
        </div>
        <div class="col-md-3">
          <h3>Resíduo</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.residuo }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <input
              readonly
              type="text"
              class="form-control"
              v-model="state.residuo"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <h3>Código</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.id_cliente }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <input
              readonly
              type="text"
              class="form-control"
              v-model="state.id_cliente"
            />
          </div>
        </div>
        <div class="col-md-6">
          <h3>Nome</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.nome_cliente }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <input
              readonly
              type="text"
              class="form-control"
              v-model="state.nome_cliente"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <h3>Código</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.id }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <input
              readonly
              type="text"
              class="form-control"
              v-model="state.id"
            />
          </div>
        </div>

        <div class="col-md-6">
          <h3>Título</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.nome }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <input
              readonly
              type="text"
              class="form-control"
              v-model="state.nome"
            />
          </div>
        </div>
        <div class="col-md-3">
          <h3 v-if="state.modo == 'visualizar'">Status</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.status }}</p>
          </div>
          <!--<div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'">
            <select type="text" class="form-control" v-model="state.status">
              <option value="recebido">Recebido</option>
              <option value="pendente">Pendente</option>
              <option value="vencido">Vencido</option>
            </select>
          </div>-->
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <h3>Valor a Receber</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.valor_receber }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <input
              readonly
              type="text"
              class="form-control"
              v-model="state.valor_receber"
            />
          </div>
        </div>
        <div class="col-md-3">
          <h3>Vencimento</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.data_vencimento }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <input
              type="text"
              class="form-control"
              v-model="state.data_vencimento"
            />
          </div>
        </div>
        <div
          class="col-md-3"
          v-if="state.status == 'recebido' || state.modo == 'editar'"
        >
          <h3>Valor Recebido</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.valor_recebido }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <input
              type="text"
              class="form-control"
              v-model="state.valor_recebido"
              v-mask-decimal.br="2"
            />
          </div>
        </div>
        <div
          class="col-md-3"
          v-if="state.status == 'recebido' || state.modo == 'editar'"
        >
          <h3>Data Recebimento</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.data_recebimento }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <Datepicker
              v-model="state.data_recebimento_date"
              :locale="pt"
              inputFormat="dd/MM/yyyy"
              style="
                border-color: #b8b8b8;
                color: #505050;
                border-radius: 15px;
                border: #b8b8b8 solid 2px;
                transition: 0.5s;
                width: 100%;
                padding: 7px;
              "
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-md-3"
          v-if="state.status == 'recebido' || state.modo == 'editar'"
        >
          <h3>Cód.Func.Recebedor</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.func_recebedor_id }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <input
              readonly
              type="text"
              class="form-control"
              v-model="state.func_recebedor_id"
            />
          </div>
        </div>
        <div
          class="col-md-3"
          v-if="state.status == 'recebido' || state.modo == 'editar'"
        >
          <h3>Func.Recebedor</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.nome_recebedor }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <input
              readonly
              type="text"
              class="form-control"
              v-model="state.nome_recebedor"
            />
          </div>
        </div>
        <div
          class="col-md-3"
          v-if="state.status == 'recebido' || state.modo == 'editar'"
        >
          <h3>Tipo de Pagamento</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.nome_tipo_pagamento }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <select
              class="form-select"
              v-model="state.tipo_pagamento"
              @change="carregarTipoPagamento($event.target.value)"
            >
              <select-tipo-pagamento
                v-for="tipo_pagamento in state.tipos_pagamento"
                :key="tipo_pagamento.id"
                :tipo_pagamento="tipo_pagamento"
              />
            </select>
          </div>
        </div>
        <div
          class="col-md-3"
          v-if="state.status == 'recebido' || state.modo == 'editar'"
        >
          <h3>Local Recebimento</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.tipo_caixa }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <select class="form-select" v-model="state.local_recebimento">
              <option
                v-for="local_recebimento in state.locais_recebimento"
                :key="local_recebimento.id"
                :value="local_recebimento.id"
              >
                {{ local_recebimento.nome }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h3>Observação</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.descricao }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <input type="text" class="form-control" v-model="state.descricao" />
          </div>
        </div>
      </div>
    </div>
    <div class="btn_direita">
      <div v-if="state.status == 'pendente' || state.status == 'vencido'">
        <button
          class="btn btn_efetuarPag my-4"
          type="button"
          @click="editarModo"
          v-if="state.modo == 'visualizar'"
        >
          Efetuar Recebimento
        </button>
      </div>
      <button
        class="btn btn_salvar my-4"
        id="submit-button"
        type="button"
        v-if="state.modo == 'editar'"
        @click="efetuarRec"
      >
        Salvar
      </button>
      <button
        class="btn btn_cancelar my-4"
        id="submit-button"
        type="button"
        @click="fecharModal"
        v-if="state.modo == 'visualizar'"
      >
        Fechar
      </button>
      <button
        class="btn btn_cancelar my-4"
        id="submit-button"
        type="button"
        @click="editarModo"
        v-if="state.modo == 'editar'"
      >
        Cancelar
      </button>
    </div>
  </Modal>
</template>
<script>
import SelectTipoPagamento from "../components/TipoPagamentoSelect.vue";
import { pt } from "date-fns/locale";
import Datepicker from "vue3-datepicker";
import Modal from "../components/EstruturaModal/EstruturaModal.vue";
import { onMounted, reactive, ref } from "vue";
import CorpoTabelaContaReceber from "../components/CorpoTabelaContasReceber";
import ContaReceberLoading from "../components/CorpoTabelaContasReceber/Loading";
import services from "../services";
import { useStorage } from "vue3-storage";
import BarraLateral from "../layout/Sistema/barraLateral";
import topo from "../components/topo";
import OffcanvasComponent from "../layout/Sistema/barraLateralMobile";

export default {
  name: "tables",
  components: {
    CorpoTabelaContaReceber,
    ContaReceberLoading,
    topo,
    Modal,
    Datepicker,
    BarraLateral,
    SelectTipoPagamento,
    OffcanvasComponent,
  },
  setup() {
    const offcanvasRef = ref(null);
    const isVisible = ref(false);
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");
    var colaborador_id = storage.getStorageSync("colaborador_id");
    var nome_pessoa = storage.getStorageSync("nome_pessoa");
    var permissoes = storage.getStorageSync("permissoes");

    var array_permissoes = [];
    for (let i = 0; i < permissoes.length; i++) {
      const element = permissoes[i];
      array_permissoes.push(element.id);
    }

    //var
    var today = new Date();
    //  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    //  var yyyy = today.getFullYear();

    ///  var primeiro_dia_mes_atual = "01/" + mm + "/" + yyyy;

    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    //  console.log("ultimo dia "+lastDayOfMonth);

    const state = reactive({
      //card-stats
      pendenteValor: false,
      recebidoValor: false,
      atrasoValor: false,
      atrasoConta: false,
      tipo_data: "Vencimento",
      nome_busca: "",
      codigo: "",
      isLoading: true,
      contas: [],
      currentClientType: "",
      hasError: false,
      modo: "visualizar",
      local_recebimento: "",
      locais_recebimento: "",
      status: "",
      data_inicial_busca: firstDayOfMonth,
      data_final_busca: lastDayOfMonth,
    });
    onMounted(() => {
      buscarContaPorNomeCliente();
      valoresTopo();
      valorPendente();
      valorRecebido();
      valorAtrasado();
      contaAtrasada();
      fetchLocalRecebimento();
      fetchTiposPagamento();
    });
    function editarModo() {
      if (state.modo == "visualizar") {
        state.modo = "editar";
      } else {
        state.modo = "visualizar";
      }
    }

    async function modalVisualizar(id) {
      state.isOpen = true;
      try {
        const { data } = await services.contaReceber.obterPorId({
          token: token,
          empresa_id: empresa_id,
          id: id,
        });
        if (data.status == "recebido") {
          state.func_recebedor_id = data.func_recebedor_id;
          state.nome_recebedor = data.nome_recebedor;
        } else {
          console.log(colaborador_id);
          console.log(nome_pessoa);
          state.func_recebedor_id = colaborador_id;
          state.nome_recebedor = nome_pessoa;
          state.data_recebimento_date = new Date();
        }
        state.nome_cadastrante = data.nome_cadastrante;
        state.data_cadastro = data.data_cadastro;
        state.status = data.status;
        state.residuo = data.residuo;
        state.id_cliente = data.id_cliente;
        state.nome_cliente = data.nome_cliente;
        state.id = data.id;
        state.nome = data.nome;
        state.valor_receber = data.valor_receber;
        state.data_vencimento = data.data_vencimento;
        state.valor_recebido = data.valor_receber;
        state.data_recebimento = data.data_recebimento;
        state.nome_tipo_pagamento = data.nome_tipo_pagamento;
        state.tipo_caixa = data.tipo_caixa;
        state.descricao = data.descricao;
      } catch (error) {
        console.log(error);
      }
    }

    function fecharModal() {
      state.isOpen = false;
      state.modo = "visualizar";
    }

    async function fetchLocalRecebimento() {
      try {
        //  state.isLoading = true;
        const { data } = await services.localRecebimento.listar({
          token: token,
          empresa_id: empresa_id,
        });
        state.locais_recebimento = data;
        //     state.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchContas() {
      try {
        state.isLoading = true;
        const { data } = await services.contaReceber.selecionaPorData({
          type: state.currentClientType,
          token: token,
          empresa_id: empresa_id,
        });
        state.contas = data;
        state.isLoading = false;
      } catch (error) {
        console.log(error);
        state.isLoading = false;
      }
    }

    async function carregarTipoPagamento(tipo_pagamento_id) {
      try {
        const { data } = await services.tipoPagamento.obterPorId({
          token: token,
          empresa_id: empresa_id,
          id: tipo_pagamento_id,
        });

        if (data.escolher_bandeira == "sim") {
          fetchBandeiras();
          state.modal_escolher_cartao = true;
          state.escolher_bandeira = "sim";
        } else {
          state.escolher_bandeira = "nao";
        }
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchBandeiras() {
      try {
        const { data } = await services.bandeira.listar({
          token: token,
          empresa_id: empresa_id,
        });
        state.bandeiras = data;
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchTiposPagamento() {
      try {
        const { data } = await services.tipoPagamento.listar({
          token: token,
          empresa_id: empresa_id,
        });
        state.tipos_pagamento = data;
      } catch (error) {
        console.log(error);
      }
    }

    async function buscarContaPorNomeCliente() {
      state.isLoading = true;
      if (state.codigo.length > 0) {
        try {
          const { data } = await services.contaReceber.obterPorId({
            token: token,
            empresa_id: empresa_id,
            id: state.codigo,
          });
          var array = [];
          array.push(data);
          state.contas = data;
        } catch (error) {
          console.log(error);
        }
      } else if (state.nome_busca.length > 0) {
        try {
          state.isLoading = true;
          const { data } = await services.contaReceber.contasPorNomeCliente({
            token: token,
            empresa_id: empresa_id,
            nome_cliente: state.nome_busca,
          });
          console.log(data);
          //  console.log(data)
          state.contas = data;
          //   state.pagination = data.pagination
          state.isLoading = false;
        } catch (error) {
          console.log(error);
        }
      } else {
        var dia_data_inicial = state.data_inicial_busca.getDate();
        if (dia_data_inicial < 10) {
          dia_data_inicial = "0" + dia_data_inicial;
        }

        var mes_data_inicial = state.data_inicial_busca.getMonth();
        mes_data_inicial = mes_data_inicial + 1;
        if (mes_data_inicial < 10) {
          mes_data_inicial = "0" + mes_data_inicial;
        }
        var data_inicial =
          dia_data_inicial.toString() +
          "/" +
          mes_data_inicial.toString() +
          "/" +
          state.data_inicial_busca.getFullYear().toString();

        //Data Final
        var dia_data_final = state.data_final_busca.getDate();
        if (dia_data_final < 10) {
          dia_data_final = "0" + dia_data_final;
        }

        var mes_data_final = state.data_final_busca.getMonth();
        mes_data_final = mes_data_final + 1;
        if (mes_data_final < 10) {
          mes_data_final = "0" + mes_data_final;
        }
        var data_final =
          dia_data_final.toString() +
          "/" +
          mes_data_final.toString() +
          "/" +
          state.data_final_busca.getFullYear().toString();

        if (state.status == "") {
          try {
            const { data } = await services.contaReceber.selecionaPorData({
              token: token,
              empresa_id: empresa_id,
              data_final,
              data_inicial,
              tipo_data: state.tipo_data,
            });
            state.contas = data;
          } catch (error) {
            console.log(error);
          }
        } else {
          try {
            const { data } = await services.contaReceber.selecionaPorDataStatus(
              {
                token: token,
                empresa_id: empresa_id,
                data_final,
                data_inicial,
                status: state.status,
                tipo_data: state.tipo_data,
              }
            );
            state.contas = data;
          } catch (error) {
            console.log(error);
          }
        }
      }
      state.isLoading = false;
    }
    async function deletarContaReceber(id) {
      if (confirm("Deseja deletar esta conta?")) {
        try {
          state.isLoading = true;
          const { data } = await services.contaReceber.deletarContasReceber({
            token: token,
            empresa_id: empresa_id,
            id,
          });
          console.log(data);
          if (data) {
            fetchContas();
          } else {
            alert("Erro ao deletar conta.");
          }
          state.isLoading = false;
        } catch (error) {
          console.log(error);
        }
      }
    }
    async function valorPendente() {
      try {
        const valorPendente = await services.contaReceber.valorPendente({
          token: token,
          empresa_id: empresa_id,
        });
        console.log(valorPendente);
        state.valorPendente = valorPendente.data;
      } catch (error) {
        console.log(error);
      }
    }

    async function valoresTopo() {
      try {
        const valores = await services.contaReceber.valoresTelaContaReceber({
          token: token,
          empresa_id: empresa_id,
        });
        console.log(valores.data);
        state.total_mes = valores.data.valor_total_receber_mes.valor_formatado;
        state.total_recebido_mes =
          valores.data.valor_total_recebido_mes.valor_formatado;
        state.percentual_recebido = valores.data.percentual_recebido;
        state.qtd_pendente_mes = valores.data.qtd_pendente_mes;
        //  state.valorPendente = valorPendente.data;
      } catch (error) {
        console.log(error);
      }
    }

    async function valorRecebido() {
      try {
        const valorRecebido = await services.contaReceber.valorRecebido({
          token: token,
          empresa_id: empresa_id,
        });
        state.valorRecebido = valorRecebido.data;
      } catch (error) {
        console.log(error);
      }
    }

    async function valorAtrasado() {
      try {
        const valorAtrasado = await services.contaReceber.valorAtrasado({
          token: token,
          empresa_id: empresa_id,
        });
        state.valorAtrasado = valorAtrasado.data;
      } catch (error) {
        console.log(error);
      }
    }

    async function contaAtrasada() {
      try {
        const contaAtrasada = await services.contaReceber.contaAtrasada({
          token: token,
          empresa_id: empresa_id,
        });
        state.contaAtrasada = contaAtrasada.data;
      } catch (error) {
        console.log(error);
      }
    }

    async function efetuarRec() {
      var data = new Date();
      var dia = String(data.getDate()).padStart(2, "0");
      var mes = String(data.getMonth() + 1).padStart(2, "0");
      var ano = data.getFullYear();
      var dataAtual = dia + "/" + mes + "/" + ano;

      //Verifica Se o caixa existe
      try {
        var obterAberto = await services.caixa.obterAberto({
          token: token,
          empresa_id: empresa_id,
          local_conta_id: state.local_recebimento,
        });
        //   console.log(obterAberto.data.data_inicial);
      } catch (error) {
        console.log(error);
      }
      //Se o caixa tiver a data de hoje
      if (obterAberto.data.data_inicial != dataAtual) {
        alert(obterAberto.data.data_inicial);
        if (!confirm("O caixa aberto não é de hoje, deseja continuar?")) {
          return;
        }
      }

      //   var dt_recebimento = state.data_recebimento_date;

      var dia_data_recebimento = state.data_recebimento_date.getDate();
      if (dia_data_recebimento < 10) {
        dia_data_recebimento = "0" + dia_data_recebimento;
      }

      var mes_data_recebimento = state.data_recebimento_date.getMonth();
      mes_data_recebimento = mes_data_recebimento + 1;
      if (mes_data_recebimento < 10) {
        mes_data_recebimento = "0" + mes_data_recebimento;
      }
      var data_recebimento =
        dia_data_recebimento.toString() +
        "/" +
        mes_data_recebimento.toString() +
        "/" +
        state.data_recebimento_date.getFullYear().toString();

      //    console.log(data_recebimento);

      try {
        const efetuarRec = await services.contaReceber.recebimento({
          token: token,
          empresa_id: empresa_id,
          id: state.id,
          func_recebedor_id: state.func_recebedor_id,
          data_vencimento: state.data_vencimento,
          valor_recebido_liquido: state.valor_recebido,
          valor_recebido: state.valor_recebido,
          data_recebimento: data_recebimento,
          tipo_pagamento_id: state.tipo_pagamento,
          local_recebimento_id: state.local_recebimento,
          caixa_id: obterAberto.data.id,
          descricao: state.descricao,
        });
        state.isOpen = false;
        console.log(efetuarRec);
        fetchContas();
      } catch (error) {
        console.log(error);
      }
    }
    async function buscarDataFinal() {
      try {
        const { data } = await services.contaReceber.buscarDataFinal({
          token: token,
          empresa_id: empresa_id,
          data: data.data_final,
        });
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    }
    async function buscarDataInicial() {
      try {
        const { data } = await services.contaReceber.buscarDataInicial({
          token: token,
          empresa_id: empresa_id,
          data: data.data_inicial,
        });
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    }
    const openOffcanvas = () => {
      // Estado para controlar a visibilidade do offcanvas
      console.log(isVisible.value);
      //offcanvasRef.value.showOffcanvas();
      if (isVisible.value) {
        offcanvasRef.value.hideOffcanvas();
      } else {
        offcanvasRef.value.showOffcanvas();
      }
    };
    return {
      state,
      buscarContaPorNomeCliente,
      deletarContaReceber,
      modalVisualizar,
      fecharModal,
      carregarTipoPagamento,
      editarModo,
      efetuarRec,
      buscarDataFinal,
      buscarDataInicial,
      pt,
      array_permissoes,
      openOffcanvas,
      offcanvasRef,
      openOffcanvas,
    };
  },
};
</script>
<style></style>
<style scoped>
@media (min-width: 1000px) and (max-width: 1400px) {
  .espaco_md {
    padding-left: 8rem;
  }
}

@media (min-width: 768px) and (max-width: 999px) {
  .espaco_md {
    padding-left: 8rem;
  }
}

.bv {
  font-weight: bold;
  font-size: 18pt;
  color: #162b48;
  margin-bottom: 0;
}

.btn_busca {
  font-size: 10pt;
  border-radius: 5px;
  background-color: rgb(3, 74, 166);
  border: none;
  color: #fff;
  font-weight: 700;
  padding: 10px;
  margin-top: 25px;
  width: 100%;
}

.direita {
  text-align: right;
}

.quad {
  padding: 13px;
  gap: 10px;
  width: 50px;
  height: 50px;
  background: #010d1f;
  border-radius: 12px;
}

.title2 {
  color: #000;
  margin-top: 10px;
}

.title1 {
  color: #000;
  margin-top: 10px;
  font-size: 11pt;
  line-height: 1;
}

.num {
  margin-top: -15px;
}

.linha2 {
  margin-top: 0;
}

.card .table th {
}

.card .table td,
.card .table th {
  padding-right: 0px !important;
}

label {
  margin-bottom: 0;
}

.arow {
  font-size: 10pt;
  font-weight: bold;
}

.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}

.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}

.btn_direita {
  text-align: right;
}

.modal_visu_contapagar {
  padding: 5px;
}

h2 {
  color: #000;
}

h3 {
  color: #000;
}

.title_modal {
  color: #034aa6;
}

.btn_esquerda {
}

.btn_efetuarPag {
  float: left;
  color: #000000;
  background-color: #aede00;
  border: #aede00 solid 5px;
}

.btn_efetuarPag:hover {
  color: #000000;
  transform: none;
}

.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}

.btn_salvar:hover {
  transform: none;
  color: #fff;
}

.i_quad {
  background: #034aa6;
  padding: 10px;
  border-radius: 100px;
  font-size: 16pt;
  color: #fff;
}

.btn_conta {
  transform: none;
  background: var(--Color-secundary, #aede00);
  color: rgb(255, 255, 255);
  border: 1px solid #aede00;
  border-radius: 5px;
  font-size: 9pt;
  padding: 10px;
  font-weight: 700;
  width: 100%;
  margin-top: 26px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .btn_busca {
    margin-top: 25px;
  }

  .div_btn_rigth {
    text-align: right;
  }

  .th_display {
    display: none;
  }
}
</style>