 <template>
  <tr @click="handleToggle">
    <td>
      {{ avaliacao.id }}
    </td>
    <td>
      {{ avaliacao.nome_cliente }}
    </td>
    <td class="d-block d-md-none d-lg-block">
      {{ avaliacao.data_avaliacao }}
    </td>
    <td>
      {{ avaliacao.nome_avaliador.split(" ")[0] }}
    </td>
    <td class="d-block d-md-none d-xl-block">
      {{ avaliacao.status }}
    </td>
    <td class="botoes text-end">
      <div v-if="avaliacao.status == 'pendente'">
        <button class="btn_pen" title="Alterar">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-pencil-fill"
            viewBox="0 0 16 16"
            @click="$emit('alterarDataHora', avaliacao.id)"
          >
            <path
              d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"
            />
          </svg>
        </button>
        <button
          class="btn_chek"
          title="Fazer Avaliação"
          @click="$emit('fazerAvaliacao', avaliacao.id)"
        >
          <i class="bi bi-clipboard2-check"></i>
        </button>
        <button
          class="btn_x"
          title="Deletar"
          @click="$emit('deletarAvaliacao', avaliacao.id)"
        >
          <i class="bi bi-x-lg"></i>
        </button>

        <div class="dropdown">
          <button
            class="btn btn-secondary dropdown-toggle btn_quad btn_red"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="bi bi-box"></i>
          </button>
          <ul class="dropdown-menu">
            <li>
              <button
                class="dropdown-item"
                type="button"
                @click="$emit('alterarDataHora', avaliacao.id)"
              >
                Alterar Data / Hora
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                type="button"
                @click="$emit('fazerAvaliacao', avaliacao.id)"
              >
                Fazer Avaliação
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                type="button"
                @click="$emit('deletarAvaliacao', avaliacao.id)"
              >
                Deletar Avaliação
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div v-else>
        <PDFAvaliacao :avaliacao="avaliacao" />

        <button
          class="btn_lupa"
          title="Visualizar"
          @click="$emit('visualizarAvaliacao', avaliacao.id)"
        >
          <i class="bi bi-search"></i>
        </button>

        <a
          title="Link"
          target="_blank"
          :href="'/avaliacao/link/' + avaliacao.hash"
        >
          <button class="btn_arows">
            <i class="bi bi-link-45deg"></i>
          </button>
        </a>

        <button
          class="btn_arows"
          title="Comparação"
          @click="$emit('fazerComparacao', avaliacao.id_cliente)"
        >
          <i class="bi bi-arrow-left-right"></i>
        </button>

        <button
          class="btn_x2"
          title="Deletar"
          @click="$emit('deletarAvaliacao', avaliacao.id)"
        >
          <i class="bi bi-x-lg"></i>
        </button>

        <div class="dropdown">
          <button
            class="btn btn-secondary dropdown-toggle btn btn_quad btn_green"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="bi bi-box"></i>
          </button>

          <ul class="dropdown-menu">
            <li>
              <button
                class="dropdown-item"
                href="#"
                @click="$emit('visualizarAvaliacao', avaliacao.id)"
              >
                Visualizar
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                href="#"
                @click="$emit('fazerComparacao', avaliacao.id_cliente)"
              >
                Fazer Comparação
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                type="button"
                @click="$emit('deletarAvaliacao', avaliacao.id)"
              >
                Deletar Avaliação
              </button>
            </li>
          </ul>
        </div>
      </div>
    </td>
  </tr>
</template>
<script>
//import { reactive } from "vue";
import PDFAvaliacao from "../PDFAvaliacao.vue";
import { reactive } from "vue";
export default {
  props: {
    isOpened: { type: Boolean, default: false },
    avaliacao: { type: Object, required: true },
  },
  setup(_, { emit }) {
    async function handleToggle() {
      //    state.isClosing = true;
      //  await wait(250)
      //    state.isOpen = !state.isOpen;
      //    state.isClosing = false;
    }
    const state = reactive({
      status: "realizado",
    });

    //function statusCor() {
    //   if (status == "realizado") {
    //
    //    } else {
    //
    //    }
    //  }
    return {
      emit,
      PDFAvaliacao,
      state,
      handleToggle,
    };
  },
  emits: [
    "fazerAvaliacao",
    "visualizarAvaliacao",
    "imprimirAvaliacao",
    "fazerComparacao",
  ],
};
</script>
<style scoped>
/*---BTNS---*/
.btn_pen {
  padding: 4px;
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
  border-radius: 5px;
  margin-right: 10px;
}
.btn_chek {
  padding: 4px;
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
  border-radius: 5px;
  margin-right: 10px;
}
.btn_x {
  padding: 4px;
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
  border-radius: 5px;
  margin-right: 10px;
}
.btn_lupa {
  padding: 4px;
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
  border-radius: 5px;
  margin-right: 10px;
}

.btn_lupa {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}

.btn_c {
  padding: 4px;
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 13pt;
}
.btn_arows {
  padding: 4px;
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
  border-radius: 5px;
  margin-right: 10px;
}
.btn_x2 {
  padding: 4px;
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
  border-radius: 5px;
  margin-right: 10px;
}
.btn_btns {
  padding: 4px;
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
  border-radius: 5px;
  margin-right: 10px;
}
.btn_quad {
  padding: 2px 4px;
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
  border-radius: 5px;
  margin-right: 10px;
  display: none;
}
.btn_quad:hover {
  color: #ffff;
  transform: none;
}
.btn_green {
  padding: 4px;
  color: #ffff;
  background: #00a806;
  border: #00a806 solid 5px;
  border-radius: 5px;
  margin-right: 10px;
  display: none;
}
.btn_red {
  padding: 4px;
  color: #ffff;
  background: #cf0000;
  border: #cf0000 solid 5px;
  border-radius: 5px;
  margin-right: 10px;
  display: none;
}
.padding_novo {
  padding: 1rem !important;
}
.dropdown-item:hover {
  color: #ffffff;
  text-decoration: none;
  background-color: #034aa6;
}
.table tr {
  border-bottom: #e3e4e9 solid 1px;
}
.table td {
  border-bottom: none;
  padding: 5px;
  padding-top: 15px;
}
.table .botoes {
  padding-top: 5px;
  padding-bottom: 5px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .btn_pen {
    padding: 4px;
    color: #ffff;
    background: #034aa6;
    border: #034aa6 solid 5px;
    border-radius: 5px;
    margin-right: 10px;
    display: none;
  }
  .btn_chek {
    padding: 4px;
    color: #ffff;
    background: #034aa6;
    border: #034aa6 solid 5px;
    border-radius: 5px;
    margin-right: 10px;
    display: none;
  }
  .btn_x {
    padding: 4px;
    color: #ffff;
    background: #034aa6;
    border: #034aa6 solid 5px;
    border-radius: 5px;
    margin-right: 10px;
    display: none;
  }
  .btn_lupa {
    padding: 4px;
    color: #ffff;
    background: #034aa6;
    border: #034aa6 solid 5px;
    border-radius: 5px;
    margin-right: 10px;
    display: none;
  }
  .btn_c {
    padding: 4px;
    color: #ffff;
    background: #034aa6;
    border: #034aa6 solid 5px;
    border-radius: 5px;
    margin-right: 10px;
    font-size: 13pt;
    display: none;
  }
  .btn_arows {
    padding: 4px;
    color: #ffff;
    background: #034aa6;
    border: #034aa6 solid 5px;
    border-radius: 5px;
    margin-right: 10px;
    display: none;
  }
  .btn_x2 {
    padding: 4px;
    color: #ffff;
    background: #034aa6;
    border: #034aa6 solid 5px;
    border-radius: 5px;
    margin-right: 10px;
    display: none;
  }
  .btn_btns {
    padding: 4px;
    color: #ffff;
    background: #034aa6;
    border: #034aa6 solid 5px;
    border-radius: 5px;
    margin-right: 10px;
    display: none;
  }

  .card .table td,
  .card .table th {
    padding-left: 0;
    padding-right: 0;
  }
  .padding_novo {
    padding: 2px;
  }

  #codigo {
    display: none;
  }
  .btn_quad {
    display: block;
  }
  .status_none {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .btn_pen,
  .btn_chek,
  .btn_x,
  .btn_lupa,
  .btn_c,
  .btn_arows,
  .btn_x2,
  #codigo {
    display: none;
  }
  .btn_btns {
    padding: 4px;
    color: #ffff;
    background: #034aa6;
    border: #034aa6 solid 5px;
    border-radius: 5px;
    margin-right: 10px;
    display: none;
  }
  .btn_quad {
    display: block;
  }
  .status_none {
    display: none;
  }

  .card .table td,
  .card .table th {
    padding-left: 0;
    padding-right: 0;
  }
  .padding_novo {
    padding: 2px;
  }
}
</style>