<template>
  <div>
    <!-- Offcanvas -->
    <div v-show="isVisible" class="offcanvas" :class="{ show: isVisible }" @click="hideOffcanvas">
      <div class="offcanvas-content" @click.stop>
        <div class="d-flex justify-content-end ">
          <button @click="hideOffcanvas" class="btn-close"></button>
        </div>
        <div class="barra_lateral">
          <div>
            <img src="../../assets/img/logo.png" class="logo" />
          </div>
          <div>
            <div class="vertical-table">
              <div class="linha">
                <div class="cell" :class="{
                  selected_cel: currentPage == '/dashboard',
                }">
                  <a href="/dashboard">
                    <i class="bi bi-house-door-fill"></i>
                    <span>Página Inicial</span>
                  </a>
                </div>
              </div>
              <div class="linha">
                <div class="cell" :class="{
                  selected_cel: currentPage == '/avaliacoes',
                }">
                  <a href="/avaliacoes">
                    <i class="bi bi-clipboard-data-fill"></i>
                    <span>Avaliações</span>
                  </a>
                </div>
              </div>
              <div class="linha">
                <div class="cell" :class="{
                  selected_cel: currentPage == '/clientes',
                }">
                  <a href="/clientes">
                    <i class="bi bi-people-fill"></i>
                    <span>Clientes</span>
                  </a>
                </div>
              </div>
              <div class="linha">
                <div class="cell" :class="{
                  selected_cel: currentPage == '/colaboradores',
                }">
                  <a href="/colaboradores">
                    <i class="bi bi-person-circle"></i>
                    <span>Colaboradores</span>
                  </a>
                </div>
              </div>
              <div class="linha">
                <div class="cell" :class="{
                  selected_cel: currentPage == '/fornecedores',
                }">
                  <a href="/fornecedores">
                    <i class="bi bi-truck"></i>
                    <span>Fornecedores</span>
                  </a>
                </div>
              </div>
              <div class="linha">
                <div class="cell" :class="{
                  selected_cel: currentPage == '/matriculas',
                }">
                  <a href="/matriculas">
                    <i class="bi bi-archive-fill"></i>
                    <span>Matrículas</span>
                  </a>
                </div>
              </div>
              <div class="linha">
                <div class="cell" :class="{
                  selected_cel: currentPage == '/contas-receber',
                }">
                  <a href="/contas-receber">
                    <i class="bi bi-cash-coin"></i>
                    <span>Contas a Receber</span>
                  </a>
                </div>
              </div>
              <div class="linha">
                <div class="cell" :class="{
                  selected_cel: currentPage == '/contas-pagar',
                }">
                  <a href="/contas-pagar">
                    <i class="bi bi-cash-coin"></i>
                    <span>Contas a Pagar</span>
                  </a>
                </div>
              </div>
              <div class="linha">
                <div class="cell" :class="{
                  selected_cel: currentPage == '/caixas',
                }">
                  <a href="caixas">
                    <i class="bi bi-coin"></i>
                    <span>Caixas</span>
                  </a>
                </div>
              </div>
              <div class="linha">
                <div class="cell" :class="{
                  selected_cel: currentPage == '/fichas-treino',
                }">
                  <a href="/fichas-treino">
                    <i class="bi bi-clipboard2-data-fill"></i>
                    <span>Ficha de Treino</span>
                  </a>
                </div>
              </div>
              <div class="linha">
                <div class="cell" :class="{
                  selected_cel: currentPage == '/aplicativo',
                }">
                  <a href="/aplicativo">
                    <i class="bi bi-phone-fill"></i>
                    <span>Aplicativo</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div style="margin-top: 20px">
            <div class="linha">
              <div class="cell" :class="{
                selected_cel: currentPage == '/grupos-musculares',
              }">
                <a href="/grupos-musculares">
                  <i class="bi bi-coin"></i>
                  <span>Grupos Musculares</span>
                </a>
              </div>
            </div>

            <div class="linha">
              <div class="cell" :class="{
                selected_cel: currentPage == '/exercicios',
              }">
                <a href="/exercicios">
                  <i class="bi bi-coin"></i>
                  <span>Exercícios</span>
                </a>
              </div>
            </div>

            <div class="linha">
              <div class="cell" :class="{
                selected_cel: currentPage == '/objetivos-treino',
              }">
                <a href="/objetivos-treino">
                  <i class="bi bi-coin"></i>
                  <span>Objetivos Treino</span>
                </a>
              </div>
            </div>

            <div class="linha">
              <div class="cell" :class="{
                selected_cel: currentPage == '/grupos',
              }">
                <a href="/grupos">
                  <i class="bi bi-coin"></i>
                  <span>Grupos</span>
                </a>
              </div>
            </div>

            <div class="linha">
              <div class="cell" :class="{
                selected_cel: currentPage == '/modelos-contrato',
              }">
                <a href="/modelos-contrato">
                  <i class="bi bi-coin"></i>
                  <span>Modelos Contrato</span>
                </a>
              </div>
            </div>

            <div class="linha">
              <div class="cell" :class="{
                selected_cel: currentPage == '/planos',
              }">
                <a href="/planos">
                  <i class="bi bi-coin"></i>
                  <span>Planos</span>
                </a>
              </div>
            </div>

            <div class="linha">
              <div class="cell" :class="{
                selected_cel: currentPage == '/tipos-pagamento',
              }">
                <a href="/tipos-pagamento">
                  <i class="bi bi-coin"></i>
                  <span>Tipos de Pagamento</span>
                </a>
              </div>
            </div>

            <div class="linha">
              <div class="cell" :class="{
                selected_cel: currentPage == '/funcoes',
              }">
                <a href="/funcoes">
                  <i class="bi bi-coin"></i>
                  <span>Funções</span>
                </a>
              </div>
            </div>

            <div class="linha">
              <div class="cell" :class="{
                selected_cel: currentPage == '/despesas-fixas',
              }">
                <a href="/despesas-fixas">
                  <i class="bi bi-coin"></i>
                  <span>Despesas Fíxas</span>
                </a>
              </div>
            </div>
            <div class="linha">
              <div class="cell" :class="{
                selected_cel: currentPage == '/produtos',
              }">
                <a href="/produtos">
                  <i class="bi bi-coin"></i>
                  <span>Produtos</span>
                </a>
              </div>
            </div>
            <div class="linha">
              <div class="cell" :class="{
                selected_cel: currentPage == '/pontuacao',
              }">
                <a href="/pontuacao">
                  <i class="bi bi-coin"></i>
                  <span>Pontuação</span>
                </a>
              </div>
            </div>
            <div class="linha">
              <div class="cell" :class="{
                selected_cel: currentPage == '/enquetes',
              }">
                <a href="/enquetes">
                  <i class="bi bi-coin"></i>
                  <span>Enquetes</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

// Estado para controlar a visibilidade do offcanvas
const isVisible = ref(false);

// Função para mostrar o offcanvas
const showOffcanvas = () => {
  isVisible.value = true;
};

// Função para esconder o offcanvas
const hideOffcanvas = () => {
  isVisible.value = false;
};

// Expondo o método para ser acessado pelo componente pai
defineExpose({ showOffcanvas, hideOffcanvas, isVisible });
</script>

<style scoped>
/* Estilos básicos para o offcanvas */
.offcanvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  height: 100%;
  background-color: #fff;
  color: #333;
  padding: 20px;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
  overflow-y: auto;
}

.offcanvas.show {
  transform: translateX(0);
  /* Offcanvas visível, movido para a direita */
}

.offcanvas-content {
  position: relative;
  z-index: 10;
}

.offcanvas .btn-close {}

.logo {
  width: 160px;
  margin-top: 30px;
}

span {
  color: rgba(0, 0, 0, 0.5);
}

i {
  margin-right: 25px;
  color: rgba(0, 0, 0, 0.8);
}

.vertical-table {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.linha {
  display: flex;
  width: 100%;
}

.cell {
  padding: 6px 20px;
  text-align: left;
  width: 100%;
}

.barra_lateral {
  text-align: center;
  background-color: #fff;
  height: 100%;
}

a {
  text-decoration: none;
}

.selected_cel {
  background-color: #034aa6;
  color: #fff !important;
  border-radius: 5px;
}

.selected_cel i,
.selected_cel span {
  color: #fff;
}
</style>
