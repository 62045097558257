<template>
  <tr @click="handleToggle">
    <th scope="row">
      <div class="media align-items-center">
        <div class="media-body">
          <span class="name mb-0 text-sm"></span>
          {{ matricula.id }}
        </div>
      </div>
    </th>
    <td>
      {{ matricula.nome_cliente }}
    </td>
    <td class="td_display">
      {{ matricula.nome_plano }}
    </td>
    <td>
      {{ matricula.status }}
    </td>
    <td class="text-right">
      <div>
        <button
          class="btn btn_lupa"
          title="Visualizar"
          @click="$emit('modalVisualizar', matricula.id)"
          :disabled="!permissoes.includes('47')"
        >
          <i class="bi bi-search"></i>
        </button>

        <button
          class="btn btn_deletar"
          title="Cancelar"
          @click="$emit('modalCancelar', matricula.id, matricula.nome_cliente)"
        >
          <i class="bi bi-x-lg"></i>
        </button>

        <button
          class="btn btn_deletar"
          title="Deletar"
          @click="$emit('deletarMatricula', matricula.id)"
          :disabled="!permissoes.includes('84')"
        >
          <i class="bi bi-trash"></i>
        </button>
      </div>
    </td>
  </tr>
</template>
<script>
import { reactive } from "vue";

export default {
  props: {
    isOpened: { type: Boolean, default: false },
    matricula: { type: Object, required: true },
    permissoes: { type: Array, required: true },
  },
  setup(props) {
    const state = reactive({
      isOpen: props.isOpened,
      isClosing: !props.isOpened,
    });
    async function handleToggle() {
      state.isClosing = true;
      //  await wait(250)
      state.isOpen = !state.isOpen;
      state.isClosing = false;
    }
    return {
      state,
      handleToggle,
    };
  },
};
</script>
<style scoped>
.btn_deletar {
  padding: 4px;
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
  border-radius: 5px;
  margin-right: 10px;
  transform: none;
}
.btn_lupa {
  padding: 4px;
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
  border-radius: 5px;
  margin-right: 10px;
  transform: none;
}
.btn:hover {
  transform: none;
  color: #fff;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .td_display {
    display: none;
  }
}
</style>
