<template>
  <barra-lateral />
  <div style="background-color: #f8f9fe; min-height: 100vh">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <topo
            :titulo="'Despesas Fixas'"
            @openOffcanvas="openOffcanvas"
          ></topo>
          <div class="row align-items-center mt-3">
            <div class="col text-end">
              <button
                type="primary"
                id="btn_new_product"
                class="btn btn-primary"
                @click="abrirModal"
              >
                Adicionar Despesa Fixa
              </button>
            </div>
          </div>
          <div
            class="card shadow p-3"
            :class="type === 'dark' ? 'bg-default' : ''"
            s
          >
            <div class="row">
              <div class="col">
                <div class="table-responsive">
                  <table
                    class="table tablesorter table align-items-center table-flush"
                  >
                    <thead class="thead-light">
                      <th>Código</th>
                      <th>Nome</th>
                      <th></th>
                    </thead>
                    <tbody class="list">
                      <corpo-grupo
                        v-for="grupo in state.grupos"
                        :key="grupo.id"
                        :grupo="grupo"
                        @deletar="deletar"
                        @modalVisualizar="modalVisualizar"
                      />
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    :width="'800px'"
    :open="state.status_modal"
    @close="state.status_modal = !state.status_modal"
  >
    <h1>Despesas Fixas</h1>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label>Código</label>
          <input
            type="text"
            class="form-control input_modal"
            v-model="state.id"
            readonly
          />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>Nome</label>
          <input
            type="text"
            class="form-control input_modal"
            v-model="state.nome"
          />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group">
          <label>Status</label>
          <select class="form-select" v-model="state.status">
            <option value="ativo">Ativo</option>
            <option value="inativo">Inativo</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label>Valor</label>
          <input type="text" class="form-control" v-model="state.valor" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>Recorrência</label>
          <select class="form-select" v-model="state.recorrencia">
            <option value="1 dia">1 Dia</option>
            <option value="7 dias">7 Dias</option>
            <option value="1 mês">1 Mês</option>
            <option value="3 meses">3 Meses</option>
            <option value="6 meses">6 Meses</option>
            <option value="1 ano">1 Ano</option>
          </select>
        </div>
      </div>
    </div>
    <button
      class="btn btn_salvar my-4"
      id="submit-button"
      type="submit"
      @click="salvarDespesa()"
    >
      Salvar
    </button>
    <button
      class="btn btn_cancelar my-4"
      id="submit-button"
      type="button"
      @click="fecharModal()"
    >
      Cancelar
    </button>
  </Modal>
  <OffcanvasComponent ref="offcanvasRef" />
</template>
<script>
import OffcanvasComponent from "../layout/Sistema/barraLateralMobile";
import { onMounted, reactive, onErrorCaptured, ref } from "vue";
import CorpoGrupo from "../components/CorpoTabelaGrupo";
import services from "../services";
import Modal from "../components/EstruturaModal/EstruturaModal.vue";
import topo from "../components/topo";
import { useStorage } from "vue3-storage";
import BarraLateral from "../layout/Sistema/barraLateral";
export default {
  components: {
    topo,
    BarraLateral,
    Modal,
    CorpoGrupo,
    OffcanvasComponent,
  },
  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");
    const state = reactive({
      isLoading: false,
      grupos: [],
      currentClientType: "",
      hasError: false,
      id: "",
      nome: "",
      valor: "",
      recorrencia: "",
      status: "",
    });
    onErrorCaptured(handleErrors);

    onMounted(() => {
      fetchDespesas();
    });
    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }
    async function abrirModal() {
      state.status_modal = true;
    }

    function fecharModal() {
      state.id = "";
      state.nome = "";
      state.valor = "";
      state.recorrencia = "";
      state.status = "ativo";
      state.status_modal = false;
    }

    async function modalVisualizar(id) {
      try {
        state.isLoading = true;
        let data = await services.despesasFixa.getById({
          empresa_id,
          id,
          token,
        });
        state.status_modal = true;
        let dados = data.data;
        state.id = dados.id;
        state.nome = dados.nome;
        state.valor = dados.valor;
        state.recorrencia = dados.recorrencia;
        state.status = dados.status;
      } catch (error) {
        console.log(error);
      }
    }
    async function deletar(id) {
      if (confirm("Deseja deletar o Despesa?")) {
        await services.despesasFixa
          .deletar({
            token,
            empresa_id,
            id,
          })
          .then((data) => {
            if (data.data.verifica_erro) {
              alert("Erro ao deletar Despesa");
            } else {
              fetchDespesas();
            }
          })
          .catch(() => {
            alert("Erro ao deletar Despesa");
          });
      }
    }

    async function salvarDespesa() {
      if (state.id != null && state.id != undefined && state.id != "") {
        await services.despesasFixa
          .update({
            empresa_id,
            token,
            id: state.id,
            nome: state.nome,
            valor: state.valor,
            caixa: state.caixa,
            recorrencia: state.recorrencia,
            status: state.status,
          })
          .then((data) => {
            if (data.data.verifica_erro) {
              alert("Erro ao alterar Despesa");
            } else {
              fetchDespesas();
              fecharModal();
            }
          })
          .catch(() => {
            alert("Erro ao alterar Despesa");
          });
      } else {
        await services.despesasFixa
          .insert({
            empresa_id,
            token,
            nome: state.nome,
            valor: state.valor,
            caixa: state.caixa,
            recorrencia: state.recorrencia,
            status: state.status,
          })
          .then((data) => {
            if (data.data.verifica_erro) {
              alert("Erro ao deletar Despesa");
            } else {
              fetchDespesas();
              fecharModal();
            }
          })
          .catch(() => {
            alert("Erro ao deletar Despesa");
          });
      }
    }

    async function fetchDespesas() {
      try {
        state.isLoading = true;
        const { data } = await services.despesasFixa.getAll({
          token: token,
          empresa_id: empresa_id,
        });
        //  console.log(data)
        state.grupos = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    const offcanvasRef = ref(null);
    const isVisible = ref(false);
    const openOffcanvas = () => {
      console.log(isVisible.value);
      if (isVisible.value) {
        offcanvasRef.value.hideOffcanvas();
      } else {
        offcanvasRef.value.showOffcanvas();
      }
    };
    return {
      state,
      abrirModal,
      fecharModal,
      salvarDespesa,
      modalVisualizar,
      deletar,
      offcanvasRef,
      openOffcanvas,
    };
  },
};
</script>

<style scoped>
.btn-primary {
  background-color: rgb(3, 74, 166);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(3, 74, 166);
  border-radius: 10px;
  font-size: 10pt;
  padding: 12px;
  font-weight: 700;
  margin-bottom: 15px;
}
.grupo {
  width: 100%;
  height: 100px;
  border: #034aa6 solid 1px;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px;
}

.nome_grupo {
  font-weight: bold;
}

.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}

.btn_salvar:hover {
  transform: none;
  color: #fff;
}

.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}

.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}

textarea {
  resize: none;
}

@media (max-width: 767px) {
  #btn_new_product {
    width: 100%;
    margin-top: 10px;
    text-align: center;
  }
}
</style>