const defaultPagination = {
  limit: 5,
  offset: 0
}
export default httpClient => ({
  getAtivos: async ({ token, empresa_id } = defaultPagination) => {
    console.log(token)
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/colaboradores-personais/' + empresa_id, { headers });
    return { data: response.data }
  },

  obterPorId: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/avaliacao/seleciona-por-id/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },

  calcularSubcutaneas: async ({ token, empresa_id, torax_subc, axila_subc, triceptal_subc, subescapular_subc, abdominal_subc, suprailiaca_subc, coxa_subc, sexo, idade, peso }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/avaliacao/calcular-subcutaneas/' + empresa_id, {
      token,
      torax_subc,
      axila_subc,
      triceptal_subc,
      subescapular_subc,
      abdominal_subc,
      suprailiaca_subc,
      coxa_subc,
      sexo,
      idade,
      peso
    }, { headers })
    return { data: response.data }
  },


  avaliacoesPorClienteId: async ({ token, empresa_id, cliente_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/avaliacao/seleciona-por-cliente/' + empresa_id, {
      token,
      cliente_id
    }, { headers })
    return { data: response.data }
  },

  qtdMes: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/qtd-avaliacoes-mes/' + empresa_id, {
      token: token
    }, { headers })
    return { data: response.data }
  },
  qtdMesPassado: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/qtd-avaliacoes-mes-passado/' + empresa_id, {
      token: token
    }, { headers })
    return { data: response.data }
  },
  qtdTotal: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/qtd-avaliacoes/' + empresa_id, {
      token: token
    }, { headers })
    return { data: response.data }
  },

  save: async ({ empresa_id, token, cliente_id, func_cadastrante_id, data_avaliacao, hora_avaliacao, personal_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/avaliacao/adicionar-agendamento/' + empresa_id, {
      token,
      cliente_id,
      func_cadastrante_id,
      data_avaliacao,
      hora_avaliacao,
      personal_id
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  salvarFeita: async ({ empresa_id, token, id, altura, peso, idade, imc, resultado_imc, ombro, busto, cintura, quadril, coxa_dir, coxa_esq, braco_dir, braco_esq,
    antebraco_dir, antebraco_esq, panturrilha_dir, panturrilha_esq, abdomem, biceps_subc, rel_cintura_quadril, resultado_relacao_cq, torax_subc, axila_subc, triceptal_subc,
    subescapular_subc, abdominal_subc, suprailiaca_subc, coxa_subc, panturrilha_subc, sexo
  }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/avaliacao/salvarFeita/' + empresa_id, {
      empresa_id,
      token,
      id,
      altura,
      peso,
      idade,
      imc,
      resultado_imc,
      ombro,
      busto,
      cintura,
      quadril,
      coxa_dir,
      coxa_esq,
      braco_dir,
      braco_esq,
      antebraco_dir,
      antebraco_esq,
      panturrilha_dir,
      panturrilha_esq,
      abdomem,
      biceps_subc,
      relacao_cintura_quadril: rel_cintura_quadril,
      resultado_relacao_cq,
      torax_subc,
      axila_subc,
      triceptal_subc,
      subescapular_subc,
      abdominal_subc,
      suprailiaca_subc,
      coxa_subc,
      panturrilha_subc,
      sexo,
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

})