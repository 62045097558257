<template>
  <tr @click="handleToggle">
    <th scope="row">
      <div class="media align-items-center">
        <div class="media-body">
          <span class="name mb-0 text-sm"></span>
          {{ enquete.id }}
        </div>
      </div>
    </th>
    <td>
      {{ enquete.nome }}
    </td>
    <td>{{ enquete.status }}</td>

    <td class="text-end ">
      <div>
        <button
          class="btn btn_lupa"
          title="Visualizar"
          @click="$emit('modalVisualizar', enquete.id)"
        >
          <i class="bi bi-search"></i>
        </button>
        <button
          class="btn btn_deletar"
          title="Deletar"
          @click="$emit('deletar', enquete.id)"
        >
          <i class="bi bi-x-lg"></i>
        </button>
      </div>
    </td>
  </tr>
</template>
<script>
import { reactive } from "vue";

export default {
  props: {
    isOpened: { type: Boolean, default: false },
    enquete: { type: Object, required: true },
  },
  setup(props) {
    const state = reactive({
      isOpen: props.isOpened,
      isClosing: !props.isOpened,
    });
    async function handleToggle() {
      state.isClosing = true;
      //  await wait(250)
      state.isOpen = !state.isOpen;
      state.isClosing = false;
    }
    return {
      state,
      handleToggle,
    };
  },
};
</script>
<style scoped>
.btn_deletar {
  padding: 4px;
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
  border-radius: 5px;
  margin-right: 10px;
  transform: none;
}
.btn_lupa {
  padding: 4px;
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
  border-radius: 5px;
  margin-right: 10px;
  transform: none;
}
.btn:hover {
  transform: none;
  color: #fff;
}
</style>
