const defaultPagination = {
  limit: 5,
  offset: 0
}
export default httpClient => ({
  listar: async ({ token, empresa_id } = defaultPagination) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/grupos/' + empresa_id, { headers });
    return { data: response.data }
  },

  listarExercicios: async ({ token, empresa_id } = defaultPagination) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/exercicios/' + empresa_id, {}, { headers });
    return { data: response.data }
  },

  obterPorId: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/seleciona/grupo/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },

  save: async ({formExercicio, empresa_id, token}) => {
    const headers = {
      "Content-Type": "multipart/form-data",
      "Authorization": token
    }
    const response = await httpClient.post('/cria/exercicios/'+empresa_id , formExercicio,{ headers })
    return {
      data: response.data,
    }
  },


  qtdTotal: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/qtd-avaliacoes/' + empresa_id, {
      token: token
    }, { headers })
    return { data: response.data }
  },


    update: async ({ empresa_id, token, id, nome, mostrar_home, caixa,descricao }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/edita/grupo/' + empresa_id, {
      token,
      id,
      nome,
      mostrar_home,
      caixa,
      descricao
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    } 
    return {
      data: response.data,
      errors
    }
  },
  deletar: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/deleta/exercicios/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },

})