import { createRouter, createWebHistory } from "vue-router";

import Dashboard from "../views/PaginaInicial.vue";
import Dash from "../views/DashBoard.vue";

import MetricasCliente from "../views/MetricasClientes";


import Clientes from "../views/Clientes.vue";
import VisualizarCliente from "../views/VisualizarCliente.vue";
import AdicionarCLiente from "../views/CadastrarCliente";

import Colaboradores from "../views/Colaboradores";
import VisualizarColaborador from "../views/VisualizarColaborador";
import AdicionarColaborador from "../views/AdicionarColaborador";

import Fornecedores from "../views/Fornecedores";
import AdicionarFornecedor from "../views/AdicionarFornecedor";

import Avaliacoes from "../views/Avaliacoes.vue";
import AdicionarAvaliacao from "../views/CadastrarAvaliacao";

import FichasTreino from "../views/FichasTreino.vue";
import AdicionarFichaTreino from "../views/CadastrarFichaTreino";
import ContinuarFichaTreino from "../views/ContinuarFichaTreino";
import ContinuarFichaComModelo from "../views/ContinuarFichaComModelo.vue";
import CadastrarTreino from "../views/CadastrarTreino";
import AdicionarFichaTreinoModelo from "../views/CadastrarModeloFichaTreino";

import FichasTreinoModelo from "../views/FichasTreinoModelo";

import Matriculas from "../views/Matriculas.vue";
import AdicionarMatricula from "../views/CadastrarMatricula";

import ContasReceber from "../views/ContasReceber.vue";
import AdicionarContaReceber from "../views/CadastrarContaReceber";

import DespesasFixas from "../views/DespesasFixas.vue";

import Bancos from "../views/Bancos.vue";
import BancoRecorrente from "../views/BancoRecorrente";

import Aplicativo from "../views/Aplicativo.vue";

import LocalContas from "../views/LocalContas.vue";
//import AdicionarContaReceber from "../views/CadastrarContaReceber";

import ContasPagar from "../views/ContasPagar.vue";
import AdicionarContaPagar from "../views/CadastrarContaPagar";

import CategoriasContasPagar from "../views/CategoriasContaPagar.vue";

import Grupos from "../views/Grupos.vue";

import GruposMusculares from "../views/GruposMusculares.vue";
import Exercicios from "../views/Exercicios.vue";

import Turmas from "../views/Turmas.vue";

import Enquetes from "../views/Enquetes.vue";

import TiposPagamento from "../views/TiposPagamento.vue";


import Caixas from "../views/Caixas.vue";

import AvaliacaoLink from "../views/AvaliacaoLink";
import Configuracoes from "../views/Configuracoes"
import Funcoes from "../views/Funcoes"

import Planos from "../views/Planos"

import Icons from "../views/Icons.vue";
import Profile from "../views/UserProfile.vue";
import Tables from "../views/Tables.vue";

import AulaExperimental from "../views/Online/AulaExperimental.vue";

import Login from "../views/Site/Login.vue";
import HomeSite from "../views/Site/Home.vue";
import Contato from "../views/Site/Contato.vue";
import Funcionalidade from "../views/Site/Funcionalidades.vue";
import Guard from '../services/middleware';
import Atividades from '../views/Atividades.vue';
import Pontuacao from '../views/Pontuacao.vue';
import Leads from '../views/Leads.vue';
import Produtos from '../views/Produtos.vue';
import Suporte from '../views/Suporte.vue';
import ModelosContrato from "../views/ModelosContrato.vue";
import AdicionarModeloContrato from "../views/CadastrarModeloContrato.vue";
import AtualizarFichaTreino from "../views/AtualizarFichaTreino.vue";

import ObjetivosTreino from "../views/ObjetivosTreino.vue";


const routes = [

  {
    path: "/",
    name: "homeSite",
    components: { default: HomeSite },
  },
  {
    path: "/contato",
    name: "contato",
    components: { default: Contato },
  },
  {
    path: "/funcionalidades",
    name: "funcionalidades",
    components: { default: Funcionalidade },
  },
  {
    path: "/avaliacao/link/:hash",
    name: "avaliacaoLink",
    components: { default: AvaliacaoLink },
  },
  {
    path: "/login",
    name: "login",
    components: { default: Login },
  },

  {
    path: "/aula-experimental/:hash",
    name: "aulaExperimental",
    components: { default: AulaExperimental }
  },

  {
    path: "/",
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        components: { default: Dashboard },
        beforeEnter: Guard.auth
      },
      {
        path: "/dash",
        name: "dash",
        components: { default: Dash },
        beforeEnter: Guard.auth
      },
      {
        path: "/metricas-cliente",
        name: "metricasCliente",
        components: { default: MetricasCliente },
        beforeEnter: Guard.auth
      },
      {
        path: "/clientes",
        name: "clientes",
        components: { default: Clientes },
        beforeEnter: Guard.auth
      },
      {
        path: "/colaboradores",
        name: "colaboradores",
        components: { default: Colaboradores },
        beforeEnter: Guard.auth
      },
      {
        path: "/adicionar-colaborador",
        name: "adicionarColaborador",
        components: { default: AdicionarColaborador },
        beforeEnter: Guard.auth
      },

      {
        path: '/visualizar-colaborador/:id',
        name: "visualizarColaborador",
        components: { default: VisualizarColaborador },
        beforeEnter: Guard.auth
      },
      {
        path: '/alterar-colaborador/:id',
        name: "alterarColaborador",
        components: { default: AdicionarColaborador },
        beforeEnter: Guard.auth
      },
      {
        path: '/alterar-cliente/:id',
        name: "alterar-cliente",
        components: { default: AdicionarCLiente },
        beforeEnter: Guard.auth
      },
      {
        path: '/visualizar-cliente/:id',
        name: "visualizarCliente",
        components: { default: VisualizarCliente },
        beforeEnter: Guard.auth
      },
      {
        path: "/adicionar-cliente",
        name: "adicionar-cliente",
        components: { default: AdicionarCLiente },
        beforeEnter: Guard.auth
      },
      {
        path: "/fornecedores",
        name: "fornecedores",
        components: { default: Fornecedores },
        beforeEnter: Guard.auth
      },
      {
        path: "/adicionar-fornecedor",
        name: "adicionar-fornecedor",
        components: { default: AdicionarFornecedor },
        beforeEnter: Guard.auth
      },

      {
        path: "/avaliacoes",
        name: "avaliacoes",
        components: { default: Avaliacoes },
        beforeEnter: Guard.auth
      },
      {
        path: "/adicionar-avaliacao",
        name: "adicionar-avaliacao",
        components: { default: AdicionarAvaliacao },
        beforeEnter: Guard.auth
      },
      {
        path: "/matriculas",
        name: "matriculas",
        components: { default: Matriculas },
        beforeEnter: Guard.auth
      },

      {
        path: "/adicionar-matricula",
        name: "adicionar-matricula",
        components: { default: AdicionarMatricula },
        beforeEnter: Guard.auth
      },

      {
        path: "/local-contas",
        name: "localContas",
        components: { default: LocalContas },
        beforeEnter: Guard.auth
      },

      {
        path: "/aplicativo",
        name: "aplicativo",
        components: { default: Aplicativo },
        beforeEnter: Guard.auth
      },

      {
        path: "/bancos",
        name: "bancos",
        components: { default: Bancos },
        beforeEnter: Guard.auth
      },

      {
        path: "/banco-recorrente",
        name: "bancoRecorrente",
        components: { default: BancoRecorrente },
        beforeEnter: Guard.auth
      },

      {
        path: "/modelos-contrato",
        name: "modelosContrato",
        components: { default: ModelosContrato },
        beforeEnter: Guard.auth
      },

      {
        path: "/adicionar-modelo-contrato",
        name: "adicionarModeloContrato",
        components: { default: AdicionarModeloContrato },
        beforeEnter: Guard.auth
      },

      {
        path: "/alterar-modelo-contrato/:id",
        name: "alterarModeloContrato",
        components: { default: AdicionarModeloContrato },
        beforeEnter: Guard.auth
      },

      {
        path: "/contas-pagar",
        name: "contasPagar",
        components: { default: ContasPagar },
        beforeEnter: Guard.auth
      },
      {
        path: "/adicionar-conta-pagar",
        name: "adicionar-conta-pagar",
        components: { default: AdicionarContaPagar },
        beforeEnter: Guard.auth
      },
      {
        path: "/categorias-conta-pagar",
        name: "categoriasContaPagar",
        components: { default: CategoriasContasPagar },
        beforeEnter: Guard.auth
      },
      {
        path: "/contas-receber",
        name: "contasReceber",
        components: { default: ContasReceber },
        beforeEnter: Guard.auth
      },
      {
        path: "/adicionar-conta-receber",
        name: "adicionar-conta-receber",
        components: { default: AdicionarContaReceber },
        beforeEnter: Guard.auth
      },

      {
        path: "/objetivos-treino",
        name: "objetivosTreino",
        components: { default: ObjetivosTreino },
        beforeEnter: Guard.auth
      },

      {
        path: "/fichas-treino-modelo",
        name: "fichasTreinoModelo",
        components: { default: FichasTreinoModelo },
        beforeEnter: Guard.auth
      },

      {
        path: "/fichas-treino",
        name: "fichasTreino",
        components: { default: FichasTreino },
        beforeEnter: Guard.auth
      },

      {
        path: "/adicionar-ficha-treino",
        name: "adicionarFichaTreino",
        components: { default: AdicionarFichaTreino },
        beforeEnter: Guard.auth
      },
      {
        path: "/ficha-treino/alterar/:id",
        name: "alterarFichaTreino",
        components: { default: AtualizarFichaTreino },
        beforeEnter: Guard.auth
      },
      {
        path: "/continuar-ficha-treino/:id",
        name: "continuarFichaTreino",
        components: { default: ContinuarFichaTreino },
        beforeEnter: Guard.auth
      },
      {
        path: "/continuar-ficha-modelo/:id",
        name: "continuarFichaComModelo",
        components: { default: ContinuarFichaComModelo },
        beforeEnter: Guard.auth
      },


      
      {
        path: "/novo-treino/:ficha_id",
        name: "cadastrarTreino",
        components: { default: CadastrarTreino },
        beforeEnter: Guard.auth
      },
      {
        path: "/novo-treino-modelo",
        name: "AdicionarFichaTreinoModelo",
        components: { default: AdicionarFichaTreinoModelo },
        beforeEnter: Guard.auth
      },


   //   ContinuarFichaTreino
      {
        path: "/configuracoes",
        name: "configuracoes",
        components: { default: Configuracoes },
        beforeEnter: Guard.auth
      },
      {
        path: "/funcoes",
        name: "funcoes",
        components: { default: Funcoes },
        beforeEnter: Guard.auth
      },

      
      {
        path: "/grupos",
        name: "grupos",
        components: { default: Grupos },
        beforeEnter: Guard.auth
      },

      {
        path: "/grupos-musculares",
        name: "gruposMusculares",
        components: { default: GruposMusculares },
        beforeEnter: Guard.auth
      },

      {
        path: "/enquetes",
        name: "enquetes",
        components: { default: Enquetes },
        beforeEnter: Guard.auth
      },

      

      {
        path: "/turmas",
        name: "turmas",
        components: { default: Turmas },
        beforeEnter: Guard.auth
      },

      {
        path: "/exercicios",
        name: "exercicios",
        components: { default: Exercicios },
        beforeEnter: Guard.auth
      },
      {
        path: "/caixas",
        name: "caixas",
        components: { default: Caixas },
        beforeEnter: Guard.auth
      },
      {
        path: "/tipos-pagamento",
        name: "tiposPagamento",
        components: { default: TiposPagamento },
        beforeEnter: Guard.auth
      },
      {
        path: "/planos",
        name: "planos",
        components: { default: Planos },
        beforeEnter: Guard.auth
      },

      {
        path: "/despesas-fixas",
        name: "despesasFixas",
        components: { default: DespesasFixas },
        beforeEnter: Guard.auth
      },

      {
        path: "/icons",
        name: "icons",
        components: { default: Icons },
        beforeEnter: Guard.auth
      },
      {
        path: "/profile",
        name: "profile",
        components: { default: Profile },
        beforeEnter: Guard.auth
      },
      {
        path: "/tables",
        name: "tables",
        components: { default: Tables },
        beforeEnter: Guard.auth
      },
      {
        path: "/atividades",
        name: "atividades",
        components: { default: Atividades },
        beforeEnter: Guard.auth
      },
      {
        path: "/pontuacao",
        name: "pontuacao",
        components: { default: Pontuacao },
        beforeEnter: Guard.auth
      },
      {
        path: "/leads",
        name: "leads",
        components: { default: Leads },
        beforeEnter: Guard.auth
      },
      {
        path: "/produtos",
        name: "produtos",
        components: { default: Produtos },
        beforeEnter: Guard.auth
      },
      {
        path: "/suporte",
        name: "suporte",
        components: { default: Suporte },
        beforeEnter: Guard.auth
      },
    ],
  },
  /*
  {
    path: "/",
    redirect: "login",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "login",
        components: { default: Login },
      },
    ],
  },*/
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
});

export default router;
