<template>
  <barra-lateral />
  <div style="background-color: #f8f9fe; min-height: 100vh">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <topo :titulo="'Atividades'"></topo>
          <div class="card shadow p-3 mt-2" :class="type === 'dark' ? 'bg-default' : ''" s>
            <div class="row align-items-center">
              <div class="col text-end mb-2">
                <base-button type="primary" id="btn_new_product" class="btn_add" @click="abrirModal">Adicionar
                  Atividade</base-button>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col">
                <div class="table-responsive">
                  <table class="table tablesorter table align-items-center table-flush">
                    <thead class="thead-light">
                      <th>Código</th>
                      <th>Nome</th>
                      <th></th>
                    </thead>
                    <tbody class="list">
                      <corpo-atividade v-for="atividade in state.atividades" :key="atividade.id" :atividade="atividade"
                        @deletar="deletar" @modalVisualizar="modalVisualizar" />
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal :open="state.status_modal" @close="state.status_modal = !state.status_modal" :width="'800px'">
    <h1>Atividade</h1>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label>Código</label>
          <input type="text" class="form-control input_modal" v-model="state.id" readonly />
        </div>
      </div>
      <div class="col-lg-7">
        <div class="form-group">
          <label>Nome</label>
          <input type="text" class="form-control input_modal" v-model="state.nome" />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>Pontuação</label>
          <input type="text" class="form-control input_modal" v-model="state.pontuacao" />
        </div>
      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <label>Descrição</label>
          <textarea class="form-control" v-model="state.descricao" rows="4"></textarea>
        </div>
      </div>
    </div>

    <button class="btn btn_salvar my-4" id="submit-button" type="submit" @click="salvarAtividade()">
      Salvar
    </button>
    <button class="btn btn_cancelar my-4" id="submit-button" type="button" @click="fecharModal()">
      Cancelar
    </button>
  </Modal>
</template>
<script>
import { onMounted, reactive, onErrorCaptured } from "vue";
import CorpoAtividade from "../components/CorpoTabelaAtividade";
import services from "../services";
import Modal from "../components/EstruturaModal/EstruturaModal.vue";
import topo from "../components/topo";
import { useStorage } from "vue3-storage";
import BarraLateral from "../layout/Sistema/barraLateral";
export default {
  name: "tables",
  components: { topo, BarraLateral, Modal, CorpoAtividade },
  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");
    const state = reactive({
      isLoading: false,
      respostas: [
        {
          nome: "",
        },
      ],
      currentClientType: "",
      hasError: false,
      id: "",
      nome: "",
      pergunta: "",
      tipo_caixa: "interno",
      descricao: "",
    });
    onErrorCaptured(handleErrors);

    onMounted(() => {
      fetchAtividades();
    });
    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }
    async function abrirModal() {
      state.status_modal = true;
    }

    function fecharModal() {
      state.id = "";
      state.nome = "";
      state.descricao = "";
      state.pontuacao = "";
      state.status_modal = false;
    }

    async function modalVisualizar(id) {
      try {
        let data = await services.atividade.obterPorId({
          empresa_id,
          id,
          token,
        });
        let dados = data.data;
        state.id = dados.id;
        state.nome = dados.nome;
        state.descricao = dados.descricao;
        state.pontuacao = dados.pontuacao;
        state.status_modal = true;
      } catch (error) {
        console.log(error);
      }
    }

    function addResposta() {
      state.respostas.push({});
    }

    async function deletar(id) {
      if (confirm("Deseja deletar a Atividade?")) {
        await services.atividade
          .deletar({
            token,
            empresa_id,
            id,
          })
          .then((data) => {
            if (data.data.verifica_erro) {
              alert("Erro ao deletar Atividade");
            } else {
              fetchAtividades();
            }
          })
          .catch(() => {
            alert("Erro ao deletar Atividade");
          });
      }
    }

    async function salvarAtividade() {
      if (state.id != null && state.id != undefined && state.id != "") {
        let retorno = await services.atividade.update(empresa_id, token, {
          id: state.id,
          nome: state.nome,
          descricao: state.descricao,
          pontuacao: state.pontuacao,
        });

        if (retorno.data.verifica_erro == false) {
          fetchAtividades();
          state.id = "";
          state.nome = "";
          state.descricao = "";
          state.pontuacao = "";
          state.status_modal = false;
        } else {
          alert("Erro ao salvar plano");
        }
      } else {
        let retorno = await services.atividade.save(empresa_id, token, {
          nome: state.nome,
          descricao: state.descricao,
          pontuacao: state.pontuacao,
        });

        if (retorno.data.verifica_erro == false) {
          fetchAtividades();
          state.id = "";
          state.nome = "";
          state.descricao = "";
          state.pontuacao = "";
          state.status_modal = false;
        } else {
          alert("Erro ao salvar plano");
        }
      }
    }

    async function fetchAtividades() {
      try {
        state.isLoading = true;
        const { data } = await services.atividade.listar({
          token: token,
          empresa_id: empresa_id,
        });
        //  console.log(data)
        state.atividades = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    return {
      state,
      abrirModal,
      fecharModal,
      salvarAtividade,
      modalVisualizar,

      deletar,
      addResposta,
    };
  },
};
</script>

<style scoped>
.btn_add_resposta {
  background-color: #034aa6;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #fff;
  padding: 0px;
  font-size: 16pt;
  border: none;
}

.grupo {
  width: 100%;
  height: 100px;
  border: #034aa6 solid 1px;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px;
}

.nome_grupo {
  font-weight: bold;
}

.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}

.btn_salvar:hover {
  transform: none;
  color: #fff;
}

.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}

.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}

textarea {
  resize: none;
}

.btn_add {
  background-color: rgb(3, 74, 166);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(3, 74, 166);
  border-radius: 10px;
  font-size: 10pt;
  padding: 12px;
  font-weight: 700;
  width: 70%;
  margin-bottom: 15px;
}

.btn_add:hover {
  cursor: pointer;
}
</style>