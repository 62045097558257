export default httpClient => ({
    buscaCategoria: async (token) => {
        const headers = {
            "Authorization": token
        }
        const response = await httpClient.post('/categorias-permissao', {
        }, { headers })
        return { data: response.data }
    },
    porCategoria: async ({ token, empresa_id, id_categoria}) => {
        const headers = {
            "Authorization": token
        }
        const response = await httpClient.post('/permissao/por-categoria/' + empresa_id, {
            token,
            id_categoria_permissao: id_categoria
        }, { headers })
        return { data: response.data }
    },
})
