export default httpClient => ({
  login: async ({ login, password }) =>{
    const response = await httpClient.post('/login',{
      login,
      password
      
    })
    let errors = null

    if(!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }

    return {
      data: response.data,
      errors
    }
  },
  verificaToken: async (token) => {
    const headers = {
      "Authorization":token
    }
    const response = await httpClient.post('/verifica-token-logado',{},{headers});
    return { data: response.data }
  },
})