<template>
  <div style="background-color: #f8f9fe; min-height: 100vh">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-2">
          <barra-lateral />
        </div>
        <div class="col-lg-10">
          <topo :titulo="'Cadastrar Colaborador'"></topo>
          <div
            class="card shadow p-3"
            :class="type === 'dark' ? 'bg-default' : ''"
          >
            <div class="row align-items-center">
              <div class="col">
                <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                  Colaborador
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div
                  class="card mb-3"
                  :class="type === 'dark' ? 'bg-default' : ''"
                >
                  <div
                    class="card-header border-0"
                    :class="type === 'dark' ? 'bg-transparent' : ''"
                  >
                    <div class="row">
                      <div class="col-md-8">
                        <div class="form-group" style="margin-bottom: 0">
                          <p
                            v-if="state.status == 'ativo'"
                            style="
                              font-size: 10pt;
                              margin-bottom: 0;
                              color: #00a65a;
                              text-transform: capitalize;
                            "
                          >
                            Colaborador {{ state.status }}
                          </p>

                          <p
                            v-else
                            style="
                              font-size: 10pt;
                              margin-bottom: 0;
                              color: #dd4b39;
                              text-transform: capitalize;
                            "
                          >
                            Colaborador {{ state.status }}
                          </p>
                        </div>
                      </div>
                      <div class="space_btn col-md-4">
                        <router-link
                          :to="'/alterar-colaborador/' + state.id"
                          class="primary"
                        >
                          <button
                            type="primary"
                            id="btn_new_product"
                            class="btn_edit"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-pencil-square"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
                              />
                              <path
                                fill-rule="evenodd"
                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                              />
                            </svg>
                          </button>
                        </router-link>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group" style="margin-bottom: 0">
                          <p style="font-size: 25pt; margin-bottom: 0">
                            {{ state.nome }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-8" style="margin-bottom: 10px">
                        Data Nasc.: {{ state.data_nascimento }} -
                        {{ state.idade }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-8" style="margin-bottom: 10px">
                        Data de Cadastro: {{ state.data_cadastro }}
                      </div>
                    </div>
                    <h4>Telefones</h4>
                    <div class="row">
                      <div class="col-md-12">
                        Principal: {{ state.telefone }}
                      </div>
                      <div class="col-md-12" v-if="state.tel_emergencia != ''">
                        Alternativo: {{ state.celular }}
                      </div>
                    </div>
                    <div class="row" v-if="state.tel_emergencia != ''">
                      <div class="col-md-12">
                        <h4>Tel. Emergência</h4>
                        {{ state.tel_emergencia }}
                      </div>
                    </div>
                    <div class="row" v-if="state.email != ''">
                      <div class="col-md-6">
                        <h4>E-mail</h4>
                        {{ state.email }}
                      </div>
                    </div>
                    <div class="row" v-if="state.endereco != ''">
                      <div class="col-md-12">
                        <h4>Endereço</h4>
                        {{ state.endereco }} - {{ state.numero }} -
                        {{ state.bairro }}
                      </div>
                    </div>
                    <div class="row" v-if="state.endereco != ''">
                      <div class="col-md-12">
                        {{ state.nome_cidade }} - {{ state.nome_estado }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="card shadow mb-3"
                  :class="type === 'dark' ? 'bg-default' : ''"
                >
                  <div class="card-header border-0">
                    <div class="row align-items-center">
                      <div class="col">
                        <h3
                          class="mb-0"
                          :class="type === 'dark' ? 'text-white' : ''"
                        >
                          Funções
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="card mb-3"
                  :class="type === 'dark' ? 'bg-default' : ''"
                >
                  <div class="col">
                    <div class="table-responsive">
                      <table
                        class="table tablesorter table align-items-center table-flush"
                      >
                        <thead class="thead-light">
                          <th>Cargos</th>
                          <th>Status</th>
                          <th></th>
                          <th></th>
                        </thead>
                        <tbody class="list">
                          <tabela-conta-cliente
                            v-for="conta in state.contas"
                            :key="conta.id"
                            :conta="conta"
                          />
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div
                  class="card shadow mb-3"
                  :class="type === 'dark' ? 'bg-default' : ''"
                >
                  <div class="card-header border-0">
                    <div class="row align-items-center">
                      <div class="col">
                        <h3
                          class="mb-0"
                          :class="type === 'dark' ? 'text-white' : ''"
                        >
                          Recebidos
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="card mb-3"
                  :class="type === 'dark' ? 'bg-default' : ''"
                >
                  <div class="col">
                    <div class="table-responsive">
                      <table
                        class="table tablesorter table align-items-center table-flush"
                      >
                        <thead class="thead-light">
                          <th>Data</th>
                          <th>Valor</th>
                          <th></th>
                          <th></th>
                        </thead>
                        <tbody class="list">
                          <tabela-matricula-cliente
                            v-for="matricula in state.matriculas"
                            :key="matricula.id"
                            :matricula="matricula"
                          />
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div
                  class="card shadow mb-3"
                  :class="type === 'dark' ? 'bg-default' : ''"
                >
                  <div class="card-header border-0">
                    <div class="row align-items-center">
                      <div class="col">
                        <h3
                          class="mb-0"
                          :class="type === 'dark' ? 'text-white' : ''"
                        >
                          Alunos
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="card mb-3"
                  :class="type === 'dark' ? 'bg-default' : ''"
                >
                  <div class="col">
                    <div class="table-responsive">
                      <table
                        class="table tablesorter table align-items-center table-flush"
                      >
                        <thead class="thead-light">
                          <th>Nome</th>
                          <th>Plano</th>
                          <th></th>
                          <th></th>
                        </thead>
                        <tbody class="list">
                          <tabela-avaliacao-cliente
                            v-for="avaliacao in state.avaliacoes"
                            :key="avaliacao.id"
                            :avaliacao="avaliacao"
                          />
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import { onMounted, reactive, onErrorCaptured } from "vue";
import services from "../services";
import { useRouter } from "vue-router";
import { useStorage } from "vue3-storage";
import BarraLateral from "../layout/Sistema/barraLateral";
import topo from "../components/topo";
export default {
  name: "tables",
  components: { BarraLateral, topo },

  setup() {
    const storage = useStorage();
    const router = useRouter();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");

    const state = reactive({
      isLoading: false,
      currentClientType: "",
      hasError: false,
    });

    onErrorCaptured(handleErrors);
    async function fetchColaboradores() {
      try {
        state.isLoading = true;
        const { data } = await services.colaboradores.obterPorId({
          type: state.currentClientType,
          token: token,
          empresa_id: empresa_id,
          id: router.currentRoute._value.params.id,
        });
        state.id = data.id;
        console.log(state.id);
        state.nome = data.nome;
        state.idade = data.idade + " anos";
        state.telefone = data.telefone;
        state.celular = data.celular;
        state.tel_emergencia = data.tel_emergencia;
        state.data_nascimento = data.data_nascimento;
        state.status = data.status;
        state.email = data.email;
        state.endereco = data.endereco;
        state.numero = data.numero;
        state.nome_cidade = data.nome_cidade;
        state.nome_estado = data.nome_estado;
        state.bairro = data.bairro;
        state.data_cadastro = data.created_at;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function fetchFuncoes() {
      try {
        state.isLoading = true;
        const { data } = await services.funcao.porColaborador({
          token: token,
          empresa_id: empresa_id,
          colaborador_id: router.currentRoute._value.params.id,
        });
        console.log(data);
        //  console.log(data)
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    onMounted(() => {});
    fetchColaboradores();
    fetchFuncoes();
    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }

    return {
      state,
      fetchFuncoes,
      pagination: {
        default: 1,
      },
    };
  },
};
</script>
<style scoped>
.space_btn {
  text-align: right;
}
.btn_edit {
  cursor: pointer;
  border: none;
  background: transparent;
}
h4 {
  margin-bottom: 0;
  margin-top: 10px;
}
</style>