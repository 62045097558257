
export default httpClient => ({
  listar: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/tipo-pagamento/' + empresa_id,  { headers });
    return { data: response.data }
  },
  listarGateway: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }

    const response = await httpClient.post('/tipo-pagamento/carregar/gateway/' + empresa_id, {
      token: token,
      via_gateway: 'sim'
    }, { headers });
    return { data: response.data }
  },
  obterPorId: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/seleciona/tipo-pagamento/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },

  save: async ({ empresa_id, token, nome, tipo, via_gateway,escolher_bandeira }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/cria/tipo-pagamento/' + empresa_id, {
      token,
      nome,
      tipo,
      via_gateway,
      escolher_bandeira
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  update: async ({ empresa_id, token, id, nome, tipo, via_gateway,escolher_bandeira }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/edita/tipo-pagamento/' + empresa_id, {
      token,
      id,
      nome,
      tipo,
      via_gateway,
      escolher_bandeira
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  deletar: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/deleta/tipo-pagamento/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },

})