<template>
  <div style="background-color: #f8f9fe; min-height: 100vh">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-2"></div>
        <div class="col-sm-10">
          <div class="row">
            <div class="col-xl-12 col-lg-12">
              <stats-card>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/">Home</router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link to="/fichas-treino" class="primary"
                        >Fichas</router-link
                      >
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Adicionar
                    </li>
                  </ol>
                </nav>

                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                  "
                >
                  <div>
                    <h3>Treinos</h3>
                  </div>
                  <div>
                    <button @click="adicionarTreino()" class="btn_novo">
                      Adicionar Treino
                    </button>
                  </div>
                </div>
                <div v-for="(treino, index) in state.treinos" :key="treino">
                  <h4>Treino {{ index + 1 }}</h4>
                  <div class="row">
                    <div
                      class="col-sm-4"
                      v-for="(musculo, i) in treino.musculos"
                      :key="musculo"
                    >
                      <div>
                        <div
                          style="
                            background-color: #034aa6;
                            justify-content: space-between;
                            color: #fff;
                            text-align: center;
                            font-weight: 600;
                            display: flex;
                          "
                        >
                          <div>{{ i }}</div>
                          <div>{{ musculo.nome }}</div>
                          <div>
                            <button
                              class="btn_transparente"
                              @click="alterarMusculo(i)"
                            >
                              <i class="bi bi-pencil-fill"></i>
                            </button>
                            <button
                              class="btn_transparente"
                              @click="deletarMusculo(index, i)"
                            >
                              <i class="bi bi-x"></i>
                            </button>
                          </div>
                        </div>
                        <table style="width: 100%">
                          <thead>
                            <th>Ordem</th>
                            <th>Exercício</th>
                            <th>Séries</th>
                          </thead>
                          <tbody>
                            <tr
                              v-for="exercicio in musculo.exercicios"
                              :key="exercicio"
                            >
                              <td>{{ exercicio.ordem }}</td>
                              <td>{{ exercicio.nome }}</td>
                              <td>{{ exercicio.series }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <button
                    class="btn btn-primary"
                    style="width: 100%"
                    @click="addMusculo(index)"
                  >
                    Add Músculo
                  </button>
                </div>
                <button class="btn btn-success" @click="salvarFicha">
                  Salvar Ficha
                </button>
              </stats-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    :open="state.modal_musculo"
    @close="state.modal_musculo = !state.modal_musculo"
  >
    <div style="padding: 20px">
      <div class="row">
        <div class="col-sm-4">
          <label>Grupo Muscular</label>
          <select
            class="form-select"
            @change="
              selecionarGrupo(
                $event.target.value,
                $event.target.selectedOptions[0].text
              )
            "
            v-model="state.grupo_selecionado"
          >
            <option value=""></option>
            <option
              v-for="musculo in state.musculos"
              :key="musculo"
              :value="musculo.id"
            >
              {{ musculo.nome }}
            </option>
          </select>
        </div>
      </div>
      <table class="table">
        <thead>
          <th style="width: 30%">Nome</th>
          <th style="width: 12%">Ordem</th>
          <th style="width: 12%">Séries</th>
          <th style="width: 12%">Repetições</th>
          <th style="width: 12%">Peso (kg)</th>
          <th style="width: 12%">Selecionar</th>
        </thead>
        <tbody>
          <tr v-for="exercicio in state.exercicios" :key="exercicio">
            <td style="margin-top: 5px">{{ exercicio.nome }}</td>
            <td>
              <input
                type="number"
                class="form-control"
                v-model="exercicio.ordem"
              />
            </td>
            <td>
              <input
                type="text"
                class="form-control"
                v-model="exercicio.series"
              />
            </td>
            <td>
              <input
                type="text"
                class="form-control"
                v-model="exercicio.repeticoes"
              />
            </td>
            <td>
              <input
                type="text"
                class="form-control"
                v-model="exercicio.peso"
              />
            </td>
            <td style="padding-top: 23px">
              <input type="checkbox" v-model="exercicio.selecionado" />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col-sm-8">
          <button class="btn btn-success" @click="adicionarGrupoMuscular()">
            Adicionar Exercícios do Grupo
          </button>
          <button class="btn btn-danger" @click="cancelar()">Cancelar</button>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import { onMounted, reactive } from "vue";
import services from "../services";
import CorpoBuscaCliente from "../components/CorpoBuscaClientes";
import { useRouter } from "vue-router";
import Modal from "../components/EstruturaModal/EstruturaModal.vue";
import { useStorage } from "vue3-storage";
import { pt } from "date-fns/locale";
import Datepicker from "vue3-datepicker";

export default {
  components: { Modal },
  setup(_, { emit }) {
    // var isOpen = ref(false);
    const storage = useStorage();
    var data_atual = new Date();
    var dd = String(data_atual.getDate()).padStart(2, "0");
    var mm = String(data_atual.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = data_atual.getFullYear();

    data_atual = dd + "/" + mm + "/" + yyyy;

    var nome_usuario = storage.getStorageSync("nome_pessoa");
    var id_func_usuario = storage.getStorageSync("colaborador_id");
    var empresa_id = storage.getStorageSync("empresa_id");
    var token = storage.getStorageSync("token");

    const router = useRouter();

    function cancel() {
      router.push({ name: "fichasTreino" });
    }

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      ficha_id: "",
      isOpenModalMusculo: false,
      data_inicial: new Date(),
      clientes: [],
      avaliadores: [],

      id_cadastrante: {
        value: id_func_usuario,
      },
      nome_cadastrante: {
        value: nome_usuario,
      },
      id_cliente: "",
      nome_cliente: "",
      //data_inicial: "",
      data_final: "",
      hora_avaliacao: "",
      personal: "",
      data_cadastro: {
        value: data_atual,
      },
      categoria: {
        value: "",
      },
      treinos: [],
      treino_selecionado: "",
    });

    onMounted(() => {
      state.ficha_id = router.currentRoute._value.params.ficha_id;
      fetchCategoria();
      fetchMusculos();
    });

    function handleBuscarCliente() {
      modal.open({
        component: "ModalBuscarAluno",
      });
    }

    function addMusculo(treino_id) {
      state.treino_selecionado = treino_id;
      state.modal_musculo = true;
    }

    function adicionarTreino() {
      var treino = {};
      treino.numero = state.treinos.length;
      //  state.treino_selecionado = treino.numero;
      state.treinos.push(treino);
    }

    function adicionarGrupoMuscular() {
      //  var exercicios = [];

      let musculo = {};
      musculo.id = state.grupo_selecionado;
      musculo.nome = state.nome_grupo;
      musculo.exercicios = [];

      for (let i = 0; i < state.exercicios.length; i++) {
        if (state.exercicios[i].selecionado) {
          musculo.exercicios.push(state.exercicios[i]);
        }
      }

      if (state.treinos[state.treino_selecionado].musculos == undefined) {
        state.treinos[state.treino_selecionado] = {};
        state.treinos[state.treino_selecionado].musculos = [];
      }
      state.treinos[state.treino_selecionado].musculos.push(musculo);
      state.grupo_selecionado = "";
      state.modal_musculo = false;
    }

    function cancelar() {
      state.modal_musculo = false;
      state.treino_selecionado = "";
    }

    function alterarMusculo() {}

    function deletarMusculo(index_treino, index_musculo) {
      state.treinos[index_treino].musculos.splice(index_musculo, 1);
    }

    function buscarCliente(id_cliente, nome_cliente) {
      state.id_cliente = id_cliente;
      state.nome_cliente = nome_cliente;
      state.isOpen = false;
    }
    function fecharModal() {
      state.isOpen = false;
      state.nome = "";
      state.clientes = [];
    }

    async function salvarFicha() {
      try {
        const { errors } = await services.ficha.saveTreinosFicha({
          empresa_id: empresa_id,
          token: token,
          treinos: state.treinos,
          ficha_id: state.ficha_id,
        });

        if (!errors) {
          //       window.localStorage.setItem('token', data.token)
          //     router.push({ name: 'products' })
          state.isLoading = false;
          return;
        }

        state.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchClientes() {
      try {
        state.isLoading = true;
        const { data } = await services.clientes.clientesPorNome({
          empresa_id: empresa_id,
          token: token,
          nome: state.nome,
        });
        console.log(data);
        //  console.log(data)
        state.clientes = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function fetchMusculos() {
      try {
        state.isLoading = true;
        const { data } = await services.ficha.getGruposMuscular({
          empresa_id: empresa_id,
          token: token,
        });
        console.log(data);
        //  console.log(data)
        state.musculos = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function selecionarGrupo(grupo_id, nome_grupo) {
      try {
        state.isLoading = true;
        const { data } = await services.ficha.getExerciciosPorMusculo({
          empresa_id: empresa_id,
          token: token,
          grupo_muscular_id: grupo_id,
        });
        state.grupo_selecionado;
        state.nome_grupo = nome_grupo;
        state.exercicios = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function fetchCategoria() {
      try {
        state.isLoading = true;
        const { data } = await services.colaboradores.getAvaliadores({
          empresa_id: empresa_id,
          token: token,
        });
        state.categorias = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }

    async function handleSubmit() {}
    return {
      state,
      salvarFicha,
      handleBuscarCliente,
      handleSubmit,
      fetchClientes,
      CorpoBuscaCliente,
      emit,
      buscarCliente,
      cancel,
      fecharModal,
      Datepicker,
      pt,
      addMusculo,
      adicionarTreino,
      fetchMusculos,
      selecionarGrupo,
      adicionarGrupoMuscular,
      cancelar,
      alterarMusculo,
      deletarMusculo,
    };
  },
};
</script>

<style scoped>
textarea {
  resize: none;
}

input {
  border-color: #b8b8b8;
  border-width: 2px;
  color: #505050;
  border-radius: 15px;
  transition: 0.5s;
}

input:focus {
  border-color: #252525;
}

select {
  border-color: #b8b8b8;
  border-width: 2px;
  color: #505050;
  border-radius: 15px;
  transition: 0.5s;
}

select:focus {
  border-color: #252525;
}

.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}

.btn_salvar:hover {
  transform: none;
  color: #fff;
}

.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}

.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}

.div_s {
  height: 350px;
  overflow-y: scroll;
}

.btn_fechar {
  color: #fff;
  background-color: #788596;
}

.btn_fechar:hover {
  color: #fff;
  background-color: #788596;
  transform: none;
}

.btn_lupa {
  background-color: #aede00;
  padding: 10px;
  border-color: #aede00 solid 1px !important;
  color: rgb(0, 0, 0) !important;
  border-radius: 10px !important;
}

.btn_lupa:hover {
  transform: none;
}

.btn_transparente {
  border: none;
  background: none;
  color: #fff;
}

.btn_novo {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}

thead {
  padding: 20px;
}
</style>