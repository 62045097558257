<template>
  <footer>
    <div class="container">
      <p>
        &copy; Copyright 2024 - Sifit -
        <span v-if="idioma == 'PT'">Gestão de Academias</span
        ><span v-else>Gestión de Gimnasio</span> &reg; CNPJ: 00.054.489/0002-88
      </p>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    idioma: { type: String, required: true },
  },
};
</script>
<style scoped>
footer {
  width: 100%;
  background: #00357a;
  color: white;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 5px;
}
</style>