export default httpClient => ({
  listar: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/caixa/caixas-paginacao/' + empresa_id, {
      token: token,
      quantidade: '20',
      quantidade_anterior: '0'
    }, { headers });
    return { data: response.data }
  },
  obterPorId: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/seleciona/caixa/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },
  obterAberto: async ({ token, empresa_id, local_conta_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/seleciona/caixa-aberto/' + empresa_id, {
      token,
      local_conta_id
    }, { headers })
    return { data: response.data }
  },
  buscaContasPagar: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/caixa/carregarContasPagar/' + empresa_id, {
      token
    }, { headers })
    return { data: response.data }
  },
  buscaContasReceber: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/caixa/carregarContasReceber/' + empresa_id, {
      token
    }, { headers })
    return { data: response.data }
  },
  totalPagar: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/total-clientes/' + empresa_id, {
      token
    }, { headers })
    return { data: response.data.length }
  },
  valorRestante: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/total-clientes/' + empresa_id, {
      token
    }, { headers })
    return { data: response.data.length }
  },
  valorAtraso: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/total-clientes/' + empresa_id, {
      token
    }, { headers })
    return { data: response.data.length }
  },
  contaAtrasada: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/total-clientes/' + empresa_id, {
      token
    }, { headers })
    return { data: response.data.length }
  },

  telaCaixaWeb: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/caixa/tela-caixa-web/' + empresa_id, {
      token
    }, { headers })
    return { data: response.data }
  },
  buscaDataCaixa: async ({ token, empresa_id, data_inicial, status, data_final }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/caixa/buscar/' + empresa_id, {
      token,
      empresa_id,
      data_inicial,
      status,
      data_final,
    }, { headers })
    return { data: response.data }
  },

  save: async ({ token, funcionario_cadastrante_id, data_cadastro, local_contas, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/abrir-caixa/' + empresa_id, {
      token,
      saldo_inicial: 0,
      funcionario_cadastrante_id,
      data_cadastro,
      local_conta_id: local_contas,
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erro')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  fecharCaixa: async ({ token, id,funcionario_fechador_id,  valor_entradas_debito, valor_entradas_credito, valor_fornecedores, valor_colaboradores, valor_total_entradas, valor_total_saidas, valor_sangria, valor_suprimento, saldo_operacional, saldo_inicial, saldo_final, data_final, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/cria/fechamento-caixa/' + empresa_id, {
      token,
      id,
      funcionario_fechador_id,
      valor_entradas_debito,
      valor_entradas_credito,
      valor_fornecedores,
      valor_colaboradores,
      valor_total_entradas,
      valor_total_saidas,
      valor_sangria,
      valor_suprimento,
      saldo_operacional,
      saldo_inicial,
      saldo_final,
      data_final,
      status:"fechado"
    }, { headers })

    return { data: response.data }
  }
})