

export default httpClient => ({
  selecionaPorData: async ({ token, empresa_id, data_inicial, data_final, tipo_data }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/contas-receber/por-data/' + empresa_id, {
      token: token,
      data_inicial,
      data_final,
      tipo_data
    }, { headers })
    return { data: response.data }
  },
  selecionaPorDataStatus: async ({ token, empresa_id, data_inicial, data_final, status, tipo_data }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/contas-receber/por-data-status/' + empresa_id, {
      token: token,
      data_inicial,
      data_final,
      tipo_data,
      status,

    }, { headers })
    return { data: response.data }
  },

  contasPorNomeCliente: async ({ token, empresa_id, nome_cliente }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/contas-receber-pornome/' + empresa_id, {
      token,
      nome: nome_cliente
    }, { headers })
    return { data: response.data }
  },
  buscarPorSituacao: async ({ token, empresa_id, nome_cliente }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/contas-receber-pornome/' + empresa_id, {
      token,
      nome: nome_cliente
    }, { headers })
    return { data: response.data }
  },
  buscarDataFinal: async ({ token, empresa_id, nome_cliente }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/contas-receber-pornome/' + empresa_id, {
      token,
      nome: nome_cliente
    }, { headers })
    return { data: response.data }
  },
  buscarDataInicial: async ({ token, empresa_id, nome_cliente }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/contas-receber-pornome/' + empresa_id, {
      token,
      nome: nome_cliente
    }, { headers })
    return { data: response.data }
  },
  buscarStatus: async ({ token, empresa_id, nome_cliente }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/contas-receber-pornome/' + empresa_id, {
      token,
      nome: nome_cliente
    }, { headers })
    return { data: response.data }
  },

  contasPorClienteId: async ({ token, empresa_id, limit,offset,cliente_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/contas-receber-porcliente/' + empresa_id, {
      token,
      cliente_id,
      limit,
      offset
    }, { headers })
    return { data: response.data }
  },
  deletarContasReceber: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/deletar-conta-receber/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },
  obterPorId: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/seleciona-contas-receber/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },

  valoresTelaContaReceber: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/contas-receber/tela-web/' + empresa_id, {
      token
    }, { headers })
    return { data: response.data }
  },



  valorPendente: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/contas-receber/valor-pendente/' + empresa_id, {
      token
    }, { headers })
    return { data: response.data }
  },
  valorRecebido: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/contas-receber/valor-recebido/' + empresa_id, {
      token
    }, { headers })
    return { data: response.data }
  },
  contaAtrasada: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/contas-receber/vencidas/' + empresa_id, {
      token
    }, { headers })
    return { data: response.data }
  },

  listaAtrasadas: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/contas-receber/vencidas/' + empresa_id, {
      token
    }, { headers })
    return { data: response.data }
  },

  valorAtrasado: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/total-clientes/' + empresa_id, { headers })
    return { data: response.data.length }
  },

  buscaContaReceberPorStatusTipoPagLocalPagCaixaId: async ({ token, empresa_id, local_conta_id, caixa_id, tipo_pagamento_id,status}) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/contas-receber-por-data-status-tipo-pagamento-local-pagamento-caixa/' + empresa_id, {
      token, empresa_id, local_conta_id, caixa_id, tipo_pagamento_id,status
    }, { headers })
    return { data: response.data}
  },


  save: async ({ token, funcionario_cadastrante_id, funcionario_recebedor_id, tipo_pagamento_id, cliente_id, residuo, banco_id, cartao_id,
    local_conta_id, caixa_id, nome, valor_receber, valor_recebido, valor_recebido_liquido, qtd_parcelas_cartao, descricao,
    data_vencimento, data_recebimento, status, observacao, empresa_id
  }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/cadastrar-conta-receber/' + empresa_id, {
      token,
      funcionario_cadastrante_id,
      funcionario_recebedor_id,
      tipo_pagamento_id,
      cliente_id,
      residuo,
      banco_id,
      cartao_id,
      local_conta_id,
      caixa_id,
      nome,
      valor_receber,
      valor_recebido,
      valor_recebido_liquido,
      qtd_parcelas_cartao,
      descricao,
      data_vencimento,
      data_recebimento,
      status,
      observacao
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  recebimento: async ({ id, token, func_recebedor_id, data_vencimento, valor_recebido, valor_recebido_liquido, data_recebimento, tipo_pagamento_id, local_recebimento_id, caixa_id, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/conta-receber/receber/' + empresa_id, {
      token,
      id,
      func_recebedor_id,
      data_vencimento,
      valor_recebido,
      valor_recebido_liquido,
      data_recebimento,
      tipo_pagamento_id,
      local_recebimento_id,
      caixa_id
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  //efetuarRec: async({token, empresa_id, id, data_vencimento, valor_recebido, data_recebimento, tipo_pagamento_id, local_recebimento_id}) =>{
  //  const response = await httpClient.post('//' + empresa_id,{
  //  token,
  // id,
  // data_vencimento, 
  //  valor_recebido,
  //  data_recebimento,
  //  tipo_pagamento_id,
  // local_recebimento_id,
  // })
  //return{
  //data: response.data,
  //}
  // }
})