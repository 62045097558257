<template>
  <barra-lateral />
  <div style="background-color: #f8f9fe; min-height: 100vh; width: 100%">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <topo :titulo="'Aplicativo'" @openOffcanvas="openOffcanvas"></topo>
          <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-3">
            <!-- Card stats -->
            <div class="row">
              <div class="col-6 col-xl-3 col-lg-6">
                <div class="card-stats">
                  <div class="quad">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-safe" viewBox="0 0 16 16">
                      <path
                        d="M1 1.5A1.5 1.5 0 0 1 2.5 0h12A1.5 1.5 0 0 1 16 1.5v13a1.5 1.5 0 0 1-1.5 1.5h-12A1.5 1.5 0 0 1 1 14.5V13H.5a.5.5 0 0 1 0-1H1V8.5H.5a.5.5 0 0 1 0-1H1V4H.5a.5.5 0 0 1 0-1H1V1.5zM2.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5h12a.5.5 0 0 0 .5-.5v-13a.5.5 0 0 0-.5-.5h-12z" />
                      <path
                        d="M13.5 6a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5zM4.828 4.464a.5.5 0 0 1 .708 0l1.09 1.09a3.003 3.003 0 0 1 3.476 0l1.09-1.09a.5.5 0 1 1 .707.708l-1.09 1.09c.74 1.037.74 2.44 0 3.476l1.09 1.09a.5.5 0 1 1-.707.708l-1.09-1.09a3.002 3.002 0 0 1-3.476 0l-1.09 1.09a.5.5 0 1 1-.708-.708l1.09-1.09a3.003 3.003 0 0 1 0-3.476l-1.09-1.09a.5.5 0 0 1 0-.708zM6.95 6.586a2 2 0 1 0 2.828 2.828A2 2 0 0 0 6.95 6.586z" />
                    </svg>
                  </div>
                  <p class="title2">Usuários do App</p>
                  <div class="num">12</div>
                </div>
              </div>
              <div class="col-6 col-xl-3 col-lg-6">
                <div class="card-stats">
                  <div class="quad">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-bank" viewBox="0 0 16 16">
                      <path
                        d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.501.501 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89L8 0ZM3.777 3h8.447L8 1 3.777 3ZM2 6v7h1V6H2Zm2 0v7h2.5V6H4Zm3.5 0v7h1V6h-1Zm2 0v7H12V6H9.5ZM13 6v7h1V6h-1Zm2-1V4H1v1h14Zm-.39 9H1.39l-.25 1h13.72l-.25-1Z" />
                    </svg>
                  </div>
                  <p class="title2">Novos Posts</p>
                  <div class="num">100</div>
                </div>
              </div>
              <div class="col-lg-3 col-12 div_btn_caixa">
                <button style="margin-top: 23px" type="button" class="btn_buscar" @click="criarNotificacao">
                  Criar Notificação
                </button>
              </div>
            </div>
          </base-header>
          <div>
            <div class="card shadow p-3" :class="type === 'dark' ? 'bg-default' : ''" s>
              <div class="row">
                <div class="col-12 col-md-6">
                  <h1>Notificações</h1>
                  <div class="table-responsive">
                    <table class="table tablesorter table align-items-center table-flush">
                      <thead class="thead-light">
                        <th>Código</th>
                        <th>Data</th>
                        <th></th>
                      </thead>
                      <tbody class="list">
                        <corpo-notificacao v-for="notificacao in state.notificacoes" :key="notificacao.id"
                          :notificacao="notificacao" @modalVisualizarNotificacao="modalVisualizarNotificacao
                            " />
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <h1>Postagens</h1>
                  <div class="table-responsive">
                    <table class="table tablesorter table align-items-center table-flush">
                      <thead class="thead-light">
                        <th>Código</th>
                        <th>Data</th>
                        <th></th>
                      </thead>
                      <tbody class="list">
                        <corpo-notificacao v-for="notificacao in state.notificacoes" :key="notificacao.id"
                          :notificacao="notificacao" @modalVisualizarNotificacao="modalVisualizarNotificacao
                            " />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal :open="state.modal_escolher_cartao" @close="state.modal_escolher_cartao = !state.modal_escolher_cartao"
    :width="'400px'">
    <div style="padding: 20px">
      <h4>Escolher Bandeiras</h4>
      <div class="row">
        <div class="col-sm-12">
          <label>Bandeira</label>
          <select class="form-select" v-model="state.bandeira" id="bandeira">
            <select-bandeiras v-for="bandeira in state.bandeiras" :key="bandeira.id" :bandeira="bandeira" />
          </select>
        </div>
      </div>

      <div class="row botoes_modal">
        <div class="col-sm-6">
          <button type="button" class="btn btn-danger largura_total" @click="fecharModal('cartao')">
            Fechar
          </button>
        </div>
      </div>
    </div>
  </Modal>
  <Modal :open="state.isOpenVisualizar" :width="'800px'" @close="state.isOpenVisualizar = !state.isOpenVisualizar">
    <h1>Criar Notificação</h1>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label>Para</label>
          <select class="form-select">
            <option>Todos</option>
          </select>
        </div>
      </div>
      <div class="col-md-9">
        <div class="form-group">
          <label>Mensagem</label>
          <input type="text" class="form-control" v-model="state.mensagem" />
        </div>
      </div>
    </div>

    <div style="text-align: right">
      <button class="btn btn_cancelar my-4" type="button" @click="fecharModalNotificacao">
        <div v-if="
          state.id_selecionado === undefined || state.id_selecionado == null
        ">
          Cancelar
        </div>
        <div v-else>Fechar</div>
      </button>
      <button v-if="
        state.id_selecionado === undefined || state.id_selecionado == null
      " class="btn btn_salvar my-4" type="button" @click="salvarNotificacao">
        Salvar
      </button>
    </div>
  </Modal>
  <OffcanvasComponent ref="offcanvasRef" />

</template>
<script>
import OffcanvasComponent from "../layout/Sistema/barraLateralMobile";
import Modal from "../components/EstruturaModal/EstruturaModal.vue";
import { pt } from "date-fns/locale";
import Datepicker from "vue3-datepicker";
import { onMounted, reactive, ref } from "vue";
import CorpoNotificacao from "../components/CorpoTabelaNotificacao";
import services from "../services";
import { useStorage } from "vue3-storage";
import topo from "../components/topo";
import BarraLateral from "../layout/Sistema/barraLateral";
export default {
  name: "tables",
  components: {
    topo,
    Modal,
    BarraLateral,
    OffcanvasComponent,
  },
  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");
    var nome_usuario = storage.getStorageSync("nome_pessoa");
    var id_func_usuario = storage.getStorageSync("colaborador_id");

    var data_atual = new Date();
    var dd = String(data_atual.getDate()).padStart(2, "0");
    var mm = String(data_atual.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = data_atual.getFullYear();

    data_atual = dd + "/" + mm + "/" + yyyy;

    var today = new Date();
    //  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    //  var yyyy = today.getFullYear();

    ///  var primeiro_dia_mes_atual = "01/" + mm + "/" + yyyy;

    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const state = reactive({
      caixoAberto: false,
      caixaLocal: false,
      id_cadastrante: id_func_usuario,
      nome_cadastrante: nome_usuario,
      data_cadastro: data_atual,
      data_inicial_busca: firstDayOfMonth,
      data_final_busca: lastDayOfMonth,
      status_busca: "",
      isLoading: false,
      hasError: false,
      isOpenVisualizar: false,
    });
    //    onErrorCaptured(handleErrors);

    onMounted(() => {
      fetchNotificacoes();
    });

    async function criarNotificacao() {
      console.log("aquii");
      state.isOpenVisualizar = true;
    }

    async function fetchNotificacoes() {
      try {
        state.isLoading = true;
        const { data } = await services.aplicativo.listarNotificacoes({
          empresa_id,
          token,
        });
        state.notificacoes = data;
      } catch (error) {
        console.log(error);
      }
    }

    async function salvarNotificacao() {
      try {
        const { data } = await services.aplicativo.salvarNotificacao({
          token: token,
          empresa_id: empresa_id,
          mensagem: state.mensagem,
        });
        if (data.verifica_erro == false) {
          state.isOpenVisualizar = false;
          fetchNotificacoes();
        } else {
          alert("Erro ao salvar Notificação!");
        }
      } catch {
        alert("Erro ao salvar Notificação!");
      }
    }

    async function modalVisualizarNotificacao(id) {
      try {
        state.isLoading = true;
        const { data } = await services.aplicativo.obterNotificacaoPorId({
          token: token,
          empresa_id,
          id,
        });
        state.mensagem = data.mensagem;
        state.id_selecionado = data.id;
        state.isOpenVisualizar = true;
      } catch (error) {
        alert("Erro ao acessar Notificação");
      }
    }

    function fecharModalNotificacao() {
      state.isOpenVisualizar = false;
      state.id_selecionado = null;
    }
    const offcanvasRef = ref(null);
    const isVisible = ref(false);

    const openOffcanvas = () => {
      // Estado para controlar a visibilidade do offcanvas
      console.log(isVisible.value);
      //offcanvasRef.value.showOffcanvas();
      if (isVisible.value) {
        offcanvasRef.value.hideOffcanvas();
      } else {
        offcanvasRef.value.showOffcanvas();
      }
    };
    return {
      state,
      CorpoNotificacao,
      fecharModalNotificacao,
      salvarNotificacao,
      fetchNotificacoes,
      Datepicker,
      pt,
      criarNotificacao,
      modalVisualizarNotificacao,
      openOffcanvas,
      offcanvasRef,
      openOffcanvas,
    };
  },
};
</script>
<style scoped>
svg {
  color: #aede00;
  width: 24px;
  height: 24px;
}

.valores {
  text-align: right;
}

.quad {
  padding: 13px;
  gap: 10px;
  width: 50px;
  height: 50px;
  background: #010d1f;
  border-radius: 12px;
}

.title2 {
  color: #000;
  margin-top: 10px;
}

.num {
  margin-top: -15px;
}

.btn_abrir_caixa {
  transform: none;
}

.btn_abrir_caixa:hover {
  transform: none;
}

.div_btn_caixa {
  margin-bottom: 15px;
}

.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}

.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}

.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}

.btn_salvar:hover {
  transform: none;
  color: #fff;
}

.modal-caixa {
  padding: 2rem;
}

.div_usuario_modal {
  border-color: #b8b8b8;
  border-width: 2px;
  color: #505050;
  border-radius: 15px;
  transition: 0.5s;
}

.btn_buscar_contas {
  color: #000;
  background: #aede00;
  border: #aede00 solid 2px;
}

.btn_buscar_contas:hover {
  transform: none;
  color: #000;
}

.div_btn_contas {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.div_btn_entradas {
  text-align: right;
  margin-top: 20px;
  margin-bottom: 20px;
}

.title_modal_visu {
  color: #000;
  font-weight: bold;
}

.btn_ver_entradas {
  color: #000;
  background: #aede00;
  border: #aede00 solid 2px;
  padding: 5px;
  width: 25%;
}

.btn_ver_entradas:hover {
  transform: none;
  color: #000;
}

.btn_ver_saidas {
  color: #fff;
  background: #788596;
  border: #788596 solid 2px;
  padding: 5px;
  width: 20%;
}

.btn_ver_saidas:hover {
  transform: none;
  color: #fff;
}

.btn_cancelar_busca {
  color: #fff;
  background: #788596;
  border: #788596 solid 2px;
  padding: 5px;
  width: 40%;
}

.btn_cancelar_busca:hover {
  transform: none;
  color: #fff;
}

.div_cancelar_buscar {
  text-align: right;
  padding-top: 30px;
}

.btn_buscar {
  font-size: 10pt;
  border-radius: 5px;
  background-color: rgb(3, 74, 166);
  border: none;
  color: #fff;
  font-weight: 700;
  padding: 10px;
  margin-top: 25px;
  width: 100%;
}

.btn_busca:hover {
  transform: none;
}

label {
  margin-bottom: 0;
}

h1 {
  font-size: 20pt;
  font-weight: bold;
}
</style>
