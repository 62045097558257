
export default httpClient => ({

  getAll: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/planos/' + empresa_id, { headers })
    return { data: response.data }
  },

  getAtivos: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/planos/' + empresa_id, { headers })
    return { data: response.data }
  },
  

  obterPorId: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/seleciona-plano/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },

  calcularSubcutaneas: async ({ token, empresa_id, torax_subc, axila_subc, triceptal_subc, subescapular_subc, abdominal_subc, suprailiaca_subc, coxa_subc, sexo, idade, peso }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/avaliacao/calcular-subcutaneas/' + empresa_id, {
      token,
      torax_subc,
      axila_subc,
      triceptal_subc,
      subescapular_subc,
      abdominal_subc,
      suprailiaca_subc,
      coxa_subc,
      sexo,
      idade,
      peso
    }, { headers })
    return { data: response.data }
  },


  avaliacoesPorClienteId: async ({ token, empresa_id, cliente_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/avaliacao/seleciona-por-cliente/' + empresa_id, {
      token,
      cliente_id
    }, { headers })
    return { data: response.data }
  },

  qtdMes: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/qtd-avaliacoes-mes/' + empresa_id, {
      token: token
    }, { headers })
    return { data: response.data }
  },
  qtdMesPassado: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/qtd-avaliacoes-mes-passado/' + empresa_id, {
      token: token
    }, { headers })
    return { data: response.data }
  },
  qtdTotal: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/qtd-avaliacoes/' + empresa_id, {
      token: token
    }, { headers })
    return { data: response.data }
  },

  save: async ({ empresa_id, token, nome, grupo_id, duracao, qtd_parcelas, personal, funcao_id, valor, valor_matricula, beneficios,qtd_dependentes }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/plano/salvar/' + empresa_id, {
      token,
      nome,
      grupo_id,
      duracao,
      qtd_parcelas,
      personal,
      funcao_id,
      valor,
      valor_matricula,
      beneficios,
      qtd_dependentes
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  update: async ({ empresa_id, id, token, nome, grupo_id, duracao, qtd_parcelas, personal, funcao_id, valor, valor_matricula, beneficios, qtd_dependentes }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/plano/update/' + empresa_id, {
      token,
      id,
      nome,
      grupo_id,
      duracao,
      qtd_parcelas,
      personal,
      funcao_id,
      valor,
      valor_matricula,
      beneficios,
      qtd_dependentes
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
    deletar: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/plano/deletar/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },
})