<template>
  <div class="topo">
      <div class="container-fluid">
          <div class="row">
              <div class="col-lg-3">
                <p class="bv" v-if="state.idioma == 'PT'">Métricas de Clientes</p>
                <p class="bv" v-else>Métricas de Clientes</p>

              </div>
              <div class="col-lg-2 div_datas">
                  <label v-if="state.idioma == 'PT'">Data Inicial</label>
                  <label v-else>La Fecha de Inicio</label>

                  
                  <!-- v-model="state.data_inicial" -->
                  <Datepicker
                    v-model="state.data_inicial"
                    :locale="pt"
                    inputFormat="dd/MM/yyyy"
                    style="
                    color: #505050;
                    border-radius: 5px;
                    border: #E7E7E7 solid 1px;
                    transition: 0.5s;
                    width: 100%;
                    padding: 5px;
                    "
                  />
              </div>
              <div class="col-lg-2 div_datas">
                  <label v-if="state.idioma == 'PT'">Data final</label>
                  <label v-else>Fecha Final</label>

                  <Datepicker
                  v-model="state.data_final"
                      :locale="pt"
                      inputFormat="dd/MM/yyyy"
                      style="
                    color: #505050;
                    border-radius: 5px;
                    border: #E7E7E7 solid 1px;
                    transition: 0.5s;
                    width: 100%;
                    padding: 5px;
                    "
                      />
              </div>
              <div class="col-lg-2">
                  <button type="button" @click="dadosPrincipais()">Buscar</button>
              </div>
              <div class="col-lg-3 div_pessoa">
                  <div class="div_imagem" v-if="foto != undefined && foto != null">
                    <img :src="foto" />
                  </div>
                  <div class="div_imagem" v-else>
                    <img src="/img/user_sifit.png" />
                  </div>
                  <div class="nome_usuario">{{ state.nome_pessoa }}</div>
              </div>
          </div>
        </div> 
      </div>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 " style="padding-top: 20px;">
    <card class="card-stats">
      <div class="row">
        <div class="col-sm-4">
          <h4>Gênero</h4>
          <GraficoPizza/>
        </div>

      </div>  
    </card>

    </base-header>

  </div>
  <Modal
    :open="state.status_modal_ausentes"
    @close="state.status_modal_ausentes = !state.status_modal_ausentes"
  >
    <h2>Clientes Ausentes</h2>
    <table class="table tabela_ausentes">
      <thead v-if="state.idioma == 'PT'">
        <th>Código</th>
        <th>Nome</th>
        <th>Dias Última Visita</th>
        <th>Data Última Visita</th>
      </thead>
      <thead v-else>
        <th>Código</th>
        <th>Nombre</th>
        <th>Dias Última Visita</th>
        <th>Data Última Visita</th>
      </thead>
      <tbody>
        <tr v-for="ausente in state.clientes_ausentes" :key="ausente.id">
          <td>{{ ausente.id }}</td>
          <td>{{ ausente.nome }}</td>
          <td>{{ ausente.dias_ultima_visita }}</td>
          <td>{{ ausente.data_ultima_visita }}</td>
        </tr>
      </tbody>
    </table>
    <div>
      <button class="btn btn-primary" @click="state.status_modal_ausentes = false">Fechar</button>
    </div>
  </Modal>

    <Modal
      :open="state.status_modal_sem_matricula"
      @close="state.status_modal_sem_matricula = !state.status_modal_sem_matricula"
    >
      <h2>Clientes Sem Matrículas</h2>
      <table class="table tabela_sem_matriculas">
        <thead>
          <th>Código</th>
          <th>Nome</th>
          <th>Dias Última Visita</th>
          <th>Data Última Visita</th>
        </thead>
        <tbody>

        </tbody>
      </table>
      <div>
        <button class="btn btn-primary" @click="state.status_modal_sem_matricula = false">Fechar</button>
      </div>
    </Modal>

    <Modal
      :open="state.status_modal_personais"
      @close="state.status_modal_personais= !state.status_modal_personais"
    >
      <h2>Personais</h2>
      <table class="table">
        <thead>
          <th>Código</th>
          <th>Nome</th>
          <th>Dias Última Visita</th>
          <th>Data Última Visita</th>
        </thead>
        <tbody>
          
        </tbody>
      </table>
      <div>
        <button class="btn btn-primary" @click="state.status_modal_personais = false">Fechar</button>
      </div>
    </Modal>



    <Modal
      :open="state.status_modal_matricula_periodo"
      @close="state.status_modal_matricula_periodo = !state.status_modal_matricula_periodo"
    >
      <label></label>
      <div
        class="form-group"
        style="margin-bottom: 0"
      >
        <textarea
          class="form-control"
          style="resize: none;"
        ></textarea>
      </div>
      <div>
        <button class="btn btn-primary" @click="state.status_modal_matricula_periodo = false">Fechar</button>
      </div>
    </Modal>

    

</template>
<script>
//import Chart from "chart.js";
import { onMounted, reactive, onErrorCaptured } from "vue";
import Modal from "../components/EstruturaModal/EstruturaModal.vue";
import GraficoPizza from "../components/Graficos/pizza"
//import { ordersChart } from "@/components/Charts/Chart";
import { pt } from "date-fns/locale";
import services from "../services";
import { useStorage } from "vue3-storage";
import Datepicker from "vue3-datepicker";

 
//let chart;
export default {
  components: {
    Datepicker,

    //  MaioresFrequentadoresTable,
    //  UltimosVisitantesTable,
  },

  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");
    var nome_pessoa = storage.getStorageSync("nome_pessoa");
    var foto = storage.getStorageSync("foto");
    nome_pessoa = nome_pessoa.split(' ')[0];

  //  var colaborador_id = storage.getStorageSync("colaborador_id");

    var today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const state = reactive({
      dados_busca: {
        matriculas_periodo: "-",
        desistencias_periodo: "-"
      },
      clientes_ausentes: [],
      clientes_sem_matricula:[],
      isLoading: false,
      currentClientType: "",
      hasError: false,
      ultimos_visitantes: [],
      maiores_visitantes: [],
      data_inicial: firstDayOfMonth,
      data_final: lastDayOfMonth,
      entradas: [],
      pendentes: [],
      personais: [],
      status_modal_ausentes: false,
      status_modal_sem_matricula: false,
      status_modal_personais: false,
      status_modal_matricula_periodo: false,
      idioma: 'PT',
      nome_pessoa: nome_pessoa
    });


    async function modalClienteAusente() {
      state.status_modal_ausentes = true;
      try {
        const clientesAusentes = await services.clientes.listaClientesAusentes({
          token: token,
          empresa_id: empresa_id,
          quantidade: 15,
          quantidade_anterior:0,
          dias_ausencia: 60
        });
        state.clientes_ausentes = clientesAusentes.data;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function modalClienteSemMatricula() {
      try {
        const clientesAusentes = await services.clientes.listaClientesSemMatricula({
          token: token,
          empresa_id: empresa_id,
          quantidade: 15,
          quantidade_anterior: 0
        });
        state.clientes_sem_matricula = clientesAusentes.data;
        state.status_modal_sem_matricula = true;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function modalPersonais() {
      state.status_modal_personais = true;
     /* try {
        const clientesAusentes = await services.clientes.listaPerso({
          token: token,
          empresa_id: empresa_id,
          quantidade: 15,
          quantidade_anterior: 0
        });
        state.clientes_ausentes = clientesAusentes.data;
        state.status_modal_sem_matricula = true;
      } catch (error) {
        handleErrors(error);
      }*/
    }


    async function modalMatriculasNoPeriodo() {
      state.status_modal_matricula_periodo();
    }


    async function dadosPrincipais() {
      
      try {
        const dadosPrincipais = await services.home.dadosPrincipais({
            data_inicial: state.data_inicial,
            data_final: state.data_final,
            token: token,
            empresa_id: empresa_id,
          });
        state.dados_busca = dadosPrincipais.data;
      } catch (error) {
        handleErrors(error);
      }


      /*
      try {
        const dadosPrincipais = await services.home.dadosPrincipais({
            data_inicial: state.data_inicial,
            data_final: state.data_final,
            token: token,
            empresa_id: empresa_id,
          });
        state.dados_principais = dadosPrincipais.data;
      } catch (error) {
        handleErrors(error);
      }*/
    }

    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }

    onErrorCaptured(handleErrors);

    onMounted(() => {
       dadosPrincipais();
    });
    return {
      state,
      dadosPrincipais,
      pt,
      Modal,
      modalClienteAusente,
      modalClienteSemMatricula,
      modalMatriculasNoPeriodo,
      modalPersonais,
      foto,
      GraficoPizza
    };
  },


};
</script>
<style scoped>

a{
  cursor: pointer;
}

.tabela_ausentes th, .tabela_ausentes td{
  padding: 0.4rem;
}

.tabela_sem_matriculas th, .tabela_sem_matriculas td{
  padding: 0.4rem;
}

.info_body{
 margin-left: 12pt; 
 margin-top:5px;
}

.qtd_body{
  font-size: 20pt;
  line-height: 1;
}
label{
  font-size: 12pt;
}
body *{
  font-size: 12pt;
}
.card-flex{
  display: flex;
}
.avaliacao {
  color: #000;
  font-weight: 600;;
  font-size:14pt;
}
.card_dir{
  margin-left: 12px;
}
.bg-default{
  background-color: #fff !important;
}
.arow {
  margin-right: 5px;
  font-size: 0.7rem !important;
  color: #034aa6;
  font-weight: 700;
}
.text1 {
  color: #034aa6;
  font-size: 7pt !important;
  font-weight: bold;
}
.num {
  margin-top: -15px;
  font-size: 12pt;
  color: var(--color-text-2, #A6A6A6); 
  font-weight: 600;
}

svg {
  color: #aede00;
  width: 24px;
  height: 24px;
}
.quad {
  gap: 10px;
  width: 50px;
  height: 50px;
  padding: 5px 14px;
  background: #010d1f;
  border-radius: 12px;
  color:#aede00;
}
.sistema {
  color: #015acf;
}
.sifit {
  color: #aede00;
}
.h1_title {
  color: #fff;
  font-size: 30pt;
}

.btn_busca {
  margin-top: 32px;
  background-color: #aede00 !important;
  border-color: #aede00 solid 1px !important;
  color: rgb(0, 0, 0) !important;
  border-radius: 15px !important;
  border: solid 1px #aede00;
  padding: 7px;
  width: 90px;
}

.btn_ver_mais {
  background-color: rgb(3, 74, 166);
  border: 1px solid rgb(3, 74, 166);
  border-radius: 15px !important;
  padding: 7px;
  width: 90px;
  color:#fff;
  font-size: 9pt;
}

.div_grafico{
  margin-top:20px;
}

.topo{
        background-color: #fff;
        padding-top: 25px;
        padding-bottom: 10px;
        border-bottom: 1px solid var(--color-stroke, #E7E7E7); 
    }
    .bv{
        font-weight: bold;
        font-size: 18pt;
        color:#162B48;
        margin-bottom: 0;
    }
    .data{
        color: var(--color-text-2, #A6A6A6); 
        font-size:12px;
    }
    label{
        color: var(--color-text-2, #A6A6A6); 
        font-size:14px;
    }
    .div_datas{
        margin-top:0px;
    }

    input{
        border: 1px solid var(--color-stroke, #E7E7E7);
        width: 100%;
        border-radius: 4px;
        padding: 5px;
    }
    button{
        border:none;
        color:#fff;
        margin-top: 28px;
        padding: 7px 16px;
        border-radius: 4px;
        background: var(--color-primary, #034AA6); 
    }
    .div_pessoa{
        display: flex;
        text-align: right;
        justify-content:flex-end;
    }
    .div_imagem{
        margin-top:10px;
        margin-right: 12px;
    }
    .div_imagem img{
        border-radius: 50%;
        width: 40px; 
        
    }
    .nome_usuario{
        color: var(--color-azul-2, #162B48); 
        font-size:12pt;
        font-weight: 600;
        margin-top: 20px;
    }
</style>
