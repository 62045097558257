export default httpClient => ({
  selecionaPorData: async ({ token, empresa_id, data_inicial, data_final }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/contas-pagar/por-data/'+empresa_id, {
      token: token,
      empresa_id,
      data_inicial,
      data_final
    }, { headers })
    console.log(response)
    return { data: response.data }

  },
  TotalPagar: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/total-clientes/' + empresa_id, { headers })
    return { data: response.data.length }
  },

  contaAtrasada: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/contas-pagar/qtd-vencidas/' + empresa_id, { headers })
    return { data: response.data.length }
  },
  valorRestante: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/total-clientes/' + empresa_id, { headers })
    return { data: response.data.length }
  },
  valorAtrasado: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/total-clientes/' + empresa_id, { headers })
    return { data: response.data.length }
  },

  obterPorId: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/seleciona-conta-pagar/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },
  contasPorNomeCliente: async ({ token, empresa_id, nome_cliente }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/contas-pagar-pornome/' + empresa_id, {
      token,
      nome: nome_cliente
    }, { headers })
    return { data: response.data }
  },

  contasFornecedorLocalCaixa: async ({ token, empresa_id, status,local_conta_id,caixa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/contas-pagar/fornecedor/status-local-caixa/' + empresa_id, {
      token,
      status,
      local_conta_id,
      caixa_id
    }, { headers })
    return { data: response.data }
  },

  contasPagarColaboradorLocalCaixa: async ({ token, empresa_id, status,local_conta_id,caixa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/contas-pagar/colaborador/status-local-caixa/' + empresa_id, {
      token,
      status,
      local_conta_id,
      caixa_id
    }, { headers })
    return { data: response.data }
  },

  save: async ({ empresa_id, token, funcionario_cadastrante_id, data_cadastro, tipo_conta, tipo_despesa, id, nome, tipo_documento_id, n_documento,
    data_emissao, id_categoria, status, fornecedor_id, fornecedor_nome, colaborador_recebedor_id, colaborador_nome, tipo_pagameto_id, funcionario_pagador_id,
    local_conta_id, descricao, valor_pagar, data_vencimento, data_pagamento, valor_pago, despesa_fixa_id, tempo_recorrencia, }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/cadastrar-conta-pagar/' + empresa_id, {
      empresa_id,
      token,
      funcionario_cadastrante_id,
      data_cadastro,
      tipo_conta,
      tipo_despesa,
      id,
      nome,
      tipo_documento_id,
      n_documento,
      data_emissao,
      id_categoria,
      status,
      fornecedor_id,
      fornecedor_nome,
      colaborador_recebedor_id,
      colaborador_nome,
      tipo_pagameto_id,
      funcionario_pagador_id,
      local_conta_id,
      descricao,
      valor_pagar,
      data_vencimento,
      data_pagamento,
      valor_pago,
      despesa_fixa_id,
      tempo_recorrencia,
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  update: async ({ token, id, nome, cpf, data_nascimento, sexo, endereco, numero, bairro, complemento, cep, cidade_id, estado_id, telefone, celular,
    tel_emergencia, email, status, data_cadastro, rg, tipo_sangue, id_leitor, sempre_aberto, observacao, func_cadastrante_id, tipo_pagameto,
    funcionario_pagador, local_pagamento, funcionario_cadastrante_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/cadastrar-cliente/1', {
      id,
      nome,
      cpf,
      data_nascimento,
      sexo,
      endereco,
      numero,
      bairro,
      complemento,
      cep,
      cidade_id,
      estado_id,
      telefone,
      celular,
      tel_emergencia,
      email,
      status,
      data_cadastro,
      rg,
      tipo_sangue,
      id_leitor,
      sempre_aberto,
      observacao,
      func_cadastrante_id,
      tipo_pagameto,
      funcionario_pagador,
      local_pagamento,
      funcionario_cadastrante_id
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }

    return {
      data: response.data,
      errors
    }
  },
  deletarConta: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/deletar-conta-pagar/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },
})