<template>
  <topo :titulo="'Cadastrar modelo de Ficha'"></topo>
  <div class="container-fluid mt-3">
    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <stats-card>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link to="/fichas-treino-modelo" class="primary"
                  >Fichas</router-link
                >
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Adicionar
              </li>
            </ol>
          </nav>
          <form @submit.prevent="handleSubmit">
            <div class="row">
              <div class="col-sm-9">
                <label>Funcionario Cadastrante</label>
                <div class="row">
                  <div class="col-md-2">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        readonly
                        v-model="state.id_cadastrante.value"
                      />
                    </div>
                  </div>
                  <div class="col-md-10">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        readonly
                        v-model="state.nome_cadastrante.value"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-lg-8">
                    <div class="form-group">
                      <label>Nome da Ficha</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="state.nome_modelo"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <h3>Personal</h3>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Personal</label>
                  <select v-model="state.personal" class="form-select">
                    <option
                      v-for="avaliador in state.avaliadores"
                      :value="avaliador.id"
                      :key="avaliador.id"
                    >
                      {{ avaliador.nome }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <button
              class="btn btn_salvar my-4"
              id="submit-button"
              :disabled="state.isLoading"
              type="submit"
            >
              Continuar
            </button>
            <button
              class="btn btn_cancelar my-4"
              id="submit-button"
              type="button"
              @click="cancel()"
            >
              Cancelar
            </button>
          </form>
        </stats-card>
      </div>
    </div>
  </div>

  <Modal
    :width="'800px'"
    :open="state.isOpen"
    @close="state.isOpen = !state.isOpen"
  >
    <div style="padding: 20px">
      <div class="row">
        <div class="col-sm-3">
          <label for="tipo_pesquisa">Tipo de Pesquisa</label>
          <select id="tipo_pesquisa" class="form-select">
            <option>Nome</option>
            <option>Código</option>
          </select>
        </div>
        <div class="col-sm-8">
          <label>Pesquisa</label>
          <input type="text" class="form-control" v-model="state.nome" />
        </div>
        <div class="col-sm-1" style="padding-left: 0">
          <button
            class="btn btn_lupa"
            type="button"
            style="margin-top: 32px"
            @click="fetchClientes"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path
                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
              />
            </svg>
          </button>
        </div>
      </div>

      <div class="row div_s" style="margin-top: 15px">
        <div class="col">
          <div class="table-responsive">
            <table
              class="table tablesorter table align-items-center table-flush"
            >
              <thead class="thead-light">
                <th>Código</th>
                <th>Nome</th>
                <th></th>
              </thead>
              <tbody class="list">
                <corpo-busca-cliente
                  @buscarCliente="buscarCliente"
                  v-for="cliente in state.clientes"
                  :key="cliente.id"
                  :cliente="cliente"
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div>
        <button type="button" class="btn btn_fechar" @click="fecharModal">
          Fechar
        </button>
      </div>
    </div>
  </Modal>
</template>
<script>
import { onMounted, reactive } from "vue";
import services from "../services";
import CorpoBuscaCliente from "../components/CorpoBuscaClientes";
import { useRouter } from "vue-router";
import Modal from "../components/EstruturaModal/EstruturaModal.vue";
import { useStorage } from "vue3-storage";
import { pt } from "date-fns/locale";
import Datepicker from "vue3-datepicker";
import topo from "../components/topo";
export default {
  components: { Modal, topo },
  setup(_, { emit }) {
    // var isOpen = ref(false);
    const storage = useStorage();
    var data_atual = new Date();
    var dd = String(data_atual.getDate()).padStart(2, "0");
    var mm = String(data_atual.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = data_atual.getFullYear();

    data_atual = dd + "/" + mm + "/" + yyyy;

    var nome_usuario = storage.getStorageSync("nome_pessoa");
    var id_func_usuario = storage.getStorageSync("colaborador_id");
    var empresa_id = storage.getStorageSync("empresa_id");
    var token = storage.getStorageSync("token");

    const router = useRouter();

    function cancel() {
      router.push({ name: "fichasTreinoModelo" });
    }

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      isOpen: false,
      data_inicial: new Date(),
      clientes: [],
      avaliadores: [],
      nome_modelo: "",
      id_cadastrante: {
        value: id_func_usuario,
      },
      nome_cadastrante: {
        value: nome_usuario,
      },
      categoria: {
        value: "",
      },
    });

    onMounted(() => {
      fetchCategoria();
      fetchAvaliadores();
    });

    function handleBuscarCliente() {
      modal.open({
        component: "ModalBuscarAluno",
      });
    }

    function buscarCliente(id_cliente, nome_cliente) {
      state.id_cliente = id_cliente;
      state.nome_cliente = nome_cliente;
      state.isOpen = false;
    }
    function fecharModal() {
      state.isOpen = false;
      state.nome = "";
      state.clientes = [];
    }

    async function fetchClientes() {
      try {
        state.isLoading = true;
        const { data } = await services.clientes.clientesPorNome({
          empresa_id: empresa_id,
          token: token,
          nome: state.nome,
        });
        console.log(data);
        //  console.log(data)
        state.clientes = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function fetchCategoria() {
      try {
        state.isLoading = true;
        const { data } = await services.colaboradores.getAvaliadores({
          empresa_id: empresa_id,
          token: token,
        });
        console.log(data);
        //  console.log(data)
        state.categorias = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function fetchAvaliadores() {
      try {
        state.isLoading = true;
        const { data } = await services.colaboradores.getAvaliadores({
          empresa_id: empresa_id,
          token: token,
        });
        console.log(data);
        //  console.log(data)
        state.avaliadores = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }

    async function handleSubmit() {
      try {
        state.isLoading = true;
        await services.ficha
          .save({
            empresa_id: empresa_id,
            token: token,
            prescritor_id: state.personal,
            nome_modelo: state.nome_modelo,
            observacao: state.observacao,
            nome_ficha: state.nome_ficha,
            tipo_ficha: state.tipo_ficha,
          })
          .then((res) => {
            if (!res.data.verifica_erro) {
              router.push({
                name: "continuarFichaTreino",
                params: { id: res.data.id },
              });
            } else {
              alert("Erro ao salvar Ficha.");
            }
            console.log();
            //
          })
          .catch(() => {
            alert("Erro ao salvar Ficha.");
          });

        /* if (!errors) {
          //       window.localStorage.setItem('token', data.token)
        //  router.push({ name: 'products' })
          router.push({ name: 'continuarFichaTreino', params: { id: 'eduardo' } })
          state.isLoading = false;
          return;
        }*/

        state.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    }
    return {
      state,
      handleBuscarCliente,
      handleSubmit,
      fetchClientes,
      CorpoBuscaCliente,
      emit,
      buscarCliente,
      cancel,
      fecharModal,
      Datepicker,
      pt,
    };
  },
};
</script>

<style scoped>
textarea {
  resize: none;
}
input {
  border-color: #b8b8b8;
  border-width: 2px;
  color: #505050;
  border-radius: 15px;
  transition: 0.5s;
}
input:focus {
  border-color: #252525;
}
select {
  border-color: #b8b8b8;
  border-width: 2px;
  color: #505050;
  border-radius: 15px;
  transition: 0.5s;
}
select:focus {
  border-color: #252525;
}
.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}
.btn_salvar:hover {
  transform: none;
  color: #fff;
}
.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}
.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}
.div_s {
  height: 350px;
  overflow-y: scroll;
}
.btn_fechar {
  color: #fff;
  background-color: #788596;
}
.btn_fechar:hover {
  color: #fff;
  background-color: #788596;
  transform: none;
}
.btn_lupa {
  background-color: #aede00;
  padding: 10px;
  border-color: #aede00 solid 1px !important;
  color: rgb(0, 0, 0) !important;
  border-radius: 10px !important;
}
.btn_lupa:hover {
  transform: none;
}
</style>