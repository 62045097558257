const defaultPagination = {
  limit: 5,
  offset: 0
}
export default httpClient => ({
  getAll: async ({ type, quantidade, quantidade_anterior, token, empresa_id } = defaultPagination) => {
    const headers = {
      "Authorization": token
    }
    const query = { quantidade, quantidade_anterior, token, empresa_id }
    if (type) {
      query.type = type
    }

    //  [Get("/avaliacoes/{empresa_id}")]
    const response = await httpClient.post('/listar-ultimas-avaliacoes/' + empresa_id, {
      quantidade: quantidade,
      quantidade_anterior: quantidade_anterior,
      token: token
    }, { headers });
    return { data: response.data }
  },

  buscar: async ({ token, empresa_id, nome_cliente } = defaultPagination) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/avaliacao/seleciona-por-nome/' + empresa_id, {
      nome_cliente,
      token: token
    }, { headers });
    return { data: response.data }
  },


  obterPorId: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/avaliacao/seleciona-por-id/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },

  obterPorHash: async ({ hash }) => {
    const response = await httpClient.get('/avaliacao/seleciona-por-link/' + hash)
    return { data: response.data }
  },

  calcularSubcutaneas: async ({ token, empresa_id, torax_subc, axila_subc, triceptal_subc, subescapular_subc, abdominal_subc, suprailiaca_subc, coxa_subc, sexo, idade, peso }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/avaliacao/calcular-subcutaneas/' + empresa_id, {
      token,
      torax_subc,
      axila_subc,
      triceptal_subc,
      subescapular_subc,
      abdominal_subc,
      suprailiaca_subc,
      coxa_subc,
      sexo,
      idade,
      peso
    }, { headers })
    return { data: response.data }
  },


  avaliacoesPorClienteId: async ({ token, empresa_id, cliente_id, data_avaliacao, limit, offset }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/avaliacao/seleciona-por-cliente/' + empresa_id, {
      token,
      cliente_id,
      data_avaliacao,
      limit,
      offset
    }, { headers })
    return { data: response.data }
  },
  compararAvaliacao: async ({ token, empresa_id, cliente_id, id_avaliacao_antiga, id_avaliacao_nova }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/avaliacao/comparar/' + empresa_id, {
      token,
      cliente_id,
      id_avaliacao_antiga,
      id_avaliacao_nova
    }, { headers })
    return { data: response.data }
  },

  telaWeb: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/avaliacao/tela-web/' + empresa_id,{ headers })
    return { data: response.data }
  },

  
  qtdAvaliacoesTotal: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/qtd-avaliacoes/' + empresa_id, {
      token: token
    }, { headers })
    return { data: response.data }
  },


  save: async ({ empresa_id, token, cliente_id, func_cadastrante_id, data_avaliacao, hora_avaliacao, personal_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/avaliacao/adicionar-agendamento/' + empresa_id, {
      token,
      cliente_id,
      func_cadastrante_id,
      data_avaliacao,
      hora_avaliacao,
      personal_id
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  salvarFeita: async ({ empresa_id, token, id, altura, peso, idade, imc, resultado_imc, ombro, busto, cintura, quadril, coxa_dir, coxa_esq, braco_dir, braco_esq,
    antebraco_dir, antebraco_esq, panturrilha_dir, panturrilha_esq, abdomem, biceps_subc, rel_cintura_quadril, resultado_relacao_cq, torax_subc, axila_subc, triceptal_subc,
    subescapular_subc, abdominal_subc, suprailiaca_subc, coxa_subc, panturrilha_subc, sexo
  }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/avaliacao/salvarFeita/' + empresa_id, {
      empresa_id,
      token,
      id,
      altura,
      peso,
      idade,
      imc,
      resultado_imc,
      ombro,
      busto,
      cintura,
      quadril,
      coxa_dir,
      coxa_esq,
      braco_dir,
      braco_esq,
      antebraco_dir,
      antebraco_esq,
      panturrilha_dir,
      panturrilha_esq,
      abdomem,
      biceps_subc,
      relacao_cintura_quadril: rel_cintura_quadril,
      resultado_relacao_cq,
      torax_subc,
      axila_subc,
      triceptal_subc,
      subescapular_subc,
      abdominal_subc,
      suprailiaca_subc,
      coxa_subc,
      panturrilha_subc,
      sexo,
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  deletarAvaliacao: async ({ token, empresa_id, cliente_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/avaliacao/deletar/' + empresa_id, {
      token,
      cliente_id,
      id
    }, { headers })
    return { data: response.data }
  },


  //alterarModal: async ({ token, empresa_id, id, altura, peso, ombro, busto, cintura, quadril, coxa_dir, coxa_esq,  braco_dir, 
  // braco_esq,  antebraco_dir,  antebraco_esq, panturrilha_dir,  panturrilha_esq,  abdomem,  biceps_subc,  rel_cintura_quadril,  resultado_relacao_cq, torax_subc,
  // axila_subc,  triceptal_subc,  subescapular_subc,  abdominal_subc,  suprailiaca_subc,  coxa_subc,  panturrilha_subc, }) => {
  //  const headers = {
  //    "Authorization": token
  //  }
  //  const response = await httpClient.post('//' + empresa_id, {
  //token,
  // id,
  //  altura,
  // peso,
  //ombro,
  //busto,
  //cintura,
  //quadril,
  //coxa_dir,
  //coxa_esq,
  //braco_dir,
  //braco_esq,
  //antebraco_dir,
  //antebraco_esq,
  //panturrilha_dir,
  //panturrilha_esq,
  //abdomem,
  //biceps_subc,
  //rel_cintura_quadril,
  //resultado_relacao_cq,
  //torax_subc,
  //axila_subc,
  //triceptal_subc,
  //subescapular_subc,
  //abdominal_subc,
  //suprailiaca_subc,
  //coxa_subc,
  //panturrilha_subc,
  //  }, { headers })
  // return { data: response.data }
  //},


  // remarcar: async ({ token, empresa_id }) => {
  //   const headers = {
  //   "Authorization": token
  //  }
  //  const response = await httpClient.post('//' + empresa_id, {
  //     token,
  //     cliente_id,
  //  }, { headers })
  //   return { data: response.data }
  // },
})