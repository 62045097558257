<template>
  <barra-lateral />
  <div style="background-color: #f8f9fe; min-height: 100vh">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <topo
            :titulo="'Fichas de Treino'"
            @openOffcanvas="openOffcanvas"
          ></topo>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label>Nome do Aluno</label>
                <input type="text" class="form-control" />
              </div>
            </div>
            <div class="col-sm-3">
              <div class="col-sm-2 col-md-3 col-lg-2 d-none d-md-block">
                <button class="btn_buscar_nome" @click="buscarPorNome()">
                  Buscar
                </button>
              </div>
            </div>

            <div class="col-sm-3 text-right">
              <router-link to="/adicionar-ficha-treino">
                <button type="button" class="btn_add_cliente">
                  Adicionar Nova Ficha
                </button>
              </router-link>
            </div>
          </div>
          <div class="row d-none">
            <div class="col-xl-3 col-lg-6">
              <card class="card-stats">
                <div class="card-flex">
                  <div>
                    <img
                      class="icon"
                      src="/img/icons/clientes_ativos.png"
                      alt="Finanças"
                    />
                  </div>
                  <div class="card_dir">
                    <p class="avaliacao">{{ state.qtdClientesAtivos }}</p>
                    <div class="num" v-if="state.idioma == 'PT'">
                      Quantidade de Fichas Ativas
                    </div>
                    <div class="num" v-else>Clientes Activos</div>
                  </div>
                </div>
              </card>
            </div>
          </div>

          <div
            class="card shadow p-3 mt-2"
            :class="type === 'dark' ? 'bg-default' : ''"
            s
          >
            <div class="row align-items-center">
              <div class="col">
                <h3
                  class="mb-0"
                  :class="type === 'dark' ? 'text-white' : ''"
                ></h3>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="table-responsive">
                  <table
                    class="table tablesorter table align-items-center table-flush"
                  >
                    <thead class="thead-light">
                      <th class="th_display">Código</th>
                      <th>Aluno</th>
                      <th>Data Inicial</th>
                      <th class="th_display">Data Final</th>
                      <th class="th_display">Status</th>
                      <th></th>
                    </thead>
                    <tbody class="list">
                      <corpo-tabela-ficha-treino
                        v-for="ficha in state.fichas"
                        :key="ficha.id"
                        :ficha="ficha"
                        @deletarFicha="deletarFicha"
                      />
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <OffcanvasComponent ref="offcanvasRef" />
</template>
<script>
import { onMounted, reactive, onErrorCaptured, ref } from "vue";
import CorpoTabelaFichaTreino from "../components/CorpoTabelaFichaTreino";
import topo from "../components/topo";
import services from "../services";
import { useStorage } from "vue3-storage";
import BarraLateral from "../layout/Sistema/barraLateral";
import OffcanvasComponent from "../layout/Sistema/barraLateralMobile";

export default {
  name: "tables",
  components: {
    topo,
    BarraLateral,
    CorpoTabelaFichaTreino,
    OffcanvasComponent,
  },
  setup() {
    const offcanvasRef = ref(null);
    const isVisible = ref(false);
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");
    const state = reactive({
      isLoading: false,
      fichas: [],
      currentClientType: "",
      hasError: false,
    });
    onErrorCaptured(handleErrors);

    onMounted(() => {
      fetchfichas();
    });
    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }

    async function fetchfichas() {
      try {
        state.isLoading = true;
        const { data } = await services.ficha.getAll({
          token: token,
          empresa_id: empresa_id,
        });
        console.log(data);
        //  console.log(data)
        state.fichas = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function deletarFicha(id) {
      if (confirm("Deseja deletar esta ficha?")) {
        try {
          state.isLoading = true;
          const { data } = await services.ficha.deletarFicha({
            token: token,
            empresa_id: empresa_id,
            id,
          });
          if (data) {
            fetchfichas();
          } else {
            alert("Erro ao deletar ficha.");
          }
        } catch (error) {
          handleErrors(error);
        }
      }
    }
    const openOffcanvas = () => {
      // Estado para controlar a visibilidade do offcanvas
      console.log(isVisible.value);
      //offcanvasRef.value.showOffcanvas();
      if (isVisible.value) {
        offcanvasRef.value.hideOffcanvas();
      } else {
        offcanvasRef.value.showOffcanvas();
      }
    };
    return {
      state,
      deletarFicha,
      openOffcanvas,
      offcanvasRef,
      openOffcanvas,
    };
  },
};
</script>
<style scoped>
@media (min-width: 1000px) and (max-width: 1400px) {
  .espaco_md {
    padding-left: 8rem;
  }
}

@media (min-width: 768px) and (max-width: 999px) {
  .espaco_md {
    padding-left: 8rem;
  }
}

.quad i {
  color: #aede00;
  font-size: 20pt;
  font-weight: bold;
}

.quad {
  padding: 5px 12px;
  width: 50px;
  height: 50px;
  background: #010d1f;
  border-radius: 12px;
}

.title2 {
  color: #000;
  margin-top: 10px;
}

.title1 {
  color: #000;
  margin-top: 10px;
  font-size: 11pt;
  line-height: 1;
}

.arow {
  margin-right: 5px;
  font-size: 0.7rem !important;
  color: #034aa6;
  font-weight: 700;
}

.text1 {
  color: #034aa6;
  font-size: 7pt !important;
  font-weight: bold;
}

.num {
  margin-top: -15px;
}

.card .table th {
  padding: 15px !important;
}

.btn_busca {
  background-color: #aede00 !important;
  border-color: #aede00 solid 1px !important;
  color: rgb(0, 0, 0) !important;
  border-radius: 15px !important;
  border-color: #aede00 !important;
}

.btn_busca:hover {
  transform: none;
}

.div_btn_buscar {
  margin-left: -20px;
}

.i_quad {
  background: #034aa6;
  padding: 10px;
  border-radius: 100px;
  font-size: 16pt;
  color: #fff;
}

.btn_add_cliente {
  transform: none;
  background: var(--Color-secundary, #aede00);
  color: rgb(255, 255, 255);
  border: 1px solid #aede00;
  border-radius: 5px;
  font-size: 9pt;
  padding: 5px;
  font-weight: 700;
  width: 100%;
  max-width: 200px;
  margin-top: 28px;
  height: 36px;
}

.btn_buscar_nome {
  margin-top: 26px;
  font-size: 10pt;
  height: 36px;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 5px;
  background-color: rgb(3, 74, 166);
  border: none;
  color: #fff;
  font-weight: 700;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .th_display {
    display: none;
  }
}

@media (max-width: 576px) {
  .th_display {
    display: none;
  }
}
</style>
