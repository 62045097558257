export default httpClient => ({
    porHash: async (hash) => {
      const response = await httpClient.post('/aula-/'+hash)
      return { data: response.data }
    },

    horariosPorGrupo: async (grupo_id,empresa_id) => {
      const response = await httpClient.post('/horarios-aulas-experimental/por-grupo/' + empresa_id, {
          grupo_id
      });
      return { data: response.data }
    }
})