<template>
  <barra-lateral />
  <div style="background-color: #f8f9fe">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <topo :titulo="'Cadastrar Conta a Receber'"></topo>
          <div class="container-fluid mt-3">
            <div>
              <div class="row">
                <div class="col-lg-5">
                  <label>Cadastramento Realizado Por:</label>
                  <div class="row">
                    <div class="col-3 col-sm-3">
                      <input type="text" class="form-control" readonly v-model="state.cadastrante_id" />
                    </div>
                    <div class="col-9 col-sm-9">
                      <input type="text" class="form-control" readonly v-model="state.nome_cadastrante" />
                    </div>
                  </div>
                </div>

                <div class="col-6 col-sm-4 col-lg-2">
                  <div class="form-group">
                    <label>Data</label>
                    <input type="text" class="form-control" v-model="state.data" />
                  </div>
                </div>

                <div class="col-6 col-sm-4 col-lg-3">
                  <div class="form-group">
                    <label>Status</label>
                    <select class="form-select" v-model="state.status" @change="alterarStatus($event.target.value)">
                      <option value="pendente">Pendente</option>
                      <option value="cancelado">Cancelado</option>
                      <option value="recebido">Recebido</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4 col-lg-2">
                  <div class="form-group">
                    <label>Resíduo</label>
                    <select class="form-select" v-model="state.residuo">
                      <option value="sim">Sim</option>
                      <option value="nao">Não</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 d-md-none">
                  <h3>Cliente</h3>
                </div>
              </div>

              <div class="row">
                <div class="col-3 col-sm-2 col-lg-2">
                  <div class="form-group">
                    <label>Código</label>
                    <input type="text" class="form-control" readonly v-model="state.cliente_id.value" />
                  </div>
                </div>

                <div class="col-9 col-sm-8 col-lg-8">
                  <div class="form-group">
                    <label>Nome</label>
                    <input type="text" class="form-control" v-model="state.nome_cliente" readonly />
                  </div>
                </div>

                <div class="col-12 col-sm-2 col-lg-2 mt-1 mb-1 mt-sm-4">
                  <button class="btn btn_lupa" type="submit" @click="modalBuscarPessoa()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-search" viewBox="0 0 16 16">
                      <path
                        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                  </button>
                </div>
              </div>

              <div class="row">
                <div class="col-3 col-sm-3">
                  <div class="form-group">
                    <label>Código</label>
                    <input type="text" class="form-control" readonly v-model="state.id" />
                  </div>
                </div>
                <div class="col-9 col-sm-9 col-lg-8">
                  <div class="form-group">
                    <label>Título</label>
                    <input type="text" class="form-control" v-model="state.titulo.value" />
                  </div>
                </div>
                <div class="col-6 col-sm-3 col-md-4 col-lg-3">
                  <label>Criar Cópias</label>
                  <div class="form-group">
                    <select class="form-select" v-model="state.criar_copias">
                      <option value="sim">Sim</option>
                      <option value="nao">Não</option>
                    </select>
                  </div>
                </div>

                <div class="col-6 col-sm-5 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label>Valor a Receber</label>
                    <input type="text" class="form-control" v-model="state.valor_receber.value" v-mask-decimal.br="2"
                      @blur="valoresRecebidos" />
                  </div>
                </div>
                <div class="col-6 col-sm-4 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label>Data Venc.</label>
                    <Datepicker v-model="state.data_vencimento" :locale="pt" inputFormat="dd/MM/yyyy" style="
                        border-color: #b8b8b8;
                        color: #505050;
                        border-radius: 5px;
                        border: #b8b8b8 solid 1px;
                        transition: 0.5s;
                        width: 100%;
                        padding: 7px;
                      " />
                  </div>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-3" v-if="state.status == 'recebido'">
                  <div class="form-group">
                    <label>Valor Recebido</label>
                    <input type="text" class="form-control" v-model="state.valor_recebido" v-mask-decimal.br="2" />
                  </div>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-3" v-if="state.status == 'recebido'">
                  <div class="form-group">
                    <label>Data Recebimento</label>
                    <Datepicker v-model="state.data_recebimento" :locale="pt" inputFormat="dd/MM/yyyy" style="
                        border-color: #b8b8b8;
                        color: #505050;
                        border-radius: 5px;
                        border: #b8b8b8 solid 2px;
                        transition: 0.5s;
                        width: 100%;
                        padding: 7px;
                      " />
                  </div>
                </div>
                <div class="col-md-4" v-if="state.status == 'recebido'">
                  <div class="form-group">
                    <label>Tipo de Pagamento</label>
                    <select class="form-select" v-model="state.tipo_pagamento"
                      @change="carregarTipoPagamento($event.target.value)">
                      <select-tipo-pagamento v-for="tipo_pagamento in state.tipos_pagamento" :key="tipo_pagamento.id"
                        :tipo_pagamento="tipo_pagamento" />
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12" v-if="state.status == 'recebido'">
                  <label>Funcionário Recebedor</label>
                  <div class="row">
                    <div class="col-3 col-sm-3">
                      <div class="form-group">
                        <input type="text" class="form-control" readonly v-model="state.recebedor_id" />
                      </div>
                    </div>
                    <div class="col-9 col-sm-9">
                      <div class="form-group">
                        <input type="text" class="form-control" readonly v-model="state.nome_recebedor" />
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Local Recebimento</label>
                    <select class="form-select" v-model="state.local_recebimento">
                      <option v-for="local_recebimento in state.locais_recebimento" :key="local_recebimento.id"
                        :value="local_recebimento.id">
                        {{ local_recebimento.nome }}
                      </option>
                    </select>
                  </div>
                  <div v-if="state.escolher_bandeira == 'sim'">
                    <div class="row">
                      <div class="col-sm-9">
                        <div class="form-group">
                          <label>Bandeira</label>
                          <input type="text" class="form-control" readonly v-model="state.nome_bandeira" />
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label>Parcelas</label>
                          <select class="form-select" v-model="state.parcelas">
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                            <option>9</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label>Perc. Juros</label>
                          <input type="text" class="form-control" readonly v-model="state.perc_juros" />
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label>Taxa Adicional</label>
                          <input type="text" class="form-control" readonly v-model="state.taxa_adicional" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="form-group">
                    <label>Observação</label>
                    <textarea class="area_obs" v-model="state.descricao"></textarea>
                  </div>
                </div>
              </div>

              <button class="btn btn_salvar my-4" id="submit-button" type="button" @click="handleSubmit()">
                Salvar
              </button>
              <button class="btn btn_cancelar my-4" id="submit-button" type="button" @click="cancel()">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Modal :width="'800px'" :open="state.modal_pesquisar_pessoa"
    @close="state.modal_pesquisar_pessoa = !state.modal_pesquisar_pessoa">
    <div style="padding: 20px">
      <div class="row">
        <div class="col-sm-3">
          <label for="tipo_pesquisa">Tipo de Pesquisa</label>
          <select id="tipo_pesquisa" class="form-select">
            <option>Nome</option>
            <option>Código</option>
          </select>
        </div>
        <div class="col-sm-8">
          <label>Pesquisa</label>
          <input type="text" class="form-control" v-model="state.nome" />
        </div>
        <div class="col-sm-1" style="padding-left: 0">
          <button class="btn btn_lupa" type="button" style="margin-top: 32px" @click="fetchClientes">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"
              viewBox="0 0 16 16">
              <path
                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
          </button>
        </div>
      </div>

      <div class="row div_s" style="margin-top: 15px">
        <div class="col">
          <div class="table-responsive">
            <loading-pesquisa v-if="state.pesquisaLoading" />
            <table class="table tablesorter table align-items-center table-flush" v-else>
              <thead class="thead-light">
                <th>Código</th>
                <th>Nome</th>
                <th></th>
              </thead>
              <tbody class="list">
                <corpo-busca-cliente @buscarCliente="buscarCliente" v-for="cliente in state.clientes" :key="cliente.id"
                  :cliente="cliente" />
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div>
        <button type="button" class="btn btn_fechar" @click="fecharModal('pesquisa_pessoa')">
          Fechar
        </button>
      </div>
    </div>
  </Modal>

  <Modal :open="state.modal_escolher_cartao" @close="state.modal_escolher_cartao = !state.modal_escolher_cartao"
    :width="'400px'">
    <div style="padding: 20px">
      <h4>Escolher Bandeiras</h4>
      <div class="row">
        <div class="col-sm-12">
          <label>Bandeira</label>
          <select class="form-select" v-model="state.bandeira" id="bandeira">
            <select-bandeiras v-for="bandeira in state.bandeiras" :key="bandeira.id" :bandeira="bandeira" />
          </select>
        </div>
      </div>

      <div class="row botoes_modal">
        <div class="col-sm-6">
          <button type="button" class="btn btn_fechar_modal largura_total" @click="fecharModal('cartao')">
            Fechar
          </button>
        </div>
        <div class="col-sm-6">
          <button type="button" class="btn btn_escolher largura_total" @click="escolherCartao()">
            Escolher
          </button>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import { validateEmpty } from "../utils/validators";
import services from "../services";
import { useField } from "vee-validate";
import { reactive, onMounted } from "vue";
import SelectTipoPagamento from "../components/TipoPagamentoSelect.vue";
import SelectBandeiras from "../components/BandeirasSelect";
import Modal from "../components/EstruturaModal/EstruturaModal.vue";
import CorpoBuscaCliente from "../components/CorpoBuscaClientes";
import { useRouter } from "vue-router";
import { useStorage } from "vue3-storage";
import { pt } from "date-fns/locale";
import Datepicker from "vue3-datepicker";
import topo from "../components/topo";
import LoadingPesquisa from "../components/CorpoTabelaAvaliacao/Loading";
import BarraLateral from "../layout/Sistema/barraLateral";
export default {
  components: {
    topo,
    LoadingPesquisa,
    Modal,
    Datepicker,
    BarraLateral,
  },
  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");
    var nome_pessoa = storage.getStorageSync("nome_pessoa");
    var colaborador_id = storage.getStorageSync("colaborador_id");
    const router = useRouter();

    onMounted(() => {
      fetchTiposPagamento();
      fetchCaixaAberto();
      fetchLocalRecebimento();
      state.nome_cadastrante = nome_pessoa;
      state.cadastrante_id = colaborador_id;

      var data = new Date();
      var dia = String(data.getDate()).padStart(2, "0");
      var mes = String(data.getMonth() + 1).padStart(2, "0");
      var ano = data.getFullYear();
      var dataAtual = dia + "/" + mes + "/" + ano;
      state.data = dataAtual;
    });

    async function fetchCaixaAberto() {
      try {
        state.isLoading = true;
        const { data } = await services.caixa.obterAberto({
          token: token,
          empresa_id: empresa_id,
        });
        if (data != 0) {
          state.caixa_id = data;
        }
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function fetchLocalRecebimento() {
      try {
        state.isLoading = true;
        const { data } = await services.localRecebimento.listar({
          token: token,
          empresa_id: empresa_id,
        });
        state.locais_recebimento = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    function cancel() {
      router.push({ name: "contasReceber" });
    }
    async function fetchTiposPagamento() {
      try {
        state.isLoading = true;
        const { data } = await services.tipoPagamento.listar({
          token: token,
          empresa_id: empresa_id,
        });
        state.tipos_pagamento = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function fetchBandeiras() {
      try {
        state.isLoading = true;
        const { data } = await services.bandeira.listar({
          token: token,
          empresa_id: empresa_id,
        });
        state.bandeiras = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function carregarTipoPagamento(tipo_pagamento_id) {
      try {
        state.isLoading = true;
        const { data } = await services.tipoPagamento.obterPorId({
          token: token,
          empresa_id: empresa_id,
          id: tipo_pagamento_id,
        });

        if (data.escolher_bandeira == "sim") {
          fetchBandeiras();
          state.modal_escolher_cartao = true;
          state.escolher_bandeira = "sim";
        } else {
          state.escolher_bandeira = "nao";
        }
      } catch (error) {
        handleErrors(error);
      }
    }
    async function deletarContaReceber(id) {
      if (confirm("Deseja deletar esta conta?")) {
        try {
          state.isLoading = true;
          const { data } = await services.clientes.deletarContaReceber({
            token: token,
            empresa_id: empresa_id,
            id,
          });
          if (data) {
            fetchClientes();
          } else {
            alert("Erro ao deletar conta.");
          }
        } catch (error) {
          handleErrors(error);
        }
      }
    }

    async function escolherCartao() {
      state.bandeira = document.querySelector("#bandeira").value;
      state.nome_bandeira =
        document.querySelector("#bandeira").selectedOptions[0].text;
      state.modal_escolher_cartao = false;

      const { data } = await services.bandeira.obterPorId({
        empresa_id: empresa_id,
        token: token,
        id: state.bandeira,
      });

      state.taxa_adicional = data.taxa_adicional;
      console.log(data);
    }

    async function fetchClientes() {
      try {
        state.pesquisaLoading = true;
        const { data } = await services.clientes.clientesPorNome({
          empresa_id: empresa_id,
          token: token,
          nome: state.nome,
        });
        console.log(data);
        //  console.log(data)
        state.clientes = data;
        //   state.pagination = data.pagination
        state.pesquisaLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    function modalBuscarPessoa() {
      state.modal_pesquisar_pessoa = true;
    }

    function fecharModal(modal) {
      if (modal == "cartao") {
        state.modal_escolher_cartao = false;
      } else if (modal == "pesquisa_pessoa") {
        state.modal_pesquisar_pessoa = false;
      }
    }

    function alterarStatus(status) {
      if (status == "pendente") {
        state.status = "pendente";
      } else if (status == "cancelado") {
        state.status = "cancelado";
      } else {
        state.nome_recebedor = nome_pessoa;
        state.recebedor_id = colaborador_id;
        state.status = "recebido";
      }
    }

    function valoresRecebidos() {
      console.log(state.valor_receber.value);
      state.valor_recebido = state.valor_receber.value;
    }

    function buscarCliente(id_cliente, nome_cliente) {
      state.cliente_id.value = id_cliente;
      state.nome_cliente = nome_cliente;
      state.modal_pesquisar_pessoa = false;
    }

    async function handleSubmit() {
      console.log("TESTE");
      try {
        state.isLoading = true;
        var dia_data_vencimento = state.data_vencimento.getDate();
        if (dia_data_vencimento < 10) {
          dia_data_vencimento = "0" + dia_data_vencimento;
        }

        var mes_data_vencimento = state.data_vencimento.getMonth();
        mes_data_vencimento = mes_data_vencimento + 1;
        if (mes_data_vencimento < 10) {
          mes_data_vencimento = "0" + mes_data_vencimento;
        }
        var data_vencimento =
          dia_data_vencimento.toString() +
          "/" +
          mes_data_vencimento.toString() +
          "/" +
          state.data_vencimento.getFullYear().toString();

        var dia_data_recebimento = state.data_recebimento.getDate();
        if (dia_data_recebimento < 10) {
          dia_data_recebimento = "0" + dia_data_recebimento;
        }

        var mes_data_recebimento = state.data_recebimento.getMonth();
        mes_data_recebimento = mes_data_recebimento + 1;
        if (mes_data_recebimento < 10) {
          mes_data_recebimento = "0" + mes_data_recebimento;
        }
        var data_recebimento =
          dia_data_recebimento.toString() +
          "/" +
          mes_data_recebimento.toString() +
          "/" +
          state.data_recebimento.getFullYear().toString();

        try {
          var obterAberto = await services.caixa.obterAberto({
            token: token,
            empresa_id: empresa_id,
            local_conta_id: state.local_recebimento,
          });
          //   console.log(obterAberto.data.data_inicial);
        } catch (error) {
          console.log(error);
        }

        //Se o caixa tiver a data de hoje
        if (obterAberto.data.data_inicial != state.data) {
          if (!confirm("O caixa aberto não é de hoje, deseja continuar?")) {
            return;
          }
        }

        if (!state.id) {
          const { errors } = await services.contaReceber.save({
            token: token,
            empresa_id: empresa_id,
            funcionario_cadastrante_id: state.cadastrante_id,
            funcionario_recebedor_id: state.recebedor_id,
            tipo_pagamento_id: state.tipo_pagamento,
            cliente_id: state.cliente_id.value,
            residuo: state.residuo,
            banco_id: state.banco_id,
            cartao_id: state.cartao_id,
            local_conta_id: state.local_recebimento,
            caixa_id: state.caixa_id,
            nome: state.titulo.value,
            valor_receber: state.valor_receber.value,
            valor_recebido: state.valor_recebido,
            valor_recebido_liquido: state.valor_recebido_liquido,
            qtd_parcelas_cartao: state.qtd_parcelas_cartao,
            descricao: state.descricao,
            data_vencimento: data_vencimento,
            data_recebimento: data_recebimento,
            status: state.status,
          });

          if (!errors) {
            //       window.localStorage.setItem('token', data.token)
            router.push({ name: "contasReceber" });
            state.isLoading = false;
            return;
          }

          if (errors.status === 400) {
            //  toast.error('Ocorreu um erro ao criar a conta')
          }
        }
        state.isLoading = false;
      } catch (error) {
        console.log(error);
        state.isLoading = false;
        state.hasErrors = !!error;
        //  toast.error('Ocorreu um erro ao criar a conta')
      }
    }

    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }

    const { value: clienteIdValue, errorMessage: clienteIdErrorMessage } =
      useField("cliente_id", validateEmpty);

    const { value: tituloValue, errorMessage: tituloErrorMessage } = useField(
      "titulo",
      validateEmpty
    );

    const { value: valorReceberValue, errorMessage: valorReceberErrorMessage } =
      useField("valor_receber", validateEmpty);

    //const {
    //value: dataVencimentoValue,
    //errorMessage: dataVencimentoErrorMessage,
    // } = useField("data_vencimento", validateEmpty);

    const state = reactive({
      tipos_pagamento: [],
      clientes: [],
      pesquisaLoading: false,
      modal: false,
      modal_escolher_cartao: false,
      cadastrante_id: 0,
      parcelas: 0,
      nome_cadastrante: "",
      data: "",
      status: "pendente",
      residuo: "nao",
      codigo: "",
      criar_copias: "nao",
      valor_recebido: "",
      //  data_recebimento: "",
      tipo_pagamento: "",
      descricao: "",
      recebedor_id: null,
      nome_recebedor: "",
      local_recebimento: "",
      locais_recebimento: "",
      cartao_id: "",
      bandeira: "",
      nome_bandeira: "",
      taxa_adicional: 0,
      banco_id: 0,
      caixa_id: 0,
      qtd_parcelas_cartao: 0,
      data_vencimento: new Date(),
      data_recebimento: new Date(),
      escolher_bandeira: "nao",
      cliente_id: {
        value: clienteIdValue,
        errorMessage: clienteIdErrorMessage,
      },
      nome_cliente: "",
      titulo: {
        value: tituloValue,
        errorMessage: tituloErrorMessage,
      },
      valor_receber: {
        value: valorReceberValue,
        errorMessage: valorReceberErrorMessage,
      },
      //data_vencimento: {
      //value: dataVencimentoValue,
      //errorMessage: dataVencimentoErrorMessage,},
    });
    return {
      state,
      SelectTipoPagamento,
      SelectBandeiras,
      alterarStatus,
      escolherCartao,
      modalBuscarPessoa,
      fecharModal,
      fetchClientes,
      CorpoBuscaCliente,
      buscarCliente,
      carregarTipoPagamento,
      handleSubmit,
      cancel,
      deletarContaReceber,
      pt,
      valoresRecebidos,
    };
  },
};
</script>
<style scoped>
.form-group {
  margin-bottom: 0.5rem;
}

.vue-modal-inner {
  max-width: 400px !important;
}

.botoes_modal {
  margin-top: 10px;
}

.largura_total {
  width: 100%;
}

.div_s {
  height: 350px;
  overflow-y: scroll;
}

.btn_lupa {
  background-color: #aede00;
  padding: 10px;
  border-color: #aede00 solid 1px !important;
  color: rgb(0, 0, 0) !important;
  border-radius: 10px !important;
  width: 100%;
}

.btn_lupa:hover {
  transform: none;
}

.btn_fechar {
  color: #fff;
  background-color: #788596;
}

.btn_fechar:hover {
  color: #fff;
  background-color: #788596;
  transform: none;
}

input {
  border-color: #b8b8b8;
  border-width: 1px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
}

input:focus {
  border-color: #252525;
}

select {
  border-color: #b8b8b8;
  border-width: 1px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
}

select:focus {
  border-color: #252525;
}

.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}

.btn_escolher {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}

.btn_fechar_modal {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}

.btn_escolher:hover {
  color: #ffff;
  transform: none;
}

.btn_fechar_modal:hover {
  color: #ffff;
  transform: none;
}

.btn_salvar:hover {
  transform: none;
  color: #fff;
}

.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}

.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}

.area_obs {
  width: 100%;
  border-width: 1px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
  outline: none;
  height: 100px;
  border: 1px solid #b8b8b8;
  resize: none;
}

.area_obs:focus {
  border-color: #252525;
  outline: none;
}

@media (min-width: 992px) and (max-width: 1199px) {}
</style>