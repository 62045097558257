
export default httpClient => ({
  listar: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/local-contas/' + empresa_id, { headers });
    return { data: response.data }
  },

  obterPorId: async ({ token, empresa_id, id }) => {
    const response = await httpClient.post('/seleciona/local-contas/' + empresa_id, {
      token,
      id
    })
    return { data: response.data }
  },


})