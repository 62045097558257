export default httpClient => ({
  getAll: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/fichas/' + empresa_id, { headers })
    return { data: response.data }
  },

  getModeloAll: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/fichas-modelo/' + empresa_id,{ headers })
    return { data: response.data }
  },

  getGruposMuscular: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/grupo-muscular/' + empresa_id, {
    }, { headers })
    return { data: response.data }
  },

  getExerciciosPorMusculo: async ({ token, empresa_id, grupo_muscular_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/exercicios-por-musculo/' + empresa_id, {
      grupo_muscular_id  
    }, { headers })
    return { data: response.data }
  },

  getBuscarFicha: async ({ token, empresa_id, ficha_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/selecionar-ficha/' + empresa_id, {
      id: ficha_id  
    }, { headers })
    return { data: response.data }
  },

  save: async ({ empresa_id, token, cliente_id, prescritor_id, nome_ficha, data_inicial, data_final,observacao, nome_modelo }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/adicionar-ficha/' + empresa_id, {
      token,
      cliente_id,
      prescritor_id,
      tipo_ficha:'padrao',
      data_inicial,
      data_final,
      observacao,
      nome_ficha,
      nome_modelo
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  saveTreinosFicha: async ({ empresa_id, token, ficha_id, treinos }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/salvar-treinos-ficha/' + empresa_id, {
      token,
      ficha_id,
      treinos
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  atualizarTreinosFicha: async ({ empresa_id, token, status, ficha_id, treinos }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/atualizar-ficha/' + empresa_id, {
      token,
      ficha_id,
      status,
      treinos
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
 
  deletarFicha: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/deletar-ficha/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },
})