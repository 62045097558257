<template>
  <div class="template">
    <div class="container">
      <div class="row topo">
        <div class="col-sm-3">
          <img src="/img/logo_sifit.png" class="logo" />
        </div>
        <div class="col-sm-3">
          <h1>Avaliação Física</h1>
          <p><span>Data: </span>{{ state.data_avaliacao }}</p>
        </div>
        <div class="col-sm-2 texto_topo">
          <p><span>Hora: </span>{{ state.hora_avaliacao }}</p>
        </div>
        <div class="col-sm-4 texto_topo">
          <p><span>Avaliador: </span>{{ state.nome_avaliador }}</p>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <div class="quadro">
            <div class="topo_quadro cor_azul">
              <img src="/img/user_sifit.png" width="33px" />
              Aluno
            </div>
            <div class="corpo_quadro">
              <div class="row">
                <div class="col-sm-7">
                  <p><span>Nome: </span>{{ state.nome_cliente }}</p>
                  <p><span>Altura: </span>{{ state.altura }}</p>
                </div>
                <div class="col-sm-5">
                  <p><span>Idade: </span>{{ state.idade }} Anos</p>
                  <p><span>Peso: </span>{{ state.peso }} Kg</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="quadro quadro_grafico_imc">
            <canvas :height="135" :id="linkChartID"></canvas>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="quadro">
            <div class="topo_quadro cor_azul">
              <img src="/img/pizza_sifit.png" width="33px" />
              Fracionamento
            </div>
            <div class="corpo_quadro">
              <div class="row">
                <div class="col-sm-6">
                  <p><span>IMC: </span>{{ state.imc }}</p>
                  <p>
                    <span>% de Gordura: </span>{{ state.percentual_gordura }}
                  </p>
                  <p><span>Peso da Gordura: </span>{{ state.peso_gordura }}</p>
                </div>
                <div class="col-sm-6">
                  <p>
                    <span>Relação Cintura Quadril: </span
                    >{{ state.relacao_cintura_quadril }}
                  </p>
                  <p><span>Taxa Metabólica Basal: </span>---</p>
                  <p>
                    <span>% Massa Magra: </span>{{ state.perc_massa_magra }}%
                  </p>
                  <p>
                    <span>Peso da Massa Magra: </span
                    >{{ state.peso_massa_magra }} Kg
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <div class="quadro">
            <div class="topo_quadro cor_azul">
              <img src="/img/circunferencia_sifit.png" width="33px" />
              Circunferencia
            </div>
            <div class="corpo_quadro">
              <div class="row">
                <div class="col-6">
                  <p><span>Ombro: </span>{{ state.ombro }}</p>
                  <p><span>Torax: </span> {{ state.busto }}</p>
                  <p><span>Abdominal: </span>{{ state.abdomem }}</p>
                </div>
                <div class="col-6">
                  <p><span>Cintura: </span>{{ state.cintura }}</p>
                  <p><span>Quadril: </span>{{ state.quadril }}</p>
                </div>
              </div>
              <p><span>Coxas</span></p>
              <div class="row">
                <div class="col-6">
                  <p><span>Direita: </span>{{ state.coxa_dir }}</p>
                </div>
                <div class="col-6">
                  <p><span>Esquerda: </span>{{ state.coxa_esq }}</p>
                </div>
              </div>
              <p><span>Braço</span></p>
              <div class="row">
                <div class="col-6">
                  <p><span>Direito: </span>{{ state.braco_dir }}</p>
                </div>
                <div class="col-6">
                  <p><span>Esquerdo: </span>{{ state.braco_esq }}</p>
                </div>
              </div>
              <p><span>Antebraço</span></p>
              <div class="row">
                <div class="col-6">
                  <p><span>Direito: </span>{{ state.antebraco_dir }}</p>
                </div>
                <div class="col-6">
                  <p><span>Esquerdo: </span>{{ state.antebraco_esq }}</p>
                </div>
              </div>
              <p><span>Panturrilhas</span></p>
              <div class="row">
                <div class="col-6">
                  <p><span>Direita: </span>{{ state.panturrilha_dir }}</p>
                </div>
                <div class="col-6">
                  <p><span>Esquerda: </span>{{ state.panturrilha_esq }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="quadro">
            <div class="topo_quadro cor_azul">
              <img src="/img/subcutaneas_sifit.png" width="33px" />
              Medidas Subcutâneas
            </div>
            <div class="corpo_quadro">
              <div class="row">
                <div class="col-6">
                  <p><span>Biceps: </span>{{ state.biceps_subc }}</p>
                  <p><span>Supra-Ilíaca: </span>{{ state.suprailiaca_subc }}</p>
                  <p><span>Axila: </span>{{ state.axila_subc }}</p>
                  <p><span>Coxa: </span>{{ state.coxa_subc }}</p>
                  <p>
                    <span>Subescapular: </span>{{ state.subescapular_subc }}
                  </p>
                </div>
                <div class="col-6">
                  <p><span>Triceptal: </span>{{ state.triceptal_subc }}</p>
                  <p><span>Torax: </span>{{ state.torax_subc }}</p>
                  <p><span>Abdominal: </span>{{ state.abdominal_subc }}</p>
                  <p><span>Panturrilha: </span>{{ state.panturrilha_subc }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive } from "vue";
import services from "../services";
import { useRouter } from "vue-router";
import { linkChart } from "../components/Charts/ChartLink";

export default {
  mounted() {
    //linkChart.createChart(this.linkChartID,50,50);
  },
  setup() {
    const router = useRouter();
    onMounted(() => {
      carregar();
    });

    const state = reactive({
      isLoading: false,
      currentClientType: "",
      hasError: false,
      nome_cliente: "",
      idade: "",
      altura: "",
      peso: "",
      imc: "",
      percentual_gordura: "",
      peso_gordura: "",
      perc_massa_magra: "",
      peso_massa_magra: "",
      relacao_cintura_quadril: "",
      busto: "",
      cintura: "",
      abdomem: "",
      quadril: "",
      ombro: "",
      coxa_dir: "",
      coxa_esq: "",
      panturrilha_dir: "",
      panturrilha_esq: "",
      braco_dir: "",
      braco_esq: "",
      antebraco_dir: "",
      antebraco_esq: "",
      biceps_subc: "",
      triceptal_subc: "",
      suprailiaca_subc: "",
      torax_subc: "",
      axila_subc: "",
      abdominal_subc: "",
      coxa_subc: "",
      panturrilha_subc: "",
      subescapular_subc: "",
      data_avaliacao: "",
      hora_avaliacao: "",
      nome_avaliador: "",
    });

    async function carregar() {
      var avaliacao = await services.avaliacoes.obterPorHash({
        hash: router.currentRoute._value.params.hash,
      });
      state.nome_cliente = avaliacao.data.nome_cliente;
      state.idade = avaliacao.data.idade_atual;
      state.altura = avaliacao.data.altura;
      state.peso = avaliacao.data.peso;
      state.imc = avaliacao.data.imc;
      state.percentual_gordura = avaliacao.data.percentual_gordura;
      state.peso_gordura = avaliacao.data.peso_gordura;
      state.perc_massa_magra = avaliacao.data.perc_massa_magra;
      state.peso_massa_magra = avaliacao.data.massa_magra;
      state.relacao_cintura_quadril = avaliacao.data.relacao_cintura_quadril;
      state.busto = avaliacao.data.busto;
      state.cintura = avaliacao.data.cintura;
      state.abdomem = avaliacao.data.abdomem;
      state.quadril = avaliacao.data.quadril;
      state.ombro = avaliacao.data.ombro;
      state.coxa_dir = avaliacao.data.coxa_dir;
      state.coxa_esq = avaliacao.data.coxa_esq;
      state.panturrilha_dir = avaliacao.data.panturrilha_dir;
      state.panturrilha_esq = avaliacao.data.panturrilha_esq;
      state.braco_dir = avaliacao.data.braco_dir;
      state.braco_esq = avaliacao.data.braco_esq;
      state.antebraco_dir = avaliacao.data.antebraco_dir;
      state.antebraco_esq = avaliacao.data.antebraco_esq;

      state.biceps_subc = avaliacao.data.biceps_subc;
      state.triceptal_subc = avaliacao.data.triceptal_subc;
      state.suprailiaca_subc = avaliacao.data.suprailiaca_subc;
      state.torax_subc = avaliacao.data.torax_subc;
      state.axila_subc = avaliacao.data.axila_subc;
      state.abdominal_subc = avaliacao.data.abdominal_subc;
      state.coxa_subc = avaliacao.data.coxa_subc;
      state.panturrilha_subc = avaliacao.data.panturrilha_subc;
      state.subescapular_subc = avaliacao.data.subescapular_subc;

      state.data_avaliacao = avaliacao.data.data_avaliacao;
      state.hora_avaliacao = avaliacao.data.hora_avaliacao;
      state.nome_avaliador = avaliacao.data.nome_personal;
      console.log(avaliacao.data.percentual_gordura);
      linkChart.createChart(
        "linkChart",
        parseFloat(avaliacao.data.percentual_gordura.replace(",", ".")),
        parseFloat(avaliacao.data.perc_massa_magra.replace(",", "."))
      );
    }
    return {
      state,
      linkChartID: "linkChart",
    };
  },
};
</script>
<style scoped>
@font-face {
  font-family: "MontSerrat";
  src: url("../../public/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "MontSerratSemiBold";
  src: url("../../public/fonts/Montserrat-SemiBold.ttf");
}

.container {
  margin-top: 15px;
  font-family: "MontSerrat";
  color: #1d1d1d;
}
.topo {
  margin-bottom: 30px !important;
}
.logo {
  width: 100%;
}

h1 {
  font-family: "MontSerratSemiBold";
  font-size: 14pt;
  font-weight: normal;
  color: #1d1d1d;
}
span {
  font-family: "MontSerratSemiBold";
  font-size: 11pt;
}
.quadro {
  background-color: #fff;
  border-radius: 10px;
  border: #f6f6f6 solid 1px;
}
.cor_azul {
  color: #034aa6;
}
.topo_quadro {
  padding: 10px;
  border-bottom: #f4f4f4;
}
.corpo_quadro {
  padding: 15px;
}
.row {
  margin-bottom: 15px;
}
p {
  margin-bottom: 2px;
  line-height: 1.5;
  font-weight: 500;
  color: #000;
}
.quadro_grafico_imc {
  padding: 5px;
}
.texto_topo {
  padding-top: 50px;
}

@media screen and (max-width: 992px) {
  .col-sm-6 {
    margin-bottom: 10px;
  }
  .col-sm-12 {
    margin-bottom: 10px;
  }
  .row {
    margin-bottom: 0px;
  }
  .texto_topo {
    padding-top: 0;
  }
  .logo {
    padding-left: 20%;
    padding-right: 20%;
  }
  h1 {
    text-align: center;
    margin-top: 15px;
  }
}
</style>