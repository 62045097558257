export default httpClient => ({
  listar: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/buscar-atividades/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },

  obterPorId: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/buscar-atividade/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },

  save: async (empresa_id, token, atividade) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/salvar-atividade/' + empresa_id, atividade, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
    update: async (empresa_id, token, atividade) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/atualizar-atividade/' + empresa_id, atividade, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
    deletar: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/deletar-atividade/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },
});