const defaultPagination = {
  limit: 5,
  offset: 0
}
export default httpClient => ({
  getAll: async ({ type, token, empresa_id, quantidade, quantidade_anterior } = defaultPagination) => {
    const query = { quantidade, quantidade_anterior }
    if (type) {
      query.type = type
    }
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/clientes-paginacao/' + empresa_id, {
      token: token,
      empresa_id: empresa_id,
      quantidade: quantidade,
      quantidade_anterior: quantidade_anterior
    }, { headers });
    return { data: response.data }
  },

  clientesPorNome: async ({ token, empresa_id, nome }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/clientesPorNome/' + empresa_id, {
      token,
      empresa_id,
      nome
    }, { headers });
    return { data: response.data }
  },

  qtdClientesAtivos: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/total-clientes-ativos/' + empresa_id, { headers });
    return { data: response.data }
  },

  qtdClientes: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/total-clientes/' + empresa_id, { headers });
    return { data: response.data }
  },

  telaClienteWeb: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/clientes/tela-web/' + empresa_id, { headers });
    return { data: response.data }
  },

  qtdClientesSemMatriculas: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/qtd-clientes-sem-matricula/' + empresa_id, { headers });
    return { data: response.data }
  },

  qtdClientesAusentes: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/clientes/ausentes/' + empresa_id, {
      token
    }, { headers });
    return { data: response.data.length }
  },

  listaClientesAusentes: async ({ token, empresa_id, quantidade, quantidade_anterior, dias_ausencia }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/clientes/ausentes/paginacao/' + empresa_id, {
      token,
      quantidade,
      quantidade_anterior,
      dias_ausencia
    }, { headers });
    return { data: response.data }
  },

  listaClientesSemMatricula: async ({ token, empresa_id, quantidade, quantidade_anterior, dias_ausencia }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/clientes-sem-matricula/' + empresa_id, {
      token,
      quantidade,
      quantidade_anterior,
      dias_ausencia
    }, { headers });
    return { data: response.data }
  },

  
frequenciaCliente: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/pessoa-frequencia/' + empresa_id, {
      token,
      pessoa_id: id
    }, { headers });
    return { data: response.data }
  },
  obterPorId: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/seleciona-cliente/' + empresa_id, {
      token,
      id
    }, { headers });
    return { data: response.data }
  },

  save: async (token, empresa_id, dados) => {
    const headers = {
      "Authorization": token, "Accept": "application/json", "Content-Type": "multipart/form-data"
    }
    const response = await httpClient.post('/cadastrar-cliente/' + empresa_id, dados, { headers });
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  update: async ( token, empresa_id, dados) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/atualizar-cliente/' + empresa_id, dados, { headers });
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  listarUltimasPresencas: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/ultimas-presencas/' + empresa_id, {
      token
    }, { headers });
    return { data: response.data }
  },
  listarMaioresPresencas: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/clientes/maiores-presencas/' + empresa_id, {
      token
    }, { headers });
    return { data: response.data }
  },
  deletarCliente: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/clientes/deletar/' + empresa_id, {
      token,
      id
    }, { headers });
    return { data: response.data }
  },

})