export default httpClient => ({
  listar: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/bandeiras/' + empresa_id, {
      token: token
    }, { headers });
    return { data: response.data }
  },

  obterPorId: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/seleciona/bandeira/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },
})