<template>
  <div style="background-color: #f8f9fe; min-height: 100vh">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-2">
          <barra-lateral />
        </div>
        <div class="col-sm-10">
          <topo :titulo="'Cadastrar Avaliação'" />

          <div class="card card-stats">
            <div class="row">
              <div class="col-xl-12 col-lg-12">
                <stats-card>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <router-link to="/dashboard">Home</router-link>
                      </li>
                      <li class="breadcrumb-item">
                        <router-link to="/avaliacoes" class="primary"
                          >Avaliações</router-link
                        >
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Cadastrar
                      </li>
                    </ol>
                  </nav>
                  <form>
                    <div class="row">
                      <div class="col-md-5">
                        <label>Funcionario cadastrante</label>
                        <div class="row">
                          <div class="col-3 col-md-3">
                            <div class="form-group">
                              <input
                                type="text"
                                readonly
                                class="form-control input cadastrante"
                                v-model="state.id_cadastrante.value"
                              />
                            </div>
                          </div>
                          <div class="col-9 col-md-9">
                            <div class="form-group">
                              <input
                                type="text"
                                readonly
                                class="form-control input"
                                v-model="state.nome_cadastrante.value"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label>Data de Lançamento</label>
                          <input
                            type="text"
                            class="form-control input"
                            v-model="state.data_cadastro.value"
                          />
                        </div>
                      </div>
                    </div>
                    <hr />

                    <h2>Cliente</h2>
                    <div class="row">
                      <div class="col-sm-3 col-lg-2">
                        <div class="form-group">
                          <label>Código</label>
                          <input
                            type="text"
                            class="form-control input"
                            v-model="state.id_cliente"
                          />
                        </div>
                      </div>
                      <div class="col-10 col-sm-8 col-lg-6">
                        <div class="form-group">
                          <label>Nome</label>
                          <input
                            type="text"
                            class="form-control input"
                            v-model="state.nome_cliente"
                          />
                        </div>
                      </div>
                      <div class="col-2 col-sm-1 col-lg-1 div_btn_buscar">
                        <button
                          class="btn btn_buscar"
                          type="button"
                          style="margin-top: 18px"
                          @click="state.isOpen = true"
                        >
                          <i class="bi bi-search"></i>
                        </button>
                      </div>
                    </div>
                    <hr class="hr_transparente" />
                    <h2>Selecione o personal responsável</h2>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label>Personal</label>
                          <select
                            v-model="state.personal"
                            class="form-control input form-select"
                          >
                            <option
                              v-for="avaliador in state.avaliadores"
                              :value="avaliador.id"
                              :key="avaliador.id"
                            >
                              {{ avaliador.nome }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-6 col-sm-3">
                        <div class="form-group">
                          <label>Data</label>
                          <Datepicker
                            v-model="state.data_avaliacao"
                            :locale="pt"
                            inputFormat="dd/MM/yyyy"
                            style="
                              border-color: #b8b8b8;
                              color: #505050;
                              border-radius: 5px;
                              border: #b8b8b8 solid 1px;
                              transition: 0.5s;
                              width: 100%;
                              padding: 6px;
                              resize: none;
                            "
                          />
                        </div>
                      </div>
                      <div class="col-6 col-sm-3">
                        <div class="form-group">
                          <label>Hora</label>
                          <input
                            type="text"
                            class="form-control input"
                            v-model="state.hora_avaliacao"
                            v-mask="'00:00'"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col text-end">
                      <button
                        class="btn btn_salvar my-4"
                        id="submit-button"
                        :disabled="state.isLoading"
                        type="button"
                        @click="handleSubmit"
                      >
                        Salvar
                      </button>

                      <button
                        class="btn btn_cancelar my-4"
                        id="submit-button"
                        type="button"
                        @click="cancel()"
                      >
                        Cancelar
                      </button>
                    </div>
                  </form>
                </stats-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    :open="state.isOpen"
    @close="state.isOpen = !state.isOpen"
    :width="'800px'"
  >
    <div style="padding: 20px">
      <div class="row">
        <div class="col-sm-3">
          <label for="tipo_pesquisa">Tipo de Pesquisa</label>
          <select id="tipo_pesquisa" class="form-select">
            <option>Nome</option>
            <option>Código</option>
          </select>
        </div>
        <div class="col-sm-8">
          <label>Pesquisa</label>
          <input type="text" class="form-control" v-model="state.nome" />
        </div>
        <div class="col-sm-1" style="padding-left: 0">
          <button class="btn btn_lupa" type="button" @click="fetchClientes">
            <i class="bi bi-search"></i>
            <span style="margin-left: 5px" class="d-inline d-sm-none"
              >Pesquisar</span
            >
          </button>
        </div>
      </div>

      <div class="row div_s" style="margin-top: 15px">
        <div class="col">
          <div class="table-responsive">
            <avaliacao-loading v-if="state.pesquisaLoading" />
            <table
              class="table tablesorter table align-items-center table-flush"
              v-else
            >
              <thead class="thead-light">
                <th>Código</th>
                <th>Nome</th>
                <th></th>
              </thead>
              <tbody class="list">
                <corpo-busca-cliente
                  @buscarCliente="buscarCliente"
                  v-for="cliente in state.clientes"
                  :key="cliente.id"
                  :cliente="cliente"
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div>
        <button type="button" class="btn btn_fechar" @click="fecharModal">
          Fechar
        </button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Datepicker from "vue3-datepicker";
import { pt } from "date-fns/locale";
import { onMounted, reactive } from "vue";
import services from "../services";
import CorpoBuscaCliente from "../components/CorpoBuscaClientes";
import { useRouter } from "vue-router";
import Modal from "../components/EstruturaModal/EstruturaModal.vue";
import { useStorage } from "vue3-storage";
import AvaliacaoLoading from "../components/CorpoTabelaAvaliacao/Loading";
import topo from "../components/topo";
import BarraLateral from "../layout/Sistema/barraLateral";
export default {
  components: {
    Modal,
    AvaliacaoLoading,
    topo,
    BarraLateral,
    Datepicker,
    CorpoBuscaCliente,
  },
  setup(_, { emit }) {
    // var isOpen = ref(false);
    const storage = useStorage();
    var data_atual = new Date();
    var dd = String(data_atual.getDate()).padStart(2, "0");
    var mm = String(data_atual.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = data_atual.getFullYear();

    data_atual = dd + "/" + mm + "/" + yyyy;

    var nome_usuario = storage.getStorageSync("nome_pessoa");
    var id_func_usuario = storage.getStorageSync("colaborador_id");
    var empresa_id = storage.getStorageSync("empresa_id");
    var token = storage.getStorageSync("token");

    //const modal = useModal();
    const router = useRouter();

    function cancel() {
      router.push({ name: "avaliacoes" });
    }

    const state = reactive({
      data_avaliacao: new Date(),
      hasErrors: false,
      isLoading: false,
      pesquisaLoading: false,
      isOpen: false,
      clientes: [],
      avaliadores: [],
      id_cadastrante: {
        value: id_func_usuario,
      },
      nome_cadastrante: {
        value: nome_usuario,
      },
      id_cliente: "",
      nome_cliente: "",
      hora_avaliacao: "",
      personal: "",
      data_cadastro: {
        value: data_atual,
      },
      categoria: {
        value: "",
      },
    });

    onMounted(() => {
      fetchCategoria();
      fetchAvaliadores();
    });
    /*
    function handleBuscarCliente() {
      modal.open({
        component: "ModalBuscarAluno",
      });
    }
*/
    function buscarCliente(id_cliente, nome_cliente) {
      state.id_cliente = id_cliente;
      state.nome_cliente = nome_cliente;
      state.isOpen = false;
    }
    function fecharModal() {
      state.isOpen = false;
      state.nome = "";
      state.clientes = [];
    }

    async function fetchClientes() {
      try {
        state.pesquisaLoading = true;
        const { data } = await services.clientes.clientesPorNome({
          empresa_id: empresa_id,
          token: token,
          nome: state.nome,
        });
        console.log(data);
        //  console.log(data)
        state.clientes = data;
        //   state.pagination = data.pagination
        state.pesquisaLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function fetchCategoria() {
      try {
        state.isLoading = true;
        const { data } = await services.colaboradores.getAvaliadores({
          empresa_id: empresa_id,
          token: token,
        });
        console.log(data);
        //  console.log(data)
        state.categorias = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function fetchAvaliadores() {
      try {
        state.isLoading = true;
        const { data } = await services.colaboradores.getAvaliadores({
          empresa_id: empresa_id,
          token: token,
        });
        console.log(data);
        //  console.log(data)
        state.avaliadores = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }

    async function handleSubmit() {
      try {
        state.isLoading = true;
        var dia_data_avaliacao = state.data_avaliacao.getDate();
        if (dia_data_avaliacao < 10) {
          dia_data_avaliacao = "0" + dia_data_avaliacao;
        }

        var mes_data_avaliacao = state.data_avaliacao.getMonth();
        mes_data_avaliacao = mes_data_avaliacao + 1;
        if (mes_data_avaliacao < 10) {
          mes_data_avaliacao = "0" + mes_data_avaliacao;
        }
        var data_avaliacao =
          dia_data_avaliacao.toString() +
          "/" +
          mes_data_avaliacao.toString() +
          "/" +
          state.data_avaliacao.getFullYear().toString();
        const { data } = await services.avaliacoes.save({
          empresa_id: empresa_id,
          token: token,
          func_cadastrante_id: id_func_usuario,
          cliente_id: state.id_cliente,
          data_avaliacao: data_avaliacao,
          hora_avaliacao: state.hora_avaliacao,
          personal_id: state.personal,
        });

        if (data.verifica_erro == false) {
          router.push({ name: "avaliacoes" });
        } else {
          alert("Erro ao cadastrar Avaliação.");
        }
      } catch (error) {
        alert("Erro ao cadastrar Avaliação.");
        console.log(error);
      }
    }
    return {
      state,
      handleSubmit,
      fetchClientes,
      emit,
      buscarCliente,
      cancel,
      fecharModal,
      pt,
    };
  },
};
</script>
<style>
@import "../app.scss";
</style>
<style scoped>
.breadcrumb {
  background: none;
}

h2 {
  color: #252525;
  font-weight: 600;
}
textarea {
  resize: none;
}
.cadastrante {
  font-weight: 500;
}
hr {
  margin-top: 10px;
  margin-bottom: 1rem;
}
.hr_transparente {
  visibility: hidden;
}
.btn_lupa {
  background-color: #aede00;
  padding: 8px;
  border-color: #aede00 solid 1px !important;
  color: rgb(0, 0, 0) !important;
  border-radius: 10px !important;
  width: 100%;
  margin-top: 23px;
}
.btn_lupa:hover {
  background-color: #aede00 !important;
  transform: none;
}
.div_btn_buscar {
  margin-left: -20px;
  margin-top: 3px;
}
.btn_buscar {
  background-color: #aede00;
  padding: 10px;
  border-color: #aede00 solid 1px !important;
  color: rgb(0, 0, 0) !important;
  border-radius: 10px !important;
}
.btn_buscar:hover {
  background-color: #aede00;
  color: rgb(0, 0, 0) !important;
}
.btn_fechar {
  color: #fff;
  background-color: #788596;
}
.btn_fechar:hover {
  color: #fff;
  background-color: #788596;
  transform: none;
}
input {
  border-color: #b8b8b8;
  border-width: 1px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
}
input:focus {
  border-color: #252525;
}
select {
  border-color: #b8b8b8;
  border-width: 1px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
}
select:focus {
  border-color: #252525;
}
.btn_salvar {
  color: #ffff;
  background: #034aa6 !important;
  border: #034aa6 solid 5px !important;
}
.btn_salvar:hover {
  transform: none;
  color: #fff;
}
.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
  margin-left: 5px;
}
.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}
.div_s {
  height: 350px;
  overflow-y: scroll;
}
.card-stats {
  margin-top: 20px;
}
@media (max-width: 719px) {
  .btn_salvar {
    width: 48%;
  }
  .btn_cancelar {
    width: 48%;
  }
}
</style>