<template>
  <div style="background: #000d1f">
    <main>
      <topo @alterarIdioma="alterarIdioma" :idioma="state.idioma" />
      <div class="container">
        <div class="row">
          <div class="col-lg-6 offset-lg-3">
            <h1 class="title1" v-if="state.idioma == 'PT'">
              REVOLUCIONE A GESTÃO
            </h1>
            <h1 class="title1" v-else>REVOLUCIONAR LA GESTIÓN</h1>

            <h1 class="title2" v-if="state.idioma == 'PT'">DE SUA ACADEMIA</h1>
            <h1 class="title2" v-else>DE TU GIMNASIO</h1>

            <p class="txt_title" v-if="state.idioma == 'PT'">
              O SIFIT possui diversas funcionalidades que facilitarão a sua vida
              como gestor e melhorarão a experiência do aluno na sua academia
            </p>
            <p class="txt_title" v-else>
              SIFIT tiene varias características que te harán la vida más fácil
              como gerente y mejorará la experiencia estudiantil en tu gimnasio
            </p>

            <h1 class="title3" v-if="state.idioma == 'PT'">
              CONHEÇA NOSSAS FUNCIONALIDADES
            </h1>
            <h1 class="title3" v-else>DESCUBRE NUESTRAS CARACTERÍSTICAS</h1>
          </div>
        </div>
        <div class="div_whats">
          <a
            href="https://api.whatsapp.com/send?phone=55044984387269&text=Ol%C3%A1,%20gostaria%20de%20conhecer%20o%20sistema!"
            target="blank"
          >
            <img class="img_whats" src="../../assets/img_new/zap.png" />
          </a>
        </div>
        <div class="row">
          <div class="col-md-5 offset-md-1">
            <img class="icon" src="../../assets/img_new/icon4.png" />
            <h1 class="title4" v-if="state.idioma == 'PT'">
              CONTROLE DE PLANOS, MATRÍCULAS E CLIENTES
            </h1>
            <h1 class="title4" v-else>
              CONTROL DE PLANES, INSCRIPCIONES Y CLIENTES
            </h1>
            <p class="descricao1" v-if="state.idioma == 'PT'">
              Controle o acesso de seus clientes na sua academia a partir de
              matrículas. Cada cliente poderá ter uma ou mais matrículas, sendo
              que elas podem ter um prazo em meses ou ser tempo livre.
              Facilitando assim para a academia saber o fim do contrato com o
              aluno e assim renová-lo.
            </p>
            <p class="descricao1" v-else>
              Controla el acceso de tus clientes a tu gimnasio mediante
              registros. Cada cliente podrá tener una o más altas, pudiendo
              tener un plazo de entrega de meses o ser tiempo libre. Esto
              facilita que la academia pueda conocer la finalización del
              contrato con el alumno y así renovarlo.
            </p>
          </div>
          <div class="col-md-5">
            <img class="not1" src="../../assets/img_new/not1.png" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 offset-1 d-none d-sm-none d-md-block">
            <img class="not2" src="../../assets/img_new/not2.png" />
          </div>
          <div class="col-md-5 div_direita">
            <img class="icon2" src="../../assets/img_new/icon1.png" />
            <h1 class="title5" v-if="state.idioma == 'PT'">
              CONTROLE FINANCEIRO
            </h1>
            <h1 class="title5" v-else>CONTROL FINANCIERO</h1>

            <p class="descricao2" v-if="state.idioma == 'PT'">
              Com Sistema Sifit você consegue gerenciar as finanças da sua
              academia. O sistema conta com Contas a Pagar, Contas a Receber,
              Categorias de Contas a Pagar (para maior facilidade de saber qual
              tipo de conta está dando mais despesa. Fechamento de caixa podendo
              ser por dia ou por período. Lançamento automático de contas a
              receber dos clientes matriculados. Além de outros recursos.
            </p>

            <p class="descricao2" v-else>
              Con Sistema Sifit podrás gestionar las finanzas de tu gimnasio. El
              sistema cuenta con Categorías de Cuentas por Pagar, Cuentas por
              Cobrar, Cuentas por Pagar (para que sea más fácil saber qué tipo
              de cuenta está generando el mayor gasto. El cierre de caja puede
              ser por día o por período. Contabilización automática de las
              cuentas por cobrar de los clientes inscritos, además a otros
              recursos.
            </p>
          </div>
          <div class="col-md-5 offset-1 d-sm-block d-md-none">
            <img class="not2" src="../../assets/img_new/not2.png" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 offset-1">
            <img class="icon" src="../../assets/img_new/icon5.png" />
            <h1 class="title4" v-if="state.idioma == 'PT'">AVALIAÇÃO FÍSICA</h1>
            <h1 class="title4" v-else>EVALUACIÓN FÍSICA</h1>

            <p class="descricao1" v-if="state.idioma == 'PT'">
              O sistema permite agendar e fazer avaliação física dos seus alunos
              através do computador, tablet ou celular. As avaliações são feita
              no método Poslov de 7 dobras ou via maquina. O sistema conta
              também com comparação entre as avaliações do mesmo paciente. As
              avaliações podem ser enviadas para o cliente através do whatsapp,
              o sistema já faz o serviço de redirecionar para o aplicativo. As
              avaliações também podem ser impressas.
            </p>

            <p class="descricao1" v-else>
              El sistema te permite programar y realizar evaluaciones físicas de
              tus alumnos A través de computadora, tableta o teléfono celular.
              Se hacen evaluaciones mediante el método Poslov de 7 veces o
              mediante máquina. El sistema cuenta también con comparación entre
              valoraciones de un mismo paciente. Hacia Las reseñas se pueden
              enviar al cliente a través de WhatsApp, el sistema ya realiza el
              servicio de redireccionamiento a la aplicación. Hacia Las
              evaluaciones también se pueden imprimir.
            </p>
          </div>
          <div class="col-md-5">
            <img class="not1" src="../../assets/img_new/sifit_table.png" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 offset-1 d-none d-sm-none d-md-block">
            <img class="not2" src="../../assets/img_new/not3.png" />
          </div>
          <div class="col-md-5 div_direita">
            <img class="icon2" src="../../assets/img_new/icon6.png" />

            <h1 class="title5" v-if="state.idioma == 'PT'">
              CONTROLE DE FICHAS
            </h1>
            <h1 class="title5" v-else>CONTROLE DE FICHAS</h1>

            <p class="descricao2" v-if="state.idioma == 'PT'">
              Contamos com controle de ficha de treino, podendo substituir a
              ficha de papel. O personal pode fazer toda a ficha de maneira
              virtual e disponibilizar o link para o cliente acessar a ficha. A
              ficha terá data de vencimento, assim avizando ao personal a hora
              de criar uma ficha nova para o seu aluno.
            </p>
            <p class="descricao2" v-else>
              Disponemos de control de fichas de formación, pudiendo sustituir
              las ficha de papel. El personal puede hacer todo el formulario en
              un virtual y proporcionar el enlace para que el cliente acceda al
              formulario. A El formulario tendrá una fecha de vencimiento,
              notificando así al personal la hora para crear un nuevo registro
              para su estudiante.
            </p>
          </div>
          <div class="col-md-5 offset-1 d-sm-block d-md-none">
            <img class="not2" src="../../assets/img_new/not3.png" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 offset-1">
            <img class="icon" src="../../assets/img_new/icon3.png" />
            <h1 class="title4" v-if="state.idioma == 'PT'">
              CONTROLE DE ACESSO
            </h1>
            <h1 class="title4" v-else>CONTROLE DE ACCESO</h1>

            <p class="descricao1" v-if="state.idioma == 'PT'">
              O sistema conta com controle de acesso. Podendo assim bloquear
              alunos inadiplentes. Além de fornecer relatório das presença de
              alunos e informar caso algum aluno esteja afastado dos treinos.
            </p>
            <p class="descricao1" v-else>
              El sistema cuenta con control de acceso. De esta manera puedes
              bloquear estudiantes morosos. Además de proporcionar un informe
              sobre la presencia de estudiantes e informar si algún estudiante
              falta a la formación.
            </p>
          </div>
          <div class="col-md-5">
            <img class="not1" src="../../assets/img_new/not4.png" />
          </div>

          <div class="col-md-10 offset-md-1 coluna">
            <div class="metade">
              <h1 class="title6" v-if="state.idioma == 'PT'">
                FACILITE A GESTÃO DA SUA ACADEMIA COM O SIFIT
              </h1>
              <h1 class="title6" v-else>
                HAZ MÁS FÁCIL LA GESTIÓN DE TU ACADEMIA CON SIFIT
              </h1>
              <div class="btn_linha">
                <button class="btn" type="button" @click="abrirModal()">
                  <span v-if="state.idioma == 'PT'"
                    >Solicitar Demonstração</span
                  >
                  <span v-else>Solicitar Demostración</span>
                </button>
              </div>
            </div>

            <img class="men1" src="../../assets/img_new/men1.png" />
          </div>
        </div>
      </div>
      <bottom />
    </main>
  </div>
  <Modal
    :open="state.modalOpen"
    @close="state.modalOpen = !state.modalOpen"
    :width="'800px'"
  >
    <div class="container" v-if="state.pedidoEnviado == false">
      <button class="btn_transparent" @click="fecharModal">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          class="svg_fechar"
          viewBox="0 0 16 16"
        >
          <path
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
      </button>
      <h1 class="modal_h1">VOCÊ ESTÁ A UM PASSO DE SIMPLIFICAR</h1>
      <h1 class="modal_h2">A GESTÃO DA SUA ACADEMIA!</h1>
      <p class="p_modal">Informação do Plano</p>
      <div class="row">
        <div class="col-sm-8">
          <label for="">Plano:</label>
          <select class="form-select" v-model="state.demonstracao.plano_id">
            <option
              v-for="(plano, index) in state.planos"
              :key="index"
              :value="plano.id"
            >
              {{ plano.nome }}
            </option>
          </select>
        </div>
        <div class="col-sm-4">
          <label for="">Data de Vencimento:</label>
          <select
            class="form-select"
            v-model="state.demonstracao.data_vencimento"
          >
            <option value="05">dia / 05</option>
            <option value="10">dia / 10</option>
            <option value="15">dia / 15</option>
            <option value="20">dia / 20</option>
          </select>
        </div>
      </div>
      <p class="p_modal">Informações de Contato</p>
      <div class="row">
        <div class="col-sm-6">
          <label for="">Empresa (Se houver):</label>
          <input
            v-model="state.demonstracao.empresa"
            type="text"
            class="form-control"
          />
        </div>

        <div class="col-sm-6">
          <label for="">Nome do Responsável:</label>
          <input
            v-model="state.demonstracao.nome_responsavel"
            type="text"
            class="form-control"
          />
        </div>
      </div>
      <div class="row">
        <!--
        <div class="col-sm-3">
          <label for="">CPF/CNPJ:</label>
          <input
            v-model="state.demonstracao.cpf_cnpj"
            type="text"
            class="form-control"
          />
        </div>
        -->
        <div class="col-sm-7">
          <label for="">Email:</label>
          <input
            v-model="state.demonstracao.email"
            type="text"
            class="form-control"
          />
        </div>
        <div class="col-sm-5">
          <label for="">Telefone:</label>
          <input
            v-model="state.demonstracao.telefone"
            type="text"
            class="form-control"
            v-mask="['(##) ####-####', '(##) #####-####']"
          />
        </div>
      </div>
      <div class="div_btn_modal">
        <button class="btn_enviar_modal" type="button" @click="handleSubmit">
          <span>Quero Melhorar Minha Gestão!</span>
        </button>
      </div>
    </div>
    <div class="container" v-else>
      <div style="padding-top: 60px; padding-bottom: 60px">
        <h1 class="modal_h2">PARABÉNS PELA ESCOLHA!</h1>
        <h1 class="modal_h1">
          EM BREVE UM CONSULTOR ENTRARÁ EM CONTATO COM VOCÊ
        </h1>
      </div>
      <div class="div_btn_modal">
        <button
          class="btn_enviar_modal"
          type="button"
          @click="fecharModalEnvidado"
        >
          <span>FECHAR</span>
        </button>
      </div>
    </div>
  </Modal>
</template>
<script>
import { onMounted, reactive } from "vue";
import services from "../../services";
import Topo from "../../layout/Site/topoSite.vue";
import Bottom from "../../layout/Site/bottomSite.vue";
import Modal from "../../components/EstruturaModal/EstruturaModal.vue";
export default {
  components: { Topo, Bottom, Modal },
  setup(_, { emit }) {
    const state = reactive({
      demonstracao: {
        plano_id: 0,
        data_vencimento: "",
        empresa: "",
        nome_responsavel: "",
        cpf_cnpj: "",
        email: "",
        telefone: "",
      },
      modalOpen: false,
      isLoading: false,
      planos: [],
      hasError: false,
      idioma: "PT",
      pedidoEnviado: false,
    });
    onMounted(() => {
      fetchPlanos();
    });

    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }

    async function fetchPlanos() {
      try {
        state.isLoading = true;
        const { data } = await services.planosSifit.getAll({});
        //  console.log(data)
        state.planos = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    function alterarIdioma(idioma) {
      state.idioma = idioma;
    }
    function abrirModal(plano_id) {
      if (plano_id != undefined) {
        state.demonstracao.plano_id = plano_id;
      }
      state.modalOpen = true;
    }
    function fecharModal() {
      state.modalOpen = false;
    }

    function fecharModalEnvidado() {
      state.modalOpen = false;
      setTimeout(() => {
        state.pedidoEnviado = false; // altera o conteúdo após 1 segundo
      }, 1000);
    }
    return {
      emit,
      state,
      alterarIdioma,
      abrirModal,
      fecharModal,
      fecharModalEnvidado,
    };
  },
};
</script>
<style>
@import "../../app.scss";
</style>
<style scoped>
h1 {
  font-family: "Exo";
}
p {
  font-family: "Montserrat";
}
.title1 {
  color: white;
  font-size: 40px;
  width: 100%;
  text-align: center;
  line-height: 1;
  margin-top: 55px;
}
.title2 {
  color: #aede00;
  font-size: 40px;
  width: 100%;
  text-align: center;
  line-height: 1;
}
.txt_title {
  margin-top: 20px;
  color: white;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 1;
}
.title3 {
  color: white;
  font-size: 28px;
  width: 100%;
  text-align: center;
  line-height: 1;
  margin-top: 80px;
}
.title4 {
  color: #aede00;
  font-size: 25px;
  width: 100%;
  text-align: left;
}
.div_direita {
  text-align: right;
}
.descricao1 {
  color: white;
  font-size: 20px;
  line-height: 1;
  text-align: left;
  font-weight: 400;
}
.not1 {
  width: 100%;
  margin-top: 95px;
}
.title5 {
  color: #aede00;
  font-size: 25px;
  width: 100%;
  text-align: right;
}
.descricao2 {
  color: white;
  font-size: 20px;
  line-height: 1;
  text-align: right;
  font-weight: 400;
}
.not2 {
  width: 100%;
  margin-top: 95px;
}
.icon {
  margin-right: 10px;
  font-size: large;
  color: white;
  background: #aede00;
  border: #aede00 solid 1px;
  border-radius: 10px;
  margin-top: 100px;
}
.icon2 {
  font-size: large;
  color: white;
  background: #aede00;
  border: #aede00 solid 1px;
  border-radius: 10px;
  margin-top: 100px;
}
.coluna {
  position: relative;
  padding: 20px;
  height: 350px;
  border-radius: 35px 0px;
  border: #aede00 solid 5px;
  margin-top: 100px;
  margin-bottom: 100px;
  background-color: white;
}
.btn_linha {
  border: #505050 1px solid;
  border-radius: 8px;
  text-align: right;
  padding: 10px;
  margin-top: 30px;
  width: 90%;
}
.btn {
  font-family: "Montserrat";
  font-size: 11px;
  font-style: normal;
  text-align: center;
  color: white;
  cursor: pointer;
  background-color: #034aa6;
  font-weight: bold;
}
.btn:hover {
  background-color: #034aa6;
}
.btn span {
  position: relative;
  transition: 0.2s;
}
.btn span:after {
  margin-top: 0px;
  content: "\2794";
  position: absolute;
  opacity: 0;
  top: 0px;
  right: -10px;
  transition: 0.2s;
  color: white;
}
.btn:hover span {
  padding-right: 10px;
  color: white;
}
.btn:hover span:after {
  opacity: 1;
  right: 1;
}
.metade {
  width: 60%;
  padding: 30px;
}
.men1 {
  position: absolute;
  width: 260px;
  height: 380px;
  right: 20px;
  top: -38px;
}
.title6 {
  color: #252525;
  font-weight: bold;
  font-size: 35px;
}
.img_whats {
  z-index: 100;
  position: fixed;
  right: 25px;
  width: 60px;
}
/*----MODAL----*/
.svg_fechar {
  color: rgb(0, 0, 0);
  width: 100%;
  height: 50px;
}
.btn_transparent {
  background: transparent;
  float: right;
  border: none;
}
.modal_h1 {
  font-family: "Exo";
  color: #000000;
  font-size: 18pt;
  line-height: 1;
}
.modal_h2 {
  font-family: "Exo";
  color: #aede00;
  font-size: 20pt;
}
.p_modal {
  font-family: "Exo";
  color: #000;
  font-size: 14pt;
  margin-top: 0.4rem;
  margin-bottom: 0.3rem;
}
.btn_enviar_modal {
  font-family: "Montserrat";
  font-style: normal;
  text-align: center;
  color: white;
  cursor: pointer;
  border-radius: 15px;
  padding: 10px 8px;
  background-color: #034aa6;
  border: #034aa6 solid 1px;
  font-weight: 600;
}
.btn_enviar_modal:hover {
  background-color: #00367d;
  border: #00367d solid 1px;
}
.div_btn_modal {
  width: 100%;
  text-align: center;
  margin-top: 15px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .not1 {
    margin-top: 150px;
  }
  .not2 {
    margin-top: 150px;
  }
  .title6 {
    font-size: 29px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .title1 {
    font-size: 30px;
  }
  .title2 {
    font-size: 30px;
  }
  .title3 {
    font-size: 30px;
    margin-top: 50px;
  }
  .title4 {
    font-size: 20px;
  }
  .descricao1 {
    font-size: 15px;
  }
  .title5 {
    font-size: 20px;
  }
  .descricao2 {
    font-size: 15px;
  }
  .icon {
    margin-top: 50px;
  }
  .icon {
    margin-top: 50px;
  }
  .not2 {
    margin-top: 155px;
  }
  .title6 {
    font-size: 25px;
  }
  .men1 {
    width: 250px;
    height: 380px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .title1 {
    font-size: 35px;
  }
  .title2 {
    font-size: 35px;
  }
  .txt_title {
    font-size: 15px;
  }
  .title3 {
    font-size: 25px;
    margin-top: 40px;
  }
  .icon {
    display: none;
  }
  .icon2 {
    display: none;
  }
  .title4 {
    font-size: 19px;
    width: 100%;
    text-align: center;
    margin-top: 40px;
  }
  .descricao1 {
    font-size: 16px;
    text-align: center;
  }
  .not1 {
    margin-top: 40px;
  }
  .not2 {
    margin-top: 40px;
  }
  .title5 {
    font-size: 19px;
    width: 100%;
    text-align: center;
    margin-top: 40px;
  }
  .descricao2 {
    font-size: 16px;
    text-align: center;
  }
  .men1 {
    position: absolute;
    width: 140px;
    height: 200px;
    right: 20px;
    top: 41px;
  }
  .coluna {
    padding: 10px;
    height: 250px;
  }
  .title6 {
    font-size: 20px;
  }
  .btn_linha {
    margin-top: 20px;
  }
  .btn {
    font-size: 9px;
  }
  .coluna {
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media (max-width: 575px) {
  .icon {
    display: none;
  }
  .icon2 {
    display: none;
  }
  .title1 {
    font-size: 25px;
    text-align: center;
  }
  .title2 {
    font-size: 25px;
    text-align: center;
  }
  .title3 {
    font-size: 23px;
    margin-top: 40px;
    text-align: center;
  }
  .txt_title {
    text-align: center;
    font-size: 15px;
  }
  .title4 {
    font-size: 15px;
    text-align: center;
    margin-top: 30px;
  }
  .title5 {
    font-size: 15px;
    text-align: center;
    margin-top: 30px;
  }
  .descricao1 {
    font-size: 15px;
    text-align: center;
  }
  .descricao2 {
    font-size: 15px;
    text-align: center;
  }
  .not1 {
    margin-top: 25px;
  }
  .not2 {
    margin-top: 25px;
  }
  .coluna {
    padding: 10px;
    height: 250px;
  }
  .title6 {
    font-size: 20px;
  }
  .coluna {
    margin-left: 20px;
    margin-right: 20px;
  }
  .men1 {
    width: 110px;
    height: 150px;
    right: 20px;
    top: 91px;
  }
  .title6 {
    font-size: 15px;
  }
  .btn {
    font-size: 9px;
  }
  .btn_linha {
    width: 100%;
    padding: 15px;
  }
}
</style>