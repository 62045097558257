<template>
  <barra-lateral />
  <div
    style="background-color: #f8f9fe; min-height: 100vh; padding-bottom: 30px"
  >
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <topo
            :titulo="'Despesas Fixas'"
            @openOffcanvas="openOffcanvas"
          ></topo>
          <div class="row">
            <div class="col-lg-3">
              <p class="bv" v-if="state.idioma == 'PT'">Clientes</p>
            </div>
          </div>
          <div class="row div_grafico">
            <div class="col-xl-4 col-lg-12 mt-4 mt-xl-0">
              <div class="card">
                <div>
                  <div class="row align-items-center">
                    <div class="col" v-if="state.idioma == 'PT'">
                      <h6 class="text-light text-uppercase ls-1 mb-1">
                        Frequência
                      </h6>
                    </div>
                    <div class="col" v-else>
                      <h6 class="text-light text-uppercase ls-1 mb-1">
                        Frequência
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="chart-area">
                  <canvas :height="250" id="chartFrequenciaAlunos"></canvas>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-8 mt-4 mt-xl-0">
              <div class="card">
                <div>
                  <div class="row align-items-center">
                    <div class="col" v-if="state.idioma == 'PT'">
                      <h6 class="text-light text-uppercase ls-1 mb-1">Idade</h6>
                    </div>
                    <div class="col" v-else>
                      <h6 class="text-light text-uppercase ls-1 mb-1">Idade</h6>
                    </div>
                  </div>
                </div>
                <div class="chart-area">
                  <canvas :height="250" id="chartIdadeAlunos"></canvas>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 mt-4 mt-xl-0">
              <div class="card">
                <div>
                  <div class="row align-items-center">
                    <div class="col" v-if="state.idioma == 'PT'">
                      <h6 class="text-light text-uppercase ls-1 mb-1">Sexo</h6>
                    </div>
                    <div class="col" v-else>
                      <h6 class="text-light text-uppercase ls-1 mb-1">Sexo</h6>
                    </div>
                  </div>
                </div>
                <div class="chart-area">
                  <canvas :height="250" id="chartSexoAlunos"></canvas>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3">
              <p class="bv" v-if="state.idioma == 'PT'">Matrículas</p>
            </div>
          </div>
          <div class="row div_grafico">
            <div class="col-xl-9 col-lg-8">
              <div class="card">
                <div>
                  <div class="row align-items-center">
                    <div class="col" v-if="state.idioma == 'PT'">
                      <h6 class="text-light text-uppercase ls-1 mb-1">
                        Por Mês
                      </h6>
                    </div>
                    <div class="col" v-else>
                      <h6 class="text-light text-uppercase ls-1 mb-1">
                        Por Mês
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="chart-area">
                  <canvas :height="250" id="chartMatriculasMes"></canvas>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 mt-4 mt-lg-0">
              <div class="card">
                <div>
                  <div class="row align-items-center">
                    <div class="col" v-if="state.idioma == 'PT'">
                      <h6 class="text-light text-uppercase ls-1 mb-1">
                        Por Plano
                      </h6>
                    </div>
                    <div class="col" v-else>
                      <h6 class="text-light text-uppercase ls-1 mb-1">
                        Por Plano
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="chart-area">
                  <canvas :height="250" id="chartMatriculasPlano"></canvas>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3">
              <p class="bv" v-if="state.idioma == 'PT'">Contas a Receber</p>
            </div>
          </div>
          <div class="row div_grafico">
            <div class="col-xl-12">
              <div class="card">
                <div>
                  <div class="row align-items-center">
                    <div class="col" v-if="state.idioma == 'PT'">
                      <h6 class="text-light text-uppercase ls-1 mb-1">
                        Por Mês
                      </h6>
                    </div>
                    <div class="col" v-else>
                      <h6 class="text-light text-uppercase ls-1 mb-1">
                        Por Mês
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="chart-area">
                  <canvas :height="250" id="chartContasReceber"></canvas>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3">
              <p class="bv" v-if="state.idioma == 'PT'">Cancelamentos</p>
            </div>
          </div>
          <div class="row div_grafico">
            <div class="col-xl-12">
              <div class="card">
                <div>
                  <div class="row align-items-center">
                    <div class="col" v-if="state.idioma == 'PT'">
                      <h6 class="text-light text-uppercase ls-1 mb-1">
                        Por Mês
                      </h6>
                    </div>
                    <div class="col" v-else>
                      <h6 class="text-light text-uppercase ls-1 mb-1">
                        Por Mês
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="chart-area">
                  <canvas :height="250" id="chartCancelamentos"></canvas>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3">
              <p class="bv" v-if="state.idioma == 'PT'">Avaliações</p>
            </div>
          </div>
          <div class="row div_grafico">
            <div class="col-xl-9 col-lg-8 mt-4 mt-xl-0">
              <div class="card">
                <div>
                  <div class="row align-items-center">
                    <div class="col" v-if="state.idioma == 'PT'">
                      <h6 class="text-light text-uppercase ls-1 mb-1">
                        Por Mês
                      </h6>
                    </div>
                    <div class="col" v-else>
                      <h6 class="text-light text-uppercase ls-1 mb-1">
                        Por Mês
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="chart-area">
                  <canvas :height="250" id="chartAvaliacaoMes"></canvas>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 mt-4 mt-xl-0">
              <div class="card">
                <div>
                  <div class="row align-items-center">
                    <div class="col" v-if="state.idioma == 'PT'">
                      <h6 class="text-light text-uppercase ls-1 mb-1">
                        Por Personal
                      </h6>
                    </div>
                    <div class="col" v-else>
                      <h6 class="text-light text-uppercase ls-1 mb-1">
                        Por Personal
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="chart-area">
                  <canvas :height="250" id="chartAvaliacaoPersonal"></canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <OffcanvasComponent ref="offcanvasRef" />
</template>
<script>
import { onMounted, reactive, ref } from "vue";
import services from "../services";
import { useStorage } from "vue3-storage";
import topo from "../components/topo";
import BarraLateral from "../layout/Sistema/barraLateral";
import OffcanvasComponent from "../layout/Sistema/barraLateralMobile";

import {
  Chart,
  ArcElement,
  DoughnutController,
  BarController,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
Chart.register(
  ArcElement,
  DoughnutController,
  BarController,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
);
export default {
  components: {
    topo,
    BarraLateral,
    OffcanvasComponent,
  },
  setup() {
    const isVisible = ref(false);
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");
    const offcanvasRef = ref(null);

    const state = reactive({
      idioma: "PT",
    });
    onMounted(() => {
      montaCharFrequenciaAlunos();
      montaChartIdadeAlunos();
      montaChartSexo();
      montaChartContasReceber();
      montaChartCancelamentos();
      montaChartMatriculaPorMes();
      montaChartMatriculaPorPlano();
      montaChartAvaliacaoPorMes();
      montaChartAvaliacaoPorPersonal();
    });
    async function montaCharFrequenciaAlunos() {
      var data = await services.home.frequenciaAlunos({
        token: token,
        empresa_id,
      });

      var titulo = [];
      var dados = [];

      for (let i = 0; i < data.data.length; i++) {
        const element = data.data[i];
        titulo.push(element.dias_na_semana);
        dados.push(element.quantidade_pessoas);
      }
      //   var vezes = ["1", "2", "3", "4", "5", "6"];
      //  dados = [20, 50, 10, 40, 30, 10];
      new Chart(document.querySelector("#chartFrequenciaAlunos"), {
        type: "bar",
        data: {
          labels: titulo,
          datasets: [
            {
              data: dados,
              backgroundColor: "#fff",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            // Desabilita a legenda
            legend: {
              display: false, // Legenda desativada
            },
          },

          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
          },
          scales: {
            x: {
              ticks: {
                color: "#fff",
              },

              display: true,
              scaleLabel: {
                display: true,
                labelString: "Point",
              },
            },
            y: {
              ticks: {
                color: "#fff",
              },

              display: true,
              scaleLabel: {
                display: true,
                labelString: "Point",
              },
            },
          },
        },
      });
    }
    const openOffcanvas = () => {
      // Estado para controlar a visibilidade do offcanvas
      console.log(isVisible.value);
      //offcanvasRef.value.showOffcanvas();
      if (isVisible.value) {
        offcanvasRef.value.hideOffcanvas();
      } else {
        offcanvasRef.value.showOffcanvas();
      }
    };

    async function montaChartIdadeAlunos() {
      var data = await services.home.faixaEtaria({
        token: token,
        empresa_id,
      });
      var faixa_etaria = [];
      var dados = [];
      for (let i = 0; i < data.data.length; i++) {
        const element = data.data[i];
        faixa_etaria.push(element.faixa_etaria);
        dados.push(element.total);
      }

      new Chart(document.querySelector("#chartIdadeAlunos"), {
        type: "bar",
        data: {
          labels: faixa_etaria,
          datasets: [
            {
              data: dados,
              backgroundColor: "#fff",
            },
          ],
        },
        chartOptions: {
          responsive: true,
          plugins: {
            // Desabilita a legenda
            legend: {
              display: false, // Legenda desativada
            },
          },
          scales: {
            x: {
              ticks: {
                color: "#fff",
              },
              beginAtZero: true,
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Point",
              },
            },
            y: {
              ticks: {
                color: "#fff",
              },
              beginAtZero: true,
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Point",
              },
            },
          },
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            // Desabilita a legenda
            legend: {
              display: false, // Legenda desativada
            },
          },
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
          },
          scales: {
            x: {
              ticks: {
                color: "#fff",
              },
              beginAtZero: true,
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Point",
              },
            },
            y: {
              ticks: {
                color: "#fff",
              },
              beginAtZero: true,
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Point",
              },
            },
          },
        },
      });
    }
    //montaChartMatriculaPorMes
    async function montaChartSexo() {
      var data = await services.home.sexo({
        token: token,
        empresa_id,
      });
      var sexo = [];
      var dados = [];
      for (let i = 0; i < data.data.length; i++) {
        const element = data.data[i];
        sexo.push(element.sexo);
        dados.push(element.porcentagem);
      }

      new Chart(document.querySelector("#chartSexoAlunos"), {
        type: "pie",
        data: {
          labels: sexo,
          datasets: [
            {
              data: dados,
              backgroundColor: ["rgb(3, 74, 166)", "#fff"],
              borderWidth: 0, // Define a largura da borda como 0
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true, // Legenda visível
              position: "top", // Posição da legenda (top, bottom, left, right)
              labels: {
                color: "#fff", // Cor do texto da legenda usando hexadecimal (azul violeta)
                font: {
                  size: 14, // Tamanho da fonte da legenda
                },
                boxWidth: 16, // Largura da caixa de cor (quadrado)
                boxHeight: 16, // Altura da caixa de cor
              },
            },
          },
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
          },

          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
            },
            /*
            yAxes: [
              {
                display: false,
                ticks: {
                  beginAtZero: true,
                },
              },
            ],*/
          },
        },
      });
    }

    async function montaChartMatriculaPorMes() {
      var data = await services.home.matriculasPorMes({
        token: token,
        empresa_id,
      });
      var titulo = [];
      var dados = [];
      for (let i = 0; i < data.data.length; i++) {
        const element = data.data[i];
        titulo.push(element.nome_mes);
        dados.push(element.total_matriculas);
      }

      new Chart(document.querySelector("#chartMatriculasMes"), {
        type: "bar",
        data: {
          labels: titulo,
          datasets: [
            {
              data: dados,
              backgroundColor: "#fff",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            // Desabilita a legenda
            legend: {
              display: false, // Legenda desativada
            },
          },
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
          },

          scales: {
            x: {
              ticks: {
                color: "#fff",
              },
              beginAtZero: true,
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Point",
              },
            },
            y: {
              ticks: {
                color: "#fff",
              },
              beginAtZero: true,
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Point",
              },
            },
          },
        },
      });
    }

    async function montaChartMatriculaPorPlano() {
      var data = await services.home.matriculasPorPlano({
        token: token,
        empresa_id,
      });
      var titulo = [];
      var dados = [];
      var cores = [];
      for (let i = 0; i < data.data.length; i++) {
        const element = data.data[i];
        titulo.push(element.nome);
        dados.push(element.porcentagem_clientes);
        const letters = "0123456789ABCDEF";
        let color = "#";
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        cores.push(color);
      }

      new Chart(document.querySelector("#chartMatriculasPlano"), {
        type: "pie",
        data: {
          labels: titulo,
          datasets: [
            {
              data: dados,
              backgroundColor: cores,
            },
          ],
        },

        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          plugins: {
            legend: {
              display: true, // Legenda visível
              position: "top", // Posição da legenda (top, bottom, left, right)
              align: "start", // Alinha os itens à esquerda
              labels: {
                align: "start", // Alinha os itens à esquerda
                color: "#fff", // Cor do texto da legenda usando hexadecimal (azul violeta)
                font: {
                  size: 14, // Tamanho da fonte da legenda
                },
                boxWidth: 16, // Largura da caixa de cor (quadrado)
                boxHeight: 16, // Altura da caixa de cor
              },
            },
          },
          scales: {
            yAxes: [
              {
                display: false,
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    }

    //aa

    async function montaChartContasReceber() {
      var data = await services.home.contasReceberPorMes({
        token: token,
        empresa_id,
      });
      var titulo = [];
      var dados1 = [];
      var dados2 = [];
      for (let i = 0; i < data.data.receber.length; i++) {
        const element = data.data.receber[i];
        titulo.push(element.mes);
        dados1.push(element.total_por_status);
      }

      for (let i = 0; i < data.data.recebidas.length; i++) {
        const element = data.data.recebidas[i];
        dados2.push(element.total_por_status);
      }

      /*   const lbl = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
      ];
      const data1 = [20, 35, 40, 25, 30, 20, 15];
      const data2 = [15, 25, 30, 20, 25, 10, 21];
*/
      new Chart(document.querySelector("#chartContasReceber"), {
        type: "bar",
        data: {
          labels: titulo,
          datasets: [
            {
              data: dados1,
              backgroundColor: "rgba(255, 99, 132, 0.2)", // cor de fundo da primeira série
              borderColor: "rgba(255, 99, 132, 1)", // cor da borda da primeira série
            },
            {
              data: dados2,
              backgroundColor: "rgba(54, 162, 235, 0.2)", // cor de fundo da segunda série
              borderColor: "rgba(255, 255, 255, 1)", // cor da borda da segunda série
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            // Desabilita a legenda
            legend: {
              display: false, // Legenda desativada
            },
          },
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
          },
          scales: {
            x: {
              ticks: {
                color: "#fff",
              },
              beginAtZero: true,
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Point",
              },
            },
            y: {
              ticks: {
                color: "#fff",
              },
              beginAtZero: true,
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Point",
              },
            },
          },
        },
      });
    }

    async function montaChartCancelamentos() {
      var data = await services.home.contasReceberPorMes({
        token: token,
        empresa_id,
      });
      return;
      console.log(data.data[0]);
      var titulo = [];
      var dados = [];
      for (let i = 0; i < data.data[0].length; i++) {
        const element = data.data[i];
        titulo.push(element.mes);
        dados.push(element.quantidade);
      }

      const lbl = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
      ];
      const data1 = [20, 35, 40, 25, 30, 20, 15];

      new Chart(document.querySelector("#chartCancelamentos"), {
        type: "bar",
        data: {
          labels: lbl,
          datasets: [
            {
              data: data1,
              backgroundColor: "rgba(255, 99, 132, 0.2)", // cor de fundo da primeira série
              borderColor: "rgba(255, 99, 132, 1)", // cor da borda da primeira série
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  fontColor: "#fff",
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  fontColor: "#fff",
                },
              },
            ],
          },
        },
      });
    }

    async function montaChartAvaliacaoPorMes() {
      var data = await services.home.avaliacoesPorMes({
        token: token,
        empresa_id,
      });
      var titulo = [];
      var dados = [];
      for (let i = 0; i < data.data.length; i++) {
        const element = data.data[i];
        titulo.push(element.mes);
        dados.push(element.quantidade);
      }

      new Chart(document.querySelector("#chartAvaliacaoMes"), {
        type: "bar",
        data: {
          labels: titulo,
          datasets: [
            {
              data: dados,
              backgroundColor: "#fff",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            // Desabilita a legenda
            legend: {
              display: false, // Legenda desativada
            },
          },
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
          },
          scales: {
            x: {
              ticks: {
                color: "#fff",
              },
              beginAtZero: true,
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Point",
              },
            },
            y: {
              ticks: {
                color: "#fff",
              },
              beginAtZero: true,
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Point",
              },
            },
          },
        },
      });
    }

    async function montaChartAvaliacaoPorPersonal() {
      var data = await services.home.avaliacoesPorPersonal({
        token: token,
        empresa_id,
      });
      var hora = [];
      var dados = [];
      var cores = [];
      for (let i = 0; i < data.data.length; i++) {
        const element = data.data[i];
        hora.push(element.nome);
        dados.push(element.percentual);

        const letters = "0123456789ABCDEF";
        let color = "#";
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        cores.push(color);
      }

      new Chart(document.querySelector("#chartAvaliacaoPersonal"), {
        type: "pie",
        data: {
          labels: hora,
          datasets: [
            {
              data: dados,
              backgroundColor: cores,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          plugins: {
            legend: {
              display: true, // Legenda visível
              position: "top", // Posição da legenda (top, bottom, left, right)
              align: "start", // Alinha os itens à esquerda
              labels: {
                align: "start", // Alinha os itens à esquerda
                color: "#fff", // Cor do texto da legenda usando hexadecimal (azul violeta)
                font: {
                  size: 14, // Tamanho da fonte da legenda
                },
                boxWidth: 16, // Largura da caixa de cor (quadrado)
                boxHeight: 16, // Altura da caixa de cor
              },
            },
          },
          /*
          scales: {
            yAxes: [
              {
                display: false,
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },*/
        },
      });
    }
    return {
      state,
      offcanvasRef,
      openOffcanvas,
    };
  },
};
</script>
<style scoped>
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #172b4d !important;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
}

.bv {
  font-weight: bold;
  font-size: 18pt;
  color: #162b48;
  margin-bottom: 0;
}
</style>