<template>
  <barra-lateral />
  <OffcanvasComponent ref="offcanvasRef" />

  <div style="background-color: #f8f9fe; min-height: 100vh">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <topo :titulo="'Funções'" @openOffcanvas="openOffcanvas"></topo>
          <div class="container-fluid mt-3">
            <div class="row">
              <div class="col-sm-2">
                <input type="text" class="form-control" placeholder="Código" />
              </div>
              <div class="col-sm-6">
                <input placeholder="Nome" type="text" class="form-control" v-model="state.busca" />
              </div>
              <div class="col-sm-2">
                <button type="primary" class="btn_busca">Buscar</button>
              </div>
              <div class="col-sm-2">
                <button type="primary" class="btn_add" @click="abrirModal">
                  Adicionar
                </button>
              </div>
            </div>
            <div class="card shadow p-3" :class="type === 'dark' ? 'bg-default' : ''">
              <div class="row">
                <div class="col">
                  <div class="table-responsive">
                    <table class="table tablesorter table align-items-center table-flush">
                      <thead class="thead-light">
                        <th>Código</th>
                        <th>Nome</th>
                        <th></th>
                      </thead>
                      <tbody class="list">
                        <corpo-tabela-funcao v-for="funcao in state.funcoes" :key="funcao.id" :funcao="funcao"
                          @deletarFuncao="deletarFuncao" @modalPermissao="modalPermissao" @qtfFuncao="qtdFun" />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal :open="state.status_modal" @close="state.status_modal = !state.status_modal" :width="'800px'">
    <h1>Função</h1>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label>Código</label>
          <input type="text" class="form-control" v-model="state.id" readonly />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>Nome</label>
          <input type="text" class="form-control" v-model="state.nome" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label>Tipo de Funcionário</label>
          <select class="form-select" v-model="state.tipo_funcionario">
            <option value="interno">Interno</option>
            <option value="externo">Externo</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label>Personal</label>
          <select class="form-select" v-model="state.personal">
            <option value="sim">Sim</option>
            <option value="nao">Não</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label>Avaliador</label>
          <select class="form-select" v-model="state.avaliador">
            <option value="sim">Sim</option>
            <option value="nao">Não</option>
          </select>
        </div>
      </div>
    </div>

    <button class="btn btn_salvar my-4" id="submit-button" type="submit" @click="salvarFuncao()">
      Salvar
    </button>
    <button class="btn btn_cancelar my-4" id="submit-button" type="button" @click="fecharFuncao()">
      Cancelar
    </button>
  </Modal>
  <Modal :open="state.status_modal_permissao" @close="state.status_modal_permissao = !state.status_modal_permissao"
    :width="'80%'">
    <h1>Permissões</h1>
    <div class="row">
      <div class="col-sm-3">
        <h4>{{ state.categorias_permissao[0].nome_categoria }}</h4>
        <ul>
          <li v-for="permissao in state.categorias_permissao[0].permissoes" :key="permissao.id">
            <input type="checkbox" :id="'permissao' + permissao.id" v-model="state.permissoes[String(permissao.id)]" />
            <label :for="'permissao' + permissao.id">
              {{ permissao.nome_permissao }} {{ permissao.id }}
            </label>
          </li>
        </ul>
        <h4>{{ state.categorias_permissao[3].nome_categoria }}</h4>
        <ul>
          <li v-for="permissao in state.categorias_permissao[3].permissoes" :key="permissao.id">
            <input type="checkbox" :id="'permissao' + permissao.id" v-model="state.permissoes[String(permissao.id)]" />
            <label :for="'permissao' + permissao.id">
              {{ permissao.nome_permissao }}
            </label>
          </li>
        </ul>
        <h4>{{ state.categorias_permissao[8].nome_categoria }}</h4>
        <ul>
          <li v-for="permissao in state.categorias_permissao[8].permissoes" :key="permissao.id">
            <input type="checkbox" :id="'permissao' + permissao.id" v-model="state.permissoes[String(permissao.id)]" />
            <label :for="'permissao' + permissao.id">
              {{ permissao.nome_permissao }}
            </label>
          </li>
        </ul>
      </div>
      <div class="col-sm-3">
        <h4>{{ state.categorias_permissao[10].nome_categoria }}</h4>
        <ul>
          <li v-for="permissao in state.categorias_permissao[10].permissoes" :key="permissao.id">
            <input type="checkbox" :id="'permissao' + permissao.id" v-model="state.permissoes[String(permissao.id)]" />
            <label :for="'permissao' + permissao.id">
              {{ permissao.nome_permissao }}
            </label>
          </li>
        </ul>

        <h4>{{ state.categorias_permissao[7].nome_categoria }}</h4>
        <ul>
          <li v-for="permissao in state.categorias_permissao[7].permissoes" :key="permissao.id">
            <input type="checkbox" :id="'permissao' + permissao.id" v-model="state.permissoes[String(permissao.id)]" />
            <label :for="'permissao' + permissao.id">
              {{ permissao.nome_permissao }}
            </label>
          </li>
        </ul>
      </div>
      <div class="col-sm-3">
        <h4>{{ state.categorias_permissao[4].nome_categoria }}</h4>
        <ul>
          <li v-for="permissao in state.categorias_permissao[4].permissoes" :key="permissao.id">
            <input type="checkbox" :id="'permissao' + permissao.id" v-model="state.permissoes[String(permissao.id)]" />
            <label :for="'permissao' + permissao.id">
              {{ permissao.nome_permissao }}
            </label>
          </li>
        </ul>

        <h4>{{ state.categorias_permissao[5].nome_categoria }}</h4>
        <ul>
          <li v-for="permissao in state.categorias_permissao[5].permissoes" :key="permissao.id">
            <input type="checkbox" :id="'permissao' + permissao.id" v-model="state.permissoes[String(permissao.id)]" />
            <label :for="'permissao' + permissao.id">
              {{ permissao.nome_permissao }}
            </label>
          </li>
        </ul>
        <h4>{{ state.categorias_permissao[11].nome_categoria }}</h4>
        <ul>
          <li v-for="permissao in state.categorias_permissao[11].permissoes" :key="permissao.id">
            <input type="checkbox" :id="'permissao' + permissao.id" v-model="state.permissoes[String(permissao.id)]" />
            <label :for="'permissao' + permissao.id">
              {{ permissao.nome_permissao }}
            </label>
          </li>
        </ul>
      </div>
      <div class="col-sm-3">
        <h4>{{ state.categorias_permissao[13].nome_categoria }}</h4>
        <ul>
          <li v-for="permissao in state.categorias_permissao[13].permissoes" :key="permissao.id">
            <input type="checkbox" :id="'permissao' + permissao.id" v-model="state.permissoes[String(permissao.id)]" />
            <label :for="'permissao' + permissao.id">
              {{ permissao.nome_permissao }}
            </label>
          </li>
        </ul>
        <h4>{{ state.categorias_permissao[14].nome_categoria }}</h4>
        <ul>
          <li v-for="permissao in state.categorias_permissao[14].permissoes" :key="permissao.id">
            <input type="checkbox" :id="'permissao' + permissao.id" v-model="state.permissoes[String(permissao.id)]" />
            <label :for="'permissao' + permissao.id">
              {{ permissao.nome_permissao }}
            </label>
          </li>
        </ul>
        <h4>{{ state.categorias_permissao[2].nome_categoria }}</h4>
        <ul>
          <li v-for="permissao in state.categorias_permissao[2].permissoes" :key="permissao.id">
            <input type="checkbox" :id="'permissao' + permissao.id" v-model="state.permissoes[String(permissao.id)]" />
            <label :for="'permissao' + permissao.id">
              {{ permissao.nome_permissao }}
            </label>
          </li>
        </ul>
      </div>
    </div>

    <button class="btn btn_salvar my-4" id="submit-button" type="submit" @click="salvarPermissoes()">
      Salvar
    </button>
    <button class="btn btn_cancelar my-4" id="submit-button" type="button" @click="fecharPermissoes()">
      Cancelar
    </button>
  </Modal>
  <Modal :open="state.status_modal_funcao" @close="state.status_modal_funcao = !state.status_modal_funcao"
    :width="'800px'">
    <h1>Função</h1>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label>Código</label>
          <input type="text" class="form-control" v-model="state.funcao.id" readonly />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>Nome</label>
          <input type="text" class="form-control" readonly v-model="state.funcao.nome" />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group">
          <label>Tipo do Funcionário</label>
          <input type="text" class="form-control" readonly v-model="state.funcao.tipo_funcionario" />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group">
          <label>Avaliador</label>
          <input type="text" class="form-control" readonly v-model="state.funcao.avaliador" />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group">
          <label>Personal</label>
          <input type="text" class="form-control" readonly v-model="state.funcao.personal" />
        </div>
      </div>
    </div>
    <button class="btn btn_cancelar my-4" id="submit-button" type="button" @click="fecharFuncaoQtd()">
      Voltar
    </button>
  </Modal>
</template>
<script>
import { onMounted, reactive, ref } from "vue";
import Modal from "../components/EstruturaModal/EstruturaModal.vue";
import { useStorage } from "vue3-storage";
import CorpoTabelaFuncao from "../components/CorpoTabelaFuncao";
import services from "../services";
import topo from "../components/topo";
import BarraLateral from "../layout/Sistema/barraLateral";
import OffcanvasComponent from "../layout/Sistema/barraLateralMobile";

export default {
  components: {
    topo,
    Modal,
    BarraLateral,
    CorpoTabelaFuncao,
    OffcanvasComponent,
  },
  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");

    const state = reactive({
      id: "",
      nome: "",
      tipo_funcionario: "",
      avaliador: "",
      personal: "",
      funcoes: [],
      isOpen: false,
      status_modal: false,
      status_modal_permissao: false,
      status_modal_funcao: false,
      funcao: {},
      categorias_permissao: [
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
      ],
      permissoes: [],
    });

    onMounted(() => {
      fetchFuncao();
      buscarFunc();
    });

    async function abrirModal() {
      state.status_modal = true;
    }

    function fecharFuncao() {
      state.status_modal = false;
    }
    function fecharFuncaoQtd() {
      state.status_modal_funcao = false;
    }

    function modalPermissao() {
      fetchCategoriasPermissao();
      state.status_modal_permissao = true;
    }

    function fecharPermissoes() {
      state.status_modal_permissao = false;
    }

    async function fetchFuncao() {
      try {
        state.isLoading = true;
        const { data } = await services.funcao.getAll({
          empresa_id,
          token,
        });
        state.funcoes = data;
      } catch (error) {
        console.log(error);
      }
    }

    async function qtdFun(empresa_id, id) {
      state.status_modal_funcao = true;
      try {
        state.isLoading = true;
        const { data } = await services.funcao.qtdFuncao(
          token,
          empresa_id,
          id,
        );
        state.funcao = data;
      } catch (error) {
        console.log(error);
      }
    }

    async function buscarFunc() {
      try {
        state.isLoading = true;
        var categorias = await services.permissao.buscaCategoria({
          empresa_id,
          token,
        });
        for (let i = 0; i < categorias.data.length; i++) {
          const element = categorias.data[i];

          let permissao = await services.permissao.porCategoria({
            empresa_id,
            token,
            id_categoria: element.id,
          });
          categorias.data[i].permissoes = permissao.data;
        }
        state.categorias_permissao = categorias.data;
        console.log(state.categorias_permissao);
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchCategoriasPermissao() {
      try {
        state.isLoading = true;
        var categorias = await services.permissao.buscaCategoria({
          empresa_id,
          token,
        });
        for (let i = 0; i < categorias.data.length; i++) {
          const element = categorias.data[i];

          let permissao = await services.permissao.porCategoria({
            empresa_id,
            token,
            id_categoria: element.id,
          });
          categorias.data[i].permissoes = permissao.data;
        }
        state.categorias_permissao = categorias.data;
        console.log(state.categorias_permissao);
      } catch (error) {
        console.log(error);
      }
    }

    async function salvarPermissoes() {
      console.log(state.permissoes);
    }

    async function salvarFuncao() {
      const { errors } = await services.funcao.save({
        empresa_id,
        token,
        nome: state.nome,
        tipo_funcionario: state.tipo_funcionario,
        avaliador: state.avaliador,
        personal: state.personal,
      });
      if (errors) {
        alert("Erro ao salvar Função");
        console.log(errors);
      }
      state.status_modal = false;
      fetchFuncao();
    }
    const offcanvasRef = ref(null);
    const isVisible = ref(false);
    const openOffcanvas = () => {
      console.log(isVisible.value);
      if (isVisible.value) {
        offcanvasRef.value.hideOffcanvas();
      } else {
        offcanvasRef.value.showOffcanvas();
      }
    };
    return {
      fetchFuncao,
      state,
      Modal,
      abrirModal,
      salvarFuncao,
      modalPermissao,
      fecharFuncao,
      fecharPermissoes,
      fetchCategoriasPermissao,
      salvarPermissoes,
      offcanvasRef,
      fecharFuncaoQtd,
      openOffcanvas,
      qtdFun,
      //fecharModal,
    };
  },
};
</script>
<style scoped>
label {
  margin-bottom: 0;
  margin-left: 0.5rem;
}

input {
  border-color: #b8b8b8;
  border-width: 1px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
  margin: 10px;
}

input:focus {
  border-color: #252525;
}

select {
  border-color: #b8b8b8;
  border-width: 1px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
}

input[type="checkbox"] {
  margin: 0;
}

li {
  list-style-type: none;
  font-size: 10pt;
}

ul {
  padding-left: 0;
}

.btn_busca {
  font-size: 10pt;
  height: 40px;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 5px;
  background-color: rgb(3, 74, 166);
  border: none;
  color: #fff;
  font-weight: 700;
  margin-top: 10px;
}

.btn_add {
  transform: none;
  background: var(--Color-secundary, #aede00);
  color: rgb(255, 255, 255);
  border: 1px solid #aede00;
  border-radius: 5px;
  font-size: 9pt;
  padding: 10px;
  font-weight: 700;
  width: 100%;
  margin-top: 10px;
}

.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}

.btn_salvar:hover {
  transform: none;
  color: #fff;
}

.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}

.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}


@media (max-width: 767px) {
  .btn_busca {
    width: 100%;
    margin-top: 10px;
    text-align: center;
  }
}
</style>
