export default httpClient => ({
  getAll: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/categoria-contas-pagar/' + empresa_id, {
      token
    }, { headers })
    return { data: response.data }
  },
  getById: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/seleciona/categoria-contas-pagar/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },
  insert: async ({ token, empresa_id, nome }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/cria/categoria-contas-pagar/' + empresa_id, {
      nome,
      token
    }, { headers })
    return { data: response.data }
  },
  update: async ({ token, empresa_id, nome, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/edita/categoria-contas-pagar/' + empresa_id, {
      id,
      nome,
      token
    }, { headers })
    return { data: response.data }
  },
  deletar: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/deletar-cat-contas-pagar/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },
})