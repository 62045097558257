export default httpClient => ({
  listarNotificacoes: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/notificacoes/listar/' + empresa_id, { headers });
    return { data: response.data }
  },
  obterNotificacaoPorId: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/notificacoes/buscar/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },
  salvarNotificacao: async ({ token, empresa_id, mensagem }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/notificacoes/criar/' + empresa_id, {
      token,
      mensagem
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
    
});