export default httpClient => ({
  config: async ({ empresa_id, token }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/configuracoes/' + empresa_id, { headers })
    return { data: response.data }
  },
  buscaPlano: async ({ empresa_id, token, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/planos-sifit/' + empresa_id, {
      token: token,
      id: id
    }, { headers })
    return { data: response.data }
  },
  save: async ({
    token,
    empresa_id,
    plano_principal,
    desktop,
    cobrar_avaliacao,
    marca_aparelho_id,
    aparelho_biometria_id,
    cod_aparelho,
    porta_aparelho,
    ip_aparelho,
    login_aparelho,
    senha_aparelho,
    aparelho_separado,
    dias_bloqueio,
    dias_ausente,
    enviar_mensagens_aniversario,
    mensagem_whatsapp,
    mensagem_sms,
    mensagem_email,
    id,
    enviar_mensagens_aniversario_whats,
    enviar_mensagens_aniversario_sms,
    enviar_mensagens_aniversario_email,
    endpoint_whatsapp,
    token_whatsapp,
    email_origem_aniversario,
    telefone_origem_sms,
    pagarme,
    controle_acesso,
    token_pagarme,
  }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/configuracoes/alterar/' + empresa_id, {
      plano_principal,
      desktop,
      cobrar_avaliacao,
      marca_aparelho_id,
      aparelho_biometria_id,
      id,
      cod_aparelho,
      porta_aparelho,
      ip_aparelho,
      login_aparelho,
      senha_aparelho,
      aparelho_separado,
      dias_bloqueio,
      dias_ausente,
      enviar_mensagens_aniversario,
      mensagem_whatsapp,
      mensagem_sms,
      mensagem_email,
      enviar_mensagens_aniversario_whats,
      enviar_mensagens_aniversario_sms,
      enviar_mensagens_aniversario_email,
      endpoint_whatsapp,
      token_whatsapp,
      email_origem_aniversario,
      telefone_origem_sms,
      controle_acesso,
      pagarme,
      token_pagarme,
    }, { headers })
    return {
      data: response.data,
    }
  },
})