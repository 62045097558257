<template>
  <tr @click="handleToggle">

    <td>
      {{ presenca.horario }}
    </td>
  </tr>
</template>
<script>
import { reactive } from "vue";

export default {
  props: {
    isOpened: { type: Boolean, default: false },
    presenca: { type: Object, required: true },
  },
  setup(props) {
    const state = reactive({
      isOpen: props.isOpened,
      isClosing: !props.isOpened,
    });
    async function handleToggle() {
      state.isClosing = true;
      //  await wait(250)
      state.isOpen = !state.isOpen;
      state.isClosing = false;
    }
    return {
      state,
      handleToggle,
    };
  },
};
</script>
