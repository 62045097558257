import {
  Chart,
  ArcElement,
  DoughnutController,
  BarController,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
Chart.register(
  ArcElement,
  DoughnutController,
  BarController,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
);

export const linkChart = {
  createChart(chartId,gordura,massa_magra) {
    const chartColor = "#FFFFFF";
    const fallBackColor = "#f96332";
    const color = this.color || fallBackColor;
    const ctx = document.getElementById(chartId).getContext("2d");
    const gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
    gradientStroke.addColorStop(0, color);
    gradientStroke.addColorStop(1, chartColor);

    new Chart(ctx, {
      type: "pie",
      data: {
        labels: [ "Massa Magra","Gordura"],
        datasets: [
          {
            label: "IMC",
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 0,
          //  backgroundColor: "#fb6340",
            data: [massa_magra,gordura],
            backgroundColor: [
           '#AEDE00','#DDF1B9'
        ],
            maxBarThickness: 10,
          },
        ],
      },
      
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'right',
          align: 'start',
        },
        tooltips: {
          enabled: true,
          mode: "index",
          intersect: false,
        },
        scales: {


        },
      },
    });
  },
};

const funcs = {
  ordersChart() {},
};

export default funcs;
