<template>
    <option :value="plano.id"  :selected="value == '1'">{{plano.nome_plano}}</option>
</template>

<script>
import { reactive } from 'vue'

export default {
    props: {
        isOpened: { type: Boolean, default: false },
        plano: { type: Object, required: true }
    },
    setup(props) {
        const state = reactive({
            isOpen: props.isOpened,
            isClosing: !props.isOpened
        })

        async function handleToggle () {
        state.isClosing = true

      //  await wait(250)
        state.isOpen = !state.isOpen
        state.isClosing = false
        
      }
      return {
        state,
        handleToggle    
      }
    },
}
</script>