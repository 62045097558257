<template>
  <tr @click="handleToggle">
    <td>
      {{ colaborador.id }}
    </td>
    <td>
      {{ colaborador.nome }}
    </td>
    <td style="text-align: right">
      <button
        class="btn btn_chek"
        type="button"
        @click="$emit('carregarColaborador', colaborador.id, colaborador.nome)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-check"
          viewBox="0 0 16 16"
        >
          <path
            d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
          />
        </svg>
      </button>
    </td>
  </tr>
</template>
<script>
import { reactive } from "vue";
import colaboradores from "../../services/colaboradores";

export default {
  props: {
    isOpened: { type: Boolean, default: false },
    colaborador: { type: Object, required: true },
  },
  setup(props, { emit }) {
    const state = reactive({
      isOpen: props.isOpened,
      isClosing: !props.isOpened,
    });
    async function handleToggle() {
      state.isClosing = true;
      //  await wait(250)
      state.isOpen = !state.isOpen;
      state.isClosing = false;
    }
    return {
      state,
      handleToggle,
      emit,
      colaboradores,
    };
  },
  emits: ["buscarColaborador"],
};
</script>
<style scoped>
.btn_chek {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 1px;
  padding: 10px;
}
.btn_chek:hover{
  color: #ffff;
  transform: none;
}
</style>
