<template>
  <barra-lateral />
  <div style="background-color: #f8f9fe; margin-bottom: 20px">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <topo :titulo="'Cadastrar Cliente'"></topo>
          <div>
            <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-3">
            </base-header>

            <div class="container-fluid mt--7">
              <div class="row">
                <div class="col-xl-12 col-lg-12">
                  <stats-card>
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <router-link to="/dashboard">Home</router-link>
                        </li>
                        <li class="breadcrumb-item">
                          <router-link to="/clientes" class="primary"
                            >Clientes
                          </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                          Adicionar
                        </li>
                      </ol>
                    </nav>
                    <form @submit.prevent="handleSubmit">
                      <h2 v-if="state.idioma == 'PT'">Dados Principais</h2>
                      <h2 v-else>Datos principales</h2>

                      <div class="row">
                        <div class="col-sm-3 col-lg-2">
                          <div class="form-group">
                            <label>Código</label>
                            <input
                              type="text"
                              class="form-control"
                              readonly
                              v-model="state.id"
                            />
                          </div>
                        </div>
                        <div class="col-sm-9 col-lg-6">
                          <div class="form-group">
                            <label v-if="state.idioma == 'PT'">Nome</label>
                            <label v-else>Nombre</label>
                            <input
                              ref="inputNome"
                              type="text"
                              class="form-control"
                              v-model="state.nome.value"
                            />
                            <span
                              v-if="!!state.nome.errorMessage"
                              class="block font-medium text-danger"
                            >
                              {{ state.nome.errorMessage }}
                            </span>
                          </div>
                        </div>

                        <div class="col-sm-4 col-lg-2">
                          <div class="form-group">
                            <label>Status</label>
                            <select
                              class="form-select"
                              v-model="state.status.value"
                              v-if="state.idioma == 'PT'"
                              ref="inputStatus"
                            >
                              <option value="ativo">Ativo</option>
                              <option value="inativo">Inativo</option>
                            </select>

                            <select
                              class="form-select"
                              v-model="state.status.value"
                              v-else
                            >
                              <option value="ativo">Activo</option>
                              <option value="inativo">Inactivo</option>
                            </select>

                            <span
                              v-if="!!state.status.errorMessage"
                              class="block font-medium text-danger"
                            >
                              {{ state.status.errorMessage }}
                            </span>
                          </div>
                        </div>

                        <div class="col-sm-4 col-lg-3 d-lg-none d-xl-block">
                          <div class="form-group">
                            <label v-if="state.idioma == 'PT'"
                              >Data Nascimento</label
                            >
                            <label v-else>Fecha de nacimiento</label>

                            <input
                              type="text"
                              ref="inputDtNasc"
                              class="form-control"
                              v-model="state.data_nascimento.value"
                              v-mask="'##/##/####'"
                            />

                            <span
                              v-if="!!state.data_nascimento.errorMessage"
                              class="block font-medium text-danger"
                            >
                              {{ state.data_nascimento.errorMessage }}
                            </span>
                          </div>
                        </div>
                        <div class="col-sm-4 col-lg-3">
                          <div class="form-group">
                            <label>Sexo</label>
                            <select
                              name=""
                              id=""
                              ref="inputSexo"
                              class="form-select"
                              v-model="state.sexo.value"
                            >
                              <option value="masculino">Masculino</option>
                              <option value="feminino">Feminino</option>
                            </select>
                            <span
                              v-if="!!state.sexo.errorMessage"
                              class="block font-medium text-danger"
                            >
                              {{ state.sexo.errorMessage }}
                            </span>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="form-group">
                            <label v-if="state.idioma == 'PT'"
                              >Tipo Sanguíneo</label
                            >
                            <label v-else>Tipo de sangre</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="state.tipo_sangue.value"
                            />
                            <span
                              v-if="!!state.tipo_sangue.errorMessage"
                              class="block font-medium text-brand-danger"
                            >
                              {{ state.tipo_sangue.errorMessage }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <h2>Documentos</h2>
                      <div class="row">
                        <div class="col-sm-6 col-lg-5">
                          <div class="form-group">
                            <label>CPF</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="state.cpf.value"
                              v-mask="'###.###.###-##'"
                            />
                          </div>
                        </div>
                        <div class="col-sm-6 col-lg-5">
                          <div class="form-group">
                            <label>RG</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="state.rg.value"
                            />
                          </div>
                        </div>
                      </div>
                      <h2>Adicionar Foto</h2>
                      <div class="row">
                        <div class="col-lg-8">
                          <label>Imagem</label>
                          <input
                            type="file"
                            class="form-control"
                            @change="adicionarImagem"
                          />
                        </div>
                        <div
                          style="display: flex"
                          class="col-md-4"
                          v-if="
                            state.imagem &&
                            state.imagem != {} &&
                            state.imagem.imagem
                          "
                        >
                          <img :src="state.imagem.imagem" class="imagem" />
                          <button
                            class="btn_deletar_imagem"
                            @click="removerImagem"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="10"
                              fill="currentColor"
                              class="bi bi-x-lg mb-2"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <h2 v-if="state.idioma == 'PT'">Dados para Contato</h2>
                      <h2 v-else>Detalles de contacto</h2>

                      <div class="row">
                        <div class="col-sm-6 col-xl-3">
                          <div class="form-group">
                            <label v-if="state.idioma == 'PT'"
                              >Telefone Principal</label
                            >
                            <label v-else>Teléfono principal</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="state.telefone.value"
                              v-mask="['(##) ####-####', '(##) #####-####']"
                            />
                          </div>
                        </div>
                        <div class="col-sm-6 col-xl-3">
                          <div class="form-group">
                            <label v-if="state.idioma == 'PT'"
                              >Celular/WhatsApp</label
                            >
                            <label v-else>Teléfono móvil/WhatsApp</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="state.celular.value"
                              v-mask="['(##) ####-####', '(##) #####-####']"
                            />
                          </div>
                        </div>
                        <div class="col-sm-6 col-xl-3">
                          <div class="form-group">
                            <label v-if="state.idioma == 'PT'"
                              >Contato de Emergência</label
                            >
                            <label v-else>Contacto de emergencia</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="state.tel_emergencia.value"
                            />
                          </div>
                        </div>
                        <div class="col-xl-3">
                          <div class="form-group">
                            <label>E-mail</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="state.email.value"
                            />
                            <span
                              v-if="!!state.email.errorMessage"
                              class="block font-medium text-brand-danger"
                            >
                              {{ state.email.errorMessage }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <h2 v-if="state.idioma == 'PT'">Endereço</h2>
                      <h2 v-else>Dirección</h2>
                      <div class="row">
                        <div class="col-sm-6 col-lg-4">
                          <div class="form-group">
                            <label v-if="state.idioma == 'PT'">CEP</label>
                            <label v-else>Código postal</label>

                            <input
                              type="text"
                              class="form-control"
                              v-model="state.cep.value"
                              v-mask="'#####-###'"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6 col-lg-4">
                          <div class="form-group">
                            <label>País</label>
                            <select
                              class="form-select"
                              v-model="state.pais_id"
                              @change="selecionaPais($event.target.value)"
                            >
                              <select-paises
                                v-for="pais in state.paises"
                                :key="pais.id"
                                :pais="pais"
                              />
                            </select>
                          </div>
                        </div>
                        <div class="col-sm-6 col-lg-4">
                          <div class="form-group">
                            <label v-if="state.idioma == 'PT'">Estado</label>
                            <label v-else>Departamento</label>
                            <select
                              v-if="state.pais_id == 1"
                              class="form-select"
                              v-model="state.estado.value"
                              @change="fetchCidades($event.target.value)"
                            >
                              <select-estados
                                v-for="estado in state.estados"
                                :key="estado.id"
                                :estado="estado"
                              />
                            </select>
                            <select
                              v-else-if="state.pais_id == 2"
                              class="form-select"
                              v-model="state.estado.value"
                              @change="fetchDistritos($event.target.value)"
                            >
                              <select-estados
                                v-for="departamento in state.departamentos"
                                :key="departamento.id"
                                :estado="departamento"
                              />
                            </select>
                            <div v-else>
                              <input type="text" />
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-6 col-lg-4">
                          <div class="form-group">
                            <label v-if="state.idioma == 'PT'">Cidade</label>
                            <label v-else>Ciudad</label>
                            <select
                              class="form-select"
                              v-model="state.cidade_id"
                              v-if="state.pais_id == 1"
                            >
                              <option
                                v-for="cidade in state.cidades"
                                :key="cidade.id"
                                :value="cidade.id"
                              >
                                {{ cidade.nome }}
                              </option>
                            </select>
                            <select
                              class="form-select"
                              v-model="state.distrito_py_id"
                              v-if="state.pais_id == 2"
                            >
                              <option
                                v-for="distrito in state.distritos"
                                :key="distrito.id"
                                :value="distrito.id"
                              >
                                {{ distrito.nome }}
                              </option>
                            </select>
                          </div>
                        </div>

                        <div class="col-sm-8 col-lg-8">
                          <div class="form-group">
                            <label v-if="state.idioma == 'PT'">Endereço</label>
                            <label v-else>Dirección</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="state.endereco.value"
                            />
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Número</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="state.numero.value"
                            />
                          </div>
                        </div>
                        <div class="col-sm-6 col-lg-6 col-xl-3">
                          <div class="form-group">
                            <label v-if="state.idioma == 'PT'">Bairro</label>
                            <label v-else>Vecindario</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="state.bairro.value"
                            />
                          </div>
                        </div>

                        <div class="col-sm-12 col-lg-6 col-xl-3">
                          <div class="form-group">
                            <label v-if="state.idioma == 'PT'"
                              >Complemento</label
                            >
                            <label v-else>Complementar</label>

                            <input
                              type="text"
                              class="form-control"
                              v-model="state.complemento.value"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-6">
                          <h2>Biometria</h2>
                          <div class="row">
                            <div class="col-sm-4 col-lg-6">
                              <div class="form-group">
                                <label>Código</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="state.id_leitor"
                                />
                              </div>
                            </div>
                            <div class="col-sm-8 col-lg-6">
                              <div class="form-group">
                                <label v-if="state.idioma == 'PT'"
                                  >Sempre Liberado</label
                                >
                                <label v-else>Siempre libre</label>
                                <select
                                  class="form-select"
                                  v-model="state.sempre_aberto"
                                >
                                  <option value="sim">Sim</option>
                                  <option value="nao">Não</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <h2>Aplicativo</h2>
                          <div class="row">
                            <div class="col-sm-6 col-lg-6">
                              <div class="form-group">
                                <label>Usuário</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="state.login_app"
                                />
                              </div>
                            </div>
                            <div class="col-sm-6 col-lg-6">
                              <div class="form-group">
                                <label v-if="state.idioma == 'PT'">Senha</label>
                                <label v-else>Senha</label>
                                <input
                                  type="password"
                                  class="form-control"
                                  v-model="state.password_app"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h2>Dados do Responsável</h2>
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="row">
                            <div class="col-sm-8 col-lg-7">
                              <div class="form-group">
                                <label>Nome</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="state.nome_responsavel"
                                />
                              </div>
                            </div>
                            <div class="col-sm-4 col-lg-5">
                              <div class="form-group">
                                <label v-if="state.idioma == 'PT'"
                                  >Telefone</label
                                >
                                <label v-else>Senha</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="state.telefone_responsavel"
                                  v-mask="['(##) ####-####', '(##) #####-####']"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="row">
                            <div class="col-sm-6 col-lg-7">
                              <div class="form-group">
                                <label>Profissão</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="state.profissao_responsavel"
                                />
                              </div>
                            </div>
                            <div class="col-sm-6 col-lg-5">
                              <div class="form-group">
                                <label v-if="state.idioma == 'PT'">CPF</label>
                                <label v-else>CPF</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="state.cpf_responsavel"
                                  v-mask="'###.###.###-##'"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <h2 v-if="state.idioma == 'PT'">Outros Dados</h2>
                      <h2 v-else>Outros Dados</h2>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label v-if="state.idioma == 'PT'"
                              >Estado Civil</label
                            >
                            <label v-else>Estado Civil</label>
                            <select
                              class="form-select"
                              v-model="state.estado_civil"
                            >
                              <option value="Solteiro">Solteiro</option>
                              <option value="Casado">Casado</option>
                              <option value="Divorciado">Divorciado</option>
                              <option value="Viuvo">Viuvo</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class="form-group">
                            <label v-if="state.idioma == 'PT'">Profissão</label>
                            <label v-else>Profissão</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="state.profissao"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div>
                            <label>Observação</label>
                            <textarea
                              class="area_obs"
                              v-model="state.observacao.value"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="div_bottom col text-end">
                        <button
                          class="btn btn_salvar"
                          id="submit-button"
                          :disabled="state.isLoading"
                          type="submit"
                          v-if="state.idioma == 'PT'"
                        >
                          Salvar
                        </button>
                        <button
                          class="btn btn_salvar"
                          id="submit-button"
                          :disabled="state.isLoading"
                          type="submit"
                          v-else
                        >
                          Ahorrar
                        </button>
                        <button
                          class="btn btn_cancelar"
                          id="submit-button"
                          type="button"
                          @click="cancel()"
                        >
                          Cancelar
                        </button>
                      </div>
                    </form>
                  </stats-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import services from "../services";
import SelectPaises from "../components/PaisesSelect.vue";
import SelectEstados from "../components/EstadosSelect.vue";
//import ModalFactory from '../components/ModalFactory'
import { useRouter } from "vue-router";
import { useStorage } from "vue3-storage";
import BarraLateral from "../layout/Sistema/barraLateral";
import topo from "../components/topo";
import { mask } from "vue-the-mask";

export default {
  directives: { mask },
  components: { SelectEstados, topo, SelectPaises, BarraLateral },
  setup(_, { emit }) {
    const router = useRouter();
    const storage = useStorage();

    const inputNome = ref(null);
    const inputStatus = ref(null);
    const inputDtNasc = ref(null);
    const inputSexo = ref(null);

    var empresa_id = storage.getStorageSync("empresa_id");
    var token = storage.getStorageSync("token");
    var colaborador_id = storage.getStorageSync("colaborador_id");
    var cidade_id = storage.getStorageSync("cidade_id");
    var estado_id = storage.getStorageSync("estado_id");
    var pais_id = storage.getStorageSync("pais_id");
    console.log(pais_id);
    function cancel() {
      router.push({ name: "clientes" });
    }

    const state = reactive({
      paises: [],
      estados: [],
      departamentos: [],
      distritos: [],
      idioma: "PT",
      hasErrors: false,
      isLoading: false,
      id_leitor: null,
      id: null,
      status: {
        value: null,
        errorMessage: null,
      },
      nome: {
        value: null,
        errorMessage: null,
      },
      data_nascimento: {
        value: null,
        errorMessage: null,
      },
      sexo: {
        value: null,
        errorMessage: null,
      },
      tipo_sangue: {
        value: null,
        errorMessage: null,
      },
      telefone: {
        value: null,
        errorMessage: null,
      },
      celular: {
        value: null,
        errorMessage: null,
      },
      tel_emergencia: {
        value: null,
        errorMessage: null,
      },
      email: {
        value: null,
        errorMessage: null,
      },
      cpf: {
        value: null,
        errorMessage: null,
      },
      rg: {
        value: null,
        errorMessage: null,
      },
      pais: {
        value: null,
        errorMessage: null,
      },
      estado: {
        value: null,
        errorMessage: null,
      },
      cidade: {
        value: null,
        errorMessage: null,
      },
      cep: {
        value: null,
        errorMessage: null,
      },
      endereco: {
        value: null,
        errorMessage: null,
      },
      numero: {
        value: null,
        errorMessage: null,
      },
      bairro: {
        value: null,
        errorMessage: null,
      },
      complemento: {
        value: null,
        errorMessage: null,
      },
      observacao: {
        value: null,
        errorMessage: null,
      },
      imagem: {},
      sempre_aberto: "nao",
      login_app: "",
      password_app: "",
      estado_civil: "",
      profissao: "",
      nome_responsavel: "",
      telefone_responsavel: "",
      profissao_responsavel: "",
      cpf_responsavel: "",
      pais_id: 1,
    });
    state.func_cadastrante_id = colaborador_id;

    async function fetchCliente() {
      try {
        state.isLoading = true;
        const { data } = await services.clientes.obterPorId({
          type: state.currentClientType,
          token: token,
          empresa_id: empresa_id,
          id: router.currentRoute._value.params.id,
        });
        console.log(data.pais_id);
        if (!data.pais_id) {
          data.pais_id = 1;
        }
        if (data.pais_id == 1) {
          await fetchCidades(data.estado_id);
        } else if (data.pais_id == 2) {
          await fetchDistritos(data.departamento_id);
        }

        state.id = data.id;
        state.sempre_aberto = data.sempre_aberto;
        state.rg.value = data.rg;
        state.cpf.value = data.cpf;
        state.nome.value = data.nome;
        state.telefone.value = data.telefone;
        state.celular.value = data.celular;
        state.tel_emergencia.value = data.tel_emergencia;
        state.data_nascimento.value = data.data_nascimento;
        state.status.value = data.status;
        state.email.value = data.email;
        state.estado.value = data.estado_id;
        state.endereco.value = data.endereco;
        state.numero.value = data.numero;
        state.bairro.value = data.bairro;
        state.observacao.value = data.observacao;
        state.complemento.value = data.complemento;
        state.cep.value = data.cep;
        state.cidade_id = data.cidade_id;
        state.pais_id = data.pais_id;
        state.sexo.value = data.sexo;
        state.tipo_sangue.value = data.tipo_sangue;
        state.id_leitor = data.id_leitor;
        state.login_app = data.login_app;
        state.estado_civil = data.estado_civil;
        state.profissao = data.profissao;
        state.nome_responsavel = data.nome_responsavel;
        state.telefone_responsavel = data.telefone_responsavel;
        state.cpf_responsavel = data.cpf_responsavel;
        state.profissao_responsavel = data.profissao_responsavel;

        if (data.imagem) {
          state.imagem.imagem = data.imagem;
        }

        return; /*


        */
        //  console.log(data)
        //   state.pagination = data.pagination
      } catch (error) {
        handleErrors(error);
      }
    }

    onMounted(() => {
      fetchPaises(), fetchEstados();
      if (router.currentRoute._value.params.id != undefined) {
        fetchCliente();
      } else {
        state.estado.value = estado_id;
        fetchCidades(estado_id);
        state.cidade_id = cidade_id;
        state.pais_id = pais_id;
      }
    });

    async function fetchPaises() {
      try {
        state.isLoading = true;
        const { data } = await services.paises.getAll({
          type: state.currentFeedbackType,
        });
        console.log(data);
        //  console.log(data)
        state.paises = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function fetchEstados() {
      try {
        state.isLoading = true;
        const { data } = await services.estados.getAll({
          type: state.currentFeedbackType,
        });
        console.log(data);
        //  console.log(data)
        state.estados = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function fetchCidades(estado_id) {
      try {
        state.isLoading = true;
        const { data } = await services.cidades.getAll(estado_id);
        console.log(data);
        state.cidades = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function fetchDistritos(departamento_id) {
      try {
        state.isLoading = true;
        const { data } = await services.distrito.getAll(departamento_id);
        console.log(data);
        state.distritos = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function selecionaPais(pais_id) {
      if (pais_id == 1) {
        try {
          state.isLoading = true;
          const { data } = await services.estados.getAll();
          state.estados = data;
          state.cidades = [];
          state.isLoading = false;
          console.log("aqui");
        } catch (error) {
          console.log(error);
          handleErrors(error);
        }
      } else if (pais_id == 2) {
        try {
          state.isLoading = true;
          const { data } = await services.departamentos.getAll(estado_id);
          state.departamentos = data;
          //  state.cidades = data;
          state.isLoading = false;
          console.log(data);
        } catch (error) {
          console.log(error);
          handleErrors(error);
        }
      } else {
        console.log("pais_id: " + pais_id);
      }
    }

    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }
    async function handleSubmit() {
      //Validação

      if (state.nome.value == null || state.nome.value == "") {
        state.nome.errorMessage = "Preencha o nome da pessoa";
        inputNome.value.focus();
        return;
      }
      if (state.status.value == null || state.status.value == "") {
        state.status.errorMessage = "Preencha o Status";
        inputStatus.value.focus();
        return;
      }
      if (
        state.data_nascimento.value == null ||
        state.data_nascimento.value == ""
      ) {
        state.data_nascimento.errorMessage = "Preencha a Data de Nascimento";
        inputDtNasc.value.focus();
        return;
      }
      if (state.sexo.value == null || state.sexo.value == "") {
        state.sexo.errorMessage = "Preencha o Sexo";
        inputSexo.value.focus();
        return;
      }
      try {
        state.isLoading = true;
        let dados = new FormData();
        dados.append("status", state.status.value);
        dados.append("empresa_id", empresa_id);
        dados.append("sempre_aberto", state.sempre_aberto);
        dados.append("nome", state.nome.value);
        dados.append("data_nascimento", state.data_nascimento.value);
        dados.append("sexo", state.sexo.value);
        dados.append("tipo_sangue", state.tipo_sangue.value);
        dados.append("telefone", state.telefone.value);
        dados.append("celular", state.celular.value);
        dados.append("tel_emergencia", state.tel_emergencia.value);
        dados.append("email", state.email.value);
        dados.append("cpf", state.cpf.value);
        dados.append("rg", state.rg.value);
        dados.append("pais_id", state.pais_id);

        dados.append("cep", state.cep.value);
        dados.append("endereco", state.endereco.value);
        dados.append("numero", state.numero.value);
        dados.append("bairro", state.bairro.value);
        dados.append("complemento", state.complemento.value);
        dados.append("observacao", state.observacao.value);
        dados.append("func_cadastrante_id", state.func_cadastrante_id);
        if (state.id_leitor) {
          dados.append("id_leitor", state.id_leitor);
        }

        dados.append("login_app", state.login_app);
        dados.append("password_app", state.password_app);
        dados.append("estado_civil", state.estado_civil);
        dados.append("profissao", state.profissao);
        dados.append("nome_responsavel", state.nome_responsavel);
        dados.append("telefone_responsavel", state.telefone_responsavel);
        dados.append("profissao_responsavel", state.profissao_responsavel);
        dados.append("cpf_responsavel", state.cpf_responsavel);
        if (state.pais_id == 1) {
          dados.append("cidade_id", state.cidade_id);
        }
        if (state.pais_id == 2) {
          dados.append("distrito_py_id", state.distrito_py_id);
        }
        if (state.imagem) {
          dados.append("imagem", state.imagem.file);
        }

        if (!state.id) {
          const data = await services.clientes.save(token, empresa_id, dados);
          if (!data.data.verifica_erro) {
            state.isLoading = false;
            router.push({ name: "clientes" });
          } else {
            alert("Erro ao criar cliente.");
          }

          if (data.status === 400) {
            alert("Erro ao criar cliente.");
          }
        } else {
          dados.append("id", state.id);
          var resposta = await services.clientes.update(
            token,
            empresa_id,
            dados
          );
          // console.log(resposta.data.verifica_erro);

          if (!resposta) {
            //       window.localStorage.setItem('token', data.token)
            //      router.push({ name: "clientes" });
            state.isLoading = false;
            return;
          }

          if (resposta.status === 400) {
            //  toast.error('Ocorreu um erro ao criar a conta')
          }
          if (resposta.data.verifica_erro == true) {
            alert(resposta.data.erro);
            return;
          }
          router.push({ name: "clientes" });
        }

        state.isLoading = false;
      } catch (error) {
        console.log(error);
        state.isLoading = false;
        state.hasErrors = !!error;
        alert("Erro ao criar cliente.");
        //  toast.error('Ocorreu um erro ao criar a conta')
      }
    }
    async function adicionarImagem(event) {
      var img = event.target.files[0];
      var objImagem = new Object();
      objImagem.file = img;
      objImagem.imagem = URL.createObjectURL(img);
      state.imagem = objImagem;
    }
    async function removerImagem() {
      state.imagem = null; // {};
      document.querySelector('input[type="file"]').value = null;
    }
    return {
      state,
      inputNome,
      inputStatus,
      inputDtNasc,
      inputSexo,
      // handleCategory,
      fetchDistritos,
      handleSubmit,
      fetchCidades,
      emit,
      cancel,
      adicionarImagem,
      removerImagem,
      selecionaPais,
    };
  },
};

/*

            token: token,
            status: state.status.value,
            empresa_id: empresa_id,
            sempre_aberto: state.sempre_aberto,
            nome: state.nome.value,
            data_nascimento: state.data_nascimento.value,
            sexo: state.sexo.value,
            tipo_sangue: state.tipo_sangue.value,
            telefone: state.telefone.value,
            celular: state.celular.value,
            tel_emergencia: state.tel_emergencia.value,
            email: state.email.value,
            cpf: state.cpf.value,
            rg: state.rg.value,
            pais_id: state.pais.value,
            estado: state.estado.value,
            cidade: state.cidade_id,
            cep: state.cep.value,
            endereco: state.endereco.value,
            numero: state.numero.value,
            bairro: state.bairro.value,
            complemento: state.complemento.value,
            observacao: state.observacao.value,
            func_cadastrante_id: state.func_cadastrante_id,
            id_leitor: state.id_leitor,
            login_app: state.login_app,
            password_app: state.password_app,
            estado_civil: state.estado_civil,
            profissao: state.profissao.value,
            nome_responsavel: state.nome_responsavel,
            telefone_responsavel: state.telefone_responsavel,
            profissao_responsavel: state.profissao_responsavel,
            cpf_responsavel: state.cpf_responsavel,
            distrito_py_id: state.distrito_py_id,

*/
</script>
<style scoped>
.form-group {
  margin-bottom: 0.5rem;
}

h2 {
  color: #252525;
  font-weight: 600;
}

textarea {
  resize: none;
}

.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}

.btn_salvar:hover {
  transform: none;
  color: #fff;
}

.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
  margin-left: 15px;
}

.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}

input {
  border-color: #b8b8b8;
  border-width: 1px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
}

input:focus {
  border-color: #252525;
}

select {
  border-color: #b8b8b8;
  border-width: 1px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
}

select:focus {
  border-color: #252525;
}

textarea {
  resize: none;
}

h2 {
  margin-top: 35px;
}

.area_obs {
  width: 100%;
  border-width: 1px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
  outline: none;
  height: 100px;
  border: 1px solid #b8b8b8;
  resize: none;
}

.area_obs:focus {
  border-color: #252525;
  outline: none;
}

.imagem {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 100px;
  border: solid 5px #aede00;
}

.btn_deletar_imagem {
  background: red;
  height: 20px;
  width: 20px;
  border: solid 2px red;
  border-radius: 10px;
  text-align: center;
  trasition: 0.2s;
}

.btn_deletar_imagem:hover {
  background: rgb(198, 16, 16);
}

.div_bottom {
  text-align: right;
  margin-top: 10px;
}

@media (max-width: 719px) {
  .btn_salvar {
    width: 100%;
  }
  .btn_cancelar {
    width: 100%;
    margin-top: 10px;
    margin-left: 0px;
  }
}
</style>