export default httpClient => ({
  salvarContato: async ({ nome, telefone, email, mensagem }) => {
    const response = await httpClient.post('/salvar-contato', {
      nome,
      telefone,
      email,
      mensagem
    })
    return { data: response.data }
  },
  salvarPedido: async ({ plano_id, data_vencimento, empresa, nome_responsavel, cpf_cnpj, email, telefone }) => {
    const response = await httpClient.post('/criar-pedido', {
      plano_id, 
      data_vencimento, 
      empresa, 
      nome_responsavel, 
      cpf_cnpj,
      email, 
      telefone
    })
    return { data: response.data }
  }
})