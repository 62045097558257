<template>
  <div>
    <pie :data="chartData" :options="chartOptions"></pie>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { Pie } from 'vue-chartjs';

export default {
  extends: Pie,
  setup() {
    const chartData = ref({
      labels: ['Red', 'Blue', 'Yellow'],
      datasets: [
        {
          data: [40, 30, 30],
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
          hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        },
      ],
    });

    const chartOptions = ref({
      responsive: true,
      maintainAspectRatio: false,
    });

    onMounted(() => {
      // Você pode adicionar lógica de inicialização aqui, se necessário
    });

    return {
      chartData,
      chartOptions,
      Pie
    };
  },
};
</script>

<style scoped>
/* Adicione estilos se necessário */
</style> 