export default httpClient => ({
  getAll: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/local-contas/' + empresa_id, { headers })
    return { data: response.data }
  },
  getByID: async ({ token, empresa_id,local_conta_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/seleciona/local-contas/' + empresa_id, {
      id:local_conta_id
    }, { headers })
    return { data: response.data }
  }
})