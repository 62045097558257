<template>
  <div style="background: #000d1f">
    <main>
      <topo @alterarIdioma="alterarIdioma" :idioma="state.idioma" />
      <section id="banner_1">
        <div class="container">
          <div class="row">
            <div class="col-md-5">
              <div class="title">
                <h1 v-if="state.idioma == 'PT'">Fale Conosco</h1>
                <h1 v-else>Hable con nosotros</h1>
                <p v-if="state.idioma == 'PT'">
                  Tem alguma dúvida, deseja saber mais sobre nosso sistema?
                  Envie uma mensagem!
                </p>
                <p v-else>
                  ¿Tienes alguna pregunta, quieres saber más sobre nuestro
                  sistema? ¡Enviar un mensaje!
                </p>
              </div>
              <div class="conteudo">
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-telephone-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                    />
                  </svg>
                  +55 (44) 99928-3841
                </p>
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-envelope-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"
                    />
                  </svg>
                  contato@sifit.com.br
                </p>
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-clock-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"
                    />
                  </svg>
                  <span v-if="state.idioma == 'PT'">
                    De segunda a sexta das 8h00 às 20h00</span
                  >
                  <span v-else> De lunes a viernes de 8:00am a 8:00pm</span>
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 col-lg-6 col-xl-5 offset-md-1">
              <form action="/formulario-contato" method="post">
                <div>
                  <label for="nome" v-if="state.idioma == 'PT'">Nome:</label>
                  <label for="nome" v-else>Nombre:</label>
                  <input
                    type="texto"
                    id="nome"
                    name="nome_usuario"
                    v-model="state.nome"
                  />
                </div>
                <div>
                  <label for="telefone" v-if="state.idioma == 'PT'"
                    >Telefone:</label
                  >
                  <label for="telefone" v-else>Teléfono:</label>
                  <input
                    type="texto"
                    id="telefone"
                    name="telefone_usuario"
                    v-model="state.telefone"
                    v-mask-phone.br
                  />
                </div>
                <div>
                  <label for="email" v-if="state.idioma == 'PT'">E-mail:</label>
                  <label for="email" v-else>Correo Electrónico:</label>
                  <input
                    type="email"
                    id="email"
                    name="email_usuario"
                    v-model="state.email"
                  />
                </div>
                <div class="msg">
                  <label for="mensagem">Mensagem:</label>
                  <textarea
                    id="texto"
                    name="Mensagem_usuario"
                    rows="10"
                    v-model="state.mensagem"
                  ></textarea>
                </div>
              </form>
              <button class="btn" type="button" @click="handleSubmit">
                <span>Enviar</span>
              </button>
            </div>
          </div>
        </div>
      </section>
      <bottom id="bottom" />
    </main>
  </div>
</template>
<script>
import services from "../../services";
import Topo from "../../layout/Site/topoSite.vue";
import Bottom from "../../layout/Site/bottomSite.vue";
import { reactive } from "vue";

export default {
  components: { Topo, Bottom },
  setup(_, { emit }) {
    const state = reactive({
      nome: "",
      telefone: "",
      email: "",
      mensagem: "",
      idioma: "PT",
    });

    async function handleSubmit() {
      //console.log('Nome: '+state.nome )
      const { errors } = await services.site.salvarContato({
        nome: state.nome,
        telefone: state.telefone,
        email: state.email,
        mensagem: state.mensagem,
      });
      console.log(errors);
      /*  try{
  
    }catch(error){

    }*/
    }
    function alterarIdioma(idioma) {
      state.idioma = idioma;
    }
    return {
      emit,

      state,
      handleSubmit,
      alterarIdioma,
    };
  },
};
</script>
<style>
@import "../../app.scss";
</style>
<style scoped>
#banner_1 {
  margin-top: 50px;
}
#banner_1 h1 {
  font-family: "Exo";
  size: 64px;
  margin: 0 0 0 0;
  margin-bottom: 0;
  color: white;
}
#banner_1 p {
  font-family: "Montserrat";
  color: white;
}
.title {
  margin-bottom: 50px;
}
.title p {
  line-height: 1;
  padding: 0;
  font-size: 110%;
  font-weight: 400;
  color: white;
}
svg {
  color: #aede00;
}
.conteudo p {
  font-weight: 400;
}
form {
  width: 100%;
}

form div + div {
  margin-top: 15px;
}

label {
  width: 100px;
  color: white;
  text-align: left;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: #ffffff solid 1px;
  border-radius: 10px;
  background: transparent;
  color: white;
  resize: none;
}

textarea {
  vertical-align: top;
  height: 5em;
  color: white;
}
.msg {
  margin-bottom: 30px;
}
.btn:hover span:after {
  opacity: 1;
  right: 1;
}
.btn {
  border-radius: 8px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  border: #aede00 solid 1px;
  color: #000d1f;
  background-color: #aede00;
  cursor: pointer;
  padding: 13px;
  width: 100%;
  margin-bottom: 50px;
}
.btn span {
  position: relative;
  transition: 0.2s;
}
.btn:hover {
  background-color: #aede00;
  border: #aede00 solid 1px;
  color: #000d1f;
}
.btn span:after {
  margin-top: -9px;
  content: "\00BB";
  position: absolute;
  opacity: 0;
  right: -10px;
  transition: 0.2s;
  font-size: x-large;
}
.btn:hover span {
  padding-right: 15px;
  margin-right: 15px;
}
main {
  min-height: 100vh;
}

#bottom {
  bottom: 0;
  position: absolute;
}

@media (min-width: 992px) and (max-width: 1199px) {
}
@media (min-width: 768px) and (max-width: 991px) {
  main {
    min-height: auto;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  main {
    min-height: auto;
  }
  #bottom {
    bottom: 0;
    position: static;
  }
}

@media (max-width: 575px) {
  main {
    min-height: auto;
  }
  #bottom {
    bottom: 0;
    position: static;
  }
}
</style>