import { createApp } from 'vue'
import App from './App.vue'
import router from './router'; // Importa o router
import 'bootstrap/dist/css/bootstrap.min.css'; // Importa o CSS do Bootstrap
import 'bootstrap'; // Importa os componentes JavaScript do Bootstrap (opcional)
import 'bootstrap-icons/font/bootstrap-icons.css'; // Importa os ícones
import './views/layout.css'
import { Collapse } from 'vue-collapsed'
createApp(App)
    .use(router)
    .component('Collapse', Collapse)
    .mount('#app')