
export default httpClient => ({
  listar: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/modelos-contrato/' + empresa_id, { headers });
    return { data: response.data }
  },

  obterPorId: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/modelo-contrato/selecionar/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },


  qtdTotal: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/qtd-avaliacoes/' + empresa_id, {
      token: token
    }, { headers })
    return { data: response.data }
  },

  save: async ({ empresa_id, token, nome, texto, status }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/modelo-contrato/salvar/' + empresa_id, {
      token,
      nome,
      texto,
      status
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

    update: async ({ empresa_id, token, id, nome, texto, status }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/modelo-contrato/editar/' + empresa_id, {
      token,
      id,
      nome,
      texto,
      status
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  deletar: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/deleta/grupo/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },

})