<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Clientes Cadastrados"
            type="gradient-red"
            :sub-title="''"
            icon="ni ni-active-40"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span class="text-success mr-2"
                ><i class="fa fa-arrow-up"></i> 3.48%</span
              >
              <span class="text-nowrap">Neste mês</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Clientes Ativos"
            type="gradient-orange"
            :sub-title="''"
            icon="ni ni-chart-pie-35"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span class="text-success mr-2"
                ><i class="fa fa-arrow-up"></i> 12.18%</span
              >
              <span class="text-nowrap">Neste mês</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="sem matrículas"
            type="gradient-green"
            :sub-title="''"
            icon="ni ni-money-coins"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span class="text-danger mr-2"
                ><i class="fa fa-arrow-down"></i> 5.72%</span
              >
              <span class="text-nowrap">Neste mês</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="inadimplentes"
            type="gradient-info"
            sub-title="49,65%"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span class="text-success mr-2"
                ><i class="fa fa-arrow-up"></i> 54.8%</span
              >
              <span class="text-nowrap">este mês</span>
            </template>
          </stats-card>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="card shadow p-3" :class="type === 'dark' ? 'bg-default' : ''">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            Configurações
          </h3>
        </div>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="home-tab"
              data-toggle="tab"
              href="#home"
              role="tab"
              aria-controls="home"
              aria-selected="true"
              >Home</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="profile-tab"
              data-toggle="tab"
              href="#profile"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
              >Biometria</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="contact-tab"
              data-toggle="tab"
              href="#contact"
              role="tab"
              aria-controls="contact"
              aria-selected="false"
              >Mensagem Aniversário</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="pag_on-tab"
              data-toggle="tab"
              href="#pag_on"
              role="tab"
              aria-controls="pag_on"
              aria-selected="false"
              >Pagamento Online</a
            >
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active aba"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Plano do Sifit</label>
                  <select
                    class="form-control"
                    v-model="state.plano_principal_id"
                  >
                    <option
                      v-for="plano in state.planos_principal"
                      :key="plano.id"
                      :value="plano.id"
                    >
                      {{ plano.nome }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Liberar acesso ao sistema desktop?</label>
                  <select class="form-control" v-model="state.desktop">
                    <option value="sim">Sim</option>
                    <option value="nao">Não</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Cobrar Avaliação do Cliente?</label>
                  <select class="form-control" v-model="state.cobrar_avaliacao">
                    <option value="sim">Sim</option>
                    <option value="nao">Não</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Dias para contar cliente como Ausente</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.dias_ausente"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade aba"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div>
              <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label>Possui?</label>
                    <select
                      class="form-control"
                      v-model="state.controle_acesso"
                    >
                      <option value="sim">Sim</option>
                      <option value="nao">Não</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Marca do Aparelho</label>
                    <select
                      type="text"
                      class="form-control"
                      v-model="state.marca_aparelho_id"
                      @change="modeloAparelho($event.target.value)"
                    >
                      <option
                        v-for="marca in state.marcas_aparelho_biometria"
                        :key="marca.id"
                        :value="marca.id"
                      >
                        {{ marca.nome }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Modelo do Aparelho</label>
                    <select
                      type="text"
                      class="form-control"
                      v-model="state.aparelho_biometria_id"
                    >
                      <option
                        v-for="modelo in state.nomes_aparelho_biometria"
                        :key="modelo.id"
                        :value="modelo.id"
                      >
                        {{ modelo.modelo_aparelho }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Código do Aparelho</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.cod_aparelho"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label>Porta</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.porta_aparelho"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>IP</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.ip_aparelho"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Login</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.login_aparelho"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Senha</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.senha_aparelho"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Travar Catraca Após (dias)</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.dias_bloqueio"
                    />
                  </div>
                </div>
              </div>
              <input
                type="checkbox"
                class="checkbox"
                v-model="state.aparelho_separado"
              />
              <label for="checkbox">
                Aparelho Biometria separado da catraca?</label
              >
            </div>
          </div>
          <div
            class="tab-pane fade aba"
            id="contact"
            role="tabpanel"
            aria-labelledby="contact-tab"
          >
            <div class="row">
              <div class="col-sm-7">
                <div class="row">
                  <div class="col-9">
                    <h2>Habilitar Mensagem via Sistema</h2>
                    <select
                      class="form-control"
                      v-model="state.enviar_mensagens_aniversario"
                    >
                      <option value="sim">Sim</option>
                      <option value="nao">Não</option>
                    </select>
                  </div>
                </div>
                <h2>Mensagem de Aniversário via WhatsApp</h2>
                <div class="row">
                  <div class="col-12">
                    <textarea
                      class="form-control"
                      v-model="state.mensagem_whatsapp"
                    ></textarea>
                  </div>
                </div>
                <h2>Mensagem de Aniversário via SMS</h2>
                <div class="row">
                  <div class="col-12">
                    <textarea
                      class="form-control"
                      v-model="state.mensagem_sms"
                    ></textarea>
                  </div>
                </div>
                <h2>Mensagem de Aniversário via E-mail</h2>
                <div class="row">
                  <div class="col-12">
                    <textarea
                      class="form-control"
                      v-model="state.mensagem_email"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="col-sm-5 side_right">
                <div>
                  <input
                    type="checkbox"
                    id="zap"
                    name="zap"
                    v-model="state.enviar_mensagens_aniversario_whats"
                  />
                  <label for="zap">Enviar via WhatsApp</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="sms"
                    name="sms"
                    v-model="state.enviar_mensagens_aniversario_sms"
                  />
                  <label for="sms">Enviar via SMS</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="email"
                    name="email"
                    v-model="state.enviar_mensagens_aniversario_email"
                  />
                  <label for="email">Enviar via E-mail</label>
                </div>
                <h4>Endpoint WhatsApp</h4>
                <input
                  v-model="state.endpoint_whatsapp"
                  type="text"
                  class="form-control"
                />
                <h4>Token WhatsApp</h4>
                <input
                  v-model="state.token_whatsapp"
                  type="text"
                  class="form-control"
                />
                <h4>E-mail de Origem</h4>
                <input
                  v-model="state.email_origem_aniversario"
                  type="text"
                  class="form-control"
                />
                <h4>Telefone Origem SMS</h4>
                <input
                  v-model="state.telefone_origem_sms"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade aba"
            id="pag_on"
            role="tabpanel"
            aria-labelledby="pag_on-tab"
          >
            <div class="col-md-4">
              <div class="form-group">
                <label>Pagamento Online</label>
                <select class="form-control" v-model="state.pagarme">
                  <option value="sim">Sim</option>
                  <option value="nao">Não</option>
                </select>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label>Token Pagar.me</label>
                <input
                  v-model="state.token_pagarme"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col text-right mb-2">
            <button class="btn btn_salvar" @click="handleSubmit" type="button">
              Salvar
            </button>
            <button class="btn btn_cancelar" @click="cancel" type="button">
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useStorage } from "vue3-storage";
import services from "../services";
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");

    var state = reactive({
      id: 0,
      dias_ausente: 0,
      enviar_mensagens_aniversario: "",
      mensagem_whatsapp: "",
      mensagem_sms: "",
      mensagem_email: "",
      enviar_mensagens_aniversario_whats: false,
      enviar_mensagens_aniversario_sms: false,
      enviar_mensagens_aniversario_email: false,
      endpoint_whatsapp: "",
      token_whatsapp: "",
      email_origem_aniversario: "",
      telefone_origem_sms: "",
      cod_aparelho: "",
      porta_aparelho: "",
      ip_aparelho: "",
      login_aparelho: "",
      senha_aparelho: "",
      aparelho_separado: false,
      dias_bloqueio: "",
      desktop: "",
      cobrar_avaliacao: "",
      pagarme: "",
      token_pagarme: "",
    });

    onMounted(() => {
      buscarPlanos();
      buscaMarca();
      fetchConfiguracoes();
    });

    async function fetchConfiguracoes() {
      const { data } = await services.configuracoes.config({
        token: token,
        empresa_id: empresa_id,
      });
      state.id = data.id;
      state.dias_ausente = data.dias_ausente;
      state.enviar_mensagens_aniversario = data.enviar_mensagens_aniversario;
      state.mensagem_whatsapp = data.mensagem_whatsapp;
      state.mensagem_sms = data.mensagem_sms;
      state.mensagem_email = data.mensagem_email;
      state.endpoint_whatsapp = data.endpoint_whatsapp;
      state.token_whatsapp = data.token_whatsapp;
      state.email_origem_aniversario = data.email_origem_aniversario;
      state.telefone_origem_sms = data.telefone_origem_sms;
      if (data.enviar_mensagens_aniversario_whats == "sim") {
        state.enviar_mensagens_aniversario_whats = true;
      }
      if (data.enviar_mensagens_aniversario_sms == "sim") {
        state.enviar_mensagens_aniversario_sms = true;
      }
      if (data.enviar_mensagens_aniversario_email == "sim") {
        state.enviar_mensagens_aniversario_email = true;
      }
      state.marca_aparelho_id = data.marca_aparelho_id;
      state.porta_aparelho = data.porta_aparelho;
      state.cod_aparelho = data.cod_aparelho;
      state.ip_aparelho = data.ip_aparelho;
      state.login_aparelho = data.login_aparelho;
      state.aparelho_biometria_id = data.aparelho_biometria_id;
      state.senha_aparelho = data.senha_aparelho;
      state.aparelho_separado = data.aparelho_separado;
      state.dias_bloqueio = data.dias_bloqueio;
      if (data.aparelho_separado == "sim") {
        state.aparelho_separado = true;
      }
      state.plano_principal_id = data.plano_principal;
      state.desktop = data.desktop;
      state.cobrar_avaliacao = data.cobrar_avaliacao;
      state.pagarme = data.pagarme;
      state.token_pagarme = data.token_pagarme;
      state.controle_acesso = data.controle_acesso;

      modeloAparelho(data.marca_aparelho_id);
    }
    async function buscaMarca(id) {
      const { data } = await services.biometrias.buscaMarca({
        token: token,
        id: id,
      });
      state.marcas_aparelho_biometria = data;
    }
    async function modeloAparelho(marca_id) {
      var data_modelo = await services.biometrias.modeloAparelho({
        token: token,
        id: marca_id,
      });
      state.nomes_aparelho_biometria = data_modelo.data;
    }
    async function buscarPlanos(id) {
      var data_plano = await services.configuracoes.buscaPlano({
        token: token,
        empresa_id: empresa_id,
        id: id,
      });
      state.planos_principal = data_plano.data;
    }
    async function handleSubmit() {
      try {
        const { errors } = await services.configuracoes.save({
          id: state.id,
          plano_principal: state.plano_principal,
          desktop: state.desktop,
          cobrar_avaliacao: state.cobrar_avaliacao,
          marca_aparelho_id: state.marca_aparelho_id,
          aparelho_biometria_id: state.aparelho_biometria_id,
          cod_aparelho: state.cod_aparelho,
          porta_aparelho: state.porta_aparelho,
          ip_aparelho: state.ip_aparelho,
          login_aparelho: state.login_aparelho,
          senha_aparelho: state.senha_aparelho,
          aparelho_separado: state.aparelho_separado,
          dias_bloqueio: state.dias_bloqueio,
          dias_ausente: state.dias_ausente,
          enviar_mensagens_aniversario: state.enviar_mensagens_aniversario,
          mensagem_whatsapp: state.mensagem_whatsapp,
          mensagem_sms: state.mensagem_sms,
          mensagem_email: state.mensagem_email,
          enviar_mensagens_aniversario_whats:
            state.enviar_mensagens_aniversario_whats,
          enviar_mensagens_aniversario_sms:
            state.enviar_mensagens_aniversario_sms,
          enviar_mensagens_aniversario_email:
            state.enviar_mensagens_aniversario_email,
          endpoint_whatsapp: state.endpoint_whatsapp,
          token_whatsapp: state.token_whatsapp,
          email_origem_aniversario: state.email_origem_aniversario,
          telefone_origem_sms: state.telefone_origem_sms,
          pagarme: state.pagarme,
          token_pagarme: state.token_pagarme,
          controle_acesso: state.controle_acesso,
          empresa_id,
          token,
        });
        console.log(errors);
      } catch (error) {
        console.log(error);
      }
    }
    function cancel() {
      router.push({ name: "dashboard" });
    }
    return {
      router,
      state,
      handleSubmit,
      modeloAparelho,
      cancel,
    };
  },
};
</script>
<style scoped>
h2 {
  color: #000;
  padding-top: 15px;
}
h4 {
  color: #000;
}
.aba {
  padding: 10px;
}
.checkbox {
  margin-right: 3px;
}
/* input {
  border-color: #b8b8b8;
  border-width: 2px;
  color: #505050;
  border-radius: 15px;
  transition: 0.5s;
}
input:focus {
  border-color: #252525;
}
select {
  border-color: #b8b8b8;
  border-width: 2px;
  color: #505050;
  border-radius: 15px;
  transition: 0.5s;
}
select:focus {
  border-color: #252525;
}
textarea {
  resize: none;
} */
/* .area_obs {
  width: 100%;
  border-width: 2px;
  color: #505050;
  border-radius: 15px;
  transition: 0.5s;
  outline: none;
  height: 100px;
  border: 2px solid #b8b8b8;
  resize: none;
} */
/* .area_obs:focus {
  border-color: #252525;
  outline: none;
} */
.side_right {
  margin-top: 45px;
}
label {
  margin-left: 5px;
  color: #000;
}
.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 3px;
}
.btn_salvar:hover {
  transform: none;
  color: #fff;
}
.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 3px;
}
.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}
</style>