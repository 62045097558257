<template>
  <barra-lateral />
  <div style="background-color: #f8f9fe; min-height: 100vh">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <topo :titulo="'Matriculas'" @openOffcanvas="openOffcanvas"></topo>
          <base-header type="gradient-success" class="pb-md-6 pb-md-8 pt-md-3">
            <div class="row">
              <div class="d-md-none col-12 div_btn_matricula">
                <a href="/adicionar-matricula" class="primary">
                  <button
                    type="button"
                    class="btn_matriculas"
                    v-if="array_permissoes.includes('4')"
                  >
                    Cadastrar Matrícula
                  </button>
                </a>
              </div>
              <div class="col-lg-2 col-md-4">
                <label>Código</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Código"
                  v-model="state.codigo"
                />
              </div>
              <div class="col-lg-6 col-md-8">
                <label>Nome</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="state.nome_busca"
                  placeholder="Nome"
                />
              </div>
              <div
                class="col-lg-2 col-md-3"
                v-if="state.tipo_pesquisa == 'porNome'"
              >
                <button
                  @click="buscaMatriculasPorNome()"
                  type="button"
                  class="btn_buscar"
                >
                  Buscar
                </button>
              </div>
              <div
                class="col-lg-2 col-md-3 col-sm-6"
                v-if="state.tipo_pesquisa == 'porNome'"
                style="text-align: right"
              >
                <button
                  class="btn btn_lupa"
                  title="Mudar Pesquisa"
                  @click="tipoPesquisa()"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                    />
                  </svg>
                </button>
              </div>
              <div
                class="col-sm-6 col-xl-3 div_btn_matricula d-none d-md-block"
                v-if="state.tipo_pesquisa == 'porNome'"
              >
                <router-link
                  to="/adicionar-matricula"
                  class="primary"
                  v-if="array_permissoes.includes('4')"
                >
                  <button type="button" class="btn_matriculas">
                    Cadastrar Matrícula
                  </button>
                </router-link>
                <button type="button" class="btn_matriculas" v-else disabled>
                  Cadastrar Matrícula
                </button>
              </div>
            </div>
            <div class="row linha2">
              <div
                class="col-lg-2 col-md-4 col-sm-6"
                v-if="state.tipo_pesquisa == 'porData'"
              >
                <label>Data Inicial</label>
                <Datepicker
                  v-model="state.data_inicial_busca"
                  :locale="pt"
                  inputFormat="dd/MM/yyyy"
                  style="
                    width: 100%;
                    height: calc(1.5em + 1.25rem + 2px);
                    padding: 0.625rem 0.75rem;
                    font-size: 0.875rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #8898aa;
                    background-color: #fff;
                    background-clip: padding-box;
                    border: 1px solid #cad1d7;
                    border-radius: 0.375rem;
                    box-shadow: none;
                    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
                  "
                />
              </div>
              <div
                class="col-lg-2 col-md-4 col-sm-6"
                v-if="state.tipo_pesquisa == 'porData'"
              >
                <label>Data Final</label>
                <Datepicker
                  v-model="state.data_final_busca"
                  :locale="pt"
                  inputFormat="dd/MM/yyyy"
                  style="
                    width: 100%;
                    height: calc(1.5em + 1.25rem + 2px);
                    padding: 0.625rem 0.75rem;
                    font-size: 0.875rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #8898aa;
                    background-color: #fff;
                    background-clip: padding-box;
                    border: 1px solid #cad1d7;
                    border-radius: 0.375rem;
                    box-shadow: none;
                    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
                  "
                />
              </div>
              <div
                class="col-lg-2 col-md-4 col-sm-6"
                v-if="state.tipo_pesquisa == 'porData'"
              >
                <label>Status</label>
                <select
                  class="form-select"
                  placeholder="Status"
                  @change="buscarPorStatus()"
                >
                  <option value="ativo">Ativo</option>
                  <option value="inativo">Inativo</option>
                </select>
              </div>
              <div
                class="col-lg-2 col-md-3 col-sm-6"
                v-if="state.tipo_pesquisa == 'porData'"
              >
                <button
                  @click="buscaMatriculasPorNome()"
                  type="button"
                  class="btn_buscar"
                >
                  Buscar
                </button>
              </div>
              <div
                class="col-lg-2 col-md-3 col-sm-6"
                v-if="state.tipo_pesquisa == 'porData'"
                style="text-align: left; margin-right: 0; padding-right: 0"
              >
                <button
                  class="btn btn_lupa"
                  title="Mudar Pesquisa"
                  @click="tipoPesquisa()"
                  style="width: 50%"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                    />
                  </svg>
                </button>
              </div>
              <div
                class="col-xl-2 col-lg-4 co-md-5 col-sm-6 div_btn_matricula"
                v-if="state.tipo_pesquisa == 'porData'"
              >
                <router-link to="/adicionar-matricula" class="primary">
                  <button class="btn_matriculas" type="button">
                    Cadastrar Matrícula
                  </button>
                </router-link>
              </div>
            </div>

            <!-- Card stats -->
            <div class="row" v-if="array_permissoes.includes('105')">
              <div class="col-xl-3 col-lg-6 mt-2">
                <card class="card-stats">
                  <i class="bi bi-check-circle i_quad"></i>
                  <p class="avaliacao">Matrículas ativas</p>
                  <div class="num">{{ state.qtdMatriculasAtivas }}</div>
                  <div class="conteudo">
                    <!--  <span class="arow"><i class="fa fa-arrow-up"></i> 12.18%</span>
              <span class="text1">Nos últimos 30 dias</span> -->
                  </div>
                </card>
              </div>
              <div class="col-xl-3 col-lg-6 mt-2">
                <card class="card-stats">
                  <i class="bi bi-clipboard2-data i_quad"></i>
                  <p class="avaliacao">Recorrentes</p>
                  <div class="num">{{ state.recorrentes }}</div>
                  <div class="conteudo">
                    <!-- <span class="arow"><i class="fa fa-arrow-up"></i> 12.18%</span>
              <span class="text1">Nos últimos 30 dias</span> -->
                  </div>
                </card>
              </div>
              <div class="col-xl-3 col-lg-6 mt-2">
                <card class="card-stats">
                  <i class="bi bi-clipboard2-x i_quad"></i>
                  <p class="n_renovadas">Matrículas não renovadas</p>
                  <div class="num">{{ state.qtdMatriculasNaoRenovadas }}</div>
                  <div class="conteudo">
                    <!-- <span class="arow"><i class="fa fa-arrow-up"></i> 12.18%</span>
              <span class="text1">Nos últimos 30 dias</span>-->
                  </div>
                </card>
              </div>
              <div class="col-xl-3 col-lg-6 mt-2">
                <card class="card-stats">
                  <i class="bi bi-clipboard2-data i_quad"></i>
                  <p class="avaliacao">Cadastradas este mês</p>
                  <div class="num">{{ state.cadastradaMes }}</div>
                  <div class="conteudo">
                    <!--<span class="arow"><i class="fa fa-arrow-up"></i> 12.18%</span>
              <span class="text1">Nos últimos 30 dias</span> -->
                  </div>
                </card>
              </div>
            </div>
          </base-header>
          <div>
            <div
              class="card shadow p-3"
              :class="type === 'dark' ? 'bg-default' : ''"
              s
            >
              <div class="row">
                <div class="col">
                  <div class="table-responsive">
                    <matricula-loading v-if="state.isLoading" />
                    <table
                      v-else
                      class="table tablesorter table align-items-center table-flush"
                    >
                      <thead class="thead-light">
                        <th>Código</th>
                        <th>Nome</th>
                        <th class="th_display">Plano</th>
                        <th>Status</th>
                        <th></th>
                      </thead>
                      <tbody class="list">
                        <corpo-tabela-matricula
                          v-for="matricula in state.matriculas"
                          :key="matricula.id"
                          :matricula="matricula"
                          @deletarMatricula="deletarMatricula"
                          @modalVisualizar="modalVisualizar"
                          @modalCancelar="modalCancelar"
                          :permissoes="array_permissoes"
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div>
                <nav aria-label="">
                  <ul class="pagination">
                    <li class="page-item">
                      <a class="page-link" href="#" aria-label="Anterior">
                        <span class="sr-only">Anterior</span>
                      </a>
                    </li>

                    <li
                      class="page-item"
                      v-for="pagina in state.qtd_paginacao"
                      :key="pagina"
                      :class="{ active: pagina == state.pagina_selecionada }"
                    >
                      <a class="page-link" @click="selecionarPagina(pagina)">{{
                        pagina
                      }}</a>
                    </li>

                    <li class="page-item">
                      <a class="page-link" href="#" aria-label="Próxima">
                        <span class="sr-only">Próxima</span>
                      </a>
                    </li>
                    <li>
                      <select
                        class="form-select"
                        v-model="state.qtd_por_pagina"
                        @change="fetchClientes"
                      >
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <OffcanvasComponent ref="offcanvasRef" />

  <Modal
    :open="state.isOpen"
    @close="state.isOpen = !state.isOpen"
    :width="'800px'"
  >
    <div class="modal_visu_contapagar">
      <h1 class="title_modal">Visualizar Matrícula</h1>
      <div class="row">
        <div class="space_btn col-md-12">
          <button
            v-if="state.modo == 'visualizar'"
            type="primary"
            id="btn_new_product"
            class="btn_edit"
            @click="editarModo"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-pencil-square"
              viewBox="0 0 16 16"
            >
              <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
              />
              <path
                fill-rule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              />
            </svg>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <h3>Func. Cadastrante</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.nome_cadastrante }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <input
              type="text"
              class="form-control"
              v-model="state.nome_cadastrante"
              readonly
            />
          </div>
        </div>
        <div class="col-md-3">
          <h3>Data</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.data_inicial }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <input
              readonly
              type="text"
              class="form-control"
              v-model="state.data_inicial"
            />
          </div>
        </div>
        <div class="col-md-2">
          <h3>Status</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.status }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <select type="text" class="form-select" v-model="state.status">
              <option value="ativo">Ativo</option>
              <option value="inativo">Inativo</option>
            </select>
          </div>
        </div>
        <div class="col-md-3" v-if="state.status == 'inativo'">
          <h3>Dt Cancelamento</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.data_inicial }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <input
              readonly
              type="text"
              class="form-control"
              v-model="state.data_inicial"
            />
          </div>
        </div>
        <div class="col-md-3">
          <h3>Renovada</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.renovada }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <input
              readonly
              type="text"
              class="form-control"
              v-model="state.renovada"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <h3>Cód.Matrícula</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.id }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <input
              readonly
              type="text"
              class="form-control"
              v-model="state.id"
            />
          </div>
        </div>

        <div class="col-md-4">
          <h3>Cliente</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.nome_cliente }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <input
              readonly
              type="text"
              class="form-control"
              v-model="state.nome_cliente"
            />
          </div>
        </div>
        <div class="col-md-5">
          <h3>Modalidade</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.nome_plano }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <input
              readonly
              type="text"
              class="form-control"
              v-model="state.nome_plano"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <h3>Data Inicial</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.data_inicial }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <input
              readonly
              type="text"
              class="form-control"
              v-model="state.data_inicial"
            />
          </div>
        </div>
        <div class="col-md-3">
          <h3>Data Final</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.data_final }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <input
              readonly
              type="text"
              class="form-control"
              v-model="state.data_final"
            />
          </div>
        </div>
        <div class="col-md-3">
          <h3>Pag via Sistema</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.pagamento_via_gateway }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <input
              readonly
              type="text"
              class="form-control"
              v-model="state.pagamento_via_gateway"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <h3>Tipo de Pagamento</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.nome_tipo_pagamento }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <input
              readonly
              type="text"
              class="form-control"
              v-model="state.nome_tipo_pagamento"
            />
          </div>
        </div>
        <div class="col-md-3">
          <h3>Valor Parcela</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.valor_mensal }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <input
              readonly
              type="text"
              class="form-control"
              v-model="state.valor_mensal"
            />
          </div>
        </div>
        <div class="col-md-3">
          <h3>Desconto</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.valor_mensal_com_desconto }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <input
              readonly
              type="text"
              class="form-control"
              v-model="state.valor_mensal_com_desconto"
            />
          </div>
        </div>
        <div class="col-md-3">
          <h3>Valor total</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.valor_total_com_desconto }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <input
              readonly
              type="text"
              class="form-control"
              v-model="state.valor_total_com_desconto"
            />
          </div>
        </div>
        <div class="col-md-3">
          <h3>Vencimento</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.dia_venc_pag }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <input
              type="text"
              class="form-control"
              v-model="state.dia_venc_pag"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h3>Observação</h3>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'visualizar'"
          >
            <p>{{ state.observacao }}</p>
          </div>
          <div
            class="form-group"
            style="margin-bottom: 0"
            v-if="state.modo == 'editar'"
          >
            <input
              type="text"
              class="form-control"
              v-model="state.observacao"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="btn_direita">
      <button
        v-if="state.modo == 'visualizar'"
        class="btn btn_cancelar my-4"
        id="submit-button"
        type="button"
        @click="fecharModal"
      >
        Fechar
      </button>
      <button
        v-if="state.modo == 'editar'"
        class="btn btn_salvar my-4"
        type="button"
        @click="updateModal"
      >
        Salvar
      </button>
      <button
        v-if="state.modo == 'editar'"
        class="btn btn_cancelar my-4"
        id="submit-button"
        type="button"
        @click="editarModo"
      >
        Cancelar
      </button>
    </div>
  </Modal>

  <Modal
    :open="state.status_modal_cancelar"
    @close="state.status_modal_cancelar = !state.status_modal_cancelar"
    :width="'800px'"
  >
    <h1>Cancelar Matrícula</h1>
    <h4>Cliente: {{ state.nome_cancelar }}</h4>

    <label>Motivo de Cancelamento</label>
    <div class="form-group" style="margin-bottom: 0">
      <textarea
        type="text"
        class="form-control"
        style="resize: none"
      ></textarea>
    </div>
    <div class="btn_direita">
      <button
        class="btn btn_cancelar_matricula my-4"
        type="button"
        @click="cancelarMatricula()"
      >
        Cancelar Matrícula
      </button>
      <button
        class="btn btn_cancelar my-4"
        id="submit-button"
        type="button"
        @click="fecharModalCancelar"
      >
        Fechar
      </button>
    </div>
  </Modal>
</template>
<script>
import Modal from "../components/EstruturaModal/EstruturaModal.vue";
import { onMounted, reactive, onErrorCaptured, ref } from "vue";
import CorpoTabelaMatricula from "../components/CorpoTabelaMatricula";
import BarraLateral from "../layout/Sistema/barraLateral";
import MatriculaLoading from "../components/CorpoTabelaMatricula/Loading";
import services from "../services";
import { useStorage } from "vue3-storage";
import Datepicker from "vue3-datepicker";
import topo from "../components/topo";
import { pt } from "date-fns/locale";
import OffcanvasComponent from "../layout/Sistema/barraLateralMobile";
export default {
  name: "tables",
  components: {
    topo,
    CorpoTabelaMatricula,
    MatriculaLoading,
    Modal,
    BarraLateral,
    Datepicker,
    OffcanvasComponent,
  },

  setup() {
    const offcanvasRef = ref(null);
    const isVisible = ref(false);
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");
    var permissoes = storage.getStorageSync("permissoes");

    var array_permissoes = [];
    for (let i = 0; i < permissoes.length; i++) {
      const element = permissoes[i];
      array_permissoes.push(element.id);
    }
    var today = new Date();
    //  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    //  var yyyy = today.getFullYear();

    ///  var primeiro_dia_mes_atual = "01/" + mm + "/" + yyyy;

    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const state = reactive({
      //card-stats
      ativaMatri: false,
      recorrente: false,
      nrenovaMatri: false,
      cadastraMes: false,

      isLoading: false,
      matriculas: [],
      currentClientType: "",
      hasError: false,
      nome_busca: "",
      modo: "visualizar",
      codigo: "",
      data_inicial_busca: firstDayOfMonth,
      data_final_busca: lastDayOfMonth,
      tipo_pesquisa: "porNome",
    });
    onErrorCaptured(handleErrors);

    onMounted(() => {
      fetchMatriculas(),
        matCadasatradaMes(),
        qtdMatriculas(),
        qtdMatriculasAtivas(),
        recorrentes(),
        qtdMatriculasNaoRenovadas();
    });

    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }
    function editarModo() {
      if (state.modo == "visualizar") {
        state.modo = "editar";
      } else {
        state.modo = "visualizar";
      }
    }
    async function qtdMatriculas() {
      try {
        const qtdMatriculas = await services.matriculas.qtdMatriculasAtivas({
          token: token,
          empresa_id: empresa_id,
        });
        state.qtdMatriculas = qtdMatriculas.data;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function modalVisualizar(id) {
      state.isOpen = true;
      try {
        const { data } = await services.matriculas.obterPorId({
          token: token,
          empresa_id: empresa_id,
          id: id,
        });
        state.data_inicial = data.data_inicial;
        state.status = data.status;
        state.renovada = data.renovada;
        state.id = data.id;
        state.nome_cliente = data.nome_cliente;
        state.nome_cadastrante = data.nome_cadastrante;
        state.data_inicial = data.data_inicial;
        state.data_final = data.data_final;
        state.nome_tipo_pagamento = data.nome_tipo_pagamento;
        state.valor_mensal_com_desconto = data.valor_mensal_com_desconto;
        state.valor_total_com_desconto = data.valor_total_com_desconto;
        state.observacao = data.observacao;
        state.dia_venc_pag = data.dia_venc_pag;
        state.valor_mensal = data.valor_mensal;
        state.pagamento_via_gateway = data.pagamento_via_gateway;
        state.nome_plano = data.nome_plano;
        state.percentual_desconto = data.percentual_desconto;
        (state.valor_total = data.valor_total), console.log(data);
      } catch (error) {
        handleErrors(error);
      }
    }

    async function modalCancelar(id, nome) {
      state.status_modal_cancelar = true;
      state.id_cancelar = id;
      state.nome_cancelar = nome;
    }

    function fecharModal() {
      state.isOpen = false;
    }

    function fecharModalCancelar() {
      state.status_modal_cancelar = false;
    }

    async function qtdMatriculasAtivas() {
      try {
        const qtdMatriculasAtivas =
          await services.matriculas.qtdMatriculasAtivas({
            token: token,
            empresa_id: empresa_id,
          });
        state.qtdMatriculasAtivas = qtdMatriculasAtivas.data;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function recorrentes() {
      try {
        const recorrentes = await services.matriculas.recorrentes({
          token,
          empresa_id: empresa_id,
        });
        state.recorrentes = recorrentes.data;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function matCadasatradaMes() {
      try {
        const cadastradaMes = await services.matriculas.matCadasatradaMes({
          token: token,
          empresa_id: empresa_id,
        });
        state.cadastradaMes = cadastradaMes.data;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function buscarPorStatus() {
      try {
        const { data } = await services.matriculas.buscarPorStatus({
          token: token,
          empresa_id: empresa_id,
          status: data.status,
        });
        console.log(data);
      } catch (error) {
        handleErrors(error);
      }
    }
    async function deletarMatricula(id) {
      if (confirm("Deseja deletar essa matrícula?")) {
        try {
          state.isLoading = true;
          const { data } = await services.matriculas.deletarMatricula({
            token: token,
            empresa_id: empresa_id,
            id,
          });
          if (data) {
            fetchMatriculas();
          } else {
            alert("Erro ao deletar matrícula.");
          }
        } catch (error) {
          handleErrors(error);
        }
      }
    }
    async function cancelarMatricula() {
      if (confirm("Deseja cancelar essa matrícula?")) {
        try {
          state.isLoading = true;
          const { data } = await services.matriculas.cancelarMatricula({
            token: token,
            empresa_id: empresa_id,
            id: state.id_cancelar,
            motivo_cancelamento: state.motivo_cancelamento,
          });
          if (data.verifica_erro == false) {
            fetchMatriculas();
            state.status_modal_cancelar = false;
          } else {
            alert("Erro ao cancelar matrícula.");
          }
        } catch (error) {
          handleErrors(error);
        }
      }
    }

    async function qtdMatriculasNaoRenovadas() {
      try {
        const qtdMatriculasNaoRenovadas =
          await services.matriculas.qtdMatriculasNaoRenovadas({
            token: token,
            empresa_id: empresa_id,
          });
        state.qtdMatriculasNaoRenovadas = qtdMatriculasNaoRenovadas.data;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function buscaMatriculasPorNome() {
      if (state.codigo.length > 0) {
        try {
          const { data } = await services.matriculas.obterPorId({
            token: token,
            empresa_id: empresa_id,
            id: state.codigo,
          });
          var array = [];
          array.push(data);
          state.matriculas = array;
        } catch (error) {
          handleErrors(error);
        }
      } else if (state.nome_busca.length > 0) {
        try {
          state.isLoading = true;
          const { data } = await services.matriculas.matriculasPorNome({
            token: token,
            empresa_id: empresa_id,
            nome_cliente: state.nome_busca,
          });
          console.log(data);
          //  console.log(data)
          state.matriculas = data;
          //   state.pagination = data.pagination
          state.isLoading = false;
        } catch (error) {
          handleErrors(error);
        }
      } else {
        //data_inicial
        var dia_data_inicial = state.data_inicial_busca.getDate();
        if (dia_data_inicial < 10) {
          dia_data_inicial = "0" + dia_data_inicial;
        }
        var mes_data_inicial = state.data_inicial_busca.getMonth();
        mes_data_inicial = mes_data_inicial + 1;
        if (mes_data_inicial < 10) {
          mes_data_inicial = "0" + mes_data_inicial;
        }
        var data_inicial =
          dia_data_inicial.toString() +
          "/" +
          mes_data_inicial.toString() +
          "/" +
          state.data_inicial_busca.getFullYear().toString();

        //data_final
        var dia_data_final = state.data_final_busca.getDate();
        if (dia_data_final < 10) {
          dia_data_final = "0" + dia_data_final;
        }
        var mes_data_final = state.data_final_busca.getMonth();
        mes_data_final = mes_data_final + 1;
        if (mes_data_final < 10) {
          mes_data_final = "0" + mes_data_final;
        }
        var data_final =
          dia_data_final.toString() +
          "/" +
          mes_data_final.toString() +
          "/" +
          state.data_final_busca.getFullYear().toString();

        if (state.status == null) {
          try {
            const { data } = await services.matriculas.selecionaPorData({
              token: token,
              empresa_id: empresa_id,
              data_final,
              data_inicial,
            });
            state.contas = data;
          } catch (error) {
            console.log(error);
          }
        } else {
          try {
            const { data } = await services.matriculas.selecionaPorDataStatus({
              token: token,
              empresa_id: empresa_id,
              data_final,
              data_inicial,
              status: state.status,
            });
            state.contas = data;
          } catch (error) {
            console.log(error);
          }
        }
      }
    }
    async function fetchMatriculas() {
      try {
        state.isLoading = true;
        const { data } = await services.matriculas.getAll({
          type: state.currentClientType,
          token: token,
          empresa_id: empresa_id,
        });
        console.log(data);
        //  console.log(data)
        state.matriculas = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function updateModal() {
      try {
        state.isLoading = true;
        if (!state.id.value) {
          const { errors } = await services.matriculas.alterarModal({
            token: token,
            empresa_id: empresa_id,
            id: state.id,
            status: state.status,
            dia_venc_pag: state.dia_venc_pag,
            observacao: state.observacao,
            data_inicial: state.data_inicial,
            valor_mensal: state.valor_mensal,
            percentual_desconto: state.percentual_desconto,
            valor_mensal_com_desconto: state.valor_mensal_com_desconto,
            plano_clientes: state.plano_clientes,
            valor_total: state.valor_total,
            valor_total_com_desconto: state.valor_total_com_desconto,
            renovada: state.renovada,
          });
          if (!errors) {
            state.modo == "visualizar";
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    function tipoPesquisa() {
      if (state.tipo_pesquisa == "porNome") {
        state.tipo_pesquisa = "porData";
      } else {
        state.tipo_pesquisa = "porNome";
      }
    }
    const openOffcanvas = () => {
      // Estado para controlar a visibilidade do offcanvas
      console.log(isVisible.value);
      //offcanvasRef.value.showOffcanvas();
      if (isVisible.value) {
        offcanvasRef.value.hideOffcanvas();
      } else {
        offcanvasRef.value.showOffcanvas();
      }
    };
    return {
      buscarPorStatus,
      fecharModal,
      modalVisualizar,
      editarModo,
      updateModal,
      state,
      buscaMatriculasPorNome,
      deletarMatricula,
      pt,
      tipoPesquisa,
      modalCancelar,
      cancelarMatricula,
      fecharModalCancelar,
      array_permissoes,
      openOffcanvas,
      offcanvasRef,
      openOffcanvas,
    };
  },
};
</script>

<style scoped>
@media (min-width: 1000px) and (max-width: 1400px) {
  .espaco_md {
    padding-left: 8rem;
  }
}

@media (min-width: 768px) and (max-width: 999px) {
  .espaco_md {
    padding-left: 8rem;
  }
}

h3 {
  font-size: 14pt;
}

p {
  color: rgba(0, 0, 0, 0.5);
}

.bv {
  font-weight: bold;
  font-size: 18pt;
  color: #162b48;
  margin-bottom: 0;
}

.avaliacao {
  color: #000;
  margin-top: 10px;
}

.n_renovadas {
  color: #000;
  margin-top: 10px;
  font-size: 11pt !important;
}

.btn_cancelar_matricula {
  background-color: #dc3545;
  border: #dc3545 solid 5px;
  color: #fff;
}

.btn_cancelar_matricula:hover {
  color: #fff;
}

.arow {
  margin-right: 5px;
  font-size: 0.7rem !important;
  color: #034aa6;
  font-weight: 700;
}

.text1 {
  color: #034aa6;
  font-size: 7pt !important;
  font-weight: bold;
}

.num {
  margin-top: -15px;
}

.btn_matriculas {
  transform: none;
  background: var(--Color-secundary, #aede00);
  color: rgb(255, 255, 255);
  border: 1px solid #aede00;
  border-radius: 5px;
  font-size: 9pt;
  padding: 10px;
  font-weight: 700;
  width: 100%;
  margin-top: 26px;
}

.linha2 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.btn_buscar {
  font-size: 10pt;
  border-radius: 5px;
  background-color: rgb(3, 74, 166);
  border: none;
  color: #fff;
  font-weight: 700;
  padding: 10px;
  margin-top: 23px;
  padding-right: 20px;
  padding-left: 20px;
}

.btn:hover {
  transform: none;
}

.btn_lupa {
  color: rgb(3, 74, 166);
  background: transparent;
  border: rgb(3, 74, 166) solid 2px;
  border-color: rgb(3, 74, 166);
  padding: 8px;
  margin-top: 26px;
  width: 30%;
  transition: 0.3s;
  transform: none;
}

.btn_lupa:hover {
  border: rgb(3, 74, 166) solid 2px;
  transform: none;
  color: #fff;
}

.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}

.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}

.btn_edit {
  cursor: pointer;
  border: none;
  background: transparent;
}

.space_btn {
  text-align: right;
}

.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
  cursor: pointer;
  margin-top: 26px;
}

.btn_salvar:hover {
  transform: none;
  color: #fff;
}

.i_quad {
  background: #034aa6;
  padding: 10px;
  border-radius: 100px;
  font-size: 16pt;
  color: #fff;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .th_display {
    display: none;
  }

  .div_btn_matricula {
    text-align: right;
    margin-top: 5px;
  }

  .div_btn_matricula_1 {
    text-align: left;
    margin-top: 5px;
  }

  .btn_lupa {
    margin-top: 30px;
    width: 60%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .btn_lupa {
    margin-top: 30px;
    width: 50%;
  }
}

@media (max-width: 767px) {
  .btn_buscar {
    width: 100%;
    margin-top: 10px;
    text-align: center;
  }

  .btn_lupa {
    width: 100%;
    margin-top: 10px;
    text-align: center;
  }
  .btn_lupa:hover {
    color: #fff !important;
  }
}
</style>