export default httpClient => ({
  getAll: async ({ empresa_id, token }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/colaboradores/' + empresa_id, { headers })
    return { data: response.data }
  },
  getAvaliadores: async ({ token, empresa_id, }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/colaboradores-avaliadores/' + empresa_id, {
      token: token,
    }, { headers })
    return { data: response.data }
  },
  obterPorId: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/seleciona-colaborador/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },
  deletarColaborador: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/deleta/colaborador/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },
  busca: async ({ nome, token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/colaboradores/pornome/' + empresa_id, {
      nome,
      token: token,
    }, { headers });
    return { data: response.data }
  },
  personaisAtivos: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/colaboradores-personais/' + empresa_id, { headers })
    return { data: response.data }
  },
  colaboradoresAtivos: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/colaboradores/qtd-ativos/' + empresa_id, {
      token
    }, { headers })
    return { data: response.data }
  },
  colaboradoresQtd: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/colaboradores/qtd/' + empresa_id, {
      token
    }, { headers })
    return { data: response.data }
  },
  colaboradoresPorNome: async ({ token, empresa_id, nome }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/colaboradores/pornome/' + empresa_id, {
      token,
      empresa_id,
      nome
    }, { headers })
    return { data: response.data }
  },
  save: async ({ token, empresa_id, nome, cpf, data_nascimento, sexo, endereco, numero, bairro, complemento, cep, cidade_id, estado_id,
    telefone, celular, tel_emergencia, email, status, data_cadastro, rg, tipo_sangue, observacao, cref, login, senha, tipo_login }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/salvar/colaborador/' + empresa_id, {
      token,
      nome,
      cpf,
      data_nascimento,
      sexo,
      endereco,
      numero,
      bairro,
      complemento,
      cep,
      cidade_id,
      estado_id,
      telefone,
      celular,
      tel_emergencia,
      email,
      status,
      data_cadastro,
      rg,
      tipo_sangue,
      observacao,
      cref,
      login,
      senha,
      tipo_login
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  update: async ({ token, id, empresa_id, nome, cpf, data_nascimento, sexo, endereco, numero, bairro, complemento, cep, cidade_id, estado_id,
    telefone, celular, tel_emergencia, email, status, rg, tipo_sangue, observacao, cref, login, senha, tipo_login }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/atualizar/colaborador/' + empresa_id, {
      id,
      nome,
      cpf,
      data_nascimento,
      sexo,
      endereco,
      numero,
      bairro,
      complemento,
      cep,
      cidade_id,
      estado_id,
      telefone,
      celular,
      tel_emergencia,
      email,
      status,
      rg,
      tipo_sangue,
      observacao,
      cref,
      login,
      senha,
      tipo_login
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  salvarFuncaoColaborador: async ({ token, empresa_id, funcao_id, colaborador_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/funcao-colaborador/salvar/' + empresa_id, {
      token,
      funcao_id,
      colaborador_id
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  deletarFuncaoColaborador: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/funcao-colaborador/deletar/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },
})