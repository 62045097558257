const defaultPagination = {
  limit: 5,
  offset: 0
}

export default httpClient => ({
  getAll: async ({ type, token, empresa_id, quantidade, quantidade_anterior } = defaultPagination) => {
    const headers = {
      "Authorization": token
    }
    const query = { quantidade, quantidade_anterior }
    if (type) {
      query.type = type
    }
    const response = await httpClient.post('/matriculas/paginacao/' + empresa_id, {
      token: token,
      quantidade: 15,
      quantidade_anterior: 0,

    }, { headers })
    console.log(response)
    return { data: response.data }

  },
  matriculasPorNome: async ({ token, empresa_id, nome_cliente }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/seleciona-matricula-nome/' + empresa_id, {
      token,
      nome_cliente
    }, { headers })
    return { data: response.data }
  },

  matriculasPorClienteId: async ({ token, empresa_id, cliente_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/matriculas/por-cliente/' + empresa_id, {
      token,
      cliente_id
    }, { headers })
    return { data: response.data }
  },

  qtdMatriculasAtivas: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/qtd-matriculas-ativas/' + empresa_id, {
      token
    }, { headers })
    return { data: response.data }
  },
  qtdMatriculas: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/qtd-matriculas-ativas/' + empresa_id, {
      token
    }, { headers })
    return { data: response.data }
  },

  recorrentes: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/matriculas/qtd-recorrente/' + empresa_id, { headers })

    return { data: response.data }
  },
  matCadasatradaMes: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/qtd-matricula-mes-atual/' + empresa_id, {
      token
    }, { headers })
    return { data: response.data }
  },

  qtdMatriculasNaoRenovadas: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/qtd-matriculas-nao-renovadas/' + empresa_id, {
      token
    }, { headers })
    return { data: response.data }
  },
  obterPorId: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/seleciona/matricula/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },
  buscaDataInicial: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/seleciona/matricula/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },
  buscarDataFinal: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/seleciona/matricula/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },
  buscarPorDataVencimentoStatus: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/seleciona/matricula/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },


    buscarPorStatus: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/seleciona/matricula/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },


  save: async (matricula, token) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/salvar/matricula/' + matricula.empresa_id, {
      token: token,
      tipo_pagamento: matricula.tipo_pagamento,
      via_gateway: matricula.via_gateway,
      plano_id: matricula.plano_id,
      cliente_id: matricula.cliente_id,
      usuario_id: matricula.usuario_id,
      observacao: matricula.observacao,
      data_inicial: matricula.data_inicial,
      id_personal: matricula.id_personal,
      data_final: matricula.data_final,
      duracao: matricula.duracao,
      tipo_pagamento_id: matricula.tipo_pagamento_id,
      valor_mensal: matricula.valor_mensal,
      valor_total: matricula.valor_total,
      status: matricula.status,
      dia_venc_pag: matricula.dia_venc_pag,
      renovada: matricula.renovada,
      percentual_desconto: matricula.percentual_desconto,
      valor_desconto_mensal: matricula.valor_desconto_mensal,
      valor_desconto_total: matricula.valor_desconto_total,
      valor_mensal_com_desconto: matricula.valor_mensal_com_desconto,
      valor_total_com_desconto: matricula.valor_total_com_desconto,
      pagamento_via_gateway: matricula.pagamento_via_gateway,
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  alterarModal: async ({ token, empresa_id, id, status, vencimeto, observacao, data_inicial, valor_mensal, percentual_desconto,
    valor_mensal_com_desconto, plano_clientes, valor_total, valor_total_com_desconto, renovada }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/atualizar/matricula/' + empresa_id, {
      token,
      id,
      status,
      vencimeto,
      observacao,
      data_inicial,
      valor_mensal,
      percentual_desconto,
      valor_mensal_com_desconto,
      plano_clientes,
      valor_total,
      valor_total_com_desconto,
      renovada,
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  deletarMatricula: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/deletar-matricula/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },
  cancelarMatricula: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/cancelar-matricula/' + empresa_id, {
      
      token,
      id
    }, { headers })
    return { data: response.data }
  },  
  porCliente: async ({ token, empresa_id, cliente_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/matriculas/por-cliente/' + empresa_id, {
      token,
      empresa_id,
      cliente_id
    }, { headers })
    return { data: response.data }
  },
})