<template>
  <button class="btn_imprimir" title="Imprimir" @click="downloadPDF">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-printer-fill"
      viewBox="0 0 16 16"
    >
      <path
        d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z"
      />
      <path
        d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"
      />
    </svg>
  </button>
</template>

<script>
import jsPDF from "jspdf";
//import {  reactive  } from 'vue'
import services from "../services";
import { useStorage } from "vue3-storage";

export default {
  name: "pfd",
  props: {
    avaliacao: Object,
  },
  setup(props) {
    const storage = useStorage();

    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");

    async function downloadPDF() {
      var avaliacao = await services.avaliacoes.obterPorId({
        token: token,
        empresa_id: empresa_id,
        id: props.avaliacao.id,
      });
      console.log(avaliacao);
      gerarPDF(avaliacao);
    }

    function gerarPDF(avaliacao) {
      (function (API) {
        API.myText = function (txt, options, x, y) {
          options = options || {};
          /* Use the options align property to specify desired text alignment
           * Param x will be ignored if desired text alignment is 'center'.
           * Usage of options can easily extend the function to apply different text
           * styles and sizes
           */
          if (options.align == "center") {
            // Get current font size
            var fontSize = this.internal.getFontSize();

            // Get page width
            var pageWidth = this.internal.pageSize.width;

            // Get the actual text's width
            /* You multiply the unit width of your string by your font size and divide
             * by the internal scale factor. The division is necessary
             * for the case where you use units other than 'pt' in the constructor
             * of jsPDF.
             */
            var txtWidth =
              (this.getStringUnitWidth(txt) * fontSize) /
              this.internal.scaleFactor;

            // Calculate text's x coordinate
            x = (pageWidth - txtWidth) / 2;
          }

          // Draw text at x,y
          this.text(txt, x, y);
        };
      })(jsPDF.API);
      var pdf = jsPDF("p", "px", "a4");
      //  var pageHeight = 842;
      //largura da página para usar abaixo
      //const pageWidthMidle = pdf.internal.pageSize.getWidth() / 2;

      var y = 30;
      //  var x = 50;

      pdf.setFontSize(20);
      var altura = 10;

      y = y + 20;
      pdf.setFont(undefined, "bold");
      pdf.myText("Avaliação Física", { align: "center" }, 20, y);
      pdf.setFont(undefined, "normal");

      pdf.setFontSize(15);
      y = y + 20;
      pdf.text("Dados Básicos", 20, y);

      pdf.setFontSize(9);
      y = y + 20;

      pdf.setFont(undefined, "bold");
      pdf.text("Nome:", 20, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.nome_cliente, 90, y);

      y = y + altura;

      pdf.setFont(undefined, "bold");
      pdf.text("Data de Nascimento:", 20, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.data_nascimento_cliente, 90, y);

      y = y + altura;

      pdf.setFont(undefined, "bold");
      pdf.text("Altura:", 20, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.altura, 90, y);
      y = y + altura;

      pdf.setFont(undefined, "bold");
      pdf.text("Sexo:", 20, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.sexo_cliente, 90, y);
      y = y + altura;

      pdf.setFont(undefined, "bold");
      pdf.text("Idade:", 20, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.idade, 90, y);
      y = y + altura;

      pdf.setFont(undefined, "bold");
      pdf.text("Peso:", 20, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.peso, 90, y);
      y = y + altura;

      pdf.setFont(undefined, "bold");
      pdf.text("IMC:", 20, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.imc, 90, y);
      y = y + altura;

      pdf.setFont(undefined, "bold");
      pdf.text("Resultado IMC:", 20, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.resultado_imc, 90, y);
      y = y + 40;

      pdf.setFontSize(15);

      pdf.text("Medidas de Circunferência", 20, y);

      //Linha
      pdf.setFontSize(9);
      y = y + altura;
      pdf.setFont(undefined, "bold");
      pdf.text("Torax:", 20, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.busto, 85, y);

      pdf.setFont(undefined, "bold");
      pdf.text("Cintura:", 100, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.cintura, 180, y);

      pdf.setFont(undefined, "bold");
      pdf.text("Abdomem:", 200, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.abdomem, 290, y);
      y = y + altura;
      //Linha
      pdf.setFontSize(9);
      pdf.setFont(undefined, "bold");
      pdf.text("Coxa Direita:", 20, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.coxa_dir, 85, y);

      pdf.setFont(undefined, "bold");
      pdf.text("Coxa Esquerda:", 100, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.coxa_esq, 180, y);

      pdf.setFont(undefined, "bold");
      pdf.text("Relação Cintura Quadril:", 200, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.relacao_cintura_quadril, 290, y);
      y = y + altura;

      //Linha
      pdf.setFontSize(9);
      pdf.setFont(undefined, "bold");
      pdf.text("Braço Direito:", 20, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.coxa_dir, 85, y);

      pdf.setFont(undefined, "bold");
      pdf.text("Braço Esquerdo:", 100, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.coxa_esq, 180, y);

      pdf.setFont(undefined, "bold");
      pdf.text("Antebraço Direito:", 200, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.antebraco_dir, 290, y);

      pdf.setFont(undefined, "bold");
      pdf.text("Antebraço Esquerdo:", 320, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.antebraco_esq, 400, y);
      y = y + altura;

      //Linha
      pdf.setFontSize(9);
      pdf.setFont(undefined, "bold");
      pdf.text("Panturrilha Direita:", 20, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.panturrilha_dir, 85, y);

      pdf.setFont(undefined, "bold");
      pdf.text("Panturrilha Esquerda:", 100, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.panturrilha_esq, 180, y);
      y = y + 40;
      pdf.setFontSize(15);

      pdf.text("Medidas de Subcutâneas", 20, y);

      //Linha
      pdf.setFontSize(9);
      y = y + altura;
      pdf.setFont(undefined, "bold");
      pdf.text("Biceps", 20, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.biceps_subc, 60, y);

      pdf.setFont(undefined, "bold");
      pdf.text("Triceptal", 100, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.triceptal_subc, 140, y);

      pdf.setFont(undefined, "bold");
      pdf.text("Sub-Escapular", 180, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.subescapular_subc, 235, y);

      pdf.setFont(undefined, "bold");
      pdf.text("Supra Ilíaca", 270, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.suprailiaca_subc, 320, y);

      pdf.setFont(undefined, "bold");
      pdf.text("Torax", 350, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.torax_subc, 375, y);

      //Linha
      pdf.setFontSize(9);
      y = y + altura;
      pdf.setFont(undefined, "bold");
      pdf.text("Axila", 20, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.axila_subc, 60, y);

      pdf.setFont(undefined, "bold");
      pdf.text("Abdominal", 100, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.abdominal_subc, 140, y);

      pdf.setFont(undefined, "bold");
      pdf.text("Coxa", 180, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.coxa_subc, 235, y);

      pdf.setFont(undefined, "bold");
      pdf.text("Panturrilha", 270, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.panturrilha_subc, 320, y);

      y = y + altura;
      pdf.setFont(undefined, "bold");
      pdf.text("Relação Cintura Quadril", 20, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.relacao_cintura_quadril, 110, y);

      y = y + altura;
      pdf.setFont(undefined, "bold");
      pdf.text("Resultado Relação", 20, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.resultado_relacao_cq, 110, y);

      pdf.setFontSize(15);
      y = y + 40;
      pdf.text("Fracionamento da Composição Corporal", 20, y);

      //Linha
      pdf.setFontSize(9);

      y = y + altura;
      pdf.setFont(undefined, "bold");
      pdf.text("Densidade Corporal", 20, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.densidade_corporal, 90, y);

      pdf.setFont(undefined, "bold");
      pdf.text("% de Gordura", 125, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.percentual_gordura, 185, y);

      pdf.setFont(undefined, "bold");
      pdf.text("% de Massa Magra", 225, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.perc_massa_magra, 290, y);

      pdf.setFont(undefined, "bold");
      pdf.text("Gordura", 330, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.resultado_perc_gordura, 380, y);

      //Linha
      pdf.setFontSize(9);

      y = y + altura;
      pdf.setFont(undefined, "bold");
      pdf.text("Peso Massa Magra", 20, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.massa_magra, 90, y);

      pdf.setFont(undefined, "bold");
      pdf.text("Peso da Gordura", 125, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.peso_gordura, 185, y);

      pdf.setFont(undefined, "bold");
      if (avaliacao.data.diferenca_peso == "acima") {
        pdf.text("Peso em excesso", 225, y);
      } else {
        pdf.text("Peso em falta", 225, y);
      }

      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.peso_excesso, 290, y);

      pdf.setFont(undefined, "bold");
      pdf.text("Peso Ideal", 330, y);
      pdf.setFont(undefined, "normal");
      pdf.text(avaliacao.data.peso_ideal, 380, y);

      //massa_magra
      pdf.setFontSize(15);
      pdf.save("info.pdf");
    }
    return {
      downloadPDF,
    };
  },
};
</script>
<style scoped>
.btn_imprimir {
  padding: 4px;
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
  border-radius: 5px;
  margin-right: 10px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .btn_imprimir {
    display: none;
  }
}
</style>