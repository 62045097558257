<template>
  <tr @click="handleToggle">
    <td>
      {{ exercicio.nome }}
    </td>

    <td class="text-end">
      <div>
        <button
          class="btn_lupa"
          title="Visualizar"
          @click="$emit('modalVisualizar', exercicio.id)"
        >
          <i class="bi bi-search"></i>
        </button>
        <button
          class="btn_deletar"
          title="Deletar"
          @click="$emit('deletar', exercicio.id)"
        >
          <i class="bi bi-x"></i>
        </button>
      </div>
    </td>
  </tr>
</template>
<script>
import { reactive } from "vue";

export default {
  props: {
    isOpened: { type: Boolean, default: false },
    exercicio: { type: Object, required: true },
  },
  setup(props) {
    const state = reactive({
      isOpen: props.isOpened,
      isClosing: !props.isOpened,
    });
    async function handleToggle() {
      state.isClosing = true;
      //  await wait(250)
      state.isOpen = !state.isOpen;
      state.isClosing = false;
    }
    return {
      state,
      handleToggle,
    };
  },
};
</script>
<style scoped>
.btn_deletar {
  padding: 2px;
  color: #ffff;
  background: transparent;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
  transform: none;
}
.btn_lupa {
  padding: 4px;
  color: #034aa6;
  background: transparent;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
  transform: none;
}

.btn_deletar i {
  color: #034aa6;
  font-size: 14pt;
  font-weight: bold;
}
.btn_lupa i {
  color: #034aa6;
  font-size: 12pt;
  font-weight: bold;
}
.btn_lupa:hover {
  background-color: #fff !important;
}
.btn_deletar {
  background-color: #fff !important;
}
.btn:hover {
  transform: none;
  background-color: #fff !important;
  color: #fff;
}
</style>
