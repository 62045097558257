<template>
  <tr @click="handleToggle">
    <th scope="row">
      <div class="media align-items-center">
        <div class="media-body">
          <span class="name mb-0 text-sm"></span>
          {{ funcao.id }}
        </div>
      </div>
    </th>
    <td>
      {{ funcao.nome }}
    </td>
    <td class="text-right">
      <div>
        <button class="btn btn_shield" title="Permissão" @click="$emit('modalPermissao')">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-shield-lock"
            viewBox="0 0 16 16">
            <path
              d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />
            <path
              d="M9.5 6.5a1.5 1.5 0 0 1-1 1.415l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99a1.5 1.5 0 1 1 2-1.415z" />
          </svg>
        </button>
        <button class="btn btn_lupa" title="Visualizar" @click="$emit('qtfFuncao', funcao.empresa_id, funcao.id)">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"
            viewBox="0 0 16 16">
            <path
              d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>
        </button>
      </div>
    </td>
  </tr>
</template>
<script>
import { reactive } from "vue";

export default {
  props: {
    isOpened: { type: Boolean, default: false },
    funcao: { type: Object, required: true },
  },
  setup(props) {
    const state = reactive({
      isOpen: props.isOpened,
      isClosing: !props.isOpened,
    });
    async function handleToggle() {
      state.isClosing = true;
      //  await wait(250)
      state.isOpen = !state.isOpen;
      state.isClosing = false;
    }
    return {
      state,
      handleToggle,
    };
  },
};
</script>
<style scoped>
.btn_shield {
  padding: 4px;
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
  border-radius: 5px;
  margin-right: 10px;
  transform: none;
}

.btn_lupa {
  padding: 4px;
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
  border-radius: 5px;
  margin-right: 10px;
  transform: none;
}

.btn:hover {
  transform: none;
  color: #fff;
}
</style>
