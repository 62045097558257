export default httpClient => ({
  busca: async ({ nome, token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/fornecedoresPorNome/' + empresa_id, {
      nome,
      token: token,
    }, { headers });
    return { data: response.data }
  },
  getAll: async ({ empresa_id, token }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/fornecedores/' + empresa_id, {
      token: token,
    }, { headers })
    return { data: response.data }
  },
  deletarFornecedor: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/deleta-fornecedor/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },
  obterPorId: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/seleciona-fornecedor/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },
  fornecedoresPorNome: async ({ token, empresa_id, nome }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/fornecedoresPorNome/' + empresa_id, {
      token,
      empresa_id,
      nome
    }, { headers })
    return { data: response.data }
  },

  save: async ({ token, empresa_id, nome, cpf, data_nascimento, sexo, endereco, numero, bairro, complemento, cep, cidade_id, estado_id,
    telefone, celular, tel_emergencia, email, status, data_cadastro, rg, tipo_sangue, observacao, cnpj, tipo_pessoa }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/salvar-fornecedor/' + empresa_id, {
      token,
      nome,
      cpf,
      data_nascimento,
      sexo,
      endereco,
      numero,
      bairro,
      complemento,
      cep,
      cidade_id,
      estado_id,
      telefone,
      celular,
      tel_emergencia,
      email,
      status,
      data_cadastro,
      rg,
      tipo_sangue,
      observacao,
      cnpj,
      tipo_pessoa
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  alterarModal: async ({ token, empresa_id, id, nome,
    cpf, data_nascimento, sexo, endereco, numero, bairro, complemento, cep, telefone, celular, tel_emergencia, email, status, tipo_pessoa, rg, sempre_aberto,
    cnpj, nome_cidade, nome_estado, observacao, }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/atualizar-fornecedor/' + empresa_id, {
      token,
      id,
      nome,
      cpf,
      data_nascimento,
      sexo,
      endereco,
      numero,
      bairro,
      complemento,
      cep,
      telefone,
      celular,
      tel_emergencia,
      email,
      status,
      tipo_pessoa,
      rg,
      sempre_aberto,
      cnpj,
      nome_cidade,
      nome_estado,
      observacao,
    }, { headers })
    if (!response.data) {
      console.log('error')
    }
    return {
      data: response.data,

    }
  },
})