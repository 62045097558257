<template>
  <barra-lateral />
  <OffcanvasComponent ref="offcanvasRef" />

  <div style="background-color: #f8f9fe; min-height: 100vh">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <topo :titulo="'Pontuação'" @openOffcanvas="openOffcanvas"></topo>
          <div class="card shadow p-3 mt-3">
            <div class="row align-items-center">
              <div class="col text-end mb-2">
                <button
                  type="button"
                  class="btn btn-primary"
                  id="btn_new_product"
                  @click="abrirModal"
                >
                  Adicionar Pontuação
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="table-responsive">
                  <table
                    class="table tablesorter table align-items-center table-flush"
                  >
                    <thead class="thead-light">
                      <th>Código</th>
                      <th>Nome</th>
                      <th>Status</th>
                      <th></th>
                    </thead>
                    <tbody class="list">
                      <corpo-pontuacao
                        v-for="pontuacao in state.pontuacoes"
                        :key="pontuacao.id"
                        :pontuacao="pontuacao"
                        @deletar="deletar"
                        @modalVisualizar="modalVisualizar"
                        @modalAtividades="modalAtividades"
                        @modalParticipantes="modalParticipantes"
                      />
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    :open="state.status_modal"
    @close="state.status_modal = !state.status_modal"
    :width="'800px'"
  >
    <h1>Pontuação</h1>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label>Código</label>
          <input
            type="text"
            class="form-control input_modal"
            v-model="state.id"
            readonly
          />
        </div>
      </div>
      <div class="col-lg-10">
        <div class="form-group">
          <label>Nome</label>
          <input
            type="text"
            class="form-control input_modal"
            v-model="state.nome"
          />
        </div>
      </div>
      <div class="col-lg-3">
        <label>Data</label>
        <Datepicker
          v-model="state.data_inicial"
          :locale="pt"
          inputFormat="dd/MM/yyyy"
          style="
            border-color: #b8b8b8;
            color: #505050;
            border-radius: 5px;
            border: #b8b8b8 solid 1px;
            transition: 0.5s;
            width: 100%;
            padding: 9px;
            resize: none;
          "
        />
      </div>
      <div class="col-lg-3">
        <label>Data</label>
        <Datepicker
          v-model="state.data_final"
          :locale="pt"
          inputFormat="dd/MM/yyyy"
          style="
            border-color: #b8b8b8;
            color: #505050;
            border-radius: 5px;
            border: #b8b8b8 solid 1px;
            transition: 0.5s;
            width: 100%;
            padding: 9px;
            resize: none;
          "
        />
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>Tipo</label>
          <select class="form-select" v-model="state.tipo">
            <option value="mensal">Mensal</option>
            <option value="periodo">Período</option>
          </select>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>Status</label>
          <select class="form-select" v-model="state.status">
            <option value="ativo">Ativo</option>
            <option value="inativo">Inativo</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6" style="margin-bottom: 12px">
        <label for="">Produto</label>
        <select class="form-select" v-model="state.produto_selecionado">
          <option v-for="(atividade, i) in state.produtos" :key="i" :value="i">
            {{ atividade.nome }}
          </option>
        </select>
      </div>
      <div class="col-sm-6" style="margin-bottom: 12px; padding-top: 30px">
        <button
          class="btn btn-primary"
          @click="adicionarProduto(state.produto_selecionado)"
        >
          Adicionar
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <table class="table tablesorter table align-items-center table-flush">
          <thead class="thead-light">
            <th>Código</th>
            <th>Nome</th>
            <th></th>
          </thead>
          <tbody class="list">
            <tr
              v-for="(produto, i) in state.produtos_adicionados"
              :key="i"
              class="align-items-center"
            >
              <td>
                {{ produto.id }}
              </td>
              <td>
                {{ produto.nome }}
              </td>
              <td>
                <button class="btn btn_excluir" @click="removerProduto(i)">
                  <i class="bi bi-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <button
      class="btn btn_salvar my-4"
      id="submit-button"
      type="submit"
      @click="salvarPontuacao()"
    >
      Salvar
    </button>
    <button
      class="btn btn_cancelar my-4"
      id="submit-button"
      type="button"
      @click="fecharModal()"
    >
      Cancelar
    </button>
  </Modal>

  <Modal
    :open="state.status_modal_atividades"
    @close="state.status_modal_atividades = !state.status_modal_atividades"
    :width="'800px'"
  >
    <h1>Atividades</h1>
    <div class="row">
      <div class="col-sm-6" style="margin-bottom: 12px">
        <label for="">Atividade</label>
        <select class="form-select" v-model="state.atividade_id">
          <option
            v-for="(atividade, i) in state.atividades"
            :key="i"
            :value="i"
          >
            {{ atividade.nome }}
          </option>
        </select>
      </div>
      <div class="col-sm-6" style="margin-bottom: 12px; padding-top: 30px">
        <button
          class="btn btn-primary"
          @click="adicionarAtividade(state.atividade_id)"
        >
          Adicionar
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <table class="table tablesorter table align-items-center table-flush">
          <thead class="thead-light">
            <th>Código</th>
            <th>Nome</th>
            <th></th>
          </thead>
          <tbody class="list">
            <tr v-for="(atividade, i) in state.atividades_adicionadas" :key="i">
              <td>
                {{ atividade.id }}
              </td>
              <td>
                {{ atividade.nome }}
              </td>
              <td>
                <button class="btn btn_excluir" @click="removerAtividade(i)">
                  <i class="bi bi-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <button
      class="btn btn_salvar my-4"
      id="submit-button"
      type="submit"
      @click="salvarAtividades()"
    >
      Salvar
    </button>
    <button
      class="btn btn_cancelar my-4"
      id="submit-button"
      type="button"
      @click="fecharModalAtividade()"
    >
      Cancelar
    </button>
  </Modal>

  <Modal
    :open="state.status_modal_participantes"
    @close="
      state.status_modal_participantes = !state.status_modal_participantes
    "
    :width="'800px'"
  >
    <h1>Ranking de Participantes</h1>
    <div class="row">
      <div class="col-sm-12">
        <table class="table tablesorter table align-items-center table-flush">
          <thead class="thead-light">
            <th>Código</th>
            <th>Nome</th>
            <th></th>
          </thead>
          <tbody class="list">
            <tr
              v-for="participante in state.participantes"
              :key="participante.id"
            >
              <td>{{ participante.pessoa_id }}</td>
              <td>{{ participante.nome }}</td>
              <td>a</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <button
      class="btn btn_salvar my-4"
      id="submit-button"
      type="submit"
      @click="salvarPontuacao()"
    >
      Salvar
    </button>
    <button
      class="btn btn_cancelar my-4"
      id="submit-button"
      type="button"
      @click="fecharModalParticipantes()"
    >
      Cancelar
    </button>
  </Modal>
</template>
<script>
import { onMounted, reactive, onErrorCaptured, ref } from "vue";
import CorpoPontuacao from "../components/CorpoTabelaPontuacao";
import services from "../services";
import Modal from "../components/EstruturaModal/EstruturaModal.vue";
import topo from "../components/topo";
import { useStorage } from "vue3-storage";
import Datepicker from "vue3-datepicker";
import { pt } from "date-fns/locale";
import BarraLateral from "../layout/Sistema/barraLateral";
import produto from "@/services/produto";
import OffcanvasComponent from "../layout/Sistema/barraLateralMobile";
export default {
  name: "tables",
  components: {
    topo,
    Modal,
    BarraLateral,
    CorpoPontuacao,
    Datepicker,
    OffcanvasComponent,
  },
  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");
    const state = reactive({
      isLoading: false,
      respostas: [
        {
          nome: "",
        },
      ],
      currentClientType: "",
      hasError: false,
      id: "",
      nome: "",
      pergunta: "",
      tipo_caixa: "interno",
      descricao: "",
      produtos: [],
      produtos_adicionados: [],
      atividades: [],
      atividades_adicionadas: [],
      atividade_id: "",
    });
    onErrorCaptured(handleErrors);

    onMounted(() => {
      fetchPontuacao();
    });
    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }
    async function abrirModal() {
      state.id = "";
      state.nome = "";
      state.data_inicial = null;
      state.data_final = null;
      state.tipo = "";
      state.status = "";
      state.status_modal = false;
      state.produtos_adicionados = [];
      state.status_modal = true;
    }

    function fecharModal() {
      state.id = "";
      state.nome = "";
      state.data_inicial = null;
      state.data_final = null;
      state.tipo = "";
      state.status = "";
      state.status_modal = false;
    }

    async function salvarAtividades() {
      let retorno = await services.pontuacao.salvarAtividade(
        empresa_id,
        token,
        {
          pontuacao_id: state.id,
          atividades: state.atividades_adicionadas,
        }
      );
      state.id = "";
      state.atividades_adicionadas = [];
      console.log(retorno);
    }

    function fecharModalAtividade() {
      state.status_modal_atividades = false;
    }

    function fecharModalParticipantes() {
      state.status_modal_participantes = false;
    }

    async function modalVisualizar(id) {
      fetchProdutos();
      try {
        let data = await services.pontuacao.obterPorId({
          empresa_id,
          id,
          token,
        });
        let dados = data.data;
        state.produtos_adicionados = dados.produtos;
        state.id = dados.id;
        state.nome = dados.nome;
        state.data_inicial = convertBRDateToJSDate(dados.data_inicial);
        state.data_final = convertBRDateToJSDate(dados.data_final);
        state.tipo = dados.tipo;
        state.status = dados.status;
        state.status_modal = true;
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchProdutos() {
      try {
        let data = await services.produto.getByTipo({
          empresa_id,
          tipo: "troca",
          token,
        });
        let dados = data.data;
        state.produtos = dados;
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchAtividadesAdicionadas(pontuacao_id) {
      try {
        let data = await services.pontuacao.obterAtividades({
          empresa_id,
          token,
          pontuacao_id,
        });
        let dados = data.data;
        state.atividades_adicionadas = dados;
      } catch (error) {
        console.log(error);
      }
    }

    async function modalAtividades(id) {
      try {
        fetchAtividades();
        fetchAtividadesAdicionadas(id);
        state.id = id;
        console.log(id);
        /* let data = await services.pontuacao.obterPorId({
          empresa_id,
          id,
          token,
        });
        let dados = data.data;
        state.id = dados.id;
        state.nome = dados.nome;
        state.data_inicial = convertBRDateToJSDate(dados.data_inicial);
        state.data_final = convertBRDateToJSDate(dados.data_final);
        state.tipo = dados.tipo;
        state.status = dados.status;*/
        state.status_modal_atividades = true;
      } catch (error) {
        console.log(error);
      }
    }

    async function modalParticipantes(id) {
      try {
        //  fetchAtividades();
        console.log(id);
        let data = await services.pontuacao.obterParticipantes({
          empresa_id,
          id,
          token,
        });

        state.participantes = data.data;
        /*
        let dados = data.data;
        state.id = dados.id;
        state.nome = dados.nome;
        state.data_inicial = convertBRDateToJSDate(dados.data_inicial);
        state.data_final = convertBRDateToJSDate(dados.data_final);
        state.tipo = dados.tipo;
        state.status = dados.status;*/
        state.status_modal_participantes = true;
      } catch (error) {
        console.log(error);
      }
    }

    function addResposta() {
      state.respostas.push({});
    }

    async function fetchAtividades() {
      try {
        state.isLoading = true;
        const { data } = await services.atividade.listar({
          token: token,
          empresa_id: empresa_id,
        });
        //  console.log(data)
        state.atividades = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function deletar(id) {
      if (confirm("Deseja deletar a Pontuação?")) {
        await services.pontuacao
          .deletar({
            token,
            empresa_id,
            id,
          })
          .then((data) => {
            if (data.data.verifica_erro) {
              alert("Erro ao deletar Pontuação");
            } else {
              fetchPontuacao();
            }
          })
          .catch(() => {
            alert("Erro ao deletar Pontuação");
          });
      }
    }

    async function salvarPontuacao() {
      if (state.id != null && state.id != undefined && state.id != "") {
        let retorno = await services.pontuacao.update(empresa_id, token, {
          id: state.id,
          nome: state.nome,
          data_inicial: formatISODateToBR(state.data_inicial),
          data_final: formatISODateToBR(state.data_final),
          tipo: state.tipo,
          status: state.status,
          produtos: state.produtos_adicionados,
        });

        if (retorno.data.verifica_erro == false) {
          fetchPontuacao();
          state.id = "";
          state.nome = "";
          state.data_inicial = "";
          state.data_final = "";
          state.tipo = "";
          state.status = "";
          state.status_modal = false;
        } else {
          alert("Erro ao salvar plano");
        }
      } else {
        let retorno = await services.pontuacao.save(empresa_id, token, {
          nome: state.nome,
          data_inicial: formatISODateToBR(state.data_inicial),
          data_final: formatISODateToBR(state.data_final),
          tipo: state.tipo,
          status: state.status,
          produtos: state.produtos_adicionados,
        });

        if (retorno.data.verifica_erro == false) {
          fetchPontuacao();
          state.id = "";
          state.nome = "";
          state.data_inicial = "";
          state.data_final = "";
          state.tipo = "";
          state.status = "";
          state.status_modal = false;
        } else {
          alert("Erro ao salvar plano");
        }
      }
    }

    function convertBRDateToJSDate(dateStr) {
      // Separa a data em dia, mês e ano
      const [day, month, year] = dateStr.split("/").map(Number);

      // Cria um objeto Date. O mês é 0-indexado, então subtraímos 1
      return new Date(year, month - 1, day);
      // return isoDateStr;
    }

    async function fetchPontuacao() {
      try {
        state.isLoading = true;
        const { data } = await services.pontuacao.listar({
          token: token,
          empresa_id: empresa_id,
        });
        //  console.log(data)
        state.pontuacoes = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    function adicionarAtividade(i) {
      if (state.atividades_adicionadas.length > 0) {
        state.atividades_adicionadas.push(state.atividades[i]);
        return;
      }
      state.atividades_adicionadas = [];
      state.atividades_adicionadas.push(state.atividades[i]);
    }

    function adicionarProduto(i) {
      if (state.produtos_adicionados.length < 0) {
        state.produtos_adicionados.push(state.produtos[i]);
        return;
      }
      state.produtos_adicionados = [];
      state.produtos_adicionados.push(state.produtos[i]);
    }

    function removerProduto(i) {
      state.produtos_adicionados.splice(i, 1);
    }

    function formatISODateToBR(isoDate) {
      // Cria um objeto Date a partir da string ISO
      const date = new Date(isoDate);

      // Ajusta para o horário local, convertendo de UTC para o fuso horário local
      const localDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );

      // Obtém o dia, mês e ano
      const day = String(localDate.getDate()).padStart(2, "0");
      const month = String(localDate.getMonth() + 1).padStart(2, "0"); // getMonth() é 0-indexado
      const year = localDate.getFullYear();

      // Obtém as horas e minutos
      //   const hours = String(localDate.getHours()).padStart(2, "0");
      //   const minutes = String(localDate.getMinutes()).padStart(2, "0");

      // Formata a data no formato brasileiro DD/MM/YYYY e a hora no formato HH:MM
      const formattedDate = `${day}/${month}/${year}`;

      // Resultado final
      return `${formattedDate}`;
    }
    const offcanvasRef = ref(null);
    const isVisible = ref(false);
    const openOffcanvas = () => {
      console.log(isVisible.value);
      if (isVisible.value) {
        offcanvasRef.value.hideOffcanvas();
      } else {
        offcanvasRef.value.showOffcanvas();
      }
    };
    return {
      offcanvasRef,
      openOffcanvas,
      state,
      abrirModal,
      fecharModal,
      fecharModalAtividade,
      salvarPontuacao,
      modalVisualizar,
      modalAtividades,
      modalParticipantes,
      fecharModalParticipantes,
      deletar,
      addResposta,
      pt,
      adicionarProduto,
      removerProduto,
      adicionarAtividade,
      salvarAtividades,
    };
  },
};
</script>

<style scoped>
.btn_add_resposta {
  background-color: #034aa6;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #fff;
  padding: 0px;
  font-size: 16pt;
  border: none;
}
.btn-primary {
  background-color: rgb(3, 74, 166);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(3, 74, 166);
  border-radius: 10px;
  font-size: 10pt;
  padding: 12px;
  font-weight: 700;
  margin-bottom: 15px;
}
.grupo {
  width: 100%;
  height: 100px;
  border: #034aa6 solid 1px;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px;
}

.nome_grupo {
  font-weight: bold;
}

.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}

.btn_salvar:hover {
  transform: none;
  color: #fff;
}

.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}

.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}

textarea {
  resize: none;
}
@media (max-width: 767px) {
  #btn_new_product {
    width: 100%;
    margin-top: 10px;
    text-align: center;
  }
}
</style>