<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 600px;
        background-image: url(img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white">Hello Jesse</h1>
            <p class="text-white mt-0 mb-5">
              This is your profile page. You can see the progress you've made
              with your work and manage your projects or assigned tasks
            </p>
            <a href="#!" class="btn btn-info">Edit profile</a>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0">
          <div class="card card-profile shadow">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    <img
                      src="img/theme/team-4-800x800.jpg"
                      class="rounded-circle"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div
              class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"
            >
              <div class="d-flex justify-content-between">
                <base-button size="sm" type="info" class="mr-4"
                  >Connect</base-button
                >
                <base-button size="sm" type="default" class="float-right"
                  >Message</base-button
                >
              </div>
            </div>
            <div class="card-body pt-0 pt-md-4">
              <div class="row">
                <div class="col">
                  <div
                    class="card-profile-stats d-flex justify-content-center mt-md-5"
                  >
                    <div>
                      <span class="heading">22</span>
                      <span class="description">Friends</span>
                    </div>
                    <div>
                      <span class="heading">10</span>
                      <span class="description">Photos</span>
                    </div>
                    <div>
                      <span class="heading">89</span>
                      <span class="description">Comments</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <h3>
                  Jessica Jones<span class="font-weight-light">, 27</span>
                </h3>
                <div class="h5 font-weight-300">
                  <i class="ni location_pin mr-2"></i>Bucharest, Romania
                </div>
                <div class="h5 mt-4">
                  <i class="ni business_briefcase-24 mr-2"></i>Solution Manager
                  - Creative Tim Officer
                </div>
                <div>
                  <i class="ni education_hat mr-2"></i>University of Computer
                  Science
                </div>
                <hr class="my-4" />
                <p>
                  Ryan — the name taken by Melbourne-raised, Brooklyn-based Nick
                  Murphy — writes, performs and records all of his own music.
                </p>
                <a href="#">Show more</a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-8 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Minha conta</h3>
                  </div>
                  <div class="col-4 text-right">
                    <a href="#!" class="btn btn-sm btn-primary">Settings</a>
                  </div>
                </div>
              </div>
            </template>

            <form>
              <h6 class="heading-small text-muted mb-4">Usuário</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="Nome"
                      placeholder="Nome"
                      input-classes="form-control-alternative"
                      v-model="model.firstName"
                    />
                  </div>

                  <div class="col-lg-3">
                    <base-input
                      alternative=""
                      label="Data de Nascimento"
                      placeholder="01/03/1993"
                      input-classes="form-control-alternative"
                      v-model="model.firstName"
                    />
                  </div>

                  <div class="col-lg-3">
                    <base-input
                      alternative=""
                      label="Sexo"
                      placeholder="Masculino"
                      input-classes="form-control-alternative"
                      v-model="model.firstName"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="Usuário"
                      placeholder="E-mail de Login"
                      input-classes="form-control-alternative"
                      v-model="model.username"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="Email address"
                      placeholder="jesse@example.com"
                      input-classes="form-control-alternative"
                      v-model="model.email"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-4">
                    <base-input
                      alternative=""
                      label="Telefone"
                      placeholder="Telefone"
                      input-classes="form-control-alternative"
                      v-model="model.username"
                    />
                  </div>
                  <div class="col-lg-4">
                    <base-input
                      alternative=""
                      label="Telefone Alternativo"
                      placeholder="Telefone Alternativo"
                      input-classes="form-control-alternative"
                      v-model="model.email"
                    />
                  </div>
                  <div class="col-lg-4">
                    <base-input
                      alternative=""
                      label="Telefone Emergência"
                      placeholder="Telefone Emergência"
                      input-classes="form-control-alternative"
                      v-model="model.email"
                    />
                  </div>
                </div>

              </div>

              <hr class="my-4" />
              <!-- Address -->
              <h6 class="heading-small text-muted mb-4">Documentos</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-4">
                    <base-input
                      alternative=""
                      label="CPF"
                      placeholder="CPF"
                      input-classes="form-control-alternative"
                      v-model="model.address"
                    />
                  </div>
                  <div class="col-lg-4">
                    <base-input
                      alternative=""
                      label="RG"
                      placeholder="RG"
                      input-classes="form-control-alternative"
                      v-model="model.city"
                    />
                  </div>
                  <div class="col-lg-4">
                    <base-input
                      alternative=""
                      label="Tipo Sanguíneo"
                      placeholder="AB+"
                      input-classes="form-control-alternative"
                      v-model="model.zipCode"
                    />
                  </div>
                </div>
              </div>

              <hr class="my-4" />
              <!-- Address -->
              <h6 class="heading-small text-muted mb-4">Endereço</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-md-9">
                    <base-input
                      alternative=""
                      label="Endereço"
                      placeholder="Endereço"
                      input-classes="form-control-alternative"
                      v-model="model.address"
                    />
                  </div>
                  <div class="col-md-3">
                    <base-input
                      alternative=""
                      label="Número"
                      placeholder="Número"
                      input-classes="form-control-alternative"
                      v-model="model.address"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-2">
                    <base-input
                      alternative=""
                      label="CEP"
                      placeholder="CEP"
                      input-classes="form-control-alternative"
                      v-model="model.zipCode"
                    />
                  </div>

                  <div class="col-lg-5">
                    <base-input
                      alternative=""
                      label="Bairro"
                      placeholder="Bairro"
                      input-classes="form-control-alternative"
                      v-model="model.zipCode"
                    />
                  </div>

                  <div class="col-lg-5">
                    <base-input
                      alternative=""
                      label="Complemento"
                      placeholder="Complemento"
                      input-classes="form-control-alternative"
                      v-model="model.zipCode"
                    />
                  </div>
                </div>


                <div class="row">
                  <div class="col-lg-4">
                    <base-input
                      alternative=""
                      label="País"
                      placeholder="País"
                      input-classes="form-control-alternative"
                      v-model="model.country"
                    />
                  </div>
                  <div class="col-lg-4">
                    <base-input
                      alternative=""
                      label="Estado"
                      placeholder="Estado"
                      input-classes="form-control-alternative"
                      v-model="model.city"
                    />
                  </div>

                  <div class="col-lg-4">
                    <base-input
                      alternative=""
                      label="Cidade"
                      placeholder="Cidade"
                      input-classes="form-control-alternative"
                      v-model="model.city"
                    />
                  </div>


                </div>
              </div>
              <hr class="my-4" />
              <!-- Description -->
              <h6 class="heading-small text-muted mb-4">About me</h6>
              <div class="pl-lg-4">
                <div class="form-group">
                  <base-input alternative="" label="About Me">
                    <textarea
                      rows="4"
                      class="form-control form-control-alternative"
                      placeholder="A few words about you ..."
                    >
A beautiful Dashboard for Bootstrap 4. It is Free and Open Source.</textarea
                    >
                  </base-input>
                </div>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "user-profile",
  data() {
    return {
      model: {
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        country: "",
        zipCode: "",
        about: "",
      },
    };
  },
};
</script>
<style></style>
