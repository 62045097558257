export default httpClient => ({
  dadosPrincipais: async ({ token, empresa_id, data_inicial, data_final  }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/dados-principais-home/' + empresa_id, {data_inicial, data_final},{ headers })
    return { data: response.data }
  },

  presencas: async ({ token, empresa_id, qntd }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/presencas/' + empresa_id, { qntd },{ headers })
    return { data: response.data }
  },

    sexo: async ({ token, empresa_id, qntd }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/percentual-sexo/' + empresa_id, { qntd },{ headers })
    return { data: response.data }
  },
    faixaEtaria: async ({ token, empresa_id, qntd }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/pessoas-faixa-etaria/' + empresa_id, { qntd },{ headers })
    return { data: response.data }
  },
    matriculasPorMes: async ({ token, empresa_id, qntd }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/matriculas-por-mes/' + empresa_id, { qntd },{ headers })
    return { data: response.data }
  },
        matriculasPorPlano: async ({ token, empresa_id, qntd }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/matriculas-por-planos/' + empresa_id, { qntd },{ headers })
    return { data: response.data }
  },
    avaliacoesPorPersonal: async ({ token, empresa_id, qntd }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/avaliacoes-por-personal/' + empresa_id, { qntd },{ headers })
    return { data: response.data }
  },
    
  frequenciaAlunos: async ({ token, empresa_id }) => {
    
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/presenca-por-dia/' + empresa_id, {}, { headers });
    return { data: response.data }
  },    

  avaliacoesPorMes: async ({ token, data_inicial, data_final, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/avaliacoes-por-mes/' + empresa_id, {
      data_inicial,
      data_final
    }, { headers });
    return { data: response.data }
  },      

contasReceberPorMes: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/contas-receber-doze-meses/' + empresa_id, {

    }, { headers });
    return { data: response.data }
  },

  cancelamentosPorMes: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/contas-receber-doze-meses/' + empresa_id, {

    }, { headers });
    return { data: response.data }
  },
    

    horarioMovimentoAcademia: async ({ token, data_inicial, data_final, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/horarios-entrada-alunos/' + empresa_id, {
      data_inicial,
      data_final
    }, { headers });
    return { data: response.data }
  },
  // horarioMovimentoAcademia: async ({ token, data_inicial, data_final, empresa_id }) => {
    diasMovimentoAcademia: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/porcentagem-frequencias/' + empresa_id, {
   //   data_inicial,
    //  data_final
    }, { headers });
    return { data: response.data }
  },
    
    
})