
export default httpClient => ({
  listar: async ({ token, empresa_id } ) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/enquetes/' + empresa_id, { headers });
    return { data: response.data }
  },

  obterPorId: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/enquete/' + empresa_id, {
      id
    }, { headers })
    return { data: response.data }
  },


  qtdTotal: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/qtd-enquetes/' + empresa_id, {
      token: token
    }, { headers })
    return { data: response.data }
  },

  save: async ({ empresa_id, token, nome, pergunta, respostas, status }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/enquete/salvar/' + empresa_id, {
      token,
      nome,
      pergunta,
      respostas,
      status
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

    update: async ({ empresa_id, id, token, nome, pergunta, respostas, status  }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/enquete/editar/' + empresa_id, {
      id,
      nome,
      pergunta,
      respostas,
      status
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  deletar: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/enquete/deletar/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },

})