import { useStorage } from "vue3-storage";
import services from '../services'

export default {
    async auth(to, from, next) {
        
        const storage = useStorage();
        var token = storage.getStorageSync("token");

        
        await services.auth.verificaToken(token).then(data =>{
            if (data.data.autorizacao) {
                next();
            }
        }).catch(function () {
            next('/login');
        });
    }
}