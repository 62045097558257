<template>
  <barra-lateral />
  <div style="background-color: #f8f9fe; min-height: 100vh">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <topo :titulo="'Produtos'" @openOffcanvas="openOffcanvas"></topo>
          <div
            class="card shadow p-3"
            :class="type === 'dark' ? 'bg-default' : ''"
          >
            <div class="row align-items-center">
              <div class="col text-end mb-2">
                <button
                  class="btn btn-primary"
                  id="btn_new_product"
                  @click="abrirModal"
                >
                  Adicionar Produto
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="table-responsive">
                  <table
                    class="table tablesorter table align-items-center table-flush"
                  >
                    <thead class="thead-light">
                      <th>Código</th>
                      <th>Nome</th>
                      <th>Status</th>
                      <th></th>
                    </thead>
                    <tbody class="list">
                      <corpo-produto
                        v-for="produto in state.produtos"
                        :key="produto.id"
                        :produto="produto"
                        @visualizar="visualizar"
                        @deletar="deletar"
                      />
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    :open="state.status_modal"
    @close="state.status_modal = !state.status_modal"
    :width="'800px'"
  >
    <h1>Produtos</h1>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label>Código</label>
          <input
            type="text"
            class="form-control input_modal"
            v-model="state.id"
            readonly
          />
        </div>
      </div>
      <div class="col-lg-10">
        <div class="form-group">
          <label>Nome</label>
          <input
            type="text"
            class="form-control input_modal"
            v-model="state.nome"
          />
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label>Descrição</label>
          <textarea class="form-control" v-model="state.descricao"></textarea>
        </div>
      </div>
      <div class="col-md-6">
        <div class="inputFile">
          <span class="arquivo">Selecione um arquivo</span>
          <input
            type="file"
            class="form-control"
            @change="adicionarImagem"
            multiple
          />
        </div>
        <div
          style="display: flex; align-items: start; justify-content: flex-start"
        >
          <div v-for="imagem in state.imagens" :key="imagem">
            <div style="margin-top: 20px">
              <img :src="imagem.imagem" style="width: 100px" />
              <button
                type="button"
                class="btn_deletar"
                @click="deletarImagem(imagem.id)"
              >
                <i class="bi bi-trash"></i>
                <!--
              <img
                src="../../../public/img/btn_deletar.png"
                class="img_deletar"
              />-->
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>Tipo</label>
          <select class="form-select" v-model="state.tipo">
            <option value="venda">Venda</option>
            <option value="troca">Pontuação</option>
          </select>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>Status</label>
          <select class="form-select" v-model="state.status">
            <option value="ativo">Ativo</option>
            <option value="inativo">Inativo</option>
          </select>
        </div>
      </div>
    </div>

    <button
      class="btn btn_salvar my-4"
      id="submit-button"
      type="submit"
      @click="salvarProduto()"
    >
      Salvar
    </button>
    <button
      class="btn btn_cancelar my-4"
      id="submit-button"
      type="button"
      @click="fecharModal()"
    >
      Cancelar
    </button>
  </Modal>
  <OffcanvasComponent ref="offcanvasRef" />
</template>
<script>
import OffcanvasComponent from "../layout/Sistema/barraLateralMobile";
import { onMounted, reactive, onErrorCaptured, ref } from "vue";
import CorpoProduto from "../components/CorpoTabelaProduto";
import services from "../services";
import Modal from "../components/EstruturaModal/EstruturaModal.vue";
import topo from "../components/topo";
import { useStorage } from "vue3-storage";
import Datepicker from "vue3-datepicker";
import { pt } from "date-fns/locale";
import BarraLateral from "../layout/Sistema/barraLateral";
export default {
  name: "tables",
  components: {
    topo,
    Modal,
    BarraLateral,
    Datepicker,
    CorpoProduto,
    OffcanvasComponent,
  },
  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");
    const state = reactive({
      isLoading: false,
      currentClientType: "",
      hasError: false,
      id: "",
      nome: "",
      pergunta: "",
      tipo_caixa: "interno",
      descricao: "",
      imagens: [],
    });
    onErrorCaptured(handleErrors);

    onMounted(() => {
      fetchProdutos();
    });
    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }
    async function abrirModal() {
      state.status_modal = true;
    }

    function fecharModal() {
      state.id = "";
      state.nome = "";
      state.data_inicial = null;
      state.data_final = null;
      state.tipo = "";
      state.status = "";
      state.status_modal = false;
    }

    async function deletarImagem(index) {
      for (let i = 0; i < state.imagens.length; i++) {
        if (state.imagens[i].id == index) {
          //     console.log("Vai apagar esse: " + index);
          //   console.log("Vai ser nesse:" + state.imagens[i].id);
          state.imagens.splice(i, 1);
        }
      }
    }

    function fecharModalAtividade() {
      state.status_modal_atividades = false;
    }

    function fecharModalParticipantes() {
      state.status_modal_participantes = false;
    }

    async function visualizar(id) {
      try {
        let data = await services.produto.obterPorId({
          empresa_id,
          id,
          token,
        });
        let dados = data.data;
        state.id = dados.id;
        state.nome = dados.nome;
        state.descricao = dados.descricao;
        state.tipo = dados.tipo;
        state.status = dados.status;
        state.imagens = dados.imagens;
        state.status_modal = true;
      } catch (error) {
        console.log(error);
      }
    }

    async function adicionarImagem(event) {
      for (let index = 0; index < event.target.files.length; index++) {
        const element = event.target.files[index];
        var objImagem = new Object();
        objImagem.id = index;
        objImagem.file = element;
        objImagem.imagem = URL.createObjectURL(element);
        state.imagens.push(objImagem);
      }
    }

    function addResposta() {
      state.respostas.push({});
    }

    async function fetchAtividades() {
      try {
        state.isLoading = true;
        const { data } = await services.atividade.listar({
          token: token,
          empresa_id: empresa_id,
        });
        //  console.log(data)
        state.atividades = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function deletar(id) {
      if (confirm("Deseja deletar o produto?")) {
        await services.produto
          .deletar({
            token,
            empresa_id,
            id,
          })
          .then((data) => {
            if (data.data.verifica_erro) {
              alert("Erro ao deletar produto");
            } else {
              fetchProdutos();
            }
          })
          .catch(() => {
            alert("Erro ao deletar produto");
          });
      }
    }

    async function salvarProduto() {
      let retorno;
      if (state.id != null && state.id != undefined && state.id != "") {
        retorno = await services.produto.update({
          token,
          empresa_id,
          id: state.id,
          nome: state.nome,
          descricao: state.descricao,
          valor: state.valor,
          pontos: state.pontos,
          status: state.status,
          tipo: state.tipo,
        });
      } else {
        retorno = await services.produto.save({
          token,
          empresa_id,
          nome: state.nome,
          descricao: state.descricao,
          valor: state.valor,
          pontos: state.pontos,
          status: state.status,
          tipo: state.tipo,
        });
        console.log(retorno);
        state.id = retorno.id;
      }
      //Imagens
      for (let i = 0; i < state.imagens.length; i++) {
        var formImagem = new FormData();
        formImagem.append("produto_id", state.id);
        formImagem.append("imagem", state.imagens[i].file);
        await services.produto.saveImagem(
          formImagem,
          empresa_id,
          token
        ); /*.catch(() => {
          toast(
            "Erro ao adicionar Imagem. Verifique o tamanho e tipo de arquivo.",
            {
              type: "error",
              autoClose: 3000,
            }
          );
          erros.push("erro_laudo");
        });*/
      }
      if (retorno.data.verifica_erro == false) {
        fetchProdutos();
        state.id = "";
        state.nome = "";
        state.data_inicial = "";
        state.data_final = "";
        state.tipo = "";
        state.status = "";
        state.status_modal = false;
      } else {
        alert("Erro ao salvar plano");
      }
    }

    function convertBRDateToJSDate(dateStr) {
      // Separa a data em dia, mês e ano
      const [day, month, year] = dateStr.split("/").map(Number);

      // Cria um objeto Date. O mês é 0-indexado, então subtraímos 1
      return new Date(year, month - 1, day);
      // return isoDateStr;
    }

    async function fetchProdutos() {
      try {
        state.isLoading = true;
        const { data } = await services.produto.getAll({
          token: token,
          empresa_id: empresa_id,
        });
        //  console.log(data)
        state.produtos = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    function formatISODateToBR(isoDate) {
      // Cria um objeto Date a partir da string ISO
      const date = new Date(isoDate);

      // Ajusta para o horário local, convertendo de UTC para o fuso horário local
      const localDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );

      // Obtém o dia, mês e ano
      const day = String(localDate.getDate()).padStart(2, "0");
      const month = String(localDate.getMonth() + 1).padStart(2, "0"); // getMonth() é 0-indexado
      const year = localDate.getFullYear();

      // Obtém as horas e minutos
      //   const hours = String(localDate.getHours()).padStart(2, "0");
      //   const minutes = String(localDate.getMinutes()).padStart(2, "0");

      // Formata a data no formato brasileiro DD/MM/YYYY e a hora no formato HH:MM
      const formattedDate = `${day}/${month}/${year}`;

      // Resultado final
      return `${formattedDate}`;
    }
    const offcanvasRef = ref(null);
    const isVisible = ref(false);
    const openOffcanvas = () => {
      console.log(isVisible.value);
      if (isVisible.value) {
        offcanvasRef.value.hideOffcanvas();
      } else {
        offcanvasRef.value.showOffcanvas();
      }
    };
    return {
      state,
      abrirModal,
      fecharModal,
      fecharModalAtividade,
      salvarProduto,
      visualizar,
      fecharModalParticipantes,
      Modal,
      deletar,
      addResposta,
      adicionarImagem,
      deletarImagem,
      pt,
      offcanvasRef,
      openOffcanvas,
    };
  },
};
</script>

<style scoped>
.btn_deletar {
  color: #fff;
  background-color: #dc3545 !important;
  border: none;
  border-radius: 50%;
  padding: 5px 7px 3px 7px;
  font-size: 12pt;
  margin-left: -10px;
}

.btn-primary {
  background-color: rgb(3, 74, 166);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(3, 74, 166);
  border-radius: 10px;
  font-size: 10pt;
  padding: 12px;
  font-weight: 700;
  margin-bottom: 15px;
}

.btn_add_resposta {
  background-color: #034aa6;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #fff;
  padding: 0px;
  font-size: 16pt;
  border: none;
}

.grupo {
  width: 100%;
  height: 100px;
  border: #034aa6 solid 1px;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px;
}

.nome_grupo {
  font-weight: bold;
}

.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}

.btn_salvar:hover {
  transform: none;
  color: #fff;
}

.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}

.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}

textarea {
  resize: none;
}
@media (max-width: 767px) {
  #btn_new_product {
    width: 100%;
    margin-top: 10px;
    text-align: center;
  }
}
</style>