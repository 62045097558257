<template>
  <barra-lateral />
  <div style="background-color: #f8f9fe; min-height: 100vh">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <topo :titulo="'Avaliações'" @openOffcanvas="openOffcanvas"></topo>
          <div style="margin-top: 10px">
            <base-header
              type="gradient-success"
              class="pb-md-6 pb-md-8 pt-md-3"
            >
              <div class="row">
                <div class="col-4 col-md-2">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Código"
                    v-model="state.codigo"
                  />
                </div>
                <div class="col-8 col-md-5">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Nome"
                    v-model="state.nome_busca"
                  />
                </div>
                <div class="col-12 col-md-2">
                  <base-button
                    class="btn_busca"
                    @click="buscarAvaliacoes()"
                    type="primary"
                    id="btn_new_product"
                    >Buscar</base-button
                  >
                </div>
                <div class="col-12 col-md-3">
                  <router-link to="/adicionar-avaliacao">
                    <button type="button" class="btn_cadastra_ava">
                      Cadastrar Avaliação
                    </button>
                  </router-link>
                </div>
              </div>
              <!-- Card stats -->
              <div class="row d-none d-md-flex mt-3">
                <div class="col-xl-3 col-lg-4 col-md-4">
                  <div class="card-stats">
                    <img
                      class="icon"
                      src="../assets/img_new/icon7.png"
                      alt="Finanças"
                    />
                    <p class="avaliacao">Avaliação no Mês</p>
                    <div class="num">{{ state.qtdAvaliacoesMes }}</div>
                    <div class="conteudo">
                      <!--  <span class="arow"><i class="fa fa-arrow-up"></i> 12.18%</span>
              <span class="text1">Nos últimos 30 dias</span> -->
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-4">
                  <div class="card-stats">
                    <img
                      class="icon"
                      src="../assets/img_new/icon8.png"
                      alt="Finanças"
                    />
                    <p class="avaliacao">Mês Passado</p>
                    <div class="num">{{ state.qtdAvaliacoesMesAnterior }}</div>
                    <div class="conteudo"></div>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-4" v-if="state.totalAva">
                  <div class="card-stats">
                    <img
                      class="icon"
                      src="../assets/img_new/icon9.png"
                      alt="Finanças"
                    />
                    <p class="avaliacao">Total de Avaliação</p>
                    <div class="num">{{ state.qtdAvaliacoesTotal }}</div>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-4">
                  <div class="card-stats">
                    <img
                      class="icon"
                      src="../assets/img_new/icon10.png"
                      alt="Gráfico"
                    />
                    <p class="avaliacao">Média Mensal</p>
                    <div class="num">{{ state.mediaMensal }}</div>
                  </div>
                </div>
              </div>
            </base-header>
            <div class="mt-3 mt-sm-0">
              <div class="card shadow">
                <div class="row" style="margin-top: 10px">
                  <div class="col">
                    <div class="table-responsive">
                      <avaliacao-loading v-if="state.isLoading" />
                      <table
                        class="table tablesorter table align-items-center table-flush"
                        v-else
                      >
                        <thead class="thead-light">
                          <th class="d-none d-lg-block">Código</th>
                          <!-- id="tit_codigo" -->
                          <th>Nome</th>
                          <th>Data / Hora</th>
                          <th>Personal</th>
                          <th class="d-block d-md-none d-lg-block">Status</th>
                          <th></th>
                        </thead>
                        <tbody class="list">
                          <corpo-tabela-avaliacao
                            v-for="avaliacao in state.avaliacoes"
                            :key="avaliacao.id"
                            :avaliacao="avaliacao"
                            @fazerAvaliacao="fazerAvaliacao"
                            @visualizarAvaliacao="visualizarAvaliacao"
                            @fazerComparacao="fazerComparacao"
                            @imprimirAvaliacao="imprimirAvaliacao"
                            @deletarAvaliacao="deletarAvaliacao"
                            @alterarDataHora="alterarDataHora"
                          />
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div>
                  <nav aria-label="">
                    <ul class="pagination">
                      <li class="page-item">
                        <a class="page-link" href="#" aria-label="Anterior">
                          <span class="sr-only">Anterior</span>
                        </a>
                      </li>
                      <div
                        class="page-item"
                        v-for="pagina in state.qtd_paginacao"
                        :key="pagina"
                        :class="{
                          active: pagina == state.pagina_selecionada,
                        }"
                      >
                        <li
                          v-if="
                            pagina == state.pagina_selecionada ||
                            pagina <= state.pagina_selecionada + 2 ||
                            pagina <= state.pagina_selecionada - 2 ||
                            pagina == state.qtd_paginacao
                          "
                        >
                          <a
                            class="page-link"
                            @click="selecionarPagina(pagina)"
                            >{{ pagina }}</a
                          >
                        </li>
                      </div>

                      <li
                        class="page-item"
                        :class="{
                          disabled:
                            state.pagina_selecionada == state.qtd_paginacao,
                        }"
                      >
                        <a class="page-link" href="#" aria-label="Next">
                          <span class="sr-only">Próxima</span>
                        </a>
                      </li>
                      <li>
                        <select
                          class="form-select"
                          v-model="state.qtd_por_pagina"
                          @change="fetchAvaliacoes"
                        >
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Modal fazer avaliação-->
  <Modal
    :open="state.statusModalFazerAvaliacao"
    @close="state.statusModalFazerAvaliacao = !state.statusModalFazerAvaliacao"
    :width="'800px'"
    style="padding: 20px; z-index: 100"
  >
    <h1 class="avaliacao_de">Avaliação de {{ state.nome }}</h1>

    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="imc-tab"
          data-bs-toggle="tab"
          data-bs-target="#imc"
          type="button"
          role="tab"
          aria-controls="imc"
          aria-selected="true"
        >
          IMC
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="circunferencia-tab"
          data-bs-toggle="tab"
          data-bs-target="#circunferencia"
          type="button"
          role="tab"
          aria-controls="circunferencia"
          aria-selected="false"
        >
          Circunferência
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="subcuntaneas-tab"
          data-bs-toggle="tab"
          data-bs-target="#subcuntaneas"
          type="button"
          role="tab"
          aria-controls="subcuntaneas"
          aria-selected="false"
        >
          Subcutâneas
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="teste_fisico-tab"
          data-bs-toggle="tab"
          data-bs-target="#teste_fisico"
          type="button"
          role="tab"
          aria-controls="teste_fisico"
          aria-selected="false"
        >
          Teste Físico
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="composicao_corporal-tab"
          data-bs-toggle="tab"
          data-bs-target="#composicao_corporal"
          type="button"
          role="tab"
          aria-controls="composicao_corporal"
          aria-selected="false"
        >
          Composição Corporal
        </button>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="imc"
        role="tabpanel"
        aria-labelledby="imc-tab"
      >
        <h4>Dados do Aluno</h4>

        <div class="row">
          <div class="col-12 col-sm-8">
            <div class="row">
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label>Idade </label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="state.idade.value"
                  />
                </div>
              </div>
            </div>
            <h4>Índice de Massa Corporal</h4>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Altura (m) Ex: 1,75 </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.altura.value"
                    v-mask="'#,##'"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Peso (kg) </label>
                  <input
                    @blur="calcularIMC"
                    type="text"
                    class="form-control"
                    v-model="state.peso.value"
                    v-mask="['##,##', '###,##']"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>IMC kg/m³ </label>
                  <input
                    type="text"
                    readonly
                    class="form-control"
                    v-model="state.imc.value"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="circunferencia"
        role="tabpanel"
        aria-labelledby="circunferencia-tab"
      >
        <div class="row">
          <div class="col-12 col-md-8">
            <h4>Medidas de Circunferências</h4>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Ombro</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.ombro.value"
                    v-mask="['##', '###']"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Torax</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.torax.value"
                    v-mask="['##', '###']"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Braço Direito</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.braco_dir.value"
                    v-mask="['##', '###']"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Braço Esquerdo</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.braco_esq.value"
                    v-mask="['##', '###']"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Antebraço Direito</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.antebraco_dir.value"
                    v-mask="['##', '###']"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Antebraço Esquerdo</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.antebraco_esq.value"
                    v-mask="['##', '###']"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Abdomem</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.abdomem.value"
                    v-mask="['##', '###']"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Cintura</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.cintura.value"
                    v-mask="['##', '###']"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Quadril</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.quadril.value"
                    @blur="calcularRelacaoCinturaQuad"
                    v-mask="['##', '###']"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Coxa Direita</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.coxa_dir.value"
                    v-mask="['##', '###']"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Coxa Esquerda</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.coxa_esq.value"
                    v-mask="['##', '###']"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Panturrilha Dir.</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.panturrilha_dir.value"
                    v-mask="['##', '###']"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Panturrilha Esq.</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.panturrilha_esq.value"
                    v-mask="['##', '###']"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Relação Cintura/Quadril</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.relacao_cintura_quadril.value"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Resultado Relação</label>
                  <input
                    class="form-control"
                    v-model="state.resultado_relacao_cq.value"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="subcuntaneas"
        role="tabpanel"
        aria-labelledby="subcuntaneas-tab"
      >
        <h4>Subcutâneas</h4>
        <div class="row">
          <div class="col-12 col-md-8">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Biceps</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.biceps_subc.value"
                    v-mask="['##', '###']"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Triceptal</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.triceptal_subc.value"
                    v-mask="['##', '###']"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Subescapular</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.subescapular_subc.value"
                    v-mask="['##', '###']"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Axila</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.axila_subc.value"
                    v-mask="['##', '###']"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Supra Ilíaca</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.suprailiaca_subc.value"
                    v-mask="['##', '###']"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Torax</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.torax_subc.value"
                    v-mask="['##', '###']"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Abdominal</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.abdominal_subc.value"
                    v-mask="['##', '###']"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Coxa</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.coxa_subc.value"
                    @blur="calcularSubcutanteas"
                    v-mask="['##', '###']"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Panturrilha</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.panturrilha_subc.value"
                    v-mask="['##', '###']"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="teste_fisico"
        role="tabpanel"
        aria-labelledby="teste_fisico-tab"
      >
        <h4>Teste Físico</h4>

        <div class="row">
          <div class="col-6 col-lg-4">
            <div class="form-group">
              <label>Força Abdominal</label>
              <input
                type="text"
                class="form-control"
                v-model="state.forca_abdominal.value"
              />
            </div>
          </div>
          <div class="col-6 col-lg-4">
            <div class="form-group">
              <label>Força MMII (IVC/cm³)</label>
              <input
                type="text"
                class="form-control"
                v-model="state.forca_mmii.value"
              />
            </div>
          </div>
          <div class="col-6 col-lg-4">
            <div class="form-group">
              <label>Flexibilidade</label>
              <input
                type="text"
                class="form-control"
                v-model="state.flexibilidade.value"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="composicao_corporal"
        role="tabpanel"
        aria-labelledby="composicao_corporal-tab"
      >
        <h4>Composição Corporal</h4>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-3">
            <div class="form-group">
              <label>% de Gordura</label>
              <input
                type="text"
                class="form-control"
                v-model="state.percentual_gordura.value"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <div class="form-group">
              <label>Peso da Gordura</label>
              <input
                type="text"
                class="form-control"
                v-model="state.peso_gordura.value"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <div class="form-group">
              <label>Densidade Corporal</label>
              <input
                type="text"
                class="form-control"
                v-model="state.densidade_corporal.value"
              />
            </div>
          </div>
          <!---<div class="col-3">
            <div class="form-group">
              <label>Taxa Metabólica Basal</label>
              <input type="text" class="form-control" />
            </div>
          </div>-->
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-3">
            <div class="form-group">
              <label>% Massa Magra</label>
              <input
                type="text"
                class="form-control"
                v-model="state.percentual_massa_magra.value"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <div class="form-group">
              <label>Peso da Massa Magra</label>
              <input
                type="text"
                class="form-control"
                v-model="state.peso_massa_magra.value"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <div class="form-group">
              <label>Peso Ideal</label>
              <input
                type="text"
                class="form-control"
                v-model="state.peso_ideal.value"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <div class="form-group">
              <label>Peso Excesso</label>
              <input
                type="text"
                class="form-control"
                v-model="state.peso_excesso.value"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col text-right mb-2">
      <button type="button" class="btn btn_salvar" @click="salvarAvaliacao">
        Salvar
      </button>
      <button type="button" class="btn btn_cancelar" @click="cancelarAvaliacao">
        Cancelar
      </button>

      <!--  <button @click="handleAgendarAvaliacao()">Agendar Avaliação</button> -->
    </div>
  </Modal>

  <!--Modal visualizar avaliação-->
  <Modal
    :open="state.statusModalVisualizarAvaliacao"
    @close="
      state.statusModalVisualizarAvaliacao =
        !state.statusModalVisualizarAvaliacao
    "
    :width="'800px'"
    style="padding: 20px"
  >
    <h1 class="avaliacao_de">Avaliação de {{ state.nome }}</h1>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="imc2-tab"
          data-bs-toggle="tab"
          data-bs-target="#imc2"
          type="button"
          role="tab"
          aria-controls="imc2"
          aria-selected="true"
        >
          IMC
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="circuferencias-tab"
          data-bs-toggle="tab"
          data-bs-target="#circuferencias"
          type="button"
          role="tab"
          aria-controls="circuferencias"
          aria-selected="false"
        >
          Circunferências
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="subcutaneas-tab"
          data-bs-toggle="tab"
          data-bs-target="#subcutaneas"
          type="button"
          role="tab"
          aria-controls="subcutaneas"
          aria-selected="false"
        >
          Subcutâneas
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="teste_fisico2-tab"
          data-bs-toggle="tab"
          data-bs-target="#teste_fisico2"
          type="button"
          role="tab"
          aria-controls="teste_fisico2"
          aria-selected="false"
        >
          Teste Físico
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="comp_corporal-tab"
          data-bs-toggle="tab"
          data-bs-target="#comp_corporal"
          type="button"
          role="tab"
          aria-controls="comp_corporal"
          aria-selected="false"
        >
          Composição Corporal
        </button>
      </li>
    </ul>

    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="imc2"
        role="tabpanel"
        aria-labelledby="imc2-tab"
      >
        <h4>Dados do Aluno</h4>

        <div class="row">
          <div class="col-12 col-md-8">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Idade </label>
                  <p>{{ state.idade.value }} anos</p>
                </div>
              </div>
            </div>
            <h4>Índice de Massa Corporal</h4>
            <div class="row">
              <div class="col-6">
                <div class="form-group" v-if="state.modo == 'visualizar'">
                  <label>Altura (m)</label>
                  <p>{{ state.altura.value }}</p>
                </div>
                <div class="form-group" v-if="state.modo == 'editar'">
                  <label>Altura (m)</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.altura.value"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group" v-if="state.modo == 'visualizar'">
                  <label>Peso (kg) </label>
                  <p>
                    {{
                      state.peso_banco.toLocaleString("pt-br", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }}
                  </p>
                </div>
                <div class="form-group" v-if="state.modo == 'editar'">
                  <label>Peso (kg) </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.peso.value"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>IMC kg/m³ </label>
                  <p>
                    {{
                      state.imc.value.toLocaleString("pt-br", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4"></div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="circuferencias"
        role="tabpanel"
        aria-labelledby="circuferencias-tab"
      >
        <div class="row">
          <div class="col-12 col-md-8">
            <h4>Medidas de Circunferências</h4>
            <div class="row">
              <div class="col-6">
                <div class="form-group" v-if="state.modo == 'visualizar'">
                  <label>Ombro</label>
                  <p>{{ state.ombro.value }}</p>
                </div>
                <div class="form-group" v-if="state.modo == 'editar'">
                  <label>Ombro</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.ombro.value"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group" v-if="state.modo == 'visualizar'">
                  <label>Torax</label>
                  <p>{{ state.torax.value }}</p>
                </div>
                <div class="form-group" v-if="state.modo == 'editar'">
                  <label>Torax</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.torax.value"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6 col-md-6">
                <div class="form-group" v-if="state.modo == 'visualizar'">
                  <label>Cintura</label>
                  <p>{{ state.cintura.value }}</p>
                </div>
                <div class="form-group" v-if="state.modo == 'editar'">
                  <label>Cintura</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.cintura.value"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group" v-if="state.modo == 'visualizar'">
                  <label>Quadril</label>
                  <p>{{ state.quadril.value }}</p>
                </div>
                <div class="form-group" v-if="state.modo == 'editar'">
                  <label>Quadril</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.quadril.value"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form-group" v-if="state.modo == 'visualizar'">
                  <label>Abdomem</label>
                  <p>{{ state.abdomem.value }}</p>
                </div>
                <div class="form-group" v-if="state.modo == 'editar'">
                  <label>Abdomem</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.abdomem.value"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form-group" v-if="state.modo == 'visualizar'">
                  <label>Coxa Direita</label>
                  <p>{{ state.coxa_dir.value }}</p>
                </div>
                <div class="form-group" v-if="state.modo == 'editar'">
                  <label>Coxa Direita</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.coxa_dir.value"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group" v-if="state.modo == 'visualizar'">
                  <label>Coxa Esquerda</label>
                  <p>{{ state.coxa_esq.value }}</p>
                </div>
                <div class="form-group" v-if="state.modo == 'editar'">
                  <label>Coxa Esquerda</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.coxa_esq.value"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group" v-if="state.modo == 'visualizar'">
                  <label>Braço Direito</label>
                  <p>{{ state.braco_dir.value }}</p>
                </div>
                <div class="form-group" v-if="state.modo == 'editar'">
                  <label>Braço Direito</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.braco_dir.value"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group" v-if="state.modo == 'visualizar'">
                  <label>Braço Esquerdo</label>
                  <p>{{ state.braco_esq.value }}</p>
                </div>
                <div class="form-group" v-if="state.modo == 'editar'">
                  <label>Braço Esquerdo</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.braco_esq.value"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group" v-if="state.modo == 'visualizar'">
                  <label>Antebraço Direito</label>
                  <p>{{ state.antebraco_dir.value }}</p>
                </div>
                <div class="form-group" v-if="state.modo == 'editar'">
                  <label>Antebraço Direito</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.antebraco_dir.value"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group" v-if="state.modo == 'visualizar'">
                  <label>Antebraço Esquerdo</label>
                  <p>{{ state.antebraco_esq.value }}</p>
                </div>
                <div class="form-group" v-if="state.modo == 'editar'">
                  <label>Antebraço Esquerdo</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.antebraco_esq.value"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group" v-if="state.modo == 'visualizar'">
                  <label>Panturrilha Dir.</label>
                  <p>{{ state.panturrilha_dir.value }}</p>
                </div>
                <div class="form-group" v-if="state.modo == 'editar'">
                  <label>Panturrilha Dir.</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.panturrilha_dir.value"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group" v-if="state.modo == 'visualizar'">
                  <label>Panturrilha Esq.</label>
                  <p>{{ state.panturrilha_esq.value }}</p>
                </div>
                <div class="form-group" v-if="state.modo == 'editar'">
                  <label>Panturrilha Esq.</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.panturrilha_esq.value"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group" v-if="state.modo == 'visualizar'">
                  <label>Relação Cintura/Quadril</label>
                  <p>{{ state.relacao_cintura_quadril.value }}</p>
                </div>
                <div class="form-group" v-if="state.modo == 'editar'">
                  <label>Relação Cintura/Quadril</label>
                  <input
                    type="text"
                    class="form-control"
                    readonly
                    v-model="state.relacao_cintura_quadril.value"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group" v-if="state.modo == 'visualizar'">
                  <label>Resultado Relação</label>
                  <p>{{ state.resultado_relacao_cq.value }}</p>
                </div>
                <div class="form-group" v-if="state.modo == 'editar'">
                  <label>Resultado Relação</label>
                  <input
                    type="text"
                    class="form-control"
                    readonly
                    v-model="state.resultado_relacao_cq.value"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="subcutaneas"
        role="tabpanel"
        aria-labelledby="subcutaneas-tab"
      >
        <h4>Subcutâneas</h4>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="row">
              <div class="col-6">
                <div class="form-group" v-if="state.modo == 'visualizar'">
                  <label>Biceps</label>
                  <p>{{ state.biceps_subc.value }}</p>
                </div>
                <div class="form-group" v-if="state.modo == 'editar'">
                  <label>Biceps</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.biceps_subc.value"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group" v-if="state.modo == 'visualizar'">
                  <label>Triceptal</label>
                  <p>{{ state.triceptal_subc.value }}</p>
                </div>
                <div class="form-group" v-if="state.modo == 'editar'">
                  <label>Triceptal</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.triceptal_subc.value"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group" v-if="state.modo == 'visualizar'">
                  <label>Subescapular</label>
                  <p>{{ state.subescapular_subc.value }}</p>
                </div>
                <div class="form-group" v-if="state.modo == 'editar'">
                  <label>Subescapular</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.triceptal_subc.value"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group" v-if="state.modo == 'visualizar'">
                  <label>Supra Ilíaca</label>
                  <p>{{ state.suprailiaca_subc.value }}</p>
                </div>
                <div class="form-group" v-if="state.modo == 'editar'">
                  <label>Supra Ilíaca</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.suprailiaca_subc.value"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group" v-if="state.modo == 'visualizar'">
                  <label>Torax</label>
                  <p>{{ state.torax_subc.value }}</p>
                </div>
                <div class="form-group" v-if="state.modo == 'editar'">
                  <label>Torax</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.torax_subc.value"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group" v-if="state.modo == 'visualizar'">
                  <label>Axila</label>
                  <p>{{ state.axila_subc.value }}</p>
                </div>
                <div class="form-group" v-if="state.modo == 'editar'">
                  <label>Axila</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.axila_subc.value"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group" v-if="state.modo == 'visualizar'">
                  <label>Abdominal</label>
                  <p>{{ state.abdominal_subc.value }}</p>
                </div>
                <div class="form-group" v-if="state.modo == 'editar'">
                  <label>Abdominal</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.abdominal_subc.value"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group" v-if="state.modo == 'visualizar'">
                  <label>Coxa</label>
                  <p>{{ state.coxa_subc.value }}</p>
                </div>
                <div class="form-group" v-if="state.modo == 'editar'">
                  <label>Coxa</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.coxa_subc.value"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group" v-if="state.modo == 'visualizar'">
                  <label>Panturrilha</label>
                  <p>{{ state.panturrilha_subc.value }}</p>
                </div>
                <div class="form-group" v-if="state.modo == 'editar'">
                  <label>Panturrilha</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.panturrilha_subc.value"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="teste_fisico2"
        role="tabpanel"
        aria-labelledby="teste_fisico2-tab"
      >
        <h4>Teste Físico</h4>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="form-group">
                  <label>Força Abdominal</label>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Força MMII (IVC/cm³)</label>
                  <input type="text" class="form-control" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Flexibilidade</label>
                  <input type="text" class="form-control" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="comp_corporal"
        role="tabpanel"
        aria-labelledby="comp_corporal-tab"
      >
        <h4>Composição Corporal</h4>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label>IMC</label>
              <p>
                {{ state.imc.value.toLocaleString("pt-br") }}
              </p>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label>Densidade Corporal</label>
              <p>
                {{
                  state.densidade_corporal.value.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label>% de Gordura</label>
              <p>
                {{
                  state.percentual_gordura.value.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
              </p>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>Resultado Porcentagem da Gordura</label>
              <p>{{ state.resultado_perc_gordura }}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label>Peso da Gordura</label>
              <p>
                {{
                  state.peso_gordura.value.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
              </p>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label>Peso em Excesso</label>
              <p>
                {{
                  state.peso_excesso.value.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
              </p>
            </div>
          </div>
        </div>
        <h4>{{ state.resultado_imc.value }}</h4>
        <div v-if="state.sexo == 'masculino'">
          <img
            src="/img/corpo_masculino/corpo_masculino1.png"
            style="width: 100%"
            v-if="state.sigla_imc === 'MS' || state.sigla_imc === 'MM'"
          />
          <img
            src="/img/corpo_masculino/corpo_masculino2.png"
            style="width: 100%"
            v-else-if="state.sigla_imc == 'ML'"
          />
          <img
            src="/img/corpo_masculino/corpo_masculino3.png"
            style="width: 100%"
            v-else-if="state.sigla_imc == 'PN' || state.sigla_imc == 'SP'"
          />
          <img
            src="/img/corpo_masculino/corpo_masculino4.png"
            style="width: 100%"
            v-else-if="state.sigla_imc == 'OG1' || state.sigla_imc == 'OG2'"
          />
          <img
            src="/img/corpo_masculino/corpo_masculino5.png"
            style="width: 100%"
            v-else-if="state.sigla_imc == 'OG3'"
          />
        </div>
        <div v-else-if="state.sexo == 'feminino'">
          <img
            src="/img/corpo_feminino/corpo_feminino1.png"
            style="width: 100%"
            v-if="state.sigla_imc === 'MS' || state.sigla_imc === 'MM'"
          />
          <img
            src="/img/corpo_feminino/corpo_feminino2.png"
            style="width: 100%"
            v-else-if="state.sigla_imc == 'ML'"
          />
          <img
            src="/img/corpo_feminino/corpo_feminino3.png"
            style="width: 100%"
            v-else-if="state.sigla_imc == 'PN' || state.sigla_imc == 'SP'"
          />
          <img
            src="/img/corpo_feminino/corpo_feminino4.png"
            style="width: 100%"
            v-else-if="state.sigla_imc == 'OG1' || state.sigla_imc == 'OG2'"
          />
          <img
            src="/img/corpo_feminino/corpo_feminino5.png"
            style="width: 100%"
            v-else-if="state.sigla_imc == 'OG3'"
          />
        </div>
      </div>
    </div>

    <div class="col text-end mb-2">
      <button
        type="button"
        class="btn btn_salvar"
        @click="updateModal"
        v-if="state.modo == 'editar'"
      >
        Salvar
      </button>

      <button
        type="button"
        class="btn btn_salvar"
        @click="editarModo"
        v-if="state.modo == 'visualizar'"
      >
        Editar
      </button>
      <button
        type="button"
        class="btn btn_cancelar"
        @click="state.statusModalVisualizarAvaliacao = false"
        v-if="state.modo == 'visualizar'"
      >
        Fechar
      </button>
      <button
        type="button"
        class="btn btn_cancelar"
        @click="editarModo"
        v-if="state.modo == 'editar'"
      >
        Cancelar
      </button>

      <!--  <button @click="handleAgendarAvaliacao()">Agendar Avaliação</button> -->
    </div>
  </Modal>

  <!--Modal comparação-->
  <Modal
    :open="state.statusModalComparacao"
    @close="state.statusModalComparacao = !state.statusModalComparacao"
    :width="'800px'"
    style="padding: 20px  z-index: 100;"
  >
    <h1>Comparar Avaliação de {{ state.avaliacao1.nome_cliente }}</h1>
    <div class="row">
      <div class="col-md-10">
        <div class="row" v-if="!state.visualizarComparacao">
          <div class="col-md-4">
            <div>
              <label>Avaliação Antiga</label>
            </div>
            <select v-model="state.data_avaliacao_antiga" class="form-select">
              <option
                v-for="data in state.data_avalicao"
                :key="data"
                :data="data"
                :value="data.id"
              >
                {{ data.data_avaliacao }}
              </option>
            </select>
          </div>
          <div class="col-md-4">
            <div>
              <label>Avaliação Nova</label>
            </div>
            <select v-model="state.data_avaliacao_nova" class="form-select">
              <option
                v-for="data in state.data_avalicao"
                :key="data"
                :data="data"
                :value="data.id"
              >
                {{ data.data_avaliacao }}
              </option>
            </select>
          </div>
          <div class="col-md-2">
            <button
              type="button"
              class="btn btn_compara"
              @click="compararAvaliacao"
            >
              Comparar
            </button>
          </div>
        </div>
      </div>
    </div>

    <ul
      class="nav nav-tabs"
      id="myTab"
      role="tablist"
      v-if="state.visualizarComparacao"
    >
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="imc_cp-tab"
          data-bs-toggle="tab"
          data-bs-target="#imc_cp"
          type="button"
          role="tab"
          aria-controls="imc_cp"
          aria-selected="true"
        >
          IMC
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="circ_cp-tab"
          data-bs-toggle="tab"
          data-bs-target="#circ_cp"
          type="button"
          role="tab"
          aria-controls="circ_cp"
          aria-selected="false"
        >
          Circunferência
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="subc_cp-tab"
          data-bs-toggle="tab"
          data-bs-target="#subc_cp"
          type="button"
          role="tab"
          aria-controls="subc_cp"
          aria-selected="false"
        >
          Subcutânea
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="teste_fis_cp-tab"
          data-bs-toggle="tab"
          data-bs-target="#teste_fis_cp"
          type="button"
          role="tab"
          aria-controls="teste_fis_cp"
          aria-selected="false"
        >
          Teste Físico
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="comp_corp_cp-tab"
          data-bs-toggle="tab"
          data-bs-target="#comp_corp_cp"
          type="button"
          role="tab"
          aria-controls="comp_corp_cp"
          aria-selected="false"
        >
          Composição Corporal
        </button>
      </li>
    </ul>
    <div
      class="tab-content"
      id="myTabContent"
      v-if="state.visualizarComparacao"
    >
      <div
        class="tab-pane fade show active"
        id="imc_cp"
        role="tabpanel"
        aria-labelledby="imc_cp-tab"
      >
        <div class="row">
          <div class="col-3 title"><label>Dados do aluno</label></div>
          <div class="col-3 title">
            <label>{{ state.avaliacao1.data_avaliacao }}</label>
          </div>
          <div class="col-3 title">
            <label>{{ state.avaliacao2.data_avaliacao }}</label>
          </div>
          <div class="col-3 title"><label>Diferença</label></div>
        </div>
        <div class="divisor"></div>
        <div class="row">
          <div class="col-3"><label>Idade:</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.idade_atual }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.idade_atual }}</label>
          </div>
          <div class="col-3"><label></label></div>
        </div>
        <div></div>
        <div class="row">
          <div class="col-5"><label>Indíce de Massa Corporal</label></div>
        </div>
        <div class="divisor"></div>
        <div class="row">
          <div class="col-3"><label>Altura:</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.altura }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.altura }}</label>
          </div>
          <div class="col-3"><label></label></div>
        </div>
        <div class="row">
          <div class="col-3"><label>Peso:</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.peso }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.peso }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.diferenca.peso }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label>IMC kg/m²:</label></div>
          <div class="col-3">
            <label>
              {{
                state.avaliacao1.imc.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}
            </label>
          </div>
          <div class="col-3">
            <label>
              {{
                state.avaliacao2.imc.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}
            </label>
          </div>
          <div class="col-3">
            <label>{{ state.diferenca.imc }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label>Resultado:</label></div>
          <div class="col-3">
            <label> {{ state.avaliacao1.resultado_imc }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.resultado_imc }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label> % Massa Magra:</label></div>
          <div class="col-3">
            <label>
              {{
                state.avaliacao1.perc_massa_magra.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}</label
            >
          </div>
          <div class="col-3">
            <label>
              {{
                state.avaliacao2.perc_massa_magra.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}</label
            >
          </div>
          <div class="col-3">
            <label>{{ state.diferenca.perc_massa_magra }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label>Massa Gorda:</label></div>
          <div class="col-3">
            <label>
              {{
                state.avaliacao1.peso_gordura.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}</label
            >
          </div>
          <div class="col-3">
            <label>{{
              state.avaliacao2.peso_gordura.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            }}</label>
          </div>
          <div class="col-3">
            <label>
              {{
                state.diferenca.peso_gordura.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}</label
            >
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label> % Gordura :</label></div>
          <div class="col-3">
            <label>
              {{
                state.avaliacao1.percentual_gordura.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}</label
            >
          </div>
          <div class="col-3">
            <label>
              {{
                state.avaliacao2.percentual_gordura.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}</label
            >
          </div>
          <div class="col-3">
            <label>{{ state.diferenca.percentual_gordura }}</label>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="circ_cp"
        role="tabpanel"
        aria-labelledby="circ_cp-tab"
      >
        <div class="row">
          <div class="col-3 title">
            <label>Medidas de Circunferência</label>
          </div>
          <div class="col-3 title">
            <label>{{ state.avaliacao1.data_avaliacao }}</label>
          </div>
          <div class="col-3 title">
            <label>{{ state.avaliacao2.data_avaliacao }}</label>
          </div>
          <div class="col-3 title"><label>Diferença</label></div>
        </div>
        <div class="divisor"></div>
        <div class="row">
          <div class="col-3"><label>Ombro:</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.ombro }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.ombro }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.diferenca.ombro }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label>Braço Dir:</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.braco_dir }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.braco_dir }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.diferenca.braco_dir }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label>Braço Esq:</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.braco_esq }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.braco_esq }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.diferenca.braco_esq }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label>Antebraço Dir:</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.antebraco_dir }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.antebraco_dir }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.diferenca.antebraco_dir }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label>Antebraço Esq:</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.antebraco_esq }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.antebraco_esq }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.diferenca.antebraco_esq }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label>Tórax:</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.busto }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.busto }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.diferenca.busto }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label>Abdômem:</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.abdomem }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.abdomem }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.diferenca.abdomem }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label>Cintura:</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.cintura }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.cintura }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.diferenca.cintura }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label>Quadril:</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.quadril }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.quadril }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.diferenca.quadril }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label>Coxa Dir:</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.coxa_dir }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.coxa_dir }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.diferenca.coxa_dir }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label>Coxa Esq:</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.coxa_esq }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.coxa_esq }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.diferenca.coxa_esq }}</label>
          </div>
        </div>

        <div class="row">
          <div class="col-3"><label>Panturrilha Dir:</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.panturrilha_dir }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.panturrilha_dir }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.diferenca.panturrilha_dir }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label>Panturrilha Esq:</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.panturrilha_esq }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.panturrilha_esq }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.diferenca.panturrilha_esq }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label>Relação Cintura/Quadril:</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.relacao_cintura_quadril }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.relacao_cintura_quadril }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.diferenca.relacao_cintura_quadril }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <span class="resultados">Resultado Relação 1:</span>
          </div>
          <div class="col-3">
            <label
              ><span class="resultados">{{
                state.avaliacao1.resultado_relacao_cq
              }}</span></label
            >
          </div>
          <div class="col-3">
            <span class="resultados">Resultado Relação 2:</span>
          </div>
          <div class="col-3">
            <label
              ><span class="resultados">{{
                state.avaliacao2.resultado_relacao_cq
              }}</span></label
            >
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="subc_cp"
        role="tabpanel"
        aria-labelledby="subc_cp-tab"
      >
        <div class="row">
          <div class="col-3 title"><label>Medidas Subcutânea</label></div>
          <div class="col-3 title">
            <label>{{ state.avaliacao1.data_avaliacao }}</label>
          </div>
          <div class="col-3 title">
            <label>{{ state.avaliacao2.data_avaliacao }}</label>
          </div>
          <div class="col-3 title"><label>Diferença</label></div>
        </div>
        <div class="divisor"></div>
        <div class="row">
          <div class="col-3"><label>Biceps:</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.biceps_subc }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.biceps_subc }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.diferenca.biceps_subc }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label>Triceptal:</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.triceptal_subc }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.triceptal_subc }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.diferenca.triceptal_subc }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label>Subescapular:</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.subescapular_subc }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.subescapular_subc }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.diferenca.subescapular_subc }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label>Axila:</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.axila_subc }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.axila_subc }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.diferenca.axila_subc }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label>Supra Ilíaca:</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.suprailiaca_subc }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.suprailiaca_subc }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.diferenca.suprailiaca_subc }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label>Toráx :</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.torax_subc }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.torax_subc }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.diferenca.torax_subc }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label>Abdômem:</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.abdominal_subc }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.abdominal_subc }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.diferenca.abdominal_subc }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label>Coxa:</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.coxa_subc }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.coxa_subc }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.diferenca.coxa_subc }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label>Panturrilha:</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.panturrilha_subc }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.panturrilha_subc }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.diferenca.panturrilha_subc }}</label>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="teste_fis_cp"
        role="tabpanel"
        aria-labelledby="teste_fis_cp-tab"
      >
        <div class="row">
          <div class="col-3 title"><label>Teste Físico</label></div>
          <div class="col-3 title">
            <label>{{ state.avaliacao1.data_avaliacao }}</label>
          </div>
          <div class="col-3 title">
            <label>{{ state.avaliacao2.data_avaliacao }}</label>
          </div>
          <div class="col-3 title"><label>Diferença</label></div>
        </div>
        <div class="divisor"></div>
        <div class="row">
          <div class="col-3"><label>Força Abdominal:</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.forca_abdominal }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.forca_abdominal }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label>Força MMII (IVC/cm³):</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.forca_mmii }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.forca_mmii }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label> Flexibilidade:</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.flexibilidade }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.flexibilidade }}</label>
          </div>
        </div>
      </div>

      <div
        class="tab-pane fade"
        id="comp_corp_cp"
        role="tabpanel"
        aria-labelledby="comp_corp_cp-tab"
      >
        <div class="row">
          <div class="col-3 title"><label>Composição Corporal</label></div>
          <div class="col-3 title">
            <label>{{ state.avaliacao1.data_avaliacao }}</label>
          </div>
          <div class="col-3 title">
            <label>{{ state.avaliacao2.data_avaliacao }}</label>
          </div>
          <div class="col-3 title"><label>Diferença</label></div>
        </div>
        <div class="divisor"></div>
        <div class="row">
          <div class="col-3"><label>IMC:</label></div>
          <div class="col-3">
            <label>
              {{
                state.avaliacao1.imc.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}</label
            >
          </div>
          <div class="col-3">
            <label>
              {{
                state.avaliacao2.imc.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}</label
            >
          </div>
          <div class="col-3">
            <label>{{ state.diferenca.imc }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label>% Gordura:</label></div>
          <div class="col-3">
            <label>
              {{
                state.avaliacao1.percentual_gordura.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}</label
            >
          </div>
          <div class="col-3">
            <label>
              {{
                state.avaliacao2.percentual_gordura.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}</label
            >
          </div>
          <div class="col-3">
            <label>
              {{
                state.diferenca.percentual_gordura.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}</label
            >
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label> Peso da Gordura:</label></div>
          <div class="col-3">
            <label>
              {{
                state.avaliacao1.peso_gordura.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}</label
            >
          </div>
          <div class="col-3">
            <label>
              {{
                state.avaliacao2.peso_gordura.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}</label
            >
          </div>
          <div class="col-3">
            <label>
              {{
                state.diferenca.peso_gordura.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}</label
            >
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label> Relação Cintura/Quadril:</label></div>
          <div class="col-3">
            <label>{{ state.avaliacao1.resultado_relacao_cq }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.avaliacao2.resultado_relacao_cq }}</label>
          </div>
          <div class="col-3">
            <label>{{ state.diferenca.resultado_relacao_cq }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><label> Peso em Excesso:</label></div>
          <div class="col-3">
            <label>
              {{
                state.avaliacao1.peso_excesso.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}</label
            >
          </div>
          <div class="col-3">
            <label>
              {{
                state.avaliacao2.peso_excesso.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}</label
            >
          </div>
          <div class="col-3">
            <label>{{
              state.diferenca.peso_excesso.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <span class="resultados">Resultado IMC 1:</span>
          </div>
          <div class="col-3">
            <label
              ><span class="resultados">{{
                state.avaliacao1.resultado_imc
              }}</span></label
            >
          </div>
          <div class="col-3">
            <span class="resultados">Resultado IMC 2:</span>
          </div>
          <div class="col-3">
            <label
              ><span class="resultados">{{
                state.avaliacao2.resultado_imc
              }}</span></label
            >
          </div>
        </div>
      </div>
    </div>

    <div
      class="tab-content"
      id="myTabContent"
      v-if="state.visualizarComparacao"
    >
      <div
        class="tab-pane fade"
        id="subcutanea"
        role="tabpanel"
        aria-labelledby="subcutanea-tab"
      ></div>
      <div
        class="tab-pane fade"
        id="teste"
        role="tabpanel"
        aria-labelledby="teste-tab"
      ></div>
      <div
        class="tab-pane fade"
        id="corporal"
        role="tabpanel"
        aria-labelledby="corporal-tab"
      ></div>
    </div>
    <div class="col text-end">
      <button
        type="button"
        class="btn btn_fechar_comparar"
        @click="fecharModalComparacao"
      >
        Fechar
      </button>
    </div>
  </Modal>

  <!--Modal alterar data e hora avaliação-->
  <Modal
    :open="state.alterarDataHora"
    @close="state.alterarDataHora = !state.alterarDataHora"
    :width="'800px'"
    style="padding: 15px; z-index: 100"
  >
    <h2 style="color: #000">
      Alterar Data / Hora da avaliação de {{ state.nome }}
    </h2>
    <div class="row">
      <div class="col-4">
        <p class="label_data">
          Data: <span class="data_ava">{{ state.data_avaliacao }}</span>
        </p>
      </div>
      <div class="col-4">
        <p class="label_hora">
          Hora: <span class="hora_ava">{{ state.hora_avaliacao }}</span>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-4" style="margin-top: 10px">
        <label class="nova_data">Nova Data:</label>
        <input
          type="text"
          class="form-control"
          v-model="state.nova_data_avaliacao"
        />
      </div>
      <div class="col-4" style="margin-top: 10px">
        <label class="nova_data">Nova Hora:</label>
        <input
          type="text"
          class="form-control"
          v-model="state.nova_hora_avaliacao"
        />
      </div>
    </div>
    <div style="margin-top: 15px" class="col text-end">
      <button type="button" class="btn btn_salvar">Salvar</button>
      <!--  <button @click="handleAgendarAvaliacao()">Agendar Avaliação</button> -->
      <button type="button" class="btn btn_cancelar" @click="cacelarAlteracao">
        Cancelar
      </button>
    </div>
  </Modal>
  <OffcanvasComponent ref="offcanvasRef" />
</template>
<script>
import { jsPDF } from "jspdf";
import { onMounted, reactive, ref } from "vue";
import BarraLateral from "../layout/Sistema/barraLateral";
import CorpoTabelaAvaliacao from "../components/CorpoTabelaAvaliacao";
import AvaliacaoLoading from "../components/CorpoTabelaAvaliacao/Loading.vue";
import topo from "../components/topo";
import services from "../services";
import Modal from "../components/EstruturaModal/EstruturaModal.vue";
import OffcanvasComponent from "../layout/Sistema/barraLateralMobile";
import { useStorage } from "vue3-storage";
import { mask } from "vue-the-mask";
export default {
  name: "tables",
  directives: { mask },
  components: {
    Modal,
    CorpoTabelaAvaliacao,
    AvaliacaoLoading,
    topo,
    BarraLateral,
    OffcanvasComponent,
  },

  setup(_, { emit }) {
    const offcanvasRef = ref(null);
    const isVisible = ref(false);
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");
    const state = reactive({
      //card-stats
      avaMes: false,
      avaMesPassado: false,
      totalAva: false,
      mediaMes: false,
      ///////////////
      modo: "visualizar",
      visualizarComparacao: false,
      isLoading: false,
      statusModalFazerAvaliacao: false,
      statusModalVisualizarAvaliacao: false,
      alterarDataHora: false,
      avaliacoes: [],
      codigo: "",
      currentClientType: "",
      hasError: false,
      avaliacao_selecionada_id: null,
      resultado_perc_gordura: "",
      sexo: "",
      nome_busca: "",
      qtd_por_pagina: 50,
      pagina_selecionada: 1,
      qtd_paginacao: 0,
      qtd_anterior: 0,
      avaliacao1: {
        imc: "",
      },
      avaliacao2: {
        imc: "",
      },
      diferenca: {},
      idade: {
        value: "",
        error: "",
      },
      altura: {
        value: "",
        error: "",
      },
      peso: {
        value: "",
        error: "",
      },
      imc: {
        value: "",
        error: "",
      },
      resultado_imc: {
        value: "",
        error: "",
      },
      ombro: {
        value: "",
        error: "",
      },
      torax: {
        value: "",
        error: "",
      },
      cintura: {
        value: "",
        error: "",
      },
      quadril: {
        value: "",
        error: "",
      },
      abdomem: {
        value: "",
        error: "",
      },
      coxa_dir: {
        value: "",
        error: "",
      },
      coxa_esq: {
        value: "",
        error: "",
      },
      braco_dir: {
        value: "",
        error: "",
      },
      braco_esq: {
        value: "",
        error: "",
      },
      antebraco_dir: {
        value: "",
        error: "",
      },
      antebraco_esq: {
        value: "",
        error: "",
      },
      panturrilha_dir: {
        value: "",
        error: "",
      },
      panturrilha_esq: {
        value: "",
        error: "",
      },
      relacao_cintura_quadril: {
        value: "",
        error: "",
      },
      resultado_relacao_cq: {
        value: "",
        error: "",
      },

      biceps_subc: {
        value: "",
        error: "",
      },
      triceptal_subc: {
        value: "",
        error: "",
      },
      subescapular_subc: {
        value: "",
        error: "",
      },
      suprailiaca_subc: {
        value: "",
        error: "",
      },
      torax_subc: {
        value: "",
        error: "",
      },
      axila_subc: {
        value: "",
        error: "",
      },
      abdominal_subc: {
        value: "",
        error: "",
      },
      coxa_subc: {
        value: "",
        error: "",
      },
      panturrilha_subc: {
        value: "",
        error: "",
      },
      forca_abdominal: {
        value: "",
        error: "",
      },

      forca_mmii: {
        value: "",
        error: "",
      },
      flexibilidade: {
        value: "",
        error: "",
      },
      peso_banco: "",
      percentual_gordura: {
        value: "",
        error: "",
      },
      peso_gordura: {
        value: "",
        error: "",
      },
      peso_massa_magra: {
        value: "",
        error: "",
      },
      percentual_massa_magra: {
        value: "",
        error: "",
      },
      taxa_metabolica: {
        value: "",
        error: "",
      },
      densidade_corporal: {
        value: "",
        error: "",
      },
      peso_ideal: {
        value: "",
        error: "",
      },
      peso_excesso: {
        value: "",
        error: "",
      },
    });
    //  onErrorCaptured(console.log);

    onMounted(() => {
      fetchAvaliacoes(), qtdAvaliacoesTotal();
      telaWeb();
    });

    /*    function console.log(error) {
      console.log(error);
      state.hasError = !!error;
    }*/
    function editarModo() {
      if (state.modo == "visualizar") {
        state.modo = "editar";
      } else {
        state.modo = "visualizar";
      }
    }
    async function buscarAvaliacoes() {
      if (state.codigo.length > 0) {
        try {
          const { data } = await services.avaliacoes.obterPorId({
            token: token,
            empresa_id: empresa_id,
            id: state.codigo,
          });
          var array = [];
          array.push(data);
          state.avaliacoes = array;
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          state.isLoading = true;
          const { data } = await services.avaliacoes.buscar({
            type: state.currentClientType,
            nome_cliente: state.nome_busca,
            token: token,
            empresa_id: empresa_id,
          });
          state.avaliacoes = data;
          //   state.pagination = data.pagination
          state.isLoading = false;
        } catch (error) {
          console.log(error);
        }
      }
    }
    async function fetchAvaliacoes() {
      try {
        state.isLoading = true;
        const { data } = await services.avaliacoes.getAll({
          type: state.currentClientType,
          quantidade: 20,
          quantidade_anterior: 0,
          token: token,
          empresa_id: empresa_id,
        });
        state.avaliacoes = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    }
    function handleAgendarAvaliacao() {
      modal.open({
        component: "ModalAgendarAvaliacoes",
      });
    }
    async function fazerComparacao(id_cliente) {
      const { data } = await services.avaliacoes.avaliacoesPorClienteId({
        token: token,
        empresa_id: empresa_id,
        cliente_id: id_cliente,
      });
      state.data_avalicao = data;
      state.statusModalComparacao = true;
      state.visualizarComparacao = false;
    }
    function fecharModalComparacao() {
      state.statusModalComparacao = false;
    }
    async function compararAvaliacao(id_cliente) {
      const { data } = await services.avaliacoes.compararAvaliacao({
        token: token,
        empresa_id: empresa_id,
        cliente_id: id_cliente,
        id_avaliacao_antiga: state.data_avaliacao_antiga,
        id_avaliacao_nova: state.data_avaliacao_nova,
      });
      state.visualizarComparacao = true;
      state.avaliacao1 = data.avaliacao_1;
      state.avaliacao2 = data.avaliacao_2;
      state.diferenca = data.diferenca;
    }
    async function visualizarAvaliacao(id) {
      try {
        const { data } = await services.avaliacoes.obterPorId({
          token: token,
          empresa_id: empresa_id,
          id,
        });
        state.statusModalVisualizarAvaliacao = true;
        state.avaliacao_selecionada_id = id;
        state.nome = data.nome_cliente;
        state.idade.value = data.idade;
        state.altura.value = data.altura;
        state.peso.value = data.peso;
        state.peso_banco = data.peso;
        state.imc.value = parseFloat(data.imc);
        state.sexo = data.sexo_cliente;
        //Dados de Circunferencia
        state.ombro.value = data.ombro;
        state.torax.value = data.busto;
        state.cintura.value = data.cintura;
        state.quadril.value = data.quadril;
        state.abdomem.value = data.abdomem;
        state.coxa_dir.value = data.coxa_dir;
        state.coxa_esq.value = data.coxa_esq;
        state.braco_dir.value = data.braco_dir;
        state.braco_esq.value = data.braco_esq;
        state.antebraco_dir.value = data.antebraco_dir;
        state.antebraco_esq.value = data.antebraco_esq;
        state.panturrilha_dir.value = data.panturrilha_dir;
        state.panturrilha_esq.value = data.panturrilha_esq;
        state.relacao_cintura_quadril.value = data.relacao_cintura_quadril;
        state.resultado_relacao_cq.value = data.resultado_relacao_cq;
        state.resultado_imc.value = data.resultado_imc;

        //Dados Subcutaneas
        state.biceps_subc.value = data.biceps_subc;
        state.triceptal_subc.value = data.triceptal_subc;
        state.subescapular_subc.value = data.subescapular_subc;
        state.suprailiaca_subc.value = data.suprailiaca_subc;
        state.torax_subc.value = data.torax_subc;
        state.axila_subc.value = data.axila_subc;
        state.abdominal_subc.value = data.abdominal_subc;
        state.coxa_subc.value = data.coxa_subc;
        state.panturrilha_subc.value = data.panturrilha_subc;

        //Composição
        state.densidade_corporal.value = data.densidade_corporal;
        state.percentual_gordura.value = data.percentual_gordura;
        state.resultado_perc_gordura = data.resultado_perc_gordura;
        state.peso_gordura.value = data.peso_gordura;
        state.peso_excesso.value = data.peso_excesso;
        state.sigla_imc = data.sigla_imc;

        console.log(data);
      } catch (error) {
        console.log(error);
      }
    }
    async function imprimirAvaliacao() {
      state.isLoading = true;
      console.log(token);
    }
    async function fazerAvaliacao(id) {
      try {
        const { data } = await services.avaliacoes.obterPorId({
          token: token,
          empresa_id: empresa_id,
          id,
        });
        state.statusModalFazerAvaliacao = true;
        //  state.idade.value = data.idade_atual;
        state.nome = data.nome_cliente;
        state.avaliacao_selecionada_id = id;
        state.sexo = data.sexo_cliente;

        var data2 = await services.clientes.obterPorId({
          token: token,
          empresa_id: empresa_id,
          id: data.cliente_id,
        });
        state.idade.value = data2.data.idade;
        console.log(data2);
      } catch (error) {
        console.log(error);
      }
    }
    async function alterarDataHora(id) {
      try {
        state.alterarDataHora = true;
        const { data } = await services.avaliacoes.obterPorId({
          token: token,
          empresa_id: empresa_id,
          id,
        });
        state.data_avaliacao = data.data_avaliacao;
        state.hora_avaliacao = data.hora_avaliacao;
        state.nome = data.nome_cliente;
      } catch (error) {
        console.log(error);
      }
    }
    async function salvaDataHora() {
      try {
        state.alterarDataHora = false;
        const data = await services.avaliacoes.remarcar({});
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    }
    async function cacelarAlteracao() {
      state.alterarDataHora = false;
    }
    async function deletarAvaliacao(id) {
      if (confirm("Deseja deletar esta avaliação?")) {
        try {
          state.isLoading = true;
          const { data } = await services.avaliacoes.deletarAvaliacao({
            token: token,
            empresa_id: empresa_id,
            id,
          });
          if (data) {
            fetchAvaliacoes();
          } else {
            alert("Erro ao deletar avaliação.");
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
    async function salvarAvaliacao() {
      try {
        state.isLoading = true;

        var retorno = await services.avaliacoes.salvarFeita({
          empresa_id: empresa_id,
          token: token,
          id: state.avaliacao_selecionada_id,
          altura: state.altura.value,
          peso: state.peso.value,
          idade: state.idade.value,
          imc: state.imc.value,
          resultado_imc: state.resultado_imc.value,
          ombro: state.ombro.value,
          busto: state.torax.value,
          cintura: state.cintura.value,
          quadril: state.quadril.value,
          coxa_dir: state.coxa_dir.value,
          coxa_esq: state.coxa_esq.value,
          braco_dir: state.braco_dir.value,
          braco_esq: state.braco_esq.value,
          antebraco_dir: state.antebraco_dir.value,
          antebraco_esq: state.antebraco_esq.value,
          panturrilha_dir: state.panturrilha_dir.value,
          panturrilha_esq: state.panturrilha_esq.value,
          abdomem: state.abdomem.value,
          biceps_subc: state.biceps_subc.value,
          rel_cintura_quadril: state.relacao_cintura_quadril.value,
          resultado_relacao_cq: state.resultado_relacao_cq.value,
          torax_subc: state.torax_subc.value,
          axila_subc: state.axila_subc.value,
          triceptal_subc: state.triceptal_subc.value,
          subescapular_subc: state.subescapular_subc.value,
          abdominal_subc: state.abdominal_subc.value,
          suprailiaca_subc: state.suprailiaca_subc.value,
          coxa_subc: state.coxa_subc.value,
          panturrilha_subc: state.panturrilha_subc.value,
          sexo: state.sexo,
          forca_abdominal: state.forca_abdominal,
        });
        //  console.log(retorno)
        if (retorno.data.verifica_erro == false) {
          state.statusModalFazerAvaliacao = false;
          state.isLoading = false;
          fetchAvaliacoes();
          return;
        }

        state.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    }

    function calcularIMC() {
      var altura = state.altura.value.replace(",", ".");
      var peso = state.peso.value.replace(",", ".");
      //calculo imc
      //   imc = peso * altura²
      var valor_imc = peso / Math.pow(altura, 2);
      state.imc.value = valor_imc;
      var imc = "";
      if (valor_imc < 16.0) {
        imc = "Magreza Severa";
      } else if (valor_imc > 16.0 && valor_imc < 19.99) {
        imc = "Magreza Moderada";
      } else if (valor_imc > 19.99 && valor_imc < 18.49) {
        imc = "Magreza Leve";
      } else if (valor_imc > 18.49 && valor_imc < 24.99) {
        imc = "Peso Normal";
      } else if (valor_imc > 24.99 && valor_imc < 29.99) {
        imc = "Sobrepeso";
      } else if (valor_imc > 29.99 && valor_imc < 34.99) {
        imc = "Obesidade Grau 1";
      } else if (valor_imc > 34.99 && valor_imc < 39.99) {
        imc = "Obesidade Grau 2";
      } else {
        imc = "Obesidade Grau 3";
      }
      state.resultado_imc.value = imc;
    }

    function calcularRelacaoCinturaQuad() {
      var cintura = state.cintura.value.replace(",", ".");
      var quadril = state.quadril.value.replace(",", ".");
      //calculo imc
      //imc = peso * altura²
      var rcq = cintura / quadril;

      state.relacao_cintura_quadril.value = rcq.toFixed(2).replace(".", ",");

      if (state.sexo == "masculino") {
        if (rcq > 0.9) {
          //  $("#ok").hide();
          //  $("#fator_risco").show();
          state.resultado_relacao_cq.value = "Fator de Risco";
        } else {
          //  $("#fator_risco").hide();
          //  $("#ok").show();
          state.resultado_relacao_cq.value = "Relação Ok";
          //  $("#resultado_rel_cq").val("Relação Ok");
        }
      } else {
        if (rcq > 0.85) {
          //  $("#ok").hide();
          //  $("#fator_risco").show();
          state.resultado_relacao_cq.value = "Fator de Risco";
        } else {
          //  $("#fator_risco").hide();
          //  $("#ok").show();
          state.resultado_relacao_cq.value = "Relação Ok";
        }
      }
    }

    async function calcularSubcutanteas() {
      try {
        state.isLoading = true;
        const { data } = await services.avaliacoes.calcularSubcutaneas({
          id: state.avaliacao_selecionada_id,
          token: token,
          empresa_id: empresa_id,
          torax_subc: state.torax_subc.value,
          axila_subc: state.axila_subc.value,
          triceptal_subc: state.triceptal_subc.value,
          subescapular_subc: state.subescapular_subc.value,
          abdominal_subc: state.abdominal_subc.value,
          suprailiaca_subc: state.suprailiaca_subc.value,
          coxa_subc: state.coxa_subc.value,
          sexo: state.sexo,
          idade: state.idade.value,
          peso: state.peso.value,
        });
        console.log(data);
        state.densidade_corporal.value = data.densidade_corporal;
        state.percentual_gordura.value = data.percentual_gordura;
        state.peso_gordura.value = data.gordura_absoluta;
        state.peso_massa_magra.value = data.massa_magra;
        state.percentual_massa_magra.value = data.percentual_massa_magra;
        state.peso_ideal.value = data.peso_ideal;
        state.peso_excesso.value = data.peso_excesso;
      } catch (error) {
        console.log(error);
      }
    }

    function cancelarAvaliacao() {
      state.statusModalFazerAvaliacao = false;
      limpar_avaliacao();
    }

    function limpar_avaliacao() {
      state.idade.value = "";
      state.idade.error = "";

      state.altura.value = "";
      state.altura.error = "";

      state.peso.value = "";
      state.peso.error = "";

      state.imc.value = "";
      state.imc.error = "";

      state.resultado_imc.value = "";
      state.resultado_imc.error = "";

      state.ombro.value = "";
      state.ombro.error = "";

      state.torax.value = "";
      state.torax.error = "";

      state.cintura.value = "";
      state.cintura.error = "";

      state.quadril.value = "";
      state.quadril.error = "";

      state.abdomem.value = "";
      state.abdomem.error = "";

      state.coxa_dir.value = "";
      state.coxa_dir.error = "";

      state.coxa_esq.value = "";
      state.coxa_esq.error = "";

      state.braco_dir.value = "";
      state.braco_dir.error = "";

      state.braco_esq.value = "";
      state.braco_esq.error = "";

      state.antebraco_dir.value = "";
      state.antebraco_dir.error = "";

      state.antebraco_esq.value = "";
      state.antebraco_esq.error = "";

      state.panturrilha_dir.value = "";
      state.panturrilha_dir.error = "";

      state.panturrilha_esq.value = "";
      state.panturrilha_esq.error = "";

      state.relacao_cintura_quadril.value = "";
      state.relacao_cintura_quadril.error = "";

      state.resultado_relacao_cq.value = "";
      state.resultado_relacao_cq.error = "";

      state.biceps_subc.value = "";
      state.biceps_subc.error = "";

      state.triceptal_subc.value = "";
      state.triceptal_subc.error = "";

      state.subescapular_subc.value = "";
      state.subescapular_subc.error = "";

      state.suprailiaca_subc.value = "";
      state.suprailiaca_subc.error = "";

      state.torax_subc.value = "";
      state.torax_subc.error = "";

      state.axila_subc.value = "";
      state.axila_subc.error = "";

      state.abdominal_subc.value = "";
      state.abdominal_subc.error = "";

      state.coxa_subc.value = "";
      state.coxa_subc.error = "";

      state.panturrilha_subc.value = "";
      state.panturrilha_subc.error = "";

      state.forca_abdominal.value = "";
      state.forca_abdominal.error = "";

      state.forca_mmii.value = "";
      state.forca_mmii.error = "";

      state.flexibilidade.value = "";
      state.flexibilidade.error = "";

      state.percentual_gordura.value = "";
      state.percentual_gordura.error = "";

      state.peso_gordura.value = "";
      state.peso_gordura.error = "";

      state.peso_massa_magra.value = "";
      state.peso_massa_magra.error = "";

      state.taxa_metabolica.value = "";
      state.taxa_metabolica.error = "";
    }

    async function telaWeb() {
      try {
        const telaweb = await services.avaliacoes.telaWeb({
          token: token,
          empresa_id: empresa_id,
        });

        console.log(telaweb.data.total_avaliacoes_mes);
        state.qtdAvaliacoesMes = telaweb.data.total_avaliacoes_mes;
        state.qtdAvaliacoesMesAnterior =
          telaweb.data.total_avaliacoes_mes_passado;
        state.mediaMensal = telaweb.data.media;
      } catch (error) {
        console.log(error);
      }
    }

    async function qtdAvaliacoesTotal() {
      try {
        const qtdAvaliacoesTotal = await services.avaliacoes.qtdAvaliacoesTotal(
          {
            token: token,
            empresa_id: empresa_id,
          }
        );
        state.qtdAvaliacoesTotal = qtdAvaliacoesTotal.data;
        state.qtd_paginacao = Math.ceil(
          parseInt(state.qtdAvaliacoesTotal) / state.qtd_por_pagina
        );
      } catch (error) {
        console.log(error);
      }
    }

    async function updateModal() {
      try {
        state.isLoading = true;
        if (!state.id.value) {
          const { errors } = await services.avaliacoes.alterarModal({
            token: token,
            empresa_id: empresa_id,
            id: state.avaliacao_selecionada_id,
            altura: state.altura.value,
            peso: state.peso.value,
            ombro: state.ombro.value,
            busto: state.torax.value,
            cintura: state.cintura.value,
            quadril: state.quadril.value,
            coxa_dir: state.coxa_dir.value,
            coxa_esq: state.coxa_esq.value,
            braco_dir: state.braco_dir.value,
            braco_esq: state.braco_esq.value,
            antebraco_dir: state.antebraco_dir.value,
            antebraco_esq: state.antebraco_esq.value,
            panturrilha_dir: state.panturrilha_dir.value,
            panturrilha_esq: state.panturrilha_esq.value,
            abdomem: state.abdomem.value,
            biceps_subc: state.biceps_subc.value,
            rel_cintura_quadril: state.relacao_cintura_quadril.value,
            resultado_relacao_cq: state.resultado_relacao_cq.value,
            torax_subc: state.torax_subc.value,
            axila_subc: state.axila_subc.value,
            triceptal_subc: state.triceptal_subc.value,
            subescapular_subc: state.subescapular_subc.value,
            abdominal_subc: state.abdominal_subc.value,
            suprailiaca_subc: state.suprailiaca_subc.value,
            coxa_subc: state.coxa_subc.value,
            panturrilha_subc: state.panturrilha_subc.value,
          });
          if (!errors) {
            state.modo == "visualizar";
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    const openOffcanvas = () => {
      // Estado para controlar a visibilidade do offcanvas
      console.log(isVisible.value);
      //offcanvasRef.value.showOffcanvas();
      if (isVisible.value) {
        offcanvasRef.value.hideOffcanvas();
      } else {
        offcanvasRef.value.showOffcanvas();
      }
    };
    return {
      state,
      compararAvaliacao,
      fazerComparacao,
      fecharModalComparacao,
      fetchAvaliacoes,
      handleAgendarAvaliacao,
      buscarAvaliacoes,
      fazerAvaliacao,
      salvarAvaliacao,
      imprimirAvaliacao,
      cancelarAvaliacao,
      calcularIMC,
      calcularRelacaoCinturaQuad,
      calcularSubcutanteas,
      visualizarAvaliacao,
      alterarDataHora,
      salvaDataHora,
      cacelarAlteracao,
      emit,
      deletarAvaliacao,
      jsPDF,
      editarModo,
      updateModal,
      offcanvasRef,
      openOffcanvas,
    };
  },
};
</script>
<style scoped>
.nav-item {
  font-size: 10pt;
}
.avaliacao_de {
  font-size: 18pt !important;
}
.nav-tabs .nav-link {
  padding: 5px;
}
h4 {
  font-size: 14pt;
}
.bv {
  font-weight: bold;
  font-size: 18pt;
  color: #162b48;
  margin-bottom: 0;
}

.tabela_modal {
  margin-top: 25px;
}

.label_data {
  font-size: 14pt;
  color: #000;
}

.label_hora {
  font-size: 14pt;
  color: #000;
}

.nova_data {
  font-size: 13pt;
}

.title {
  color: #000;
}

.divisor {
  width: 100%;
  height: 3px;
  background-color: #aede00 !important;
  border-color: #aede00 solid 1px !important;
  border-radius: 5px;
}

.form-group {
  margin-bottom: 0.4rem;
}

.resultados {
  color: #0404fe;
  font-weight: bold;
}

.avaliacao {
  color: #000;
  margin-top: 10px;
}

.arow {
  margin-right: 5px;
  font-size: 0.7rem !important;
  color: #034aa6;
  font-weight: 700;
}

.text1 {
  color: #034aa6;
  font-size: 7pt !important;
  font-weight: bold;
}

.num {
  margin-top: -15px;
}

.quad {
  height: 169px;
  width: 100%;
}

.card .table th {
  padding: 15px !important;
}

.btn_avalicao {
  text-align: right;
}

.btn:hover {
  transform: none;
}

.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
  margin-left: 10px;
}

.btn_salvar:hover {
  transform: none;
  color: #fff;
}

.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
  margin-left: 10px;
}

.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}

.btn_compara {
  color: #000;
  background-color: #aede00;
  border: #aede00 solid 1px;
  margin-top: 24px;
}

.btn_compara:hover {
  transform: none;
  color: #000;
}

.btn_fechar_comparar {
  color: #fff;
  background-color: #788596;
  border: #788596 solid 2px;
  margin-top: 50px;
}

.btn_fechar_comparar:hover {
  transform: none;
  color: #fff;
}

.circle {
  border: #034aa6 solid 1px !important;
  background-color: #034aa6 !important;
}

.btn_edit {
  cursor: pointer;
  border: none;
  background: transparent;
}

.space_btn {
  text-align: right;
}

.btn_busca {
  background-color: #aede00 !important;
  border-color: #aede00 solid 1px !important;
  color: rgb(0, 0, 0) !important;
  border-radius: 11px !important;
  border-color: #aede00 !important;
  margin-top: 10px;
  padding: 6px 8px;
  margin-left: 5px;
  font-weight: 500;
  cursor: pointer;
}

.btn_cadastra_ava {
  background-color: rgb(3, 74, 166);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(3, 74, 166);
  border-radius: 10px;
  font-size: 9pt;
  padding: 9px;
  font-weight: 700;
  width: 100%;
  margin-top: 10px;
}

.btn_fechar_comparar:hover {
  background-color: #788596;
}

.btn_fechar_comparar:hover {
  background-color: #788596;
}

.page-item.active .page-link {
  border: #034aa6 solid 1px;
  background-color: #034aa6;
}

/** #788596 */
@media (max-width: 719px) {
  h1 {
    font-size: 14pt;
  }

  .btn_salvar {
    width: 48%;
  }

  .btn_cancelar {
    width: 48%;
  }

  .btn_compara {
    width: 100%;
  }

  .btn_fechar_comparar {
    width: 100%;
  }

  .btn_busca {
    width: 100%;
    text-align: center;
  }
}

@media (min-width: 720px) {
  .btn_busca {
    margin-top: 0px;
  }

  .btn_cadastra_ava {
    margin-top: 0;
    width: 70%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .btn_cadastra_ava {
    background-color: rgb(3, 74, 166);
    color: rgb(255, 255, 255);
    border: 1px solid rgb(3, 74, 166);
    border-radius: 10px;
    font-size: 9pt;
    padding: 9px;
    font-weight: 700;
    width: 100%;
    margin-top: 3px;
  }

  #tit_codigo {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #tit_codigo {
    display: none;
  }

  .avaliacao {
    font-size: 11pt;
  }
}

@media (min-width: 1000px) and (max-width: 1400px) {
  .espaco_md {
    padding-left: 10rem;
  }
}

@media (min-width: 768px) and (max-width: 999px) {
  .espaco_md {
    padding-left: 4rem;
  }
}

@media (max-width: 719px) {
  .btn_salvar {
    width: 100%;
    margin-bottom: 10px;
    margin-left: 0px;
  }
  .btn_cancelar {
    width: 100%;
    margin-left: 0px;
  }
}
</style>