<template>
  <div
    style="width: 100vw; height: 100vh"
    :style="{ 'background-color': state.cor1 }"
  >
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <div
            class="card"
            style="margin-top: 40px"
            :style="{ 'background-color': state.cor2 }"
          >
            <div style="position: relative; margin-bottom: 20px">
              <img src="/img/centrion.jpg" style="width: 100%; height: 180px" />
              <div
                class="logo"
                style="
                  width: 150px;
                  height: 150px;
                  background-color: #fff;
                  margin-top: -100px;
                  position: absolute;
                  z-index: 100;
                  text-align: center;
                  border-radius: 50%;
                  left: 50%;
                  margin-left: -75px;
                  border: #000 solid 2px;
                "
              >
                <img
                  src=" /img/logo_sifit.png"
                  style="width: 70%; margin-top: 55px"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <h4 :style="{ color: state.cor1 }">
                  Agende sua aula experimental
                </h4>
                <h5 :style="{ color: state.cor1 }">Seus Dados</h5>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label :style="{ color: state.cor1 }">Nome</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.nome"
                    :style="{
                      'background-color': state.cor2,
                      color: state.cor1,
                      borderColor: state.cor1,
                    }"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-7">
                <div class="form-group">
                  <label :style="{ color: state.cor1 }">E-mail</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.email"
                    :style="{
                      'background-color': state.cor2,
                      color: state.cor1,
                      borderColor: state.cor1,
                    }"
                  />
                </div>
              </div>
              <div class="col-sm-5">
                <div class="form-group">
                  <label :style="{ color: state.cor1 }"
                    >Telefone / Whatsapp</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.telefone"
                    v-mask-phone.br
                    :style="{
                      'background-color': state.cor2,
                      color: state.cor1,
                      borderColor: state.cor1,
                    }"
                  />
                </div>
              </div>
            </div>
            <h5 :style="{ color: state.cor1 }">Seu Agendamento</h5>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label :style="{ color: state.cor1 }">Modalidade</label>
                  <select
                    class="form-control"
                    v-model="state.modalidade"
                    :style="{
                      'background-color': state.cor2,
                      color: state.cor1,
                      borderColor: state.cor1,
                    }"
                    @change="selecionaModalidade($event.target.value)"
                  >
                    <option
                      :value="modalidade.id"
                      v-for="modalidade in state.modalidades"
                      :key="modalidade.id"
                    >
                      {{ modalidade.nome }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label :style="{ color: state.cor1 }">Data</label>
                  <!--    <Datepicker
                    v-model="state.data_final"
                    :locale="pt"
                    inputFormat="dd/MM/yyyy"
                    :style="{
                      'background-color': state.cor2,
                      color: state.cor1,
                      border: 'solid 1px',
                      borderColor: state.cor1,
                    }"
                    style="
                      color: '#505050';
                      border-radius: 7px;

                      transition: 0.5s;
                      width: 100%;
                      padding: 8px;
                    "
                  />-->
                  <select
                    class="form-control"
                    :style="{
                      'background-color': state.cor2,
                      color: state.cor1,
                      borderColor: state.cor1,
                    }"
                  >
                    <option v-for="horario in state.horarios" :key="horario">
                      {{ horario.data }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label :style="{ color: state.cor1 }">Hora</label>
                  <select
                    class="form-control"
                    :style="{
                      'background-color': state.cor2,
                      color: state.cor1,
                      borderColor: state.cor1,
                    }"
                  >
                    <option value="1">10:00</option>
                    <option value="2">11:00</option>
                    <option value="3">12:00</option>
                  </select>
                </div>
              </div>
            </div>
            <button
              class="btn btn_agendar"
              :style="{ 'background-color': state.cor1, color: state.cor2 }"
              @click="handleSubmit"
            >
              Agendar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import services from "../../services";
//import Datepicker from "vue3-datepicker";
import { pt } from "date-fns/locale";
import { useRouter } from "vue-router";
export default {
  components: {
    // Datepicker,
  },
  setup() {
    var empresa_id = 1;
    const router = useRouter();
    var empresa_hash = router.currentRoute._value.params.hash;
    const state = reactive({
      nome: "",
      email: "",
      telefone: "",
      modalidade: "",
      cor1: "#ccc",
      cor2: "#000",
      modalidades: [],
    });

    onMounted(() => {
      fetchEmpresa();
      //     fetchModalidade();
    });

    async function fetchEmpresa() {
      try {
        state.isLoading = true;
        let data = await services.empresa.porHash(empresa_hash);
        console.log(data);
        state.modalidades = data.data.grupos_aula_experimental;
      } catch (error) {
        console.log(error);
      }
    }

    async function selecionaModalidade(grupo_id) {
      try {
        state.isLoading = true;
        let data = await services.aulaExperimental.horariosPorGrupo(
          grupo_id,
          empresa_id
        );
        var horarios = [];
        for (let index = 0; index < data.data.length; index++) {
          var array_dias_semana = criarArrayComString(
            data.data[index].dias_semana
          );
          for (let i2 = 0; i2 < array_dias_semana.length; i2++) {
            var horario = {};
            horario.turma_id = data.data[index].id;
            //  horario.data = getNextWeekday(n_dia).toDateString();

            const n_dia = array_dias_semana[i2];
            horario.data = formatDateToBrazilian(getNextWeekday(n_dia));
            horarios.push(horario);
          }
          //  console.log(criarArrayComString(data.data[index].dias_semana));
          //  console.log(getNextWeekday(3));
        }
        state.horarios = horarios;
        //       state.
        //        state.modalidades = ;
      } catch (error) {
        console.log(error);
      }
    }
    ///api/buscar-horarios-por-plano

    async function handleSubmit() {
      try {
        state.isLoading = true;
        let data = await services.grupos.listarGrupoAulaExperimental({
          empresa_id,
        });
        state.modalidades = data.data;
      } catch (error) {
        console.log(error);
      }
    }
    function getNextWeekday(targetDay) {
      // Definindo os dias da semana onde 0 é domingo e 6 é sábado
      //  const daysOfWeek = [0, 1, 2, 3, 4, 5, 6]; // [domingo, segunda-feira, ..., sábado]

      if (targetDay < 0 || targetDay > 6) {
        throw new Error("O dia deve estar entre 0 (domingo) e 6 (sábado).");
      }

      // Obtendo a data atual
      const today = new Date();

      // Calculando o dia da semana atual
      const currentDay = today.getDay();

      // Calculando a diferença entre o dia desejado e o dia atual
      let daysUntilNext = (targetDay - currentDay + 7) % 7;
      if (daysUntilNext === 0) {
        daysUntilNext = 7; // Se for hoje o dia desejado, queremos a próxima ocorrência
      }

      // Adicionando os dias à data atual
      today.setDate(today.getDate() + daysUntilNext);

      return today;
    }

    function criarArrayComString(input) {
      // Passo 1: Divida a string em substrings usando vírgula como separador
      const stringArray = input.split(",");

      // Passo 2: Filtre valores vazios (se houver) e converta cada valor para número
      const numberArray = stringArray
        .filter((value) => value.trim() !== "") // Remove strings vazias
        .map((value) => Number(value)); // Converte para número

      return numberArray;
    }

    function formatDateToBrazilian(date) {
      // Obter o dia, mês e ano
      const day = date.getDate();
      const month = date.getMonth() + 1; // Adicionar 1 porque os meses começam do 0
      const year = date.getFullYear();

      // Adicionar zero à esquerda se necessário
      const dayFormatted = day < 10 ? `0${day}` : day;
      const monthFormatted = month < 10 ? `0${month}` : month;

      // Montar a data no formato DD/MM/YYYY
      return `${dayFormatted}/${monthFormatted}/${year}`;
    }

    return {
      //  fetchModalidade,
      selecionaModalidade,
      handleSubmit,
      state,
      pt,
    };
  },
};
</script>

<style>
.card {
  padding: 15px;
}
label {
  color: #000;
}
h3,
h4,
h5 {
  color: #000;
}
</style>