
export default httpClient => ({
  listar: async ({ token, empresa_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.get('/bancos/' + empresa_id,  { headers });
    return { data: response.data }
  },
  obterPorId: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/seleciona/banco/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },

  save: async ({ empresa_id, token, nome, codigo }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/cria/banco/' + empresa_id, {
      token,
      nome,
      codigo
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  update: async ({ empresa_id, token, id, nome, codigo }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/edita/banco/' + empresa_id, {
      token,
      id,
      nome,
      codigo
    }, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  deletar: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/deleta/banco/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },

})